import { Checkbox, DatePicker, Input } from 'antd';
import { Tooltip } from "devextreme-react";
import 'devextreme/dist/css/dx.light.css';
import { useEffect, useState } from "react";
import { FaBinoculars } from 'react-icons/fa';
import edit from '../../../../../../assets/edit.png';
import newfile from '../../../../../../assets/new.png';
import swap from '../../../../../../assets/swap.png';
import close from '../../../../../../assets/close.png';
import { AlertSMS, AppraiseSettings, DeleteTask, TaskSettings } from "../../../../../accessories/custom-toolbars/widgets/customToolbarModals";
import printer from '../../../../../../assets/printer.png';
import reset from '../../../../../../assets/refresh-small.png';
import { saveModalData } from '../../../../../accessories/component_infos';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';
import { setGeneralValue, settask_form_disable, settask_footer_update } from '../../../../../../features/generalSlice';
import { task_form_disable, task_footer_update } from '../../../../../../features/Task&Todo/Task/TaskDetails';
import { ModalTemplate } from '../../../../../../templates/modal';

interface props{
    toggler ?: ()=> void
    handleUpdate?: () => void //update handler
    searchTextOnchange?: (value: string) => void //search text change
    startDateChanged?: (value: any) => void //start date change
    endDateChanged?: (value: any) => void //end date change
    checkOnChange?: (value: any) => void //checkbox change
    handleFind?: () => void //find handler
    handleRefresh?: () => void //refresh main grid
    handlePrint?: () => void // handle print functionality
    useCallBackFunc?: boolean
    withDates?: boolean //add dates to the toolbar?
    toggleNew?: boolean
    toggleUpdate?: boolean
    handleEdit?: (value: boolean) => void
    
}



export const CapacitorBankToolbar = ({toggler,handleRefresh, handlePrint, handleFind, checkOnChange}:props)=>{

    const { Search } = Input;

    const dateFormat = " DD MMM YYYY";
    const dispatch = useDispatch()
     // from redux


     const disablex = useSelector((state: RootState) => state.addTask.taskFormStatus);
     const updateMode = useSelector((state: RootState) => state.addTask.taskFooterUpdate);
     const selectedRecord = useSelector((state: RootState) => state.addTask.selectedRecord)
 
     // relative to the new button
     const handleNew = () => {
         dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
 
         dispatch(task_form_disable(false));
         dispatch(settask_form_disable(false));
 
         //  this destroys the update mode state
         dispatch(task_footer_update(false));
         dispatch(settask_footer_update(false))
 
         //clear data to redux
         dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
     };
 
     const handleCancel = () => {
         dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
         dispatch(task_form_disable(true));
         dispatch(settask_form_disable(true))
         // dispatch(update_route_state(false))
         dispatch(task_footer_update(false));
         dispatch(settask_footer_update(false))
 
 
         // dispatch(settask_footer_update(false))
         dispatch(setGeneralValue({ expr: 'updateMode', value: false }));
 
         // NO ONE SHOULD DELETE THIS !!!!
         // handleCallbackCancel!();
     };
   

    const [switchiconMsg, setSwitchiconMsg] = useState<string>('Personal')
    const [isActive, setIsActive] = useState<boolean>(false)
    const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false)
    const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false)
    const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false)
    const size:number = 16;

    const [refreshValue, setRefreshValue] = useState(false);
    const [showModalDisableButton, setshowModalDisableButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState<any>(saveModalData);
    const [icon, setIcon] = useState<'warning' | 'success' | 'question'>();
    const [updatingstatus, setUpdatingstatus] = useState(false);
    const [showCancel, setshowCancel] = useState(false);
    const [Active, setActive] = useState(false);
    const [refreshx, setRefreshx] = useState(false);

    //handle edit
    const handleUpdate = () => {
        console.log(Object.keys(selectedRecord).length)
        if (Object.keys(selectedRecord).length === 0) {
            // dispatch(task_form_disable(true));
            setIcon('warning')
            setshowModalDisableButton(true);
            setshowCancel(false)
            setModalData({ message: "No record selected", title: "Invalid record", okText: "Ok" });
            setOpenModal(true);
        }

        else {
            //enable forms
            dispatch(task_form_disable(true));

            //enable Update Mode
            dispatch(task_footer_update(true))
        }
    }


  // Three state checkboxes
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | null>(null); //actual value to use

  useEffect(() => {
      checkOnChange && checkOnChange(checkValue);
  }, [checkValue])

  const onChange = (value: boolean) => {
      if (indeterminate === true) {
          setCheckedBox(true);
          setIndeterminate(false);
          setCheckValue(true);
      }
      else if (!indeterminate && checkedBox) {
          setCheckedBox(false);
          setIndeterminate(false);
          setCheckValue(false)
      }
      else if (!indeterminate && !checkedBox) {
          setCheckedBox(false);
          setIndeterminate(true);
          setCheckValue(null)
      }
  };


    return (
        <div style={{borderBottomWidth : '1px'}} className="w-full h-full flex  justify-between border-gray-200 mb-1">
            <div className="">
            <ModalTemplate icon_width={modalData.iconSize === 'large' ? 100 : 50} icon={icon} cancelText={modalData.cancelText}
                    disableCancel={!showCancel}
                    cancelHandler={() => { setOpenModal(false) }} open={openModal} okHandler={() => { setOpenModal(false) }}
                    message={modalData.message} okText={modalData.okText} title={modalData.title} />
            <ul  className="flex justify-evenly w-full items-center h-full py-0.5">
            {(disablex) ?
                            <li id="newRecord" onClick={() => handleNew()} style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={newfile} />New </span>
                                <Tooltip
                                    target="#newRecord"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">New Record</p>
                                </Tooltip>
                            </li>
                            :
                            <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                                <Tooltip
                                    target="#close-cancel"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Cancel</p>
                                </Tooltip>
                            </li>
                        }
                        {!updateMode ?
                            <li id="editRecordst" onClick={() => { handleUpdate() }} style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100" src={edit} />Edit </span>
                                <Tooltip
                                    target="#editRecordst"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Edit Record</p>
                                </Tooltip>
                            </li>
                            :
                            <li id="close-cancel" onClick={() => handleCancel()} style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center" >
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="pr-1 " src={close} />Cancel </span>
                                <Tooltip
                                    target="#close-cancel"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Cancel</p>
                                </Tooltip>
                            </li>
                        }

                    <li id="switch" onClick={toggler} style={{borderWidth : '1px'}}  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center">
                    <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"> <img className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100" src={swap} />Switch </span> 
                        <Tooltip
                            target="#switch"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                            >
                            <p className="h-2">Switch interface / table view</p>
                        </Tooltip>
                    </li>
                    
                  
                       
                        
                </ul>      

            </div>

            <div className="flex">
                        <ul className=" flex items-center">

                            <div>Search Text</div>
                            <li className="px-1">
                                <Input size="small" allowClear placeholder="" style={{ width: 180 }} />
                            </li>
                            <li className="px-0.5">
                                <DatePicker size="small" placeholder="Start Date" style={{ width: 125 }} format={dateFormat} />
                            </li>
                            <li className="px-0.5">
                                <DatePicker size="small" placeholder="End Date" style={{ width: 125 }} format={dateFormat} />
                            </li>
                            <li id="status" className="hover:cursor-pointer mr-1  text-xs px-1 py-1   text-gray-600 rounded flex items-center">
                                <Checkbox
                                    checked={checkedBox}
                                    indeterminate={indeterminate}
                                    onChange={(checkValue: any) => {
                                        onChange(checkValue);
                                    }}
                                />
                                <Tooltip
                                    target="#status"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Show Status</p>
                                </Tooltip>
                            </li>
                            <li onClick={() => {
                                if (handleFind) {
                                    handleFind();
                                } else {

                                }
                            }} id="findst" style={{ borderWidth: '1px' }} className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center">
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 "><FaBinoculars size={15.5} color="#007AFF" /></span>
                                <Tooltip
                                    target="#findst"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Find</p>
                                </Tooltip>
                            </li>

                            {/* refresh */}
                            <li
                                onClick={() => {
                              handleRefresh &&      handleRefresh!();

                                }}
                                id="refreshst" style={{ borderWidth: '1px' }}
                                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center">
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 "> <img className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={reset} /></span>
                                <Tooltip
                                    target="#refreshst"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Refresh</p>
                                </Tooltip>
                            </li>

                            <li
                                onClick={() => handlePrint && handlePrint()}
                                id="printst"
                                style={{ borderWidth: '1px' }}
                                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center">
                                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 "> <img className="dark:bg-slate-900 dark:text-gray-100 duration-100" src={printer} /></span>
                                <Tooltip
                                    target="#printst"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                    hideOnOutsideClick={true}
                                >
                                    <p className="">Print/Export</p>
                                </Tooltip>
                            </li>

                        </ul>

                        <DeleteTask isActive={isActive} setIsActive={setIsActive} />
                        <TaskSettings SettingsisActive={SettingsisActive} SettingssetIsActive={SettingssetIsActive} />
                        <AlertSMS AlertSMSisActive={AlertSMSisActive} AlertSMSsetIsActive={AlertSMSsetIsActive} />
                        <AppraiseSettings AppraiseisActive={AppraiseisActive} AppraisesetIsActive={AppraisesetIsActive} />
                    </div>
        </div>
    )
};

const data = [
    {
        label: 'Group',
       
      },
      {
        label: 'Staff Name',
       
      },
      {
        label: 'Job Title',
       
      },
      {
        label: 'Department',
       
      },
      {
        label: 'Section',
       
      },
      {
        label: 'Unit',
       
      },
      {
        label: 'Location',
       
      },
]

// const data = [
//     { id: 1, text: "Task",  },
//     { id: 2, text: "Progress Activity",  },
//     { id: 3, text: "Task & Start Date",  },
//     { id: 4, text: "Task & Completion Date",  },
//     { id: 5, text: "Task & Due Date", },
//     { id: 6, text: "Progress Activity & Start Date", },
//     { id: 7, text: "Progress Activity & Completion Date",  },
//     { id: 8, text: "Progress Activity & Due Date", },
//     { id: 9, text: "Status", },
//     { id: 10, text: "Status & Start Date", },
//     { id: 11, text: "Status & Completion Date", },
//     { id: 12, text: "Status & Due Date", },
//     { id: 13, text: "Priority", },
//     { id: 14, text: "Priority & Start Date", },
//     { id: 15, text: "Priority & Completion Date", },
//     { id: 16, text: "Priority & Due Date", },
//     { id: 17, text: "Assigned By", },
//     { id: 18, text: "Assigned By & Start Date", },
//     { id: 19, text: "Assigned By & Completion Date", },
//     { id: 20, text: "Assigned By & Due Date", },
//     { id: 21, text: "Assigned To", },
//     { id: 22, text: "Assignred To & Start Date", },
//     { id: 23, text: "Assigned To & Completion Date", },
//     { id: 24, text: "Assigned To & Due Date", },
// ];