import { useCallback, useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import { VehicleConditionToolbar } from "./vehicleConditions-toolbar";
import CustomLoader from "../../../../../../accessories/custom-loader";
import * as React from "react";
import {
  formModalStates,
  searchCriteriaTypes,
  searchTextTypes,
} from "../../../../../../human-resource/setups/data/types/selectsTypes";
import {
  currentDate,
  startingDate,
} from "../../../../../../accessories/component_infos";
import { ReturnReport } from "./returnReport";
import { FormModal } from "../../../../../../accessories/formModal";
import VehicleConditionDetails from "./vehicleConditions-details";
import { useAxiosService } from "../../../../../../../general/requestConfig";
import { GetAsmTmsPrivilegesInterface } from "../../../../../../../interfaces";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";

type stateTypes = {
  openFormModal: boolean;
};

const VehicleConditions = () => {
  const fetcher = useAxiosService();
  const [_, userId] = useAccountInfo();
  const [showTable, setShowTable] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };
  const [states, setStates] = useState<stateTypes>({
    openFormModal: false,
  });
  const { openFormModal } = states;
  const updateStates = useCallback((key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  }, []);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [Active, setActive] = useState<boolean | undefined>(undefined);
  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [selectedTopDatax, setSelectedTopDatax] = useState<any>();
  const [getCurrentDate, setGetCurrentdate] = useState<any>();
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({ pageSize: 15, pageNumber: 1 });

  // const [searchUrl, setSearchUrl] = useState(
  //   `AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn?PageSize=${
  //     pagination.pageSize
  //   }&PageNumber=${
  //     pagination.pageNumber
  //   }&startDate=${startDate}&endDate=${endDate}&criteriaIndex=${
  //     searchCriteria.index
  //   }&search=${searchText.text}&active=${""}`
  // );
  // const [table_one_data, table_one_error, table_one_loading] = useFetch(
  //   searchUrl,
  //   refresh,
  //   "vcrIdpk"
  // );
  const [refreshAssignmentGrid, setRefreshAssignmentGrid] =
    React.useState<boolean>(false);
  const [firstTable, setFirstTable] = React.useState<any>([]);
  const [firstLoading, setFirstLoading] = React.useState<boolean>(false);
  const [formPriviledges, setPriviledges] =
    React.useState<GetAsmTmsPrivilegesInterface>();

  React.useEffect(() => {
    setFirstLoading(true);
    fetcher({
      method: "GET",
      url: `AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn`,
      params: {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        startDate: startDate,
        endDate: endDate,
        criteriaIndex: searchCriteria.index,
        search: searchText.text,
        active: Active,
      },
    }).then((res) => setFirstTable(res));
    setFirstLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endDate,
    pagination.pageNumber,
    pagination.pageSize,
    searchCriteria.index,
    searchText.text,
    startDate,
    refresh,
  ]);

  React.useEffect(() => {
    fetcher({
      url: `SysAdminFormAccessPrivileges/GetAsmTmsPrivileges`,
      params: {
        EmpID: userId,
      },
    }).then((res) => setPriviledges(res));
  }, [userId]);

  const formModal: formModalStates[] = [
    { id: 0, label: "Return Report", module: <ReturnReport /> },
  ];

  return (
    <div className="w-full h-full">
      <FormModal
        height={1000}
        width={"90%"}
        openState={openFormModal}
        onCancel={() => {
          updateStates("openFormModal", false);
        }}
        child={formModal[0].module}
        title={"Transport - Condition Returns Report"}
      />
      {firstLoading && <CustomLoader text="Fetching Data" />}
      <div className="mx-2">
        <VehicleConditionToolbar
          setRefreshAssignmentGrid={setRefreshAssignmentGrid}
          refreshAssignmentGrid={refreshAssignmentGrid}
          getCurrentDate={getCurrentDate}
          toggler={switch_toggle}
          startDateChanged={(date: string) => {
            alert("startdate: " + date);
          }}
          endDateChanged={(date: string) => {
            alert("enddate: " + date);
          }}
          selectedRecord={selectedTopDatax}
          handleReport={() => {
            updateStates("openFormModal", true);
          }}
          handleGridRefresh={() => {
            setRefresh(!refresh);
          }}
          switchState={setShowTable}
          handleFind={(
            searchValue: string,
            startDate,
            endDate,
            isActive,
            searchCriteria
          ) => {
            setSearchText((state) => {
              return { ...state, text: searchValue };
            });
            setStartDate(startDate);
            setEndDate(endDate);
            setActive(isActive);
            setSearchCriteria((state) => {
              return { ...state, index: searchCriteria };
            });
          }}
          handleRefresh={() => {
            // setSearchUrl(
            //   `AssetTamVehicleConditionReturn/PopGdvSearchAssetTamVehicleConditionReturn?startDate=${startDate}&endDate=${endDate}&criteriaIndex=0&search=${
            //     searchText.text
            //   }&active=${false}`
            // );
            // setRefresh(!refresh);
            setPagination({ pageSize: 15, pageNumber: 1 });
            setSearchText({ temp: "", text: "" });
          }}
        />
      </div>
      <div className="px-2 mb-0.5">
        <VehicleConditionDetails
          setRefreshAssignmentGrid={setRefreshAssignmentGrid}
          refreshAssignmentGrid={refreshAssignmentGrid}
          formPriviledges={formPriviledges}
          setSelectedTopData={setSelectedTopDatax}
          setPagination={setPagination}
          toggled={showTable}
          refreshState={setRefresh}
          table_one_data={firstTable}
          refreshGrid={() => {
            setRefresh(!refresh);
          }}
          setCurrentDatex={setGetCurrentdate}
        />
      </div>
    </div>
  );
};

export default VehicleConditions;
