/* eslint-disable react/jsx-pascal-case */
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { createSearchParams, useNavigate } from "react-router-dom";
import { RootState } from "../../../../../../app/store";
import {
  task_footer_update,
  task_form_disable,
  update_route_state,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import useFetch from "../../../../../../hooks/useFetch";
import {
  navbar_height,
  runScheduleAlert,
  startingDate,
} from "../../../../../accessories/component_infos";
import { Maintenance_schedulestoolbar } from "../../../../../accessories/custom-toolbars/maintenance_schedulestoolbar";
import { footer_datagrid_cols } from "./data/datagrid_cols";
import { Maintenance_Schedule_Details_Form } from "./widgets/Maintenace_schedules_details_form";
import { Maintenance_Schedule_Table_Form } from "./widgets/Maintenance_schedule_table";
import { Maintenance_Schedule_Single_Table_Form } from "./widgets/single_grid";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { useCallback, useEffect, useRef, useState } from "react";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import dayjs from "dayjs";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { GetDataFunc } from "../../../../../../functions/get";
import {
  setBulk_Cancel_Button,
  setDisabled_Form,
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Update_Button,
} from "../../../../../../features/VehicleRedux/TMS-Sicers/Maintenance/MaintenanceScheduleSlice";
import { UpdateDataFunc } from "../../../../../../functions/update";
import CustomLoader from "../../../../../accessories/custom-loader";
import { PostDataFunc } from "../../../../../../functions/post";
import { PatchDataFunc } from "../../../../../../functions/patch";
import { useAxiosService } from "../../../../../../general/requestConfig";
import { trim } from "lodash";

interface props {
  state_change: boolean;
}

interface stateTypes {
  rightVeh: string;
  rightSelectedRecord: { id: number; name: string };
  miniVDSearch: { temp: string; text: string };
  miniVASearch: { temp: string; text: string };
  addedSelectedRecord?: any;
}

export const Maintenance_Schedule = ({ state_change }: props) => {
  const fetcher = useAxiosService();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );

  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  //resizing

  const formRef = useRef<any>(null);
  const [initHeight, FullHeight] = useResizeMode(switch_change);

  var fullPage = FullHeight - (navbar_height + news_flash_height + 95);

  var pageHeight =
    initHeight - (navbar_height + news_flash_height + formheight + 50);

  const gridMode = useSelector(
    (state: RootState) => state.addTask.ppe_request_update_route
  );

  const formCode = useSelector((state: RootState) => state.general.formCode);
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const initialState = () => ({
    mt: { id: 0, name: "" },
    rp: { label: "", id: 0 },
    ws: { id: 0, name: "" },
    date: dayjs().format(),
    mdate: dayjs().format(),
    fdur: 0,
    fd: 0,
    remarks: "",
    sms1: 0,
    sms2: 0,
    sms3: 0,
    sms4: 0,
    driver: false,
    ao: false,
    admin: false,
    vc: false,
    man: false,
    dir: false,
    active: false,
    batchNo: 0,
    smsdate: dayjs().format(),
    nfd: 0,
    confirmModal: { state: false, title: "", message: "", action: "post" },
    warningModal: { state: false, title: "", message: "" },
    successModal: { state: false, title: "", message: "" },
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
  });

  const [states, setStates] = useState<stateTypes>({
    rightVeh: "Vehicle Details",
    rightSelectedRecord: { id: 0, name: "" },
    miniVDSearch: { temp: "", text: "" },
    miniVASearch: { temp: "", text: "" },
    addedSelectedRecord: [],
  });

  const [generalState, setGeneralState] = useState<any>(initialState());

  const { miniVASearch, miniVDSearch } = states;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fdispatch = useDispatch();

  //..........................................//

  const current_page_route =
    "/assets-manager/transport/entries/Maintenance/maintenance-schedule";
  const home = "single_Grid_View";
  const multigrid = "multiGrid_View";

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [datagridRecord, setDatagridRecord] = useState<any>();

  const date = dayjs().format("DD MMM YYYY");

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const isShowDblTable = useSelector(
    (state: RootState) => state.general.isShowDblTable
  );

  const [startDate, setStartDate] = useState<string>(startingDate);
  const [endDate, setEndDate] = useState<string>(date);

  const searchCriteriaData = [
    { id: 0, value: "Reg No" },
    { id: 1, value: "Class" },
    { id: 2, value: "Make" },
    { id: 3, value: "Model" },
    { id: 4, value: "Batch No" },
  ];

  const vehicleCriteriaData: { id: number; label: string }[] = [
    { id: 0, label: "Vehicle Details" },
    { id: 1, label: "Vehicle Assignemts" },
  ];

  const [refreshValue, setRefreshValue] = useState(false);

  const [sortKey, setsortKey] = useState<string>("vdtIDpk");

  const [totalActiveVehiclex] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetTotalActiveVehiclex`,
    refreshValue
  );

  const [totRoadWorthyScheduledx] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetTotRoadWorthyScheduledx?pageNumber=1&pageSize=40`,
    refreshValue
  );

  const [totPMScheduledx] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetTotPMScheduledx`,
    refreshValue
  );

  const [totInsuranceRenewalScheduledx] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetTotInsuranceRenewalScheduledx`,
    refreshValue
  );

  const footerLegend: any[] = [
    {
      id: 0,
      label: `No Roadworthy Renewal Schedule - ${totalActiveVehiclex.countx - totRoadWorthyScheduledx.countx
        } / ${totRoadWorthyScheduledx.countx} / ${totalActiveVehiclex.countx}`,
    },
    {
      id: 1,
      label: `No Insurance Renewal Schedule - ${totalActiveVehiclex.countx - totInsuranceRenewalScheduledx.countx
        } / ${totInsuranceRenewalScheduledx.countx} / ${totalActiveVehiclex.countx
        }`,
    },
    {
      id: 2,
      label: `No PM Schedule - ${totalActiveVehiclex.countx - totPMScheduledx.countx
        } / ${totPMScheduledx.countx} / ${totalActiveVehiclex.countx}`,
    },
  ];

  const [pagination, setPagination] = useState<{
    pageSize: number;
    pageNumber: number;
  }>({
    pageSize: 15,
    pageNumber: 1,
  });

  const [datagridAll, setDatagridAll] = useState<any[]>();
  const [fetchStatus, setFetchStatus] = useState<{
    loading: boolean;
    error: any;
  }>({
    loading: false,
    error: undefined,
  });

  useEffect(() => {
    try {
      setFetchStatus((state) => ({ ...state, loading: true }));
      fetcher({
        url: `AsmTsmEntMaintenanceSchedule/GetAllMaintenanceScheduleFromView`,
        method: `GET`,
        params: {
          searchText: trim(searchText.text),
          active: activeStatus.status,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          searchCriteriaIndex: searchCriteria.index,
          pagenumber: pagination.pageNumber,
          pagesize: pagination.pageSize,
        },
      }).then((res) => setDatagridAll(res));
      setFetchStatus((state) => ({ ...state, loading: false }));
    } catch (error) {
      setFetchStatus((state) => ({ ...state, error }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText.text,
    activeStatus.status,
    startDate,
    endDate,
    searchCriteria.index,
    pagination.pageNumber,
    pagination.pageSize,
    refreshValue,
  ]);

  const [alertData, alertData_errorVA, alertData_loadingVA] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetAllMaintenanceScheduleRecords`,
    refreshValue,
    sortKey
  );

  const [scheduleAlert, scheduleAlert_error, scheduleAlert_loading] = useFetch(
    `AsmTsmEntMaintenanceSchedule/AsmTmsMaintenanceScheduleAlert`,
    refreshValue
  );
  const [
    getStatusWithCodex,
    GetStatusWithCodex_errror,
    GetStatusWithCodex_loading,
  ] = useFetch(
    `AsmTsmEntMaintenanceSchedule/GetSettingStatusWithCodex`,
    refreshValue
  );

  const [AllVehData, setAllVehData] = useState<any>();
  const [miniDataVD, setminiDataVD] = useState<any>({});

  const [MiniVA, MiniVAError, MiniVALoading] = useFetch(
    `AsmTsmEntMaintenanceSchedule/PopGdcVehicleAssignment?searchText=${states.miniVASearch?.text}`
  );

  const [MiniVD, MiniVDError, MiniLoading] = useFetch(
    `AsmTsmEntMaintenanceSchedule/PopGdcVehicleDetails?searchText=${states.miniVDSearch?.text}`
  );

  const [ClientSMSProfile, err, loading] = useFetch(
    `SMSALert/GetNumberOfSMSLeft?CstCode=INNORIKGH01`,
    true
  );

  const rightGridFunc = () => {
    setAllVehData([...MiniVA, ...MiniVD]);
  };

  const DeleteModal = {
    message: "Are you sure you want to Delete the selected schedule?",
    title: "Delete? ",
    okText: "Yes",
    cancelText: "No",
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(DeleteModal);
  const [modalAlert, setModalAlert] = useState<any>(runScheduleAlert);

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [showCancel, setshowCancel] = useState(false);

  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const [employeeID, userID, user] = useAccountInfo();

  const [newMode, setNewMode] = useState<boolean>(false);

  /// States
  const [miniVD, setMiniVD] = useState<any>([]);

  // new button toggler
  const new_toggle = () => {
    dispatch(update_route_state(true));

    if (gridMode) {
      dispatch(task_form_disable(false));

      navigate({
        pathname: current_page_route,
        search: createSearchParams({
          view: multigrid,
        }).toString(),
      });

      // show display
      dispatch(update_route_state(false));
    }
  };

  const cancel_toggle = () => {
    dispatch(update_route_state(false));

    if (gridMode === false) {
      navigate({
        pathname: current_page_route,
        search: createSearchParams({
          view: home,
        }).toString(),
      });

      dispatch(task_form_disable(true));

      dispatch(update_route_state(true));
    }
  };

  const bulkEditData = async () => {
    //......................
    fdispatch(setNew_Cancel_Button(true));
    fdispatch(setEdit_Cancel_Button(false));
    fdispatch(setSave_Update_Button(false));
    fdispatch(setDisabled_Form(false));
    fdispatch(setBulk_Cancel_Button(false));
    //................................

    try {
      // will chcek on the mftdeleted i dont understand
      //getting all vehicles under this batch number
      const res = await GetDataFunc(
        `AsmTsmEntMaintenanceSchedule/BulkEditMaintenanceSchedule?MtfBatchNo=${selectedRecord.mtsBatchNo}`
      ) as any;
      if (res?.status === 200) {
        setMiniVD(res.data);
      }
    } catch (error: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: error.response ? error.response.data : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };
  // switch toggle
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(false);
    } else {
      setSwitch_change(true);
    }
  };

  const delData = async () => {
    if (
      selectedRecord !== undefined &&
      Object.keys(selectedRecord)?.length < 1
    ) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Select a Record",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      try {
        // will chcek on the mftdeleted i dont understand
        await PatchDataFunc(
          `AsmTsmEntMaintenanceSchedule/DeleteMaintenanceSchedule?mtfIDpk=${selectedRecord.mtsIdpk
          }&deleteMaintenance.MtfDeleted=${true}&deleteMaintenance.MtfEditedBy=${userID}`,
          {}
        );
      } catch (error: any) {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: error.response ? error.response.data : "Server Error",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    }
  };

  const updateStates = useCallback((key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  }, []);

  //.............MaintenanceAlertSMS..........
  const [promptDays, setpromptDaysx] = useState(0);

  const [updateTablex, setUpdateTablex] = useState<boolean>(false);
  const [currentDatex, setCurrentDatex] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [nextAlertDatex, setNextAlertDatex] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [loadingAlert, setLoadingAlert] = useState<boolean>(false);

  const runAlert = async () => {
    try {
      setOpenModal(false);
      setLoadingAlert(true);

      for (let drx = 0; drx < alertData.length; drx++) {
        try {
          if (alertData[drx].mtsMaintenanceDate !== null) {
            const promptDaysArray = [
              alertData[drx].mtsFirstSmsPromptDays,
              alertData[drx].mtsSecondSmsPromptDays,
              alertData[drx].mtsThirdSmsPromptDays,
              alertData[drx].mtsFourthSmsPromptDays,
            ];
            const maintenanceDate = dayjs(alertData[drx].mtsMaintenanceDate);
            const currentDate = dayjs(currentDatex);
            let shouldUpdate = false;

            for (let days = 0; days < promptDaysArray.length; days++) {
              if (promptDaysArray[days] > 0) {
                setpromptDaysx(promptDaysArray[days]);
                const nextDate = maintenanceDate
                  .add(promptDaysArray[days], "day")
                  .format("YYYY-MM-DD");
                if (
                  dayjs(nextDate).isSame(currentDate) ||
                  dayjs(nextDate).isAfter(currentDate)
                ) {
                  shouldUpdate = true;
                  if (shouldUpdate) {
                    setUpdateTablex(true);

                    try {
                      await UpdateDataFunc(
                        `AsmTsmEntMaintenanceSchedule/UpdateMtfNextAlertDate?updateStatus=${shouldUpdate}&mtsIDpk=${alertData[drx].mtsIDpk}`,
                        {
                          mtsNextAlertDate: nextDate,
                          mtsEditedDate: currentDatex,
                          mtsEditedBy: "1",
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                  }
                  setNextAlertDatex(nextDate);

                  break;
                }
              }
            }
          }
        } catch (e: any) {
          setOpenModal(true);
          setLoadingAlert(false);
          fdispatch(setNew_Cancel_Button(true));
          fdispatch(setEdit_Cancel_Button(true));
          fdispatch(setBulk_Cancel_Button(true));
          fdispatch(setDisabled_Form(true));
          setModalData({
            message: "Next Alert Dates failed to update",
            title: "Failed",
            okText: "Ok",
          });
          setIcon("warning");
        }
      }
    } catch (e: any) {
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.response ? e.response.data : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }

    //..........................CheckingReciever.................
    try {
      for (let drx = 0; drx < scheduleAlert.length; drx++) {
        if (scheduleAlert[drx].mtsMaintenanceDate !== null) {
          let alertSentx = false;

          let smsMsgxx = "The vehicle with Reg No...";
          let alertMsgx = "The vehicle with Reg No...";
          if (getStatusWithCodex === true) {
            if (scheduleAlert[drx].mtsPromptDriver === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptDriver`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  let res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );

                  if ((await res2).data.length === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    //TODO Sending SMS alert
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            } //TODO aupdate last Alert date
            //Alert Controller
            else if (scheduleAlert[drx].mtsPromptAdmin === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptVehicleController`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  const res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );
                  if ((await res2).data === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // Alert Admin
            else if (scheduleAlert[drx].mtsPromptAdmin === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptAdmin`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  const res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );
                  if ((await res2).data === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            //Alert Officer
            else if (scheduleAlert[drx].mtsPromptOwner === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptOwner`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  const res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );
                  if ((await res2).data === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }

            //Alert Manager
            else if (scheduleAlert[drx].mtsPromptManager === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptManager`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  const res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );
                  if ((await res2).data === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            //Alert Diretor
            else if (scheduleAlert[drx].mtsPromptDirector === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptDirector`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} at ${scheduleAlert[drx].wsdName}`;

                  smsMsgxx = `Hi ${(await res).data[drxx].empFirstName
                    } the vehicle with Reg. No.${scheduleAlert[drx].vdtRegNo
                    } will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate
                    } at ${scheduleAlert[drx].wsdName}`;

                  const res2 = GetDataFunc(
                    `AsmTsmEntMaintenanceSchedule/GetByMessageAndUser?message=${smsMsgxx}`
                  );
                  if ((await res2).data === 0) {
                    //Save Alert
                    try {
                      await PostDataFunc(
                        `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                        {
                          altFormCode: "asmTransMaintenanceSchedules",
                          altMessage: alertMsgx,
                          altTakeAction: true,
                        }
                      );
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowModalDisableButton(false);
                      setModalData({
                        message: e.response ? e.response.data : "Server Error",
                        title: "Unexpected Error!",
                        okText: "Ok",
                      });
                    }
                    try {
                      await PostDataFunc(`SMSAlert/SaveToLogs`, {
                        slgMessage: smsMsgxx,
                        slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                        slgSender: "Innorik",
                        slgSent: true,
                        slgRecordIDfk: 0,
                        slgFormCode: formCode,
                        slgType: null,
                        slgClass: null,
                        slgSmsServiceProvider: "INNORIK",
                        slgEmpIDfk: employeeID,
                        slgInitiatorEmpIDfk: employeeID,
                        slgCreatedBy: userID,
                      });
                    } catch (e: any) {
                      setOpenModal(true);
                      setshowCancel(false);
                      setModalData({
                        message:
                          "Unable to send SMS. \n Contact your Administrator",
                        title: "SMS Failed",
                        okText: "Ok",
                      });
                    }
                  }
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
          } else {
            //Alerting Driver
            if (scheduleAlert[drx].mtsPromptDriver === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptDriver`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }

                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // //Alerting Contoller
            else if (scheduleAlert[drx].mtsPromptVehicleController === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptVehicleController`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }

                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // //Alerting Admin
            else if (scheduleAlert[drx].mtsPromptAdmin === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptAdmin`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }
                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // //Alerting Manager
            else if (scheduleAlert[drx].mtsPromptManager === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptManager`
                );
                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }

                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // //Alerting Director
            else if (scheduleAlert[drx].mtsPromptDirector === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptDirector`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }

                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }
            // //Alert approving Officer
            else if (scheduleAlert[drx].mtsPromptOwner === true) {
              try {
                const res = GetDataFunc(
                  `AsmTsmEntMaintenanceSchedule/GetSmsReciepient?vdtIdpk=${scheduleAlert[drx].vdtIDpk}&role=mtsPromptOwner`
                );

                for (let drxx = 0; drxx < (await res).data.length; drxx++) {
                  alertMsgx = `The vehicle with Reg No ${scheduleAlert[drx].vdtRegNo} will be due for ${scheduleAlert[drx].mtpName} on ${scheduleAlert[drx].mtsMaintenanceDate} `;

                  //Save Alert
                  try {
                    await PostDataFunc(
                      `AsmTsmEntMaintenanceSchedule/SaveSmsAlert`,
                      {
                        altFormCode: "asmTransMaintenanceSchedules",
                        altMessage: alertMsgx,
                        altTakeAction: true,
                      }
                    );
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowModalDisableButton(false);
                    setModalData({
                      message: e.response ? e.response.data : "Server Error",
                      title: "Unexpected Error!",
                      okText: "Ok",
                    });
                  }

                  try {
                    await PostDataFunc(`SMSAlert/SaveToLogs`, {
                      slgMessage: smsMsgxx,
                      slgMobNo: ClientSMSProfile[0].scdAdminMobNo,
                      slgSender: "Innorik",
                      slgSent: true,
                      slgRecordIDfk: 0,
                      slgFormCode: formCode,
                      slgType: null,
                      slgClass: null,
                      slgSmsServiceProvider: "INNORIK",
                      slgEmpIDfk: employeeID,
                      slgInitiatorEmpIDfk: employeeID,
                      slgCreatedBy: userID,
                    });
                  } catch (e: any) {
                    setOpenModal(true);
                    setshowCancel(false);
                    setModalData({
                      message:
                        "Unable to send SMS. \n Contact your Administrator",
                      title: "SMS Failed",
                      okText: "Ok",
                    });
                  }

                  alertSentx = true;
                }
              } catch (e: any) {
                setOpenModal(true);
                setshowModalDisableButton(false);
                setModalData({
                  message: e.response ? e.response.data : "Server Error",
                  title: "Unexpected Error!",
                  okText: "Ok",
                });
              }
            }

            //  updating Last alert Date
            try {
              if (alertSentx === true) {
                await UpdateDataFunc(
                  `AsmTsmEntMaintenanceSchedule/UpdateLastSentSms?mtsIDpk=${scheduleAlert[drx].mtsIDpk}`,
                  {}
                );
              }
            } catch (e: any) {
              setOpenModal(true);
              setshowModalDisableButton(false);
              setModalData({
                message: e.response ? e.response.data : "Server Error",
                title: "Unexpected Error!",
                okText: "Ok",
              });
            }
          }
        }
      }
      setOpenModal(true);
      setLoadingAlert(false);
      fdispatch(setNew_Cancel_Button(true));
      fdispatch(setEdit_Cancel_Button(true));
      fdispatch(setBulk_Cancel_Button(true));
      fdispatch(setDisabled_Form(true));
      setshowCancel(false);
      setModalData({
        message: "Schedule alerts did run successfully",
        title: "Schedule Run Successful",
        okText: "Ok",
      });
      setIcon("success");
    } catch (e: any) {
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.response ? e.response.data : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
    //...................................................
  };

  //..............................................

  const getVeh = async (para: boolean) => {
    if (para || (AllVehData !== undefined && AllVehData.length > 0)) {
      for (
        let selectedVeh = 0;
        selectedVeh < AllVehData.length;
        selectedVeh++
      ) {
        if (selectedRecord.vdtIdpk === AllVehData[selectedVeh].vdtIDpk) {
          setminiDataVD([selectedVeh]);
          updateStates("rightSelectedRecord", {
            id: AllVehData[selectedVeh].vdtIDpk,
            name: AllVehData[selectedVeh].vdtRegNo,
          });
        }
      }
      para = false;
    }
  };

  const sortFind = async (para: boolean) => {
    if (para || (AllVehData !== undefined && AllVehData.length > 0)) {
      for (
        let selectedVeh = 0;
        selectedVeh < AllVehData.length;
        selectedVeh++
      ) {
        if (selectedRecord?.vdtIdpk === AllVehData[selectedVeh]?.vdtIDpk) {
          const clicked_veh = selectedVeh;
          setminiDataVD([clicked_veh, ...miniDataVD]);

          updateStates("rightSelectedRecord", {
            id: AllVehData[selectedVeh].vdtIDpk,
            name: AllVehData[selectedVeh].vdtRegNo,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (AllVehData !== undefined && AllVehData.length !== 0) {
      sortFind(true);
      // fdispatch(setBulk_Cancel_Button(true));
    }
  }, [selectedRecord]);

  useEffect(() => {
    rightGridFunc();
    set_form_height(formRef.current?.clientHeight + 50);
  }, [switch_change, refreshValue]);


  return (
    <>
      {loadingAlert && <CustomLoader text="Sending Alert(s)" />}

      {!!fetchStatus.error && <WarningAlert />}
      <div className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base mt-1 px-2 ">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={showModalDisableButton}
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === DeleteModal.title
              ? delData()
              : modalData.title === runScheduleAlert.title
                ? runAlert()
                : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <div className="">
          <Maintenance_schedulestoolbar
            toggler={switch_toggle}
            setVisible={() => {
              setSwitch_change(true);
              fdispatch(setBulk_Cancel_Button(true));
            }}
            handleDelete={() => {
              setModalData(DeleteModal);
              setOpenModal(true);
              setIcon("question");
            }}
            runAlert={() => {
              setModalData(modalAlert);
              setOpenModal(true);
              setIcon("question");
            }}
            handleEdit={(value: boolean) => {
              setUpdateMode(value);
            }}
            handleNewClick={(value) => {
              setGeneralState(initialState());
            }}
            onBulkEditCancel={() => {
              setMiniVD([]);
            }}
            handleBulkEdit={() => {
              bulkEditData();
              dispatch(task_footer_update(true));
            }}
            setOuterSelected={setSelectedRecord}
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            checkOnChange={(check) => {
              setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            handleFind={(startDate, endDate, searchValue) => {
              // setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              }); //active status
              setsearhCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
              setStartDate(startDate);
              setEndDate(endDate);
              setSearchText((state) => ({
                ...state,
                text: searchValue,
              }));
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
            }}
            handleRefresh={() => {
              setSearchText({ temp: searchText.temp, text: "" });
              setActiveStatus({ temp: activeStatus.status, status: "" });
              setRefreshValue((prev) => !prev);
            }}
            startDateChanged={(e) => {
              console.info("start date: ", e);
              console.log("start date f: ", dayjs(e).format());
              setStartDate(dayjs(e).format());
            }}
            endDateChanged={(e) => {
              console.info("end date: ", e);
              console.log("end date f: ", dayjs(e).format());
              setEndDate(dayjs(e).format());
            }}
            startDate={startDate}
            endDate={endDate}
            searchCriteriaData={searchCriteriaData}
            outerSelected={selectedRecord}
          />
        </div>
        <div className="mt-0.5 ">
          <p
            id={"form_header"}
            style={{ borderColor: borderTheme }}
            className="w-full border-[1px] rounded-t-md pt-1 pl-4 bg-slate-100"
          >
            Maintenance Frequency Details
          </p>
          {switch_change && (
            <div
              ref={formRef}
              style={{ borderColor: borderTheme }}
              className=" flex justify-between gap-x-4 border-b-4 mb-1 pb-1"
            >
              <div
                className="w-full flex border rounded-b-md border-t-0 justify-between gap-x-4"
                style={{ borderColor: borderTheme }}
              >
                <div style={{ width: "800px", height: "350px" }}>
                  <Maintenance_Schedule_Details_Form
                    new_toggle={new_toggle}
                    cancel_toggle={cancel_toggle}
                    selectedRecordx={selectedRecord}
                    setOuterSelect={setSelectedRecord}
                    statesx={states}
                    updateStatesx={updateStates}
                    setRefreshValuex={setRefreshValue}
                    // rightVDdatax={MiniVD}
                    rightVDdatax={miniVD}
                    footerDatax={datagridAll}
                    datagridRecord={datagridRecord}
                    states={generalState}
                    setStates={setGeneralState}
                  />
                </div>

                <div
                  style={{ width: "calc(100% - 810px)" }}
                  className="h-full pr-2 rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
                >
                  <div>
                    {/* {(DisabledForm || !Bulkedit) ? ( */}
                    {
                      !isShowDblTable ? (
                        <Maintenance_Schedule_Single_Table_Form
                          data={miniVD.length === 0 ? MiniVD : miniVD}
                          dataVA={MiniVA}
                          setRefreshCallback={setRefreshValue}
                          updateStatesx={updateStates}
                          statesx={states}
                          vehicleCriteria={vehicleCriteriaData}
                          singleOnchange={(e) => {
                            updateStates("miniVDSearch", {
                              temp: e,
                              text: states.miniVDSearch?.text,
                            });
                          }}
                          SingleValue={miniVASearch.temp}
                          handleSearch={() => {
                            updateStates("miniVDSearch", {
                              temp: miniVDSearch.temp,
                              text: miniVDSearch.temp,
                            });
                          }}
                        />
                      ) :
                        (
                          <Maintenance_Schedule_Table_Form
                            data={MiniVD}
                            dataVA={MiniVA}
                            setRefreshCallback={setRefreshValue}
                            updateStatesx={updateStates}
                            statesx={states}
                            vehicleCriteria={vehicleCriteriaData}
                            handleSearch={() => {
                              updateStates("miniVDSearch", {
                                temp: miniVDSearch.temp,
                                text: miniVDSearch.temp,
                              });
                            }}
                          />
                        )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Footer gridtable  */}
        <div
          style={{ height: "" }}
          className="px-2 dark:bg-slate-900 dark:text-darkModeSkin-base"
        >
          <Datagrid_template
            pageNumber={pagination.pageNumber}
            pageSize={pagination.pageSize}
            dataId="mtsIdpk"
            handlePageNumber={(page) => {
              setPagination((state) => ({ ...state, pageNumber: page }));
            }}
            handlePageSize={(size) => {
              setPagination((state) => ({ ...state, pageSize: size }));
            }}
            gridheight={switch_change ? pageHeight : fullPage}
            columns={footer_datagrid_cols ?? []}
            data={datagridAll}
            onRowClick={(e) => {
              setSelectedRecord(e);
              setDatagridRecord(e);
              // alert(JSON.stringify(e, null, 2));
              dispatch(task_form_disable(true));
              //.......................
              fdispatch(setNew_Cancel_Button(true));
              fdispatch(setEdit_Cancel_Button(true));
              fdispatch(setBulk_Cancel_Button(true));
              fdispatch(setDisabled_Form(true));
              fdispatch(setSave_Update_Button(true));
              //.......................
              setMiniVD([]);
              fdispatch(setDisabled_Form(true));

              //enable Update Mode
              dispatch(task_footer_update(false));
            }}
            rowDoubleClicked={(e) => {
              getVeh(true);
              dispatch(task_form_disable(false));

              //.......................
              fdispatch(setNew_Cancel_Button(true));
              fdispatch(setEdit_Cancel_Button(false));
              fdispatch(setBulk_Cancel_Button(true));
              fdispatch(setDisabled_Form(false));
              fdispatch(setSave_Update_Button(false));
              //.......................
              // alert(JSON.stringify(e, null, 2));
              setDatagridRecord(e);

              //enable Update Mode
              setSwitch_change(true);
              dispatch(task_footer_update(true));
              setSelectedRecord(e);
            }}
          />

          <div className="w-full flex justify-evenly">
            {footerLegend?.map(({ id, label }) => {
              return (
                <div key={id}>
                  <p className="text-blue-500">{label}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
