import { Select, DatePicker, Form, Input, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useImperativeHandle, useState } from "react";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../accessories/buttons";
import {
  api_url,
//   borderTheme,
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import reset from "../../../../../../assets/reset.png";
import { meter_TH } from "../data/meter-details-data";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../templates/modal";
import { DataGrid } from "devextreme-react";
import {
  Column,
  GroupPanel,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import {
  datalistTypes,
  exprsTypes,
  modalTypes,
  searchTextTypes,
  selectObjectTypes,
  selectsTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import axios from "axios";
import CustomLoader from "../../../../../accessories/custom-loader";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  task_footer_table_selected,
  task_form_disable,
  task_footer_update,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import React from "react";
import SetupsDatalistTemplat from "../../../../../human-resource/setups/data/setups_datalist_template";
import { formPropsConst } from "../../../../../accessories/constants";
import InputSelect_template from "../../../../../../templates/InputSelect";

const { Option } = Select;

interface props {
  toggled?: boolean;
  searchTextValue: string;
  meter_loads: boolean;
  meter_data: any[];
}

interface stateTypes {
  selectedMeterNo?: string;
  selectedSerialNo?: string;
  selectedShortName?: string;
  selectedCurrentStation?: any;
  selectedCurrentFeeder?: any;
  selectedCurrentCustomer?: any;
  selectedManufacturer: selectObjectTypes;
  selectedAccuracyClass?: { id: number; value: string };
  selectedSupplyConnection?: { id: number; value: string };
  selectedDisplayType?: { id: number; value: string };
  selectedActive?: boolean;
  selectedVerified?: boolean;
  selectedStationService?: boolean;
  selectedCurrentStatus?: { id: number; value: string };
  selectedFirmware?: { id: number; value: string };
  selectedCTRatio?: any;
  selectedPTRatio?: any;
  selectedManufacturerDate?: string;
  selectedSupplyDate?: string;
  selectedInstallationStartDate?: string;
  selectedCommissioningDate?: string;
  selectedDecommissioningDate?: string;
  selectedRemarks?: any;
  deactivateFields?: any;
  showUpdateButton?: any;
  showCancelButton?: any;
  rowData?: any;
  validationModalState: { state: boolean; title: string; message: string };
  confirmationModalState: { state: boolean; title: string; message: string };

  successModalState: { state: boolean; title: string; message: string };

  searchText: searchTextTypes;
  selectedRecordId: number;

  isOpenDropdown1?: boolean,
  isOpenDropdown2?: boolean,
  isOpenDropdown3?: boolean,
   isOpenDropdown4?: boolean,


  isOpenDropdownKey1?: "isOpenDropdown1",
  isOpenDropdownKey2?: "isOpenDropdown2",
  isOpenDropdownKey3?: "isOpenDropdown3",
  isOpenDropdownKey4?: "isOpenDropdown4",

  meterNoRefresh?: boolean;
   meterSerialNoRefresh?: boolean;
   meterShortNameRefresh?: boolean;
   meterManufRefresh?: boolean;

//    meterManufRefresh?: boolean;
//    meterAccRefresh: boolean;
//    meterFirmwareRefresh?: boolean;
//    meterSupplyConRefresh?: boolean;
//    meterDispTypeRefresh?: boolean;
//    meterCurrStatusRefresh?: boolean;

}

const MeterDetails = React.forwardRef(
  ({ toggled, searchTextValue, meter_data, meter_loads }: props, ref) => {
    const news_flash_height = useSelector(
      (state: RootState) => state.general.newsflashHeight
    );
    const heights_out = navbar_height + news_flash_height + toolbar_height;

    //varying form height
    const form_height = 490;

    const borderTheme = useSelector(
     (state: RootState) => state.general.cssBorderClass
   );


    //Table Height
    const pageHeight = window.innerHeight - heights_out - 10; //height without navbar
    const [tableHeight, settableHeight] = useState(
      window.innerHeight - heights_out
    ); //height without navbar and toolbar
    const [table_form_Height, settable_form_Height] = useState(
      window.innerHeight - heights_out
    ); //height without navbar and toolbar and form

    const [showTable, setShowTable] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const dateFormat = "DD MMM YYYY HH: mm:ss";




    //Initialize values
    //const [meterNoValue, setMeterNoValue] = useState('');
    const [states, setCurrentState] = useState<stateTypes>({
      selectedMeterNo: "",
      selectedSerialNo: "",
      selectedShortName: "",
      selectedCurrentStation: "",
      selectedCurrentFeeder: "",
      selectedCurrentCustomer: "",
      selectedManufacturer: { id: 0, value: "" },
      deactivateFields: true,
      showUpdateButton: false,
      showCancelButton: false,
      selectedAccuracyClass: { id: 0, value: "" },
      selectedSupplyConnection: { id: 0, value: "" },
      selectedDisplayType: { id: 0, value: "" },

      selectedActive: false,
      selectedVerified: false,
      selectedStationService: false,
      selectedCurrentStatus: { id: 0, value: "" },
      selectedFirmware: { id: 0, value: "" },
      selectedCTRatio: "",
      selectedPTRatio: "",
      selectedManufacturerDate: dayjs(new Date()).format(),
      selectedSupplyDate: dayjs(new Date()).format(),
      selectedInstallationStartDate: dayjs(new Date()).format(),
      selectedCommissioningDate: dayjs(new Date()).format(),
      selectedDecommissioningDate: dayjs(new Date()).format(),
      selectedRemarks: "",
      rowData: {},
      validationModalState: { state: false, title: "", message: "" },
      confirmationModalState: { state: false, title: "", message: "" },
      successModalState: { state: false, title: "", message: "" },
      searchText: { text: "", temp: "" },
      selectedRecordId: 0,

      // monitor dropdown states
      isOpenDropdown1: false,
      isOpenDropdown2: false,
      isOpenDropdown3: false,
      isOpenDropdown4: false,

      isOpenDropdownKey1: "isOpenDropdown1",
      isOpenDropdownKey2: "isOpenDropdown2",
      isOpenDropdownKey3: "isOpenDropdown3",
      isOpenDropdownKey4: "isOpenDropdown4",
      
      meterNoRefresh: false,
      meterSerialNoRefresh: false,
      meterShortNameRefresh: false,
      meterManufRefresh: false,

    });

    const updateState = (stateName: string | number, value: any) => {
      setCurrentState((prevStates: stateTypes) => ({
        ...prevStates,
        [stateName]: value,
      }));
    };

    const setStatesFromGrid = (data: any, clearFields?: boolean) => {
      // initialize states
      console.log(data.mtrShtName);
      const state: any = [
        {
          stateName: "selectedMeterNo",
          value: clearFields ? "" : data.mtrMeterNo,
          stringify: true,
        },
        {
          stateName: "selectedSerialNo",
          value: clearFields ? "" : data.mtrSerialNo,
        },
        {
          stateName: "selectedShortName",
          value: clearFields ? "" : data.mtrShtName,
        },
        {
          stateName: "selectedCurrentStation",
          value: clearFields ? "" : data.fstnName,
        },
        {
          stateName: "selectedCurrentFeeder",
          value: clearFields ? "" : data.fdrName,
        },
        {
          stateName: "selectedCurrentCustomer",
          value: clearFields ? "" : data.fcustName,
        },
        {
          stateName: "selectedAccuracyClass",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.aclIdpk, value: data.aclName },
        },
        {
          stateName: "selectedManufacturer",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.mftIdpk, value: data.mftName },
        },
        {
          stateName: "selectedSupplyConnection",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.spcIdpk, value: data.spcShtName },
        },
        {
          stateName: "selectedDisplayType",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.dptIdpk, value: data.dptName },
        },

        {
          stateName: "selectedActive",
          value: clearFields ? false : data.mtrActive,
        },
        {
          stateName: "selectedVerified",
          value: clearFields ? "" : data.mtrVerified,
        },
        {
          stateName: "selectedStationService",
          value: clearFields ? "" : data.emaStationService,
        },
        {
          stateName: "selectedCurrentStatus",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.estIdpk, value: data.estName },
        },
        {
          stateName: "selectedFirmware",
          value: clearFields
            ? { id: 0, value: "" }
            : { id: data.fmwIdpk, value: data.fmwName },
        },
        {
          stateName: "selectedCTRatio",
          value: clearFields ? "" : data.emaCtRatio,
        },
        {
          stateName: "selectedPTRatio",
          value: clearFields ? "" : data.emaPtRatio,
        },

        {
          stateName: "selectedManufacturerDate",
          value: clearFields
            ? dayjs(new Date()).format()
            : data.mtrManufactureDate,
        },
        {
          stateName: "selectedSupplyDate",
          value: clearFields ? dayjs(new Date()).format() : data.mtrSupplyDate,
        },
        {
          stateName: "selectedInstallationStartDate",
          value: clearFields
            ? dayjs(new Date()).format()
            : data.mtrInstallationDate,
        },
        {
          stateName: "selectedCommissioningDate",
          value: clearFields
            ? dayjs(new Date()).format()
            : data.mtrDateCommissioned,
        },
        {
          stateName: "selectedDecommissioningDate",
          value: clearFields
            ? dayjs(new Date()).format()
            : data.mtrDateDecommissioned,
        },
        {
          stateName: "selectedRemarks",
          value: clearFields ? "" : data.mtrRmks,
        },
        { stateName: "selectedRemarks", value: clearFields ? 0 : data.mtrIdpk },
      ];

      //setstates
      for (let i = 0; i < state.length; i++) {
        state.stringify
          ? runGenerator(state[i].stateName, JSON.stringify(state[i].value))
          : runGenerator(state[i].stateName, state[i].value);
      }
    };

    useImperativeHandle(ref, () => {
      return {
        search: () => {
          runGenerator("searchText", {
            text: states.searchText.temp,
            temp: states.searchText.temp,
          });
        },
      };
    });
    useEffect(() => {
      runGenerator("searchText", {
        text: states.searchText.text,
        temp: searchTextValue,
      });
    }, [searchTextValue]);

    // Get all the form and table data
    const [meter_no_data, meter_no_error, meter_no_loads] = useFetch(
      `meters/PopCmbMeterNos`,
      false,
      "",
      false,
      "powerapp"
    );
    const [meter_serial_no_data, meter_serial_no_error, meter_serail_no_loads] =
      useFetch("AssetMgrTamMeters/GetAllMeterSerialNumbers");
    const [meter_shortname_data, meter_shortname_error, meter_shortname_loads] =
      useFetch("AssetMgrTamMeters/GetAllMeterShortnames");
    const [meter_manuf_data, meter_manuf_error, meter_manuf_loads] = useFetch(
      "meters/PopLueManufacturers",false,
      "",
      false,
      "powerapp"
    );
    const [meter_acc_data, meter_acc_error, meter_acc_loads] = 
    useFetch("meters/PopLueAccuracyClass",false,
      "",
      false,
      "powerapp");

    const [meter_firmware_data, meter_firmware_error, meter_firmware_loads] =
      useFetch("AssetMgrTamMeters/GetAllMeterFirmwares");
    const [meter_supplycon_data, meter_supplycon_error, meter_supplycon_loads] =
      useFetch(
        "AssetMgrTamMeters/GetAllMeterSupplyConnections?active=true"
      );
    const [meter_disptype_data, meter_disptype_error, meter_disptype_loads] =
      useFetch("AssetMgrTamMeters/GetAllMeterDisplayTypes?active=true");
    const [
      meter_currentstatus_data,
      meter_currentstatus_error,
      meter_currentstatus_loads,
    ] = useFetch(
      "AssetMgrTamMeters/GetAllMeterCurrentStatuses?active=true"
    );
    

    //datagrid methods
    const datagridRowClick = (data: any) => {
      //set states
      //console.log(data)
      setStatesFromGrid(data);

      //deactivate form fields
      runGenerator("deactivateFields", true);

      //deactivate update & cancel button
      runGenerator("showUpdateButton", false);
      runGenerator("showCancelButton", false);
      runGenerator("rowData", data);
      dispatch(task_footer_table_selected(data));
      dispatch(task_form_disable(true));

      // dispatch(update_route_state(false))
      dispatch(task_footer_update(false));
    };

    const datagridRowDoubleClick = () => {
      runGenerator("deactivateFields", false);
      runGenerator("showUpdateButton", true);
      runGenerator("showCancelButton", true);
      //enable forms
      dispatch(task_form_disable(true));

      //enable Update Mode
      dispatch(task_footer_update(true));
    };

    // The runGenerator() sets the currentState
    function runGenerator(data: any, value: any) {
      setCurrentState((currentState: any) => ({
        ...currentState,
        [data]: value,
      }));
    }

    const modals: modalTypes[] = [
      //warning
      {
        disableCancel: true,
        icon: "warning",
        open: states.validationModalState.state,
        okHandler: () =>
          runGenerator("validationModalState", {
            ...states.validationModalState,
            state: false,
          }),
        cancelHandler: () =>
          runGenerator("validationModalState", {
            ...states.validationModalState,
            state: false,
          }),
        message: states.validationModalState.message,
        okText: "Ok",
        title: states.validationModalState.title,
        cancelText: "No, cancel",
      },

      //confirmation
      {
        disableCancel: false,
        icon: "question",
        open: states.confirmationModalState.state,
        okHandler: () =>
          states.confirmationModalState.title === "Save record?"
            ? postData()
            : updateData(),
        cancelHandler: () =>
          runGenerator("confirmationModalState", {
            ...states.confirmationModalState,
            state: false,
          }),
        message: states.confirmationModalState.message,
        okText: "Yes Save",
        title: states.confirmationModalState.title,
        cancelText: "No, cancel",
      },

      //confirmation
      {
        disableCancel: true,
        icon: "success",
        open: states.successModalState.state,
        okHandler: () =>
          runGenerator("successModalState", {
            ...states.successModalState,
            state: false,
          }),
        cancelHandler: () =>
          runGenerator("successModalState", {
            ...states.successModalState,
            state: false,
          }),
        message: states.successModalState.message,
        okText: "Ok",
        title: states.successModalState.title,
        cancelText: "No, cancel",
      },
    ];

    //handle data validation and post
    const validateFields = (method: "save" | "update"): boolean => {
      //validations
      if (!states.selectedMeterNo) {
        runGenerator("validationModalState", {
          state: true,
          title: "Enter Meter No",
          message: "Please select meter no",
        });
        return false;
      }

      runGenerator("confirmationModalState", {
        state: true,
        title: method === "save" ? "Save record?" : "Update Record?",
        message: `Are you sure you want to ${method} this record?`,
      });

      return true;
    };

    const postData = async () => {
      console.log(states.selectedAccuracyClass?.id);
      //post data
      try {
        runGenerator("confirmationModalState", {
          state: false,
          title: "",
          message: "",
        });
        const res = await axios.post(
          `${api_url}/api/AssetMgrTamMeters/CreateTAMEnergyMeter`,
          {
            mtrMeterNo: states.selectedMeterNo,
            mtrSerialNo: states.selectedSerialNo,
            mtrShtName: states.selectedShortName,
            mtrManufacturerIdfk: states.selectedManufacturer.id,
            mtrFirmwareIdfk: states.selectedFirmware?.id,
            mtrAccuracyClassIdfk: states.selectedAccuracyClass?.id,
            mtrSupplyConnectionIdfk: states.selectedSupplyConnection?.id,
            mtrDisplayTypeIdfk: states.selectedDisplayType?.id,
            mtrStatusIdfk: states.selectedCurrentStatus?.id,
            mtrMobileNetworkIdfk: 1,
            mtrMobileNo: "string",
            mtrManufactureDate: states.selectedManufacturerDate,
            mtrSupplyDate: states.selectedSupplyDate,
            mtrInstallationDate: states.selectedInstallationStartDate,
            mtrDateCommissioned: states.selectedCommissioningDate,
            mtrDateDecommissioned: states.selectedDecommissioningDate,
            mtrCurrentAssignmentIdfk: 1,
            mtrStationService: states.selectedStationService,
            mtrVerified: states.selectedVerified,
            mtrActive: states.selectedActive,
            mtrRmks: states.selectedRemarks,
            mtrCreatedBy: 1,
          }
        );
        //alert('record saved')
        setRefresh(!refresh);
        runGenerator("successModalState", {
          state: true,
          title: "Success",
          message: "Record Successfully Saved",
        });
      } catch (error: any) {
        console.log(error);

        //set states for db errors modal
        //setErrState(true);
        //seterrTitle(error.response ? error.response.data.message : 'Server Error');
        //seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
      }
    };

    const disablex = useSelector(
      (state: RootState) => state.addTask.taskFormStatus
    );
    const updateMode = useSelector(
      (state: RootState) => state.addTask.taskFooterUpdate
    );
    const dispatch = useDispatch();

    useEffect(() => {
      if (!disablex) {
        console.log("ds");

        dispatch(task_footer_table_selected({}));

        dispatch(task_form_disable(false));
        //  this destroys the update mode state
        dispatch(task_footer_update(false));
        runGenerator("deactivateFields", false);
        runGenerator("showCancelButton", true);
        runGenerator("rowData", {});
      } else {
        runGenerator("deactivateFields", true);
        runGenerator("showUpdateButton", false);
        runGenerator("showCancelButton", false);

        // setShowCancel(false); setDisableForms(true); setUpdating(false)
      }
      if (updateMode) {
        //enable forms
        dispatch(task_form_disable(true));

        //enable Update Mode
        dispatch(task_footer_update(true));

        runGenerator("deactivateFields", false);
        runGenerator("showUpdateButton", true);
        runGenerator("showCancelButton", true);
      }
    }, [disablex, updateMode]);

    const updateData = async () => {
      //put data
      try {
        runGenerator("confirmationModalState", {
          state: false,
          title: "",
          message: "",
        });
        const res = await axios.put(
          `${api_url}/api/AssetMgrTamMeters/UpdateTamEnterMeter/${states.selectedRecordId}`,
          {
            mtrMeterNo: states.selectedMeterNo,
            mtrSerialNo: states.selectedSerialNo,
            mtrShtName: states.selectedShortName,
            mtrManufacturerIdfk: states.selectedManufacturer.id,
            mtrFirmwareIdfk: states.selectedFirmware?.id,
            mtrAccuracyClassIdfk: states.selectedAccuracyClass?.id,
            mtrSupplyConnectionIdfk: states.selectedSupplyConnection?.id,
            mtrDisplayTypeIdfk: states.selectedDisplayType?.id,
            mtrStatusIdfk: states.selectedCurrentStatus?.id,
            mtrMobileNetworkIdfk: 1,
            mtrMobileNo: "0248958558",
            mtrManufactureDate: states.selectedManufacturerDate,
            mtrSupplyDate: states.selectedSupplyDate,
            mtrInstallationDate: states.selectedInstallationStartDate,
            mtrDateCommissioned: states.selectedCommissioningDate,
            mtrDateDecommissioned: states.selectedDecommissioningDate,
            mtrCurrentAssignmentIdfk: 1,
            mtrStationService: states.selectedStationService,
            mtrVerified: states.selectedVerified,
            mtrActive: states.selectedActive,
            mtrRmks: states.selectedRemarks,
            mtrEditedBy: 1,
            mtrEditedDate: "2023-03-31T14:47:13.104Z",
          }
        );
        //alert('Record Updated')
        setRefresh(!refresh);
        runGenerator("successModalState", {
          state: true,
          title: "Success",
          message: "Record Successfully Updated",
        });
      } catch (error: any) {
        console.log(error);

        //set states for db errors modal
        // setErrState(true);
        //seterrTitle(error.response ? error.response.data.message : 'Server Error');
        //seterrMessage(error.response ? error.response.data.errors[0] : 'Error connecting to server. Please contact system administrator');
      }
    };

    const datalist_selects: selectsTypes[] = [
      {
        id: 0,
        label: "Meter No",
        optionsData: meter_no_data,
        dataExpr: "mtrMeterNo",
        idExpr: "mtrIdpk",
        stateName: "selectedMeterNo",
        nameKey: states.selectedMeterNo,
        isOpenDropdown: states.isOpenDropdown1,
        isOpenDropdownKey: states.isOpenDropdownKey1,
        refresh: states.meterNoRefresh,
        refreshStateName: "meterNoRefresh",
      //   labelCol: 2,
      //   wrapperCol: 4,
      },
      {
        id: 1,
        label: "Serial No",
        optionsData: meter_serial_no_data,
        dataExpr: "mtrSerialNo",
        idExpr: "mtrSerialNo",
        stateName: "selectedSerialNo",
        nameKey: states.selectedSerialNo,
        isOpenDropdown: states.isOpenDropdown2,
         isOpenDropdownKey: states.isOpenDropdownKey2,
         refresh: states.meterSerialNoRefresh,
         refreshStateName: "meterSerialNoRefresh",
      //   labelCol: 2,
      //   wrapperCol: 4,
      },
      {
        id: 2,
        label: "Short Name ",
        optionsData: meter_shortname_data,
        dataExpr: "mtrShtName",
        idExpr: "mtrShtName",
        stateName: "selectedShortName",
        nameKey: states.selectedShortName,
        isOpenDropdown: states.isOpenDropdown3,
        isOpenDropdownKey: states.isOpenDropdownKey3,
        refresh: states.meterShortNameRefresh,
        refreshStateName: "meterShortNameRefresh",
      //   labelCol: 2,
      //   wrapperCol: 4,
      },
    ];

    return (
      <>
        {meter_loads ? (
          <CustomLoader />
        ) : (
          <div>
            <style
              dangerouslySetInnerHTML={{
                __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
              }}
            />

            {toggled && (
              <div className="w-full   border rounded-md">
                <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">
                  Meter Details
                </div>
                {/* The form */}
                <div className="py-2 flex ">
                  {/* Modals */}
                  {modals.map(
                    (
                      {
                        disableCancel,
                        icon,
                        okHandler,
                        cancelHandler,
                        open,
                        cancelText,
                        okText,
                        message,
                        title,
                      }: modalTypes,
                      index
                    ) => {
                      return (
                        <div key={index}>
                          <ModalTemplate
                            icon={icon && icon}
                            disableCancel={disableCancel}
                            cancelText={cancelText && cancelText}
                            open={open}
                            okHandler={okHandler}
                            cancelHandler={cancelHandler}
                            message={message}
                            okText={okText}
                            title={title}
                          />
                        </div>
                      );
                    }
                  )}

                  <Form
                    {...formPropsConst}
                    wrapperCol={{ span: 18 }}
                    className="ml-2  "
                    style={{ width: "450px" }}
                  >
                    {/* <Form.Item label={<p className='text-xs'>{'Start Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} format={dateFormat} style={{ display: 'inline-block', width: 'calc(65%)' }} />


               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'End Date'}</p>} style={{ marginBottom: 0 }}>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} style={{ display: 'inline-block', width: 'calc(65%)' }} format={dateFormat} />


               </Form.Item> */}

                    {/* <Form.Item label={<p className='text-xs'>{'Meter No'}</p>} style={{ marginBottom: 0 }}>
                  <Form.Item
                     name="issuedBy"
                     rules={[{ required: false }]}
                     style={{ display: 'inline-block', width: 'calc(95.5%)' }}
                  > <div className="flex flex-row items-center">
                        <Select
                           className='W w-full mr-0 mb-0.5 py-0'
                           placeholder=""
                              value={states.selectedMeterNo}
                              onChange={(e:any) => {
                                 runGenerator('selectedMeterNo', e);
                              }}

                              // defaultValue={states.rowData.mtrMeterNo}
                              // key={states.rowData.mtrMeterNo}
                              disabled={states.deactivateFields}
                           >
                              
                        {meter_no_data?.map((option: any, index: number) => {
                           return(
                              <Option key={index} value={option["mtrMeterNo"!]}>
                                 {`${option["mtrMeterNo"]}`}
                              </Option>
                           );
                        })}
                           
                        </Select>

                        <span
                                                    onClick={()=>{
                                                      
                                                    }}
                                                    style={{height:22.5, marginBottom:0.6,borderWidth:1,width:25.2}}
                                                    className=' ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                </span>
                     </div>
                  </Form.Item>
               </Form.Item> */}
                    {datalist_selects.map((datalist_select: selectsTypes) => {
                      return (
                        <div className="">
                          {/* <SetupsDatalistTemplat
                                 datalistStyle={{ width: '295px' }}

                                 outerDisable={states.deactivateFields}
                                 listid={datalist_select.label}
                                 span={true}
                                 nameExpr={datalist_select.nameExpr}
                                 idExpr={datalist_select.idExpr}
                                 selectedData={datalist_select.nameKey}
                                 useCallbackFunc
                                 setCallbackValue={(value: string | number) => {
                                    runGenerator(datalist_select.stateName, value);
                                 }}
                                 label={datalist_select.label}
                                 options={datalist_select.optionsData} /> */}
                          <Form.Item
                      label={<p className="text-xs">{datalist_select.label}</p>}
                      style={{ marginBottom: 0,  }}
                    >
                      <Form.Item
                        name="requestWindow"
                        rules={[{ required: false }]}
                        style={{ display: "inline-block", width: "calc(95.5%)" }}
                      >
                        {" "}
                            <InputSelect_template
                              placeHolder={datalist_select.label}
                              disabled={states.deactivateFields}
                              idexpr={datalist_select.idExpr}
                              dataexp={datalist_select.dataExpr}
                              useCallFunc
                              options={datalist_select.optionsData}
                              selectStyle={{ width: 290 }}
                              isOpenDropdown={datalist_select.isOpenDropdown}
                              handleRefresh={() => {
                                 updateState(datalist_select.stateName!, "");
                                 updateState(datalist_select.refreshStateName!, !datalist_select.refreshStateName);
                               }}
                               handleFocus={(e: any) => {
                                 updateState(datalist_select.isOpenDropdownKey, !datalist_select.isOpenDropdown);
                               }}                              
                            />
                          </Form.Item>
                          </Form.Item>
                        </div>
                      );
                    })}

                    {/* <Form.Item label={<p className='text-xs'>{'Serial No'}</p>} style={{ marginBottom: 0 }}>
                  <Form.Item
                     name="issuedBy"
                     rules={[{ required: false }]}
                     style={{ display: 'inline-block', width: 'calc(95.5%)' }}
                  > <div className="flex flex-row items-center">
                        <Select
                           className='W w-full mr-0 mb-0.5 py-0'
                           placeholder="" 
                           value={states.selectedSerialNo}
                           onChange={(e:any) => {
                              runGenerator('selectedSerialNo', e)
                           }}
                           disabled={states.deactivateFields}

                           >
                              {meter_serial_no_data?.map((option: any, index: number) => {
                           return(
                              <Option key={index} value={option["mtrSerialNo"!]}>
                                 {`${option["mtrSerialNo"]}`}
                              </Option>
                           );
                        })}
                        </Select>

                        <span
                                                    onClick={()=>{
                                                     
                                                    }}
                                                    style={{height:22.5, marginBottom:0.6,borderWidth:1,width:25.2}}
                                                    className=' ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                </span>
                     </div>
                  </Form.Item>
               </Form.Item>

               <Form.Item label={<p className='text-xs'>{'Short Name'}</p>} style={{ marginBottom: 0 }}>
                  <Form.Item
                     name="receivedBy"
                     rules={[{ required: false }]}
                     style={{ display: 'inline-block', width: 'calc(95.5%)' }}
                  > <div className="flex flex-row items-center">
                        <Select
                           className='W w-full mr-0 mb-0.5 py-0'
                           placeholder="" 
                           value={states.selectedShortName}
                           onChange={(e:any) => {
                              runGenerator('selectedShortName', e)
                           }}

                           defaultValue={states.rowData.mtrShtName}
                           key={states.rowData.mtrShtName}
                           disabled={states.deactivateFields}
                           >
                            {meter_shortname_data?.map((option: any, index: number) => {
                           return(
                              <Option key={index} value={option["mtrShtName"!]}>
                                 {`${option["mtrShtName"]}`}
                              </Option>
                           );
                        })}
                        </Select>

                        <span
                                                    onClick={()=>{
                                                     
                                                    }}
                                                    style={{height:22.5, marginBottom:0.6,borderWidth:1,width:25.2}}
                                                    className=' ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                </span>
                     </div>
                  </Form.Item>
               </Form.Item> */}

                    <Form.Item
                      label={<p className="text-xs">{"Current Station"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="requestWindow"
                        rules={[{ required: false }]}
                        style={{ display: "inline-block", width: "295px" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className="bg-amber-50"
                            value={states.selectedCurrentStation}
                            onChange={(e: any) => {
                              runGenerator(
                                "selectedCurrentStation",
                                e.target.value
                              );
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Current Feeder"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="requestWindow"
                        rules={[{ required: false }]}
                        style={{ display: "inline-block", width: "295px" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className="bg-amber-50"
                            value={states.selectedCurrentFeeder}
                            onChange={(e: any) => {
                              runGenerator(
                                "selectedCurrentFeeder",
                                e.target.value
                              );
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Current Customer"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="requestWindow"
                        rules={[{ required: false }]}
                        style={{ display: "inline-block", width: "295px" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className="bg-amber-50"
                            value={states.selectedCurrentCustomer}
                            onChange={(e: any) => {
                              runGenerator(
                                "selectedCurrentCustomer",
                                e.target.value
                              );
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Manufacturer"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedManufacturer.value}
                            onChange={(e: any) => {
                              const manufacturer = JSON.parse(e);
                              runGenerator("selectedManufacturer", {
                                id: manufacturer.mftIdpk,
                                value: manufacturer.mftName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_manuf_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["mftName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Accuracy Class"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedAccuracyClass?.value}
                            onChange={(e: any) => {
                              console.log(e);
                              const selected = JSON.parse(e);
                              runGenerator("selectedAccuracyClass", {
                                id: selected.aclIdpk,
                                value: selected.aclName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_acc_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["aclName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Supply Connection"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedSupplyConnection?.value}
                            onChange={(e: any) => {
                              const selected = JSON.parse(e);

                              runGenerator("selectedSupplyConnection", {
                                id: selected.spcIDpk,
                                value: selected.spcName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_supplycon_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["spcName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Display Type"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedDisplayType?.value}
                            onChange={(e: any) => {
                              const selected = JSON.parse(e);

                              runGenerator("selectedDisplayType", {
                                id: selected.dptIDpk,
                                value: selected.dptName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_disptype_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["dptName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Active"}</p>}
                      style={{ marginBottom: "1px", }}
                    >
                      <Form.Item
                        name="year"
                        style={{ display: "inline-block",}}
                      >
                        <div>
                          <Checkbox
                            checked={states.selectedActive}
                            onChange={(e) => {
                              runGenerator("selectedActive", e.target.checked);
                            }}
                          ></Checkbox>
                        </div>
                      </Form.Item>
                      <Form.Item
                        // labelCol={{ span: 10 }}
                        label={<p className="text-xs">{"Station Service?"}</p>}
                        name="month"
                        style={{ display: "inline-block", marginLeft: "170px" }}
                      >
                        <div>
                          <Checkbox
                            checked={states.selectedStationService}
                            onChange={(e: any) => {
                              runGenerator(
                                "selectedStationService",
                                e.target.checked
                              );
                            }}
                          ></Checkbox>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      style={{ display: "inline-block", marginBottom: "2px" }}
                      label={<p className="text-xs">{"Verified?"}</p>}
                      className="w-full mb-1"
                    >
                      <div className=" items-end" style={{ width: "320px" }}>
                        <div className="w-full flex  flex-row justify-between  ">
                          <div>
                            <Checkbox
                              checked={states.selectedVerified}
                              onChange={(e: any) => {
                                runGenerator(
                                  "selectedVerified",
                                  e.target.checked
                                );
                              }}
                              disabled={states.deactivateFields}
                            ></Checkbox>
                          </div>
                          <ul className="flex mr-5">
                            {states.showUpdateButton === true ? (
                              <UpdateButton
                                handleUpdate={() => validateFields("update")}
                              />
                            ) : (
                              <SaveButton
                                useCallbackFunc
                                handleSave={() => validateFields("save")}
                              />
                            )}
                            {states.showCancelButton === true ? (
                              <Cancel
                                useCallbackFunc={true}
                                cancel_button_toggler={() => {
                                  runGenerator("showUpdateButton", false);
                                  runGenerator("showCancelButton", false);
                                  runGenerator("deactivateFields", true);
                                  runGenerator("rowData", {});
                                  dispatch(task_form_disable(true));
                                  // dispatch(update_route_state(false))
                                  dispatch(task_footer_update(false));
                                }}
                              />
                            ) : (
                              <NewButton
                                useCallbackFunc={true}
                                new_button_toggler={() => {
                                  setStatesFromGrid([], true);
                                  runGenerator("deactivateFields", false);
                                  runGenerator("showCancelButton", true);
                                  runGenerator("rowData", {});
                                  dispatch(task_footer_table_selected({}));
                                }}
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>

                  <Form
                    {...formPropsConst}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    className="ml-2 "
                    style={{ width: "30%" }}
                  >
                    <Form.Item
                      label={<p className="text-xs">{"Current Status"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedCurrentStatus?.value}
                            onChange={(e: any) => {
                              const selected = JSON.parse(e);
                              runGenerator("selectedCurrentStatus", {
                                id: selected.estIDpk,
                                value: selected.estName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_currentstatus_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["estName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Firmware"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        rules={[{ required: false }]}
                        style={{
                          display: "inline-block",
                          width: "calc(95.5%)",
                        }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Select
                            className="W w-full mr-0 mb-0.5 py-0"
                            placeholder=""
                            value={states.selectedFirmware?.value}
                            onChange={(e: any) => {
                              const selected = JSON.parse(e);

                              runGenerator("selectedFirmware", {
                                id: selected.fmwIDpk,
                                value: selected.fmwName,
                              });
                            }}
                            disabled={states.deactivateFields}
                          >
                            {meter_firmware_data?.map(
                              (option: any, index: number) => {
                                return (
                                  <Option
                                    key={index}
                                    value={JSON.stringify(option)}
                                  >
                                    {`${option["fmwName"]}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              marginBottom: 0.6,
                              borderWidth: 1,
                              width: 25.2,
                            }}
                            className=" ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded"
                          >
                            <img
                              className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={reset}
                              alt="reset"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"CT Ratio"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(95%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className="bg-amber-50"
                            value={states.selectedCTRatio}
                            onChange={(e: any) => {
                              runGenerator("selectedCTRatio", e.target.value);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"PT Ratio"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="requestWindow"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(95%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <Input
                            style={{ marginBottom: 0 }}
                            className="bg-amber-50"
                            value={states.selectedPTRatio}
                            onChange={(e: any) => {
                              runGenerator("selectedPTRatio", e.target.value);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Manufacture Date"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DatePicker
                            size="small"
                            placeholder=""
                            style={{ width: "95%" }}
                            format={dateFormat}
                            value={dayjs(states.selectedManufacturerDate)}
                            onChange={(e: any) => {
                              runGenerator("selectedManufacturerDate", e);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Supply Date"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DatePicker
                            size="small"
                            placeholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                            value={dayjs(states.selectedSupplyDate)}
                            onChange={(e: any) => {
                              runGenerator("selectedSupplyDate", e);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={
                        <p className="text-xs">{"Installation Start Date"}</p>
                      }
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DatePicker
                            size="small"
                            placeholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                            value={dayjs(states.selectedInstallationStartDate)}
                            onChange={(e: any) => {
                              runGenerator("selectedInstallationStartDate", e);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Commissioning Date"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DatePicker
                            size="small"
                            placeholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                            value={dayjs(states.selectedCommissioningDate)}
                            onChange={(e: any) => {
                              runGenerator("selectedCommissioningDate", e);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={
                        <p className="text-xs">{"Decommissioning Date"}</p>
                      }
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="receivedBy"
                        rules={[{ required: true }]}
                        style={{ display: "inline-block", width: "calc(100%)" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <DatePicker
                            size="small"
                            placeholder="Start Date"
                            style={{ width: "95%" }}
                            format={dateFormat}
                            value={dayjs(states.selectedDecommissioningDate)}
                            onChange={(e: any) => {
                              runGenerator("selectedDecommissioningDate", e);
                            }}
                            disabled={states.deactivateFields}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs">{"Remarks"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        style={{
                          display: "inline-block",
                          marginBottom: "1px",
                          width: "calc(290%)",
                        }}
                        className=" mb-1 mr-2 "
                      >
                        <TextArea
                          style={{
                            resize: "none",
                            maxHeight: "80px",
                            minHeight: "80px",
                            height: "80px",
                            width: "",
                          }}
                          className="o  overflow-y-scroll resize-none"
                          rows={3}
                          value={states.selectedRemarks}
                          onChange={(e: any) => {
                            runGenerator("selectedRemarks", e.target.value);
                          }}
                          readOnly={states.deactivateFields}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            )}

            <div className="pt-0.5">
              <Datagrid_template
                gridheight={toggled === true ? pageHeight - 358 : pageHeight}
                columns={meter_TH}
                data={meter_data}
                onRowClick={(e: any) => datagridRowClick(e)}
                rowDoubleClicked={datagridRowDoubleClick}
              />
            </div>
          </div>
        )}
      </>
    );
  }
);

export default MeterDetails;
