export const capacitor_bank_TH = [
  {
    id: 0,
    caption: "NOMENCLATURE",
    dataField: "cbkNomenclature",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 1,
    caption: "SHORT NAME",
    dataField: "cbkShtName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 2,
    caption: "STATION",
    dataField: "fstnName",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 3,
    caption: "ACTIVE?",
    dataField: "cbkActive",
    alignment: "left",
    fixed: true,
    width: null,
  },
  {
    id: 4,
    caption: "START DATE",
    dataField: "cbkStartDate",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 5,
    caption: "END DATE",
    dataField: "cbkEndDate",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 6,
    caption: "REMARKS",
    dataField: "cbkRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
  {
    id: 7,
    caption: "QTY LEFT",
    dataField: "qtyLeft",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 8,
    caption: "ACTIVE",
    dataField: "active",
    alignment: "left",
    fixed: false,
    width: 60,
  },
  {
    id: 9,
    caption: "SIGNED?",
    dataField: "signed",
    alignment: "left",
    fixed: false,
    width: 60,
  },
  {
    id: 10,
    caption: "REQUEST WINDOW",
    dataField: "requestWindow",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 11,
    caption: "BATCH No",
    dataField: "batchNo",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 12,
    caption: "ISS. STAFF No",
    dataField: "isStaffNo",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 13,
    caption: "ISSUED BY",
    dataField: "issuedBy",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 14,
    caption: "REC. STAFF No",
    dataField: "recStaffNo",
    alignment: "left",
    fixed: false,
    width: null,
  },

  {
    id: 15,
    caption: "RECEIVED BY",
    dataField: "receivedBy",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 16,
    caption: "ASSIGNED LOC.",
    dataField: "assignedLoc",
    alignment: "left",
    fixed: false,
    width: null,
  },
  {
    id: 17,
    caption: "REMARKS",
    dataField: "cbkRmks",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
  {
    id: 18,
    caption: "fstnIdpk",
    dataField: "fstnIdpk",
    alignment: "left",
    fixed: false,
    width: 4000,
  },
];

export const capacitor_bank_data = [];
