import React, { useState } from 'react'
import { searchCriteriaTypes, searchTextTypes, selectsTypes } from '../../../../../../human-resource/setups/data/types/selectsTypes'
import { SelectsTemplate } from '../../../../../../../templates/select'
import { DateTemplate } from '../../../../../../../templates/date'
import  Datagrid_template from '../../../../../../../templates/Datagrid'
import { Form } from 'antd'
import { FaBinoculars } from 'react-icons/fa'
import { Tooltip } from 'devextreme-react'
import dayjs from 'dayjs'
import { footer_datagrid_fuelreturns_cols, footer_datagrid_service_pro_cols } from '../data/datagrid_cols'
import charticon from '../../../../../../../assets/3dline.png';
import useFetch from '../../../../../../../hooks/useFetch'

interface props {
    handleFind?: () => void
    manualData : any
    height ?: any
}

interface stateTypes {
    startDate ?: string
    endDate ?: string
    chartCriteria1State?: { id: number, name: string },
    chartCriteria2State?: { id: number, name: string },
    chartCriteria3State?: { id: number, name: string },
    vehNoState?: { id: number, name: string },
}

export const ChartEntries = ({ handleFind, manualData,height }: props) => {

    const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
        temp: 0,
        index: 0,
      });

      const [searchText, setSearchText] = useState<searchTextTypes>({
        temp: "",
        text: "",
      });

    const editedDate = dayjs().format("DD MMM YYYY");
    const startingDate = dayjs().startOf("month").format("DD MMM YYYY");

   

    const chartCriteria1 = [

        { id: 0, value: 'Vehicle Fuel Amount (GHC)' },
        { id: 1, value: 'Vehicle Fuel Quantity (Ltrs)' },
        { id: 2, value: 'Vehicle Fuel Amount & Quantity' },

    ]
    const chartCriteria2 = [

        { id: 0, value: 'Bar' },
        { id: 1, value: 'Line' },
        { id: 2, value: 'Area' },

    ]
    const chartCriteria3 = [
        { id: 0, value: 'Registration No' },
    ]


    const [states] = useState<stateTypes>({
        startDate : startingDate,
        endDate : editedDate,
        chartCriteria1State : { id: chartCriteria1[0].id, name: chartCriteria1[0].value },
        chartCriteria2State : { id: chartCriteria2[0].id, name: chartCriteria2[0].value },
        chartCriteria3State : { id: chartCriteria3[0].id, name: chartCriteria3[0].value },
        vehNoState : { id: 0, name: '' },
    });


    const [startDate, setStartDate] = useState<string>(startingDate);
    const [endDate, setEndDate] = useState<string>(editedDate);

    const [spLogs] = useFetch(`FuelManagement/SearchSeviceLog?startDate=${startDate}&endDate=${endDate}&criteria=${searchCriteria.index}&toggle=true&search=${searchText.text}`)
    


    const [veh_data] = useFetch(`FuelManagement/dumpVehiclesAll`)
    const topNav: selectsTypes[] = [
        { id: 0, label: '', idExpr: 'id', dataExpr: 'value', optionsData: chartCriteria1, stateName: '', defaultValue: states.chartCriteria1State?.name, style: '99%' },
        { id: 1, label: '', idExpr: 'id', dataExpr: 'value', optionsData: chartCriteria2, stateName: '', defaultValue: states.chartCriteria2State?.name, style: '99%' },

    ]
    const topNav2: selectsTypes[] = [
        { id: 0, label: '', idExpr: '', dataExpr: '', optionsData: [], stateName: '', defaultValue: states.startDate, style: '99%' },
        { id: 1, label: '', idExpr: 'id', dataExpr: 'value', optionsData: chartCriteria3, stateName: '', defaultValue: states.chartCriteria3State?.name, style: '99%' },

    ]
    const topNav3: selectsTypes[] = [
        { id: 0, label: '', idExpr: '', dataExpr: '', optionsData: [], stateName: '', defaultValue: states.endDate, style: '99%' },
        { id: 1, label: '', idExpr: 'vdtIDpk', dataExpr: 'vdtRegNo', optionsData: veh_data, stateName: 'searchText', defaultValue: '', style: '99%' },

    ] 

    return (
        <div style={{height: height}} className='w-full flex justify-between gap-1 px-2'>

            <div className='w-1/2'>
                {/* Top Navigation */}
                <p className="pt-1 mx-2 pl-4 w-full bg-slate-100 rounded-tl text-blue-600">
                    Service Provider Transaction Logs
                </p>
                <div className='w-full flex '>
                    <Form
                        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
                        name={"basic"}
                        labelCol={{ span: 2 }}
                        // wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        size="small"
                        layout="horizontal"
                    >
                        <div className='w-full flex '>
                            <div className='w-1/3'>
                                {topNav.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <div className='w-full '>
                                            <SelectsTemplate
                                                idexpr={idExpr}
                                                dataexp={dataExpr}
                                                placeHolder={defaultValue}
                                                disabledCustomWidth={true}
                                                cusWidth={style}
                                                useCusWidth={true}
                                                label={''}
                                                span
                                                useCallFunc
                                                options={optionsData}
                                                selectedValue={(e) => { }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>

                            {/* 2nd */}
                            <div className='w-1/3'>
                                {topNav2.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <div className='w-full '>
                                            {id === 0 ?
                                                <div className=''>
                                                    <DateTemplate
                                                        label={''}
                                                        width={style} 
                                                        changeDate={(e : any) => { setStartDate(dayjs(e).format('DD MMM YYYY')) }}
                                                        // selectedDate={dayjs(defaultValue)}
                                                    />
                                                </div>
                                                :
                                                <SelectsTemplate
                                                    idexpr={idExpr}
                                                    dataexp={dataExpr}
                                                    label={''}
                                                    placeHolder={defaultValue}
                                                    disabledCustomWidth={true}
                                                    cusWidth={style}
                                                    useCusWidth={true}
                                                    span
                                                    useCallFunc
                                                    options={optionsData}
                                                    selectedValue={(e) => { setsearhCriteria(prev => ({...prev, temp: e.id}))}}
                                                />
                                            }
                                        </div>
                                    )
                                })}

                            </div>

                            {/* 3rd */}
                            <div className='w-1/3'>
                                {topNav3.map(({ id, label, idExpr, dataExpr, optionsData, stateName, defaultValue, style }) => {
                                    return (
                                        <div className='w-full '>
                                            {id === 0 ?
                                                <div className=''>
                                                    <DateTemplate
                                                        label={''}
                                                        width={style}
                                                        changeDate={(e : any) => { setEndDate(dayjs(e).format('DD MMM YYYY')) }}
                                                       
                                                    />
                                                </div>
                                                :
                                                <SelectsTemplate
                                                    idexpr={idExpr}
                                                    dataexp={dataExpr}
                                                    label={''}
                                                    placeHolder={defaultValue}
                                                    span
                                                    disabledCustomWidth={true}
                                                    cusWidth={style}
                                                    useCusWidth={true}
                                                    useCallFunc
                                                    options={optionsData}
                                                    selectedValue={(e) => { setSearchText(prev => ({...prev, temp: e.vdtRegNo})) }}
                                                />
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </Form>
                    <li id="find11" style={{ borderWidth: '1px' }} onClick={() => { setSearchText(prev => ({...prev, text: prev.temp})); setsearhCriteria(prev => ({...prev, index: prev.temp})) }} className="hover:cursor-pointer mt-1 mr-1 border-slate-200 text-xs px-4 justify-center text-gray-600 rounded flex items-center">
                        <span> <FaBinoculars size={20} color="#007AFF" /> </span>
                        <Tooltip
                            target="#find11"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                        >
                            <p>Find</p>
                        </Tooltip>
                    </li>

                    <ul>
                        <li id="find22" style={{ borderWidth: '1px' }} onClick={() => {}} className="hover:cursor-pointer my-1 justify-center border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                            <span className='w-4 flex justify-center items-center'> <img src={charticon} className='flex justify-center items-center' alt="image here" /> </span>
                            <Tooltip
                                target="#find22"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p>Show Chart</p>
                            </Tooltip>
                        </li>

                        <li style={{ borderWidth: '1px' }} className='w-full flex  justify-center items-center'><span className='w-full justify-center items-center flex text-xs'>Show Chart</span></li>
                    </ul>

                </div>


                <div>
                    <Datagrid_template
                        gridheight={height - 100}
                        columns={footer_datagrid_service_pro_cols}
                        data={spLogs} 
                    />
                </div>
            
            </div>

            <div className='w-1/2 '>
                <div className='w-full flex justify-between bg-slate-100 h-6'>
                    <div className='w-2/3'>
                        <p className="pt-1 mx-2 pl-4 w-full   rounded-tl text-blue-600">
                            Manual Fuel Purchase Logs
                        </p>
                    </div>

                    <div className='w-1/3 flex justify-end space-x-1 items-center list-none  '>
                        <li className='border-[1px] px-1 rounded '><span>Show Chart</span></li>
                        <li id="find33" style={{ borderWidth: '1px' }} onClick={() => { if (handleFind) { handleFind() } else { } }} className="hover:cursor-pointer my-1 w-16 justify-center border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center">
                            <span className='w-4 flex justify-center items-center'> <img src={charticon} className='flex justify-center items-center' alt="image here" /> </span>
                            <Tooltip
                                target="#find33"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                hideOnOutsideClick={true}
                            >
                                <p>Find</p>
                            </Tooltip>
                        </li>
                    </div>

                </div>
                <div>
                    <Datagrid_template
                        gridheight={height - 30}
                        columns={footer_datagrid_fuelreturns_cols}
                        data={manualData}
                  
                    />
                </div>

            </div>

        </div>
    )
}
