import { useEffect, useRef, useState } from "react";
// import type { SelectProps } from 'antd';
import { format } from "date-fns";
import axios from "axios";
import { TabPanel, Tooltip } from "devextreme-react";
import { Item } from "devextreme-react/accordion";
import { ModalTemplate } from "../../../../../templates/modal";
import {
  navbar_height,
  api_url,
  currentDate,
} from "../../../../accessories/component_infos";
import { footer_datagrid_cols } from "./data/datagrid_cols";
import { Footer_datagrid_template } from "./data/footer_datagrid";
import { ActivitiesCapture_tab2_Details } from "./widgets/activities_capture_tab2";
import { ActivitiesCapture_Job_Details } from "./widgets/activity_capture_Job_details";
import { Activities_Capture_Details } from "./widgets/activities_capture_details";
import { ActivitiesCapture_update } from "./widgets/activities_capture_updates";
import { ActivitiesCapture_Details_Form } from "./widgets/activity_capture_detail_form";
import { ActivityCap_toolbar } from "../../../../accessories/custom-toolbars/activitesCapture_toolbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import useFetch from "../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../templates/Datagrid";
import { WarningAlert } from "../../../../accessories/warningAlert";
import useResizeMode from "../../../../../hooks/useResizeMode";
import dayjs from "dayjs";
import {
  searchCriteriaTypes,
  searchTextTypes,
  selectsTypes,
  statusTypes,
} from "../../../../human-resource/setups/data/types/selectsTypes";
import { GetDataFunc } from "../../../../../functions/get";
import {
  setGeneralValue,
  settask_form_disable,
} from "../../../../../features/generalSlice";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../features/Task&Todo/Task/TaskDetails";
import { SMSAlerts } from "../../../../accessories/custom-toolbars/widgets/entriesModal/SMSAlerts";
import { SMSAlerts2 } from "../../../../accessories/custom-toolbars/widgets/entriesModal/SMSAlerts2";
import { UpdateDataFunc } from "../../../../../functions/update";
import { useBusyLoader } from "../../../../../hooks/useBusyLoader";
import { PostDataFunc } from "../../../../../functions/post";
import { useAccountInfo } from "../../../../../hooks/useAccountInfo";
import { useCrudFunc } from "../../../../../functions/crud";
import { UpdateStates } from "../../../../../functions/updateStates";

interface props {
  state_change: boolean;
}

interface stateTypes {
  selectedTask_update?: any;
  openSelectedTask?: any;
  selectedRecord?: any;
  reportDate?: string;
  pmDriver?: { id: number; name: string };
  approvingOfficer?: { id: number; name: string };
  regNo?: { id: number; name: string };
  alertMessage?: string;
  workShop?: any;
  open?: boolean;
  refresh?: boolean;
  date?: string;
  isGridData: boolean;
  arrivalTime: any;
  departureTime: any;
  maintenanceType?: any;
  userInfo?: any;
  // DBdata?: any;
}

export const ActivitiesCapture = ({ state_change }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  // const width = window.innerWidth/2
  // const fixedWidth = 'calc(50% - 8px)';
  // const [selectedTask_update, setSelectedTask_update] = useState();
  const height = window.innerHeight - (navbar_height + news_flash_height + 0); //height without navbar
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [refresh, setRefresh] = useState(false);
  const [initialH, fullH] = useResizeMode(switch_change);
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 40);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 45);
  // const fullPage = window.innerHeight - (navbar_height + 45) ; //height without navbar

  //date
  const genFormat = "YYYY-MM-DD";
  const today = dayjs().endOf("month").format(genFormat);
  const initialStart = dayjs()
    .subtract(5, "year")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });
  const [searchText, setSearchText] = useState<searchTextTypes>({
    text: "",
    temp: "",
  });
  const [searchCriteria, setSearchCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const searchCriteriaData = [
    { id: 0, value: "Reg. No" },
    { id: 2, value: "Workshop" },
    { id: 3, value: "PM Item" },
  ];
  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15,
  });
  const [status, setStatus] = useState<statusTypes>({ temp: "", status: "" });
  const [posting, updating] = useCrudFunc();
  // switch toggle
  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };
  const formCode = useSelector((state: RootState) => state.general.formCode);
  // DATA from redux

  // Onchange States
  const [states, setStates] = useState<stateTypes>({
    selectedTask_update: [],
    openSelectedTask: [],
    selectedRecord: [],
    date: "",
    reportDate: "",
    pmDriver: { id: 0, name: "" },
    approvingOfficer: { id: 0, name: "" },
    alertMessage: "",
    workShop: { id: 0, name: "" },
    regNo: { id: 0, name: "" },
    open: true,
    refresh: false,
    isGridData: false,
    arrivalTime: currentDate,
    departureTime: currentDate,
    maintenanceType: [],
    userInfo: [],

    // DBdata : [],
  });

  console.log("Activity States", states);
  const [DBdata, setDBdata] = useState<any>([]);

  const [refreshstate, setRefreshstate] = useState<any>(false);
  // const [fetchDataGrid, dataGridErr, dataGridLoading] = useFetch(
  //   `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesForGdv?pmaActive=${status.status}&pmaDateIn=${startDate.text}&pmaDateOut=${endDate.text}&searchText=${searchText.text}&selectedIndex=${searchCriteria.index}`,
  //   refreshstate,
  //   "pmaIDpk"
  // );
  const [fetchDataGrid, dataGridErr, dataGridLoading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesForGdv?pagenumber=${paging.pageNumber}&pagesize=${paging.pageSize}&pmaActive=${status.status}&pmaDateIn=${startDate.text}&pmaDateOut=${endDate.text}&searchText=${searchText.text}&selectedIndex=${searchCriteria.index}`,
    refreshstate,
    "pmaIDpk"
  );
  //end points
  const [rdriver_data, rdriver_error, rdriver_loading] = useFetch(
    "AsmTmsEntMaintenanceActivity/GetEntAsmTmsLueReportingDrivers"
  );
  const [workshop_data, workshop_error, workshop_loading] = useFetch(
    `PmActivitiesCaptures/GetAllVwAsmTmsWorkshopDetailsFewCols`
  );
  const [approving_offs, approving_offs_error, approving_offs_loading] =
    useFetch(`AssetTamVehicleAssignment/GetApprovingOfficers`);

  const [AvYearCount, setAvYearCount] = useState();
  const [yearCount, yearCount_response, yearCount_loading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesActivityCountThisYear?pmaDateIn=${states.selectedRecord[0]?.pmaDateIn}`
  );
  const [AvMonthCount, setAvMonthCount] = useState();
  const [monthCount, monthCount_response, monthCount_loading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesActivityCountThisMonth?pmaDateIn=${states.selectedRecord[0]?.pmaDateIn}`
  );
  const [totalAvg, setTotalAvg] = useState();
  const [totalComp, totalComp_reponse, totalLoading] = useFetch(
    `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesActivityCount`
  );
  console.log("yearCount", yearCount, "monthCount", monthCount);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [pmDetailsData, setPmDetailsData] = useState<any>();
  // const DBdata_Fetch = async () => {
  //   const res = await GetDataFunc(
  //     `PmActivitiesCaptures/GetAllAsmTmsPmActivitiesForGdv?pmaActive=${status.status}&pmaDateIn=${startDate.text}&pmaDateOut=${endDate.text}&searchText=${searchText.text}&selectedIndex=${searchCriteria.index}`
  //   );
  //   if (res.data.data) {
  //     setDBdata(res.data.data.reverse().slice(0, 100));
  //   }
  //   if (DBdata) {
  //     console.log(DBdata);
  //     // setState('DBdata', res.data.data.reverse().slice(0,100))
  //     console.log("hiiiiiiiii");
  //   } else {
  //   }

  //   console.log(res.data);
  // };

  // Fxn to set states by passing parameters
  const setState = (key: string, value: any) => {
    console.log(key, value);
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  console.log(states);

  const [openAlertModal, setOpenAlertModal] = useState(false);

  //multiple selectmap
  const multipleSelectMap: selectsTypes[] = [
    {
      id: 0,
      label: "Driver",
      defaultValue: states.pmDriver?.name,
      idExpr: "empIDpk",
      dataExpr: "empNameStaffNo",
      optionsData: rdriver_data,
      stateName: "pmDriver",
    },
    {
      id: 1,
      label: "Approving Officer",
      defaultValue: states.approvingOfficer?.name,
      idExpr: "",
      dataExpr: "ownerEmpName",
      optionsData: approving_offs,
      stateName: "approvingOfficer",
    },
    {
      id: 2,
      label: "Workshop",
      defaultValue: states.workShop.name,
      idExpr: "wsdIdpk",
      dataExpr: "wsdName",
      optionsData: workshop_data,
      stateName: "workShop",
    },
  ];
  // Endpoint
  // const [DBdata, DBdata_error, DBdata_loading] = useFetch(`api/AsmTmsEntMaintenanceActivity/GetEntAsmTsmMaintenanceActivityAll`)

  const maintenanceRequestModal = {
    message: "Are you sure you want to open Job Card for this request",
    title: "Record Selected?",
    okText: "Yes, open",
    cancelText: "No, Cancel",
  };

  const MaterailModal = {
    message: "Are you sure you want to Submit materials for this request",
    title: "Materials Submission",
    okText: "Yes, Submit",
    cancelText: "No, Cancel",
  };
  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);

    const formattedDate = format(date, "EEE, dd MMM yyyy");

    return `${formattedDate}`;
  };

  const message = states.selectedTask_update[0]
    ? `Hello, ${
        states.pmDriver?.name ?? states.selectedTask_update[0]?.driverEmpName
      },  this is to inform you that your vehicle with Reg No '${
        states.selectedTask_update[0]?.vdtRegNo
      }' is due for Preventive Maintainance (PM).You are therefore requested to present the vehicle at the '${
        states.workShop.name
      }' workshop on '${states.date ?? currentDate}' at 00:00
Thank you.
Sent from innoX`
    : "No record selected";
  console.log("sss", states.pmDriver.name);

  const [modalData, setModalData] = useState<any>(maintenanceRequestModal);
  // When Open button is clicked
  const open = () => {
    try {
      if (states.selectedTask_update.length !== 0) {
        setIcon("question");
        setshowModalDisableButton(false);
        setModalData({
          message:
            "Are you sure you want to open this job card? (NB: please click on PM Details tab to continue)",
          title: "Open selected job card? ",
          okText: "Yes, open",
        });
        dispatch(task_form_disable(false));
        setOpenModal(true);
        setState("openSelectedTask", states.selectedTask_update);
        setState("maintenanceType", {
          mtpIdpk: 6,
          mtpName: "Preventive Maintenance",
        });
        setState("userInfo", {
          id: userId,
          mtpName: userInfo.empName,
        });
        dispatch(
          setGeneralValue({
            expr: "RegNo",
            value: {
              id: states.selectedTask_update[0].vdtIDpk,
              name: states.selectedTask_update[0]?.vdtRegNo,
            },
          })
        );
        setState("isGridData", false);
      } else {
        dispatch(task_form_disable(true));
        // modal here
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "Please select a Record",
          title: "Grid Record Selection Error",
          okText: "Ok",
        });
        setOpenModal(true);
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: err.message,
        title: "Record Selection!",
        okText: "Ok",
      });
    }
  };

  const cancel = () => {
    if (states.selectedTask_update.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const openScheduleAlert = () => {
    if (states.selectedTask_update.length === 0) {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select a Record",
        title: "Grid Record Selection Error",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setOpenAlertModal(true);
    }
  };

  const Submit = async () => {};
  const runOpen = async () => {
    setPmDetailsData(states.selectedTask_update[0]);
    setOpenModal(false);
  };
  //send mail
  const sendMail = async () => {
    try {
      setBusyLoader("Sending SMS....");
      await posting(`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsRecipientEmpIdfk:
          states.selectedTask_update[0]?.mrqRequestedByEmpIDfk,
        imsSender: userInfo.empName,
        imsRecordIdfk: states.selectedTask_update[0]?.pmaIDpk,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });
      setIcon("success");
      setshowModalDisableButton(true);
      setModalData({
        message: "SMS sent successfully!",
        title: "SMS Alert!",
        okText: "Ok",
      });
      setOpenModal(true);
      setBusyLoader("");
    } catch (err: any) {
      console.log(err);
      setIcon("warning");
      setModalData({
        message: "Failed to send SMS. Please try again!",
        title: "SMS not sent! ",
        okText: "Ok",
        iconSize: "large",
      });
      setshowModalDisableButton(true);
      setOpenModal(true);
      setBusyLoader("");
    }
  };
  const mail = () => {
    setIcon("question");
    setModalData({
      message: "Are you sure you want to send SMS?",
      title: "Send SMS? ",
      okText: "Ok",
      iconSize: "small",
    });
    setshowModalDisableButton(true);
    setOpenModal(true);
    setOpenAlertModal(false);
  };

  console.log(states.openSelectedTask);
  const iconBigger =
    "The amount for the material selected is either above or below your approval limits. Please check and try again!";

  useEffect(() => {
    // DBdata_Fetch();
    set_form_height(formRef.current?.clientHeight + 45);
    console.log(formRef);

    const fetchData = async () => {
      const aveargeYr = await yearCount();
      if (yearCount_response.ok) setAvYearCount(aveargeYr);

      const averageMonth = await monthCount();
      if (monthCount_response.ok) setAvMonthCount(averageMonth);
      const total = await totalComp();
      if (totalComp_reponse.ok) setTotalAvg(total);
    };

    fetchData();
  }, [
    refreshstate,
    yearCount,
    yearCount_response,
    monthCount,
    monthCount_response,
    totalComp_reponse,
    totalComp,
  ]);
  const percentage = yearCount_loading ? 0 : yearCount[0]?.pmaPercentComplete;
  const monthPercentage = monthCount_loading
    ? 0
    : monthCount[0]?.pmaPercentComplete;
  const totalPercentage = totalLoading ? 0 : totalComp[0]?.pmaPercentComplete;

  const smsCheckboxes = [
    {
      id: 0,
      label1: "Driver",
      label2: "Approving Officer",
      stateName1: "",
      stateName2: "",
      defaultValue: "",
    },
  ];

  console.log("isGridData", states.isGridData);
  console.log("status", status);
  return (
    <>
      {dataGridErr && <WarningAlert />}
      <div
        style={{ height: "calc(100)" }}
        className="  flex dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
      >
        {/* toggles between the taskForm and the footer grid table vertically */}
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 50}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={icon === "question" ? false : true}
          cancelHandler={() => {
            setOpenModal(false);
            dispatch(task_form_disable(true));
          }}
          open={openModal}
          okHandler={() => {
            // runOpen();
            modalData.message === "Are you sure you want to send SMS?"
              ? sendMail()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />

        <SMSAlerts2
          open={openAlertModal}
          cancelHandler={() => {
            setOpenAlertModal(false);
          }}
          okText={""}
          title={"Transport - Schedule PM"}
          message={message}
          isMultipleSelects={true}
          // ismultiCheckboxes={true}
          selectedValue={() => {}}
          checkboxes={smsCheckboxes}
          useCallFunc
          handleDate={(date: any) => {
            setState("date", dayjs(date).format("YYYY-MM-DD"));
          }}
          selectedDate={states.date}
          Clabel="Prompt"
          multipleSelects={multipleSelectMap}
          updateStatesx={setState}
          setCallbackValue={(e) => {}}
          sendMail={mail}
        />

        <div
          style={{ width: "calc(100%)" }}
          className="h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
        >
          <div className="mx-2">
            <ActivityCap_toolbar
              startDate={startDate.temp}
              endDate={endDate.temp}
              startDateChanged={(date) =>
                setStartDate({
                  temp: dayjs(date).format(genFormat),
                  text: startDate.text,
                })
              }
              endDateChanged={(date) =>
                setEndDate({
                  temp: dayjs(date).format(genFormat),
                  text: endDate.text,
                })
              }
              searchTextOnchange={(text) =>
                setSearchText({ temp: text, text: searchText.text })
              }
              checkOnChange={(check) =>
                setStatus({ temp: check, status: status.status })
              }
              handleFind={() => {
                setStartDate({ text: startDate.temp, temp: startDate.temp });
                setEndDate({ text: endDate.temp, temp: endDate.temp });
                setSearchText({ temp: searchText.temp, text: searchText.temp });
                setStatus({ temp: status.temp, status: status.temp });
                setSearchCriteria({
                  temp: searchCriteria.temp,
                  index: searchCriteria.temp,
                });
              }}
              searchCriteriaOnchange={(newIndex, criteria) => {
                setSearchCriteria({
                  temp: newIndex,
                  index: searchCriteria.index,
                });
              }}
              searchCriteriaData={searchCriteriaData}
              opened={open}
              updateStates={setState}
              handleRefresh={() => {
                setRefreshstate(!refreshstate);
                setSearchText({ temp: "", text: "" });
                setStatus({ temp: "", status: "" });
              }}
              toggle={() => {
                setSwitch_change(!switch_change);
              }}
              change={setSwitch_change}
              selectedRecord={states.selectedRecord[0]}
            />
          </div>
          <div className="">
            {switch_change && (
              <div
                ref={formRef}
                className="px-2 flex justify-between gap-x-2 border-b-2  mb-0.5 pb-0.5"
              >
                {/* Internal toggler : expands the right taskUpdate horizontally */}

                <div style={{ width: "700px", height: "500px" }}>
                  <TabPanel className="w-full border">
                    <Item title={"PM Schedules"}>
                      <ActivitiesCapture_Details_Form
                        setState={setState}
                        opened={open}
                        openScheduleAlert={openScheduleAlert}
                        selectedTask={states.selectedTask_update[0]}
                        footerGridData={states.selectedRecord[0]}
                        refresh={refreshstate}
                      />
                    </Item>

                    <Item title={"PM Details"}>
                      <ActivitiesCapture_tab2_Details
                        arrivalTime={states.arrivalTime}
                        departureTime={states.departureTime}
                        selectedRecord={states.selectedRecord[0]}
                        updateStates={setState}
                        datgridCol={pmDetailsData}
                        openedTask={states.openSelectedTask[0]}
                        mainType={states.maintenanceType}
                        userInfox={states.userInfo}
                        setrefresh={setRefreshstate}
                        refresh={refreshstate}
                        opened={open}
                      />
                    </Item>
                  </TabPanel>
                </div>

                {/* Double tap(click) feature to toggle to and fro of the right taskUpdate section */}
                <div
                  style={{ width: "calc(50%)" }}
                  className="h-full w-full  rounded-md items-center dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base  "
                >
                  <TabPanel className="w-full h-full  border">
                    <Item title={"Inventory Checklist"}>
                      <ActivitiesCapture_Job_Details
                        pmaId={states.selectedRecord[0]?.pmaIDpk}
                        selectedRecord={states.selectedRecord}
                        openSelectedTask={states.openSelectedTask}
                        updateStates={setState}
                        statesx={states}
                        regNo={states.regNo.name}
                      />
                    </Item>

                    <Item title={"Maintenance Checklist"}>
                      <Activities_Capture_Details
                        selectedRecord={states.selectedRecord[0]}
                        submit={Submit}
                        updateStates={setState}
                        statesx={states}
                      />
                    </Item>

                    <Item title={"PM Progress Updates"}>
                      <ActivitiesCapture_update
                        selectedRecord={states.selectedRecord[0]}
                      />
                    </Item>
                  </TabPanel>
                </div>
              </div>
            )}
          </div>

          {/* Footer gridtable  */}
          <div
            style={{ height: "" }}
            className="px-2 py-1 dark:bg-slate-900 dark:text-darkModeSkin-base"
          >
            <Datagrid_template
              gridheight={switch_change ? pageHeight : fullPage}
              columns={footer_datagrid_cols}
              data={fetchDataGrid}
              pageSize={paging.pageSize}
              pageNumber={paging.pageNumber}
              handlePageSize={(e: number) => {
                UpdateStates("pageSize", e, setPaging);
              }}
              handlePageNumber={(e: number) => {
                UpdateStates("pageNumber", e, setPaging);
              }}
              onRowClick={(data) => {
                dispatch(task_form_disable(true));
                dispatch(settask_form_disable(true));
                dispatch(task_footer_update(false));
                setSelectedRow(data);
                console.log("PERCY", data);
                setState("selectedRecord", [data]);
                setState("isGridData", true);
                // setState("openSelectedTask", [data]);
              }}
              rowDoubleClicked={(data: any) => {
                setSelectedRow(data);
                setState("selectedRecord", [data]);
                dispatch(task_form_disable(false));
                dispatch(settask_form_disable(false));
                dispatch(task_footer_update(true));
                setSwitch_change && setSwitch_change(true)
              }}
            />

            {/* <Footer_datagrid_template
            gridheight={}
            columns={footer_datagrid_cols}
            data={fetchDataGrid}
            setState={setState}
          /> */}
          </div>
          {/* Done section */}
          <section
            className="border-2 border-stone-300 flex justify-between align-middle mx-2 rounded-md bg-slate-100 items-center"
            style={{ height: "30px" }}
          >
            <div className="pl-2 text-blue-500   ">Done...</div>
            <div className="flex ">
              <div className="flex flex-col" id="progress1">
                <div className="w-60 h-4 mx-5 bg-gray-200 rounded-sm dark:bg-gray-700">
                  <div className="relative h-full flex ">
                    <div className="absolute h-full }bg-blue-600 text-xs font-medium text-blue-100 text-center leading-none rounded-sm "></div>
                    <div className="relative text-xs text-black w-full text-center">
                      {yearCount_loading
                        ? "Loading..."
                        : `${totalPercentage ?? "0"}%`}
                    </div>
                  </div>
                  <Tooltip
                    target="#progress1"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Total average percentage complete</p>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col" id="progress2">
                <div className="w-60 h-4 mx-5 bg-gray-200 rounded-sm dark:bg-gray-700">
                  <div className="relative h-full flex ">
                    <div className="absolute h-full }bg-blue-600 text-xs font-medium text-blue-100 text-center leading-none rounded-sm "></div>
                    <div className="relative text-xs text-black w-full text-center">
                      {yearCount_loading
                        ? "Loading..."
                        : `${percentage ?? "0"}%`}
                    </div>
                  </div>
                  <Tooltip
                    target="#progress2"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Current year average percentage complete</p>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col" id="progress3">
                <div className="w-60 h-4 mx-5 bg-gray-200 rounded-sm dark:bg-gray-700">
                  <div className="relative h-full flex ">
                    <div className="absolute h-full }bg-blue-600 text-xs font-medium text-blue-100 text-center leading-none rounded-sm "></div>
                    <div className="relative text-xs text-black w-full text-center">
                      {yearCount_loading
                        ? "Loading..."
                        : `${monthPercentage ?? "0"}%`}
                    </div>
                  </div>
                  <Tooltip
                    target="#progress3"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">
                      {" "}
                      Current month average percentage complete
                    </p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Right side */}
      </div>
    </>
  );
};

// Dummy data

const data: any = [
  {
    id: 0,
    officer: true,
    workshopOfficer: true,
    supervisor: false,
    SupUpperLimit: 4,
  },
  { id: 1, officer: false, supervisor: true, SupUpperLimit: 8 },
  {
    id: 2,
    officer: false,
    supervisor: false,
    manager: true,
    MgrUpperLimit: 12,
  },
];
