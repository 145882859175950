/* eslint-disable react/jsx-pascal-case */
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  Router,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import useFetch from "../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../templates/Datagrid";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";
import { HrSetupsCustomToolbar } from "../../../../../accessories/custom-toolbars/hrSetupsCustomtoolbar";
// import { searchTextTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import {
  searchCriteriaTypes,
  searchTextTypes,
  statusTypes,
} from "../../../../../human-resource/setups/data/types/selectsTypes";

import { CardDetailsColumns } from "./data/cardDetailsColumns";
import CardDtailsForm from "./widgets/cardDetailsForm";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { RootState } from "../../../../../../app/store";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../../../../templates/modal";
import React from "react";

export const CardDetails = forwardRef(({ a }: any, ref) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const dispatch = useDispatch();
  //navigation
  const setupPageRoute = window.location.pathname; //get current route of page
  const datagridOnlySearchParam = "table-only"; //search param in datagrid mode
  const formModeSearchParam = "form-and-table"; //search param in form mode mode
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [refresh, setrefresh] = useState<boolean>(false);
  const [error, setError] = useState(false);

  //miscellaneous heights
  // All heights out
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const [form_height, set_form_height] = useState<number>(300.5);
  const [pagination, setPagination] = React.useState<{
    pageSize: number;
    pageNumber: number;
  }>({
    pageSize: 15,
    pageNumber: 1,
  });

  var [initHeight, fullheight] = useResizeMode(
    searchParams.get("view") === formModeSearchParam ||
      searchParams.get("view") === null
  );

  var formGridHeight = initHeight - (15 + heights_out);

  const [privType, setPrivType] = useState("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const dataGridAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      // toggle_staff_form();
    } else {
      setWarnTitle("No Access!");
      setWarnMessage("No Access Privilege");
      setError(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      return;
    }
  };

  // handle interface switch
  const toggle_staff_form = (): void => {
    const view = searchParams.get("view");

    view === null || view === formModeSearchParam
      ? navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: datagridOnlySearchParam,
          }).toString(),
        })
      : navigate({
          pathname: setupPageRoute,
          search: createSearchParams({
            view: formModeSearchParam,
          }).toString(),
        });
  };

  function switchView() {
    const view = searchParams.get("view");
    if (view !== formModeSearchParam) {
      navigate({
        pathname: setupPageRoute,
        search: createSearchParams({
          view: `form-and-table`,
        }).toString(),
      });
    } else {
      return;
    }
  }

  //Table Height
  var tableHeight = fullheight - 600; //height without navbar and toolbar

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });
  const [warnMessage, setWarnMessage] = useState("");
  const [warnTitle, setWarnTitle] = useState("");
  const [busyLoader, setBusyLoader] = useBusyLoader();
  const [data, cardError, loading] = useFetch(
    `AssetMgrTmsCardDetail/getAllCardDetails?criteria=${searchCriteria.index}&searchText=${searchText.text}&isActive=${activeStatus.status}&pagenumber=${pagination.pageNumber}&pagesize=${pagination.pageSize}`,
    refresh,
    "cdtIdpk"
  );
  useEffect(() => {
    loading ? setBusyLoader(".") : setBusyLoader("");
  }, [loading]);

  const [selectedData, setSelectedData] = useState({});

  const searchCriteriaData = [
    { id: 0, value: "Card Nos" },
    { id: 1, value: "PIN" },
    { id: 2, value: "Serv. Providers" },
  ];
  return (
    <>
      {/* handle get error */}
      <ModalTemplate
        disableCancel={true}
        title={warnTitle}
        okText={"Ok"}
        message={warnMessage}
        open={error}
        okHandler={() => {
          setError(false);
        }}
        cancelHandler={() => {
          setError(false);
        }}
      />
      <div className="h-full ">
        <div className="px-2">
          <HrSetupsCustomToolbar
            searchTextOnchange={(searchValue) => {
              setSearchText((prev) => ({ ...prev, temp: searchValue }));
            }}
            checkOnChange={(check) => {
              setActiveStatus((prev) => ({ ...prev, temp: check }));
            }}
            handleRefresh={() => {
              setActiveStatus({ temp: "", status: "" });
              setSearchText({ temp: "", text: "" });
              setsearhCriteria({ temp: 0, index: 0 });
              setrefresh(!refresh);
            }}
            handleFind={() => {
              setSearchText((prev) => ({ ...prev, text: prev.temp })); //search text
              setActiveStatus((prev) => ({ ...prev, status: prev.temp })); //active status
              setsearhCriteria((prev) => ({ ...prev, index: prev.temp }));
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearhCriteria((prev) => ({ ...prev, temp: newIndex }));
            }}
            withDates={false}
            searchCriteria
            searchCriteriaData={searchCriteriaData}
            outerSelected={selectedData}
            handleNew={() => {
              setSelectedData({});
            }}
            toggler={toggle_staff_form}
          />
        </div>

        {/* form section */}
        {searchParams.get("view") !== datagridOnlySearchParam && (
          <section style={{ height: form_height }} className=" form ">
            <CardDtailsForm
              busyloader={(text) => setBusyLoader(text ?? "")}
              refreshGrid={() => setrefresh((prev) => !prev)}
              clearSelected={() => setSelectedData({})}
              data={data}
              selectedField={selectedData}
              setSelectedField={setSelectedData}
            />
          </section>
        )}

        {/* datagrid section */}
        <section className=" px-2">
          <Datagrid_template
            handlePageNumber={(page) => {
              setPagination((prev) => ({ ...prev, pageNumber: page }));
            }}
            handlePageSize={(size) => {
              setPagination((prev) => ({ ...prev, pageSize: size }));
            }}
            gridheight={
              searchParams.get("view") === formModeSearchParam ||
              searchParams.get("view") === null
                ? tableHeight + 100
                : formGridHeight
            }
            data={data}
            columns={CardDetailsColumns}
            onRowClick={(selected: any) => {
              setSelectedData(selected);
              //disable Update Mode
              dispatch(setGeneralValue({ expr: "updateMode", value: false }));

              //disable forms
              dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
            }}
            rowDoubleClicked={() => {
              switchView();
              dataGridAccessPriv();
            }}
          />
        </section>
      </div>
    </>
  );
});
