/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import edit from "../../../../../../../assets/edit.png";
import close from "../../../../../../../assets/close.png";
import reset from "../../../../../../../assets/reset.png";
import save from "../../../../../../../assets/save.png";
import newIcon from "../../../../../../../assets/new.png";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import { Job_ac } from "../data/mini_datagrid_cols";
import useFetch from "../../../../../../../hooks/useFetch";
import { DatePicker, Form, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  currentDate,
  deleteModalData2,
  saveModalData,
  updateModalData,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeneralValue,
  settask_footer_update,
} from "../../../../../../../features/generalSlice";
import { RootState } from "../../../../../../../app/store";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { task_footer_table_selected } from "../../../../../../../features/forms/entries/safetyManager/ppeRequest";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { UpdateDataFunc } from "../../../../../../../functions/update";
import { PostDataFunc } from "../../../../../../../functions/post";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { name } from "mustache";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { debounce } from "lodash";
import { FetchM_Job_activtiesDetails } from "../../../../../../../features/apiCalls/maintenanceactApi/jobActiveDetails";
import { Tooltip } from "devextreme-react";
import { current } from "@reduxjs/toolkit";
interface props {
  selectedRecord?: any;
  openSelectedTask?: any;
  setrefreshx?: (e: boolean) => void;
  jobDetailslookUp?: any;
}

interface stateTypes {
  job?: { id: number; name: string };
  date?: string;
  description?: string;
  remarks?: string;
  JobSelectedRecord?: any;
  isOpenDropdown: boolean;
  isLoading: boolean;
  isOpenDropdownKey: string;
  searchJob: string;
}

export const Maintenance_activities_Job_Details = ({
  selectedRecord,
  openSelectedTask,
  setrefreshx,
  jobDetailslookUp,
}: props) => {
  const [employeeId, userId, userInfo] = useAccountInfo();
  const dispatch = useDispatch();
  //  Current date
  const editedDate = dayjs().format();

  // states initialization
  const [states, setStates] = useState<stateTypes>({
    job: { id: 0, name: "" },
    date: currentDate,
    description: "",
    remarks: "",
    JobSelectedRecord: [],
    isLoading: false,
    isOpenDropdown: false,
    isOpenDropdownKey: "isOpenDropdown",
    searchJob:""
  });

  //  Onchange fxn
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  // Data from redux
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.TJobUpdateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const [busyLoader, setBusyLoader] = useBusyLoader(); //loader
  //

  // Modal dialog data states

  const [refreshValue, setRefreshValue] = useState(false);
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [posting, updating] = useCrudFunc();
  console.log("job states", states);
  // Endpoints

  const [Job_data, Job_error, Job_loading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsMaintenanceActiviityJobDetails?mtaIDpk=${
      selectedRecord?.mtaIDpk
    }&pagenumber=${1}&pagesize=${20}`,
    refreshValue
  );
  const [job_detail] = useFetch(`AsmTmsEntMaintenanceActivity/GetEntAsmTmsPopLueJobs?search=${states.searchJob}&pagenumber=1&pagesize=20`)
console.log("jobGrid",Job_data)
  const [job_data, job_error, job_loading] = useFetch(
    `AsmTmsEntMaintenanceActivity/GetEntAsmTmsPopLueJobs`,
    refreshValue
  );

  const populateFields = async (clear?: boolean) => {
    dispatch(task_footer_table_selected([{}]));

    const gridState = [
      {
        key: "date",
        value: clear ? currentDate : states.JobSelectedRecord?.mjdDate,
      },
      {
        key: "job",
        value: clear
          ? { id: 0, name: "" }
          : { id: states.JobSelectedRecord?.mjdIdpk, name: states.JobSelectedRecord?.mjdJob },
      },
      {
        key: "description",
        value: clear ? "" : states.JobSelectedRecord?.mjdDescription,
      },
      { key: "remarks", value: clear ? "" : states?.JobSelectedRecord.mjdRmks },
    ];

    for (let i of gridState) setState(i.key, i.value);
  };

  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  //new button
  const newAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      handleNew();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //save button
  const saveAccessPriv = () => {
    setPrivType("save");
    if (savePriv === true) {
      setModalData(saveModalData);
      validationForms();
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //update button
  const updateAccessPriv = () => {
    setPrivType("update");
    if (updatePriv === true) {
      setModalData(updateModalData);
      validationForms();
      setOpenModal(true);
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };

  //edit button
  const eidtAccessPriv = () => {
    if (updatePriv === true) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      // dispatch(settask_footer_update(false))
      dispatch(setGeneralValue({ expr: "TJobUpdateMode", value: true }));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  // handleNew
  const handleNew = () => {
    populateFields(true);
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    dispatch(setGeneralValue({ expr: "TJobUpdateMode", value: false }));
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

    dispatch(setGeneralValue({ expr: "TJobUpdateMode", value: false }));
  };

  // Validation
  const validationForms = async () => {
    setIcon("warning");
    if (
      dayjs(states.date).isAfter(currentDate)
    ) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please check date, date cannot be greater than today.",
        title: "Date field required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.job.name == "") {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for job.",
        title: "Job field required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else if (states.description === "") {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data entry for Description",
        title: "Description filed required!",
        okText: "Ok",
      });
      setOpenModal(true);
    } else {
      setshowModalDisableButton(false);
      setIcon("question");
      setOpenModal(true);
    }
  };
  const postData = async () => {
    try {
      setBusyLoader("Saving Record.....");
      await posting(
        `AsmTmsEntMaintenanceActivity/CreateEntMaintenanceActivityJobDetails`,
        {
          mjdMaintActivityIdfk:
            selectedRecord !== undefined && selectedRecord?.mtaIDpk,
          mjdJob: states.job.name,
          mjdDescription: states.description,
          mjdDate: states.date,
          mjdRmks: states.remarks,
          mjdCreatedBy: employeeId,
        },
        `Added the Job ${states.job.name} to Maintenance Activity with ID ${selectedRecord?.mtaIDpk} for vehicle with ID ${selectedRecord?.vdtIDpk}`
      );
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setRefreshValue(!refreshValue);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Job details saved successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.response.data.Title
          ? e.response.data.Title
          : e.response.data.errors
          ? e.response.data.errors
          : e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const updateData = async () => {
    try {
      setBusyLoader("Updating Record.....");
      await updating(
        `AsmTmsEntMaintenanceActivity/UpdateEntMaintenanceActivityJobDetails`,
        {
          mjdIdpk: states.JobSelectedRecord.mjdIdpk,
          mjdMaintActivityIdfk:
            selectedRecord !== undefined && selectedRecord?.mtaIDpk,
          mjdJob: states.job?.name,
          mjdDescription: states.description,
          mjdDate: states.date,
          mjdRmks: states.remarks,
          mjdEditedBy: employeeId,
        },
        `Updated Job Details with ID ${states.JobSelectedRecord?.mjdIDpk}`
      );
      setRefreshValue(!refreshValue);

      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Job details updated successfully!",
        title: "Data Saved!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.response.data.Title
          ? e.response.data.Title
          : e.response.data.errors
          ? e.response.data.errors
          : e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const deleteJd = async () => {
    try {
      setBusyLoader("Deleting Record.....");
      await PatchDataFunc(
        `AsmTmsEntMaintenanceActivity/DeleteJobDetails?mjdIDpk=${states.JobSelectedRecord.mjdIdpk}`,
        {}
      );
      setRefreshValue(!refreshValue);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Record deleted successfully!",
        title: "Record Deleted!",
        okText: "Ok",
      });
      populateFields(true);
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.response.data.Title
          ? e.response.data.Title
          : e.response.data.errors
          ? e.response.data.errors
          : e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };
  // Mini datagrid function
  //----------------------------------------------------------------
  const onChangeRecord = (e: any) => {
    populateFields(false);
    if (setState !== undefined) {
      setState("JobSelectedRecord", e.data);

      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
    }
  };

  // double click feature
  const allowUpdate = (e: any) => {
    if (setState !== undefined) {
      setState("JobSelectedRecord", e.data);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  const handleDelete = () => {
    if (states.JobSelectedRecord?.length === 0) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Record Selected", title: "No Record!" });
    } else {
      setIcon("question");
      setModalData(deleteModalData2);
      setOpenModal(true);
    }
  };
  // --------------------------------------------------------------------
  const deleteJdAccessPriv = () => {
    if (readPriv === true) {
      handleDelete();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  useEffect(() => {
    populateFields(false);
  }, [states.JobSelectedRecord]);
  console.log(jobDetailslookUp);
  return (
    <div>
      <ModalTemplate
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "question" ? false : true}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData === saveModalData
            ? postData()
            : modalData === updateModalData
            ? updateData()
            : setOpenModal(false);
          modalData === deleteModalData2 ? deleteJd() : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />

      <div className="px-2 border-b-4 mb-1 pb-1">
        <Form
          className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-2 px-2 "
          name="basic"
          labelCol={{ span: 7 }}
          // wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          size="small"
          layout="horizontal"
          disabled={form_disable}
        >
          {/* Date */}
          <Form.Item
            wrapperCol={{ span: 6 }}
            className=""
            labelCol={{ span: 4 }}
            label={
              <p className=" dark:bg-fill dark:text-darkModeSkin-base text-xs">
                {"Date"}
              </p>
            }
          >
            <div className=" w-full flex ">
              <DatePicker
                allowClear={false}
                onChange={(date, dateString) => {
                  setState("date", dateString);
                }}
                value={dayjs(states.date)}
                placeholder={states.date}
                format="DD MMM YYYY"
                className="w-full mb-5"
              />
            </div>
          </Form.Item>

          {/* JOB */}
          <Form.Item
            labelCol={{ span: 4 }}
            label={
              <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                {"Job "}
              </p>
            }
          >
            <InputSelect_template
              useCallFunc
              style={{ width: "99%", marginTop: 3, marginBottom: 5 }}
              selectedValue={(value: any) => {
                setState(states.isOpenDropdownKey, false);
                setState("job", { id: value.mjdIdpk, name: value.mjdJob });
              }}
              placeHolder={states.job.name}
              options={
                job_detail
              }
              idexpr="mjdIdpk"
              dataexp="mjdJob"
              span={false}
              isLoading={states.isLoading}
              isOpenDropdown={states.isOpenDropdown}
              handleRefresh={() => {
                setState("job", "");
                setRefreshValue(!refreshValue);
              }}
              handleSearch={
                debounce((e) => {
                  setState("job", e);
                  setState("searchJob",e)
                  dispatch(FetchM_Job_activtiesDetails(e) as unknown as any);
                  setState(states.isOpenDropdownKey, true);
                }, 500) // Adjust the debounce delay as needed
              }
              handleFocus={() => {
                setState(states.isOpenDropdownKey, !states.isOpenDropdown);
              }}
            />
          </Form.Item>

          {/* Textarea */}
          {/* Remarks */}
          {/* <Form.Item
            labelCol={{ span: 2 }}
            label={
              <p className=" dark:bg-fill dark:text-darkModeSkin-base text-xs">
                {"Description"}
              </p>
            }
          > */}
   
            <div className="w-full flex ml-[6px]">
              <div style={{ width: "calc(93%)" }} className="">
                <TextareaTemplate
                  height={45}
                  useCallbackFunc
                  setCallbackValue={(e) => {
                    setState("description", e);
                  }}
                  defaultValue={states.description}
                  readonly={form_disable}
                  placeholder={states.description}
                  label="Description"
                  labelCol={4}
                />
              </div>
              <ul
                style={{ width: "6%" }}
                className=" flex flex-col justify-center "
              >
                {form_disable === true ? (
                  <button
                    id="newx"
                    onClick={newAccessPriv}
                    style={{
                      height: "40px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center w-full mb-1 ml-0.5 border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={newIcon} />
                    <Tooltip
                      target="#newx"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Create new job details</p>
                    </Tooltip>
                  </button>
                ) : states.JobSelectedRecord?.length !== 0 ? (
                  <button
                    id="closex"
                    onClick={handleCancel}
                    style={{
                      height: "35px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5   border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={close} />
                  </button>
                ) : states.JobSelectedRecord?.length == 0 &&
                  form_disable === false ? (
                  <button
                    id="closex"
                    onClick={handleCancel}
                    style={{
                      height: "35px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center w-full mb-0.5 ml-0.5  border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={close} />
                    <Tooltip
                      target="#close"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Close</p>
                    </Tooltip>
                  </button>
                ) : (
                  <button
                    id="newx"
                    onClick={handleNew}
                    style={{
                      height: "35px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5    border-2 border-slate-200 rounded "
                  >
                    <img className="px-0.5 " src={newIcon} />
                    <Tooltip
                      target="#newx"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Create new job details</p>
                    </Tooltip>
                  </button>
                )}
              </ul>
            </div>
         
          {/* </Form.Item> */}

          {/* Textarea 2 */}

          {/* Remarks */}
          <Form.Item
            labelCol={{ span: 4 }}
            label={
              <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs ">
                {"Remarks"}
              </p>
            }
          >
            <div className="w-full flex">
              <div style={{ width: "calc(100%)" }} className="">
                <TextareaTemplate
                  height={55}
                  useCallbackFunc
                  setCallbackValue={(e) => {
                    setState("remarks", e);
                  }}
                  defaultValue={states.remarks}
                  readonly={form_disable}
                />
              </div>
              <ul
                style={{ width: "8%" }}
                className=" flex flex-col justify-center "
              >
                {update_state_change === false ? (
                  <button
                    id="savex"
                    onClick={() => {
                      saveAccessPriv();
                    }}
                    disabled={form_disable}
                    style={{
                      height: "50px",
                      width: "100%",
                      marginBottom: 2,
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full ml-0.5 px-1.5  border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={save} />
                    <Tooltip
                      target="#savex"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Save job details</p>
                    </Tooltip>
                  </button>
                ) : update_state_change === true ? (
                  <button
                    id="updatex"
                    onClick={() => {
                      updateAccessPriv();
                    }}
                    style={{
                      height: "50px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-0.5 ml-0.5 px-1.5  border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={save} />
                    <Tooltip
                      target="#updatex"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Update job details</p>
                    </Tooltip>
                  </button>
                ) : (
                  <button
                    id="savex"
                    // onClick={''}
                    style={{
                      height: "50px",
                      width: "100%",
                      borderColor: borderTheme,
                    }}
                    className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  w-full mb-1 ml-0.5 px-1.5  border-2 border-slate-200 rounded"
                  >
                    <img className="px-0.5" src={save} />
                    <Tooltip
                      target="#savex"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Save job details</p>
                    </Tooltip>
                  </button>
                )}
              </ul>
            </div>
          </Form.Item>
        </Form>
      </div>

      {/* Datagrid */}
      <div className="flex justify-end items-center  ">
        <p
          id={"form_header5"}
          className="pt-1  pl-4 w-full bg-slate-100 rounded-tl"
        >
          Job Activities Details
        </p>
        <ul className="flex justify-evenly items-center ">
          <li
            id="refreshx"
            className="hover:cursor-pointer  border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center "
          >
            <span
              onClick={() => {
                setRefreshValue(!refreshValue);
              }}
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className="  flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={reset}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#refreshx"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Refresh</p>
            </Tooltip>
          </li>

          <li
            id="editx"
            className="hover:cursor-pointer  border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span
              onClick={() => {
                eidtAccessPriv();
              }}
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className=" flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className="w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={edit}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#editx"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Edit job details</p>
            </Tooltip>
          </li>

          <li
            id="deletex"
            className="hover:cursor-pointer  border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span
              onClick={() => {
                deleteJdAccessPriv();
              }}
              style={{
                height: 22.5,
                borderWidth: 1,
                width: 25.2,
                borderColor: borderTheme,
              }}
              className="  flex justify-center hover:cursor-pointer border-gray-300 rounded"
            >
              <img
                className=" w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                src={close}
                alt="reset"
              />
            </span>
            <Tooltip
              target="#deletex"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Delete job details</p>
            </Tooltip>
          </li>
        </ul>
      </div>

      <div className="">
        <Mini_datagrid_template
          columns={Job_ac}
          data={Job_data}
          gridheight={243}
          setState={setState}
          onChangeRecord={onChangeRecord}
          updateMode={allowUpdate}
        />
      </div>
    </div>
  );
};
