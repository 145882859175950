/* eslint-disable react/jsx-pascal-case */
import React, { useState, useCallback, useRef, useEffect } from "react";

import { Form, Input, Select, DatePicker, Slider, InputNumber } from "antd";

import refresh from "../../../../../../assets/refresh.png";
import { AiOutlineSwitcher } from "react-icons/ai";

import dayjs from "dayjs";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import useFetch from "../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../templates/modal";
import {
  EditButton,
  SaveButton,
  UpdateButton,
  NewButton,
  Cancel,
} from "../../../../../accessories/buttons";
import {
  saveModalData,
  api_url,
  updateModalData,
  currentDate,
} from "../../../../../accessories/component_infos";
import { Mini_datagrid_template } from "../data/mini_datagrid";
import {
  maintenance_checklist,
  progress_updatechecklist,
} from "../data/mini_datagrid_cols";
import Datagrid_template from "../../../../../../templates/Datagrid";
import { TextareaTemplate } from "../../../../../../templates/textarea";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../../../../features/generalSlice";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { PostDataFunc } from "../../../../../../functions/post";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import { useBusyLoader } from "../../../../../../hooks/useBusyLoader";
import { useCrudFunc } from "../../../../../../functions/crud";

interface props {
  // this means whether a prop is passed on the component or not the component called
  // anywhere should work (i.e. with the ?) but without the ? it takes it's strict rule
  // that without a prop there will be an error
  toggler?: () => void;
  backToggler?: () => void;
  datgridCol?: any;
  selectedRecord?: any;
}

interface stateTypes {
  id?: number;
  date?: string;
  complete?: number;
  job?: number;
  description?: string;
  selectedProgress?: any;
}

export const ActivitiesCapture_update = ({
  toggler,
  backToggler,
  selectedRecord,
}: props) => {
  const dateFormat = " DD MMM YYYY HH: mm";

  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );

  const [states, setStates] = useState<stateTypes>({
    id: 0,
    date: dayjs(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
    complete: 0,
    job: 0,
    description: "",
  });
  console.log("capUpStates", states);
  const setState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };
  const [posting, updating] = useCrudFunc();
  const [refreshstate, setRefreshstate] = useState<boolean>(false);
  //  Current date

  const [pmProgressUpdate, pmProgressUpdate_error, pmProgressUpdate_loading] =
    useFetch(
      `PmActivitiesCaptures/GetAllVwAsmTmsPmActivityUpdate?pauPreventiveMaintActivityIDx=${selectedRecord?.pmaIDpk}`,
      refreshstate
    );
  console.log("selectedFuckingRecords", selectedRecord);
  // Modal dialog data states

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [busyLoader, setBusyLoader] = useBusyLoader();
  //Access Priviledge

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const validatSave = async () => {
    setIcon("warning");

    if (states.date === "" || states.date === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for activity date",
        title: "Activity Date ",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.date !== selectedRecord?.pmaDateOut) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "The update date cannot be greater than vehicle departure date. Please check the dates and try again",
        title: "Activity Date",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.complete === 0 || states.complete === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for %complete",
        title: "%Complete",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.description === "" || states.description === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for activity update",
        title: "Activity Update",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setshowModalDisableButton(false);
      setModalData({
        message: "Are you sure you want to save PM progress?",
        title: "PM Progress Save!",
        okText: "Save",
        cancelText: "Cancel",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };
  const validatUpdate = async () => {
    setIcon("warning");

    if (states.date === "" || states.date === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please select data entry for activity date.",
        title: "Activity Date ",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.date !== selectedRecord?.pmaDateOut) {
      setshowModalDisableButton(true);
      setModalData({
        message:
          "The update date cannot be greater than vehicle departure date. Please check the dates and try again",
        title: "Activity Date",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.complete === 0 || states.complete === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for %complete.",
        title: "%Complete",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else if (states.description === "" || states.description === undefined) {
      setshowModalDisableButton(true);
      setModalData({
        message: "Please enter data for activity update.",
        title: "Activity Update",
        okText: "Ok",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setshowModalDisableButton(false);
      setModalData({
        message: "Are you sure you want to update PM progress?",
        title: "PM Progress Update!",
        okText: "Update",
        cancelText: "Cancel",
      });
      setOpenModal(true);
      setIcon("question");
    }
  };

  const updateData = async () => {
    try {
      setBusyLoader("Updating PM Progress...");
      await updating(
        `PmActivitiesCaptures/UpdateAsmTmsPMProgressUpdate`,
        {
          pauIDpk: states.id,
          pauDate: dayjs(states.date).format("YYYY-MM-DD"),
          pauActivityUpdate: `${states.description}`,
          pauPercentComplete: states.complete,
          pauCompletionDate: states.selectedProgress.pauCreationDate,
          pauEditedBy: userId,
        },
        ``
      );
      await updating(
        `PmActivitiesCaptures/UpdateAsmTmsMaintenanceRequestProgress`,
        {
          mrqIDpk: selectedRecord?.mrqIDpk,
          mrqCompletionDate: states.selectedProgress.pauCreationDate,
          mrqPercentComplete: states.complete,
        },
        ``
      );
      setRefreshstate(!refreshstate);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(task_form_disable(true));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Pm progress-update updated successfully.",
        title: "Data updated!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Date cannot be greater than vehicle departure date..please check the date and check again!",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const postData = async () => {
    try {
      setBusyLoader("Saving pm progress...");
      await posting(
        `PmActivitiesCaptures/CreateAsmTmsPmProgressUpdate`,
        {
          pauDate: dayjs(states.date),
          pauPreventiveMaintActivityIDfk: selectedRecord?.pmaIDpk,
          pauActivityUpdate: states.description,
          pauPercentComplete: states.complete,
          pauCompletionDate: dayjs(states.date),
        },
        ``
      );
      setRefreshstate(!refreshstate);
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(task_form_disable(true));
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "PM progress-update saved successfully!",
        title: "Data saved!",
        okText: "Ok",
      });
      setBusyLoader("");
    } catch (e: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({
        message: e.message,
        title: "Unexpected Error!",
        okText: "Ok",
      });
      setBusyLoader("");
    }
  };

  const options = [];
  for (let i = 1; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  //dispatch
  const dispatch = useDispatch();

  const [toggled, setToggled] = useState(false);

  //save modal
  // const saveModal = {
  //   message: "Are you sure you want to save PM Progress?",
  //   title: "PM Progress Save!",
  //   okText: "Save",
  //   cancelText: "Cancel",
  // };
  //update modal
  // const updateModal = {
  //   message: "Are you sure you want to update PM Progress?",
  //   title: "PM Progress Update!",
  //   okText: "Update",
  //   cancelText: "Cancel",
  // };

  //new button
  const newAccessPriv = () => {
    if (savePriv === true) {
      setStates({
        id: 0,
        date: dayjs(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
        complete: 0,
        job: 0,
        description: "",
      }); //clear fields
      setToggled(!toggled);
      dispatch(task_form_disable(false));
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //update button
  const updateAccessPriv = () => {
    if (updatePriv === true) {
      validatUpdate();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  //save button
  const saveAccessPriv = () => {
    if (updatePriv === true) {
      validatSave();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const validateEdit = () => {
    if (states.selectedProgress === undefined) {
      setshowModalDisableButton(false);
      setModalData({
        message: "Please select a record to edit",
        title: "No record selected!",
        okText: "Ok",
        cancelText: "Cancel",
      });
      setOpenModal(true);
      setIcon("warning");
    } else {
      setToggled(!toggled);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(settask_footer_update(true));
    }
  };
  //edit button
  const editAccessPriv = () => {
    if (updatePriv === true) {
      validateEdit();
    } else {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(false);
      setModalData({ message: "No Access Privilege", title: "Access Denied!" });
    }
  };
  const handleCancel = () => {
    setToggled(!toggled);
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };
  return (
    <div>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "question" ? false : true}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          modalData.title === "PM Progress Save!"
            ? postData()
            : setOpenModal(false);
          modalData.title === "PM Progress Update!"
            ? updateData()
            : setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />
      <div className="py-2 px-4">
        {!toggled ? (
          <Datagrid_template
            gridheight={395}
            columns={progress_updatechecklist}
            data={pmProgressUpdate}
            disablePaging={true}
            disableGroupPanel
            disableSearch
            onRowClick={(data) => {
              setStates({
                id: data?.pauIDpk,
                date: data?.pauDate,
                complete: data?.pauPercentComplete,
                job: data?.updatedByJbtShtName,
                description: data?.pauActivityUpdate,
                selectedProgress: data,
              });
            }}
          />
        ) : (
          <div
            style={{ width: "100%" }}
            className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base"
          >
            <Form
              className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base  rounded py-1 px-2 "
              name="basic"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              size="small"
              layout="horizontal"
              disabled={form_disable}
            >
              <Form.Item
                wrapperCol={{ span: 15 }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Update Date"}
                  </p>
                }
                className="mb-1 "
              >
                <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center ">
                  <DatePicker
                    onChange={(date, dateString) => {
                      setState("date", dayjs(date).format("YYYY-MM-DDTHH:mm:ss"));
                    }}
                    showTime={{ defaultValue: dayjs("00:00:00", "HH:mm") }}
                    format={dateFormat}
                    value={dayjs(states.date)}
                    allowClear={false}
                  />
                </div>
              </Form.Item>

              <Form.Item
                className="mb-1"
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"% Complete"}
                  </p>
                }
              >
                <div className="w-full flex dark:bg-slate-100 dark:text-darkModeSkin-base">
                  <Slider
                    style={{ width: "100%" }}
                    className="mr-5"
                    min={0}
                    max={100}
                    onChange={(e) => {
                      setState("complete", e);
                    }}
                    value={states.complete}
                  />
                  <InputNumber
                    className="h-6 w-16 flex items-center justify-center"
                    size="small"
                    min={0}
                    max={100}
                    value={states.complete}
                    onChange={(e) => {
                      setState("complete", e);
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item
                style={{ display: "inline-block", marginBottom: "2px" }}
                label={
                  <p className="dark:bg-slate-900 dark:text-darkModeSkin-base text-xs">
                    {"Activity Update "}
                  </p>
                }
                className="w-full mb-1"
              >
                <div className=" dark:bg-slate-900 dark:text-darkModeSkin-base">
                  <TextareaTemplate
                    height={327}
                    useCallbackFunc
                    defaultValue={states.description}
                    setCallbackValue={(e: string) => {
                      setState("description", e);
                    }}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        )}

        <div className="flex mt-2 justify-between">
          <div className="flex">
            {" "}
            <span>Done...</span>
            <span className="mx-[56px]"></span>{" "}
            <EditButton
              onClick={() => {
                editAccessPriv();
              }}
            />
          </div>

          <div className="flex">
            {" "}
            {update_state_change === true ? (
              <UpdateButton handleUpdate={updateAccessPriv} />
            ) : (
              <SaveButton
                disableButton={form_disable}
                handleSave={() => {
                  saveAccessPriv();
                }}
              />
            )}
            <span className="mx-1"></span>
            {toggled ? (
              <Cancel
                useCallbackFunc={true}
                cancel_button_toggler={() => {
                  handleCancel();
                }}
              />
            ) : (
              <NewButton
                new_button_toggler={() => {
                  newAccessPriv();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
