/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  navbar_height,
  saveModalData,
} from "../../../../../accessories/component_infos";
import { Fuel_transactiontoolbar } from "../../../../../accessories/custom-toolbars/fuel_transactiontoolbar";
import { Return_Request_Details_Form } from "./widgets/return_req";
import { Fuel_Transaction_Details_Form } from "./widgets/Transaction_details";
import useFetch from "../../../../../../hooks/useFetch";
import {
  FD_cols,
  VF_cols,
  footer_datagrid_authV_cols,
  footer_datagrid_auth_cols,
  footer_datagrid_fuelreturns_cols,
} from "./data/datagrid_cols";
import Datagrid_template from "../../../../../../templates/Datagrid";
import dayjs, { Dayjs } from "dayjs";
import { useAccountInfo } from "../../../../../../hooks/useAccountInfo";
import {
  searchTextTypes,
  statusTypes,
  searchCriteriaTypes,
  formModalStates,
} from "../../../../../human-resource/setups/data/types/selectsTypes";
import { useDispatch } from "react-redux";
import { setGeneralValue } from "../../../../../../features/generalSlice";
import { ModalTemplate } from "../../../../../../templates/modal";
import { ChartEntries } from "./widgets/chartEntries";
import { AuthModal } from "../../../../../accessories/custom-toolbars/widgets/entriesModal/authModal";
import { FuelDump_Transaction_Details_Form } from "./widgets/fuelTrans";
import { WarningAlert } from "../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../hooks/useResizeMode";
import { UpdateDataFunc } from "../../../../../../functions/update";
import { GetDataFunc } from "../../../../../../functions/get";
import { PatchDataFunc } from "../../../../../../functions/patch";
import { PostDataFunc } from "../../../../../../functions/post";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { ContextMenu } from "devextreme-react";
import { useCrudFunc } from "../../../../../../functions/crud";
import { FormModal } from "../../../../../accessories/formModal";
import { Alert_Drivers } from "../../AlertDrivers/AlertDetails";
import { UpdateStates } from "../../../../../../functions/updateStates";

interface props {
  state_change: boolean;
}

interface stateTypes {
  purModeState?: any;
  selectedRecord?: any;
  password?: string;
  uom?: { id: number; name: string };
  criteriaState?: { id: number; name: string };
  chartLState?: { id: number; name: string };
  startDate?: string;
  endDate?: string;
  quanA?: number;
  remarksA?: string;
  openFormModalState: boolean;
  authModalOpen?: boolean;
  setIcon?: any;
  setModalData?: any;
}

export const Return_Request = ({ state_change }: props) => {
  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType] = useState<string>("save");
  const [updatePriv] = useFormPriviledge(privType);

  const dispatch = useDispatch();
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const [formheight, set_form_height] = useState<number>(0);
  const [switch_change, setSwitch_change] = useState<boolean>(true);

  const [initialH, fullH] = useResizeMode(switch_change);

  const formRef = useRef<any>(null);
  var fullPage = fullH - (navbar_height + news_flash_height + 35);
  var pageHeight =
    initialH - (navbar_height + news_flash_height + formheight + 25);

  const switch_toggle = () => {
    if (switch_change) {
      setSwitch_change(!switch_change);
    } else {
      setSwitch_change(!switch_change);
    }
  };

  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const formCode = useSelector((state: RootState) => state.user?.formCode);

  const genFormat = "YYYY-MM-DD";
  const today = dayjs().format(genFormat);
  const initialStart = dayjs()
    .subtract(1, "month")
    .startOf("year")
    .format(genFormat);
  const [startDate, setStartDate] = useState<searchTextTypes>({
    text: initialStart,
    temp: initialStart,
  });
  const [endDate, setEndDate] = useState<searchTextTypes>({
    text: today,
    temp: today,
  });

  const [states, setStates] = useState<stateTypes>({
    purModeState: { id: 0, name: "" },
    selectedRecord: {},
    password: "",
    uom: { id: 0, name: "" },
    criteriaState: { id: 0, name: "" },
    chartLState: { id: 0, name: "Bar" },
    startDate: initialStart,
    endDate: today,
    quanA: 0,
    remarksA: "",
    authModalOpen: false,
    setIcon: "question",
    setModalData: {},
    openFormModalState: false,
  });

  console.log(states, "xxx");

  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 20,
  });

  const updateStates = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const [searchText, setSearchText] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<statusTypes>({
    temp: "",
    status: "",
  });
  const [searchCriteria, setsearhCriteria] = useState<searchCriteriaTypes>({
    temp: 0,
    index: 0,
  });

  const searchCriteriaData = [
    { id: 0, value: "Registration No" },
    { id: 1, value: "Chassis No" },
    { id: 2, value: "Vehicle Class" },
    { id: 3, value: "Vehicle Make" },
    { id: 4, value: "Vehicle Model" },
    { id: 5, value: "Manufacturer" },
    { id: 6, value: "Invalid Entry Dates" },
  ];

  const [authUnitsData] = useFetch(
    `FuelManagement/GetFuelDumpAuthoriserFuelUnits`
  );
  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAuthModal, setOpenAuthModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isChart, setIsChart] = useState<boolean>(false);

  const [qtyReq, setQtyReq] = useState<
    "sum" | "avg" | "count" | "custom" | "max" | "min"
  >("sum");
  const [qtyAuth, setQtyAuth] = useState("Sum");
  const [qtyDisp, setQtyDisp] = useState("Sum");
  const [amountType, setAmountType] = useState("Sum");
  const [distanceType, setDistanceType] = useState("Sum");
  const [Posting, Updating, Patching] = useCrudFunc();

  const [DBdata, DBerror] = useFetch(
    `FuelManagement/SearchFuelManagement?startDate=${startDate.text}&endDate=${
      endDate.text
    }&criteria=${searchCriteria.index}&toggle=${activeStatus.status}&search=${
      searchText.text
    }&PageNumber=${1}&PageSize=${paging.pageSize}`,
    // `FuelManagement/SearchFuelManagement?startDate=${startDate.text}&endDate=${endDate.text}&criteria=${searchCriteria.index}&toggle=${activeStatus.status}&search=${searchText.text}`,
    refresh,
    "fmgIDpk"
  );

  const lockModalData = {
    message: "Please are you sure you want to lock the selected transaction?",
    title: "Lock Transaction",
    okText: "Yes",
    cancelText: "No",
  };
  const unlockModalData = {
    message: `Please are you sure you want to unlock the selected transaction? `,
    title: "Lock Transaction",
    okText: "Yes",
    cancelText: "No",
  };
  const unlockAllModalData = {
    message: `Please are you sure you want to unlock all fuel purchase entries for the month of ${dayjs(
      states.selectedRecord?.fmgDate
    ).format("MMM YYYY")}?`,
    title: "Unlock All Transaction",
    okText: "Yes",
    cancelText: "No",
  };
  const lockAllModalData = {
    message: `Please are you sure you want to lock all fuel purchase entries for the month of ${dayjs(
      states.selectedRecord?.fmgDate
    ).format("MMM YYYY")}?`,
    title: "Lock All Transaction",
    okText: "Yes",
    cancelText: "No",
  };

  const doubleClickAccessPriv = (e: any) => {
    if (updatePriv === true) {
      setSwitch_change(true)
      updateStates("selectedRecord", e);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    } else {
      setIcon("warning");
      setOpenModal(true);
      setModalData({ title: "Access Denied!", message: "No Access Privilege" });
    }
  };

  const Lock = async () => {
    try {
      if (states.selectedRecord.fmgLocked === false) {
        await Updating(
          `FuelManagement/LockData?Id=${states.selectedRecord.fmgIDpk}`,
          {
            fmgLocked: true,
            fmgLockedByEmpIdfk: states.selectedRecord.fmgIDpk,
            fmgLockDate: dayjs().format(),
          },
          `Locked the Asset Mgr-Trans-Fuel Management transaction with ID ${states.selectedRecord.fmgIDpk} successfully`
        );
        const newSelectedRecord = {
          ...states.selectedRecord,
          fmgLocked: !states.selectedRecord.fmgLocked,
        };
        updateStates("selectedRecord", newSelectedRecord);
        setRefresh(!refresh);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(false);
        setModalData({
          message: "Request locked successfully.",
          title: "Request locked",
          okText: "Ok",
        });
      } else {
        await Updating(
          `FuelManagement/LockData?Id=${states.selectedRecord.fmgIDpk}`,
          {
            fmgLocked: false,
            fmgLockedByEmpIdfk: states.selectedRecord.fmgIDpk,
            fmgLockDate: dayjs().format(),
          },
          `Unlocked the Asset Mgr-Trans-Fuel Management transaction with ID ${states.selectedRecord.fmgIDpk} successfully`
        );
        const newSelectedRecord = {
          ...states.selectedRecord,
          fmgLocked: !states.selectedRecord.fmgLocked,
        };
        updateStates("selectedRecord", newSelectedRecord);
        setRefresh(!refresh);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Request unlocked successfully.",
          title: "Request Unlocked",
          okText: "Ok",
        });
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const LockAll = async () => {
    try {
      if (states.selectedRecord.fmgLocked === true) {
        await Updating(
          `FuelManagement/LockAllFuelPurchase`,
          {
            fmgLocked: false,
            fmgLockedByEmpIdfk: employeeId,
            fmgDate: dayjs().format(),
            fmgLockDate: dayjs().format(),
          },
          `Locked the Asset Mgr-Trans-Fuel Management transaction for the month of ${dayjs(
            states.selectedRecord?.fmgDate
          ).format("MMM YYYY")}, By ${userInfo.empName} successfully`
        );
        const newSelectedRecord = {
          ...states.selectedRecord,
          fmgLocked: !states.selectedRecord.fmgLocked,
        };
        updateStates("selectedRecord", newSelectedRecord);
        setRefresh(!refresh);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Request unlocked successfully.",
          title: "All Request Unlocked",
          okText: "Ok",
        });
      } else {
        await Updating(
          "FuelManagement/LockAllFuelPurchase",
          {
            fmgLocked: true,
            fmgLockedByEmpIdfk: employeeId,
            fmgDate: dayjs().format(),
            fmgLockDate: dayjs().format(),
          },
          `Locked the Asset Mgr-Trans-Fuel Management transaction for the month of ${dayjs(
            states.selectedRecord?.fmgDate
          ).format("MMM YYYY")} successfully`
        );
        const newSelectedRecord = {
          ...states.selectedRecord,
          fmgLocked: !states.selectedRecord.fmgLocked,
        };
        updateStates("selectedRecord", newSelectedRecord);
        setRefresh(!refresh);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Request locked successfully.",
          title: "All Request Locked",
          okText: "Ok",
        });
      }

    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const deleteModalData = {
    message: `Please are you sure you want to delete this request?`,
    title: "Delete Request",
    okText: "Yes",
    cancelText: "No",
  };

  const deleteData = async () => {
    try {
      await Updating(
        "AsmTmsEntFuelManagement/DeleleEntFuelManagement",
        {
          fmgIdpk: states.selectedRecord.fmgIDpk,
          fmgEditedDate: dayjs().format(),
          fmgDeleteRmks: "string",
          fmgEditedBy: userId,
          fmgDeleted: true,
        },
        states.selectedRecord.pcmName === "Card"
          ? `Deleted the Asset Mgr-Trans-Carded Fuel Transaction Log with ID ${
              states.selectedRecord.fmgIDpk
            }, on ${dayjs().format()} by ${userInfo.empName} successfully`
          : `Deleted the Asset Mgr-Trans-Fuel Dump Fuel Transaction Log with ID ${states.selectedRecord.fmgIDpk} successfully`
      );

      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Please data deleted successfully.",
        title: "Deleted Request",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const verifyModalData = {
    message: `Please are you sure you want to verify the requests for ${dayjs(
      states.selectedRecord?.fmgDate
    ).format("MMM YYYY")}?`,
    title: "Verify",
    okText: "Yes",
    cancelText: "No",
  };

  const verifyData = async () => {
    try {
      // alert(formCode)
      const res = await Patching(
        `FuelManagement/Verify?FormCode=${formCode}&FormAction=${`Verified the Asset Mgr-Trans-Fuel Management transaction with ID ${states.selectedRecord.fmgIDpk} successfully`}&fmgIDpk=${
          states.selectedRecord.fmgIDpk
        }`,
        {}
      );
      if (res.data.success === true) {
        setRefresh(!refresh);
        setIcon("success");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Data verified successfully.",
          title: "Verify Request",
          okText: "Ok",
        });
      } else {
        throw Error(res.data.message);
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const passwordModalData = {
    message: ``,
    title: "Enter Password?",
    okText: "Ok",
    cancelText: "No, Cancel",
  };

  const passwordData = async () => {
    try {
      const res = await GetDataFunc(
        `FuelManagement/GetFuelDumpPassword?EmpId=${employeeId}&UsaPassword=${states.password}`
      );

      console.log(res.data, "passwordData");

      if (res.data.success === true) {
        setOpenModal(false);
        setOpenAuthModal(true);
      } else {
        setIcon("warning");
        setOpenModal(true);
        setshowModalDisableButton(true);
        setModalData({
          message: "Wrong Credentials.",
          title: "Unexpected Error!",
          okText: "Ok",
        });
      }
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);

      setModalData({
        message: err.response.data.errors.UsaPassword[0] ?? "Wrong Credentials",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const updateFuelReturnsData = {
    message: `Please are you sure you want to update the returns report for ${dayjs(
      states.selectedRecord?.fmgDate
    ).format("MMM YYYY")} ?`,
    title: "Update Report",
    okText: "Yes",
    cancelText: "No",
  };

  const updateFuelReturns = async () => {
    try {
      await Updating(
        `FuelManagement/UpdateFuelReturns?startDate=${startDate.text}&endDate=${endDate.text}`,
        {}
      );
      setRefresh(!refresh);
      setIcon("success");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: "Data updated successfully.",
        title: "Update Report",
        okText: "Ok",
      });
    } catch (err: any) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const criteria = [
    { id: 0, name: "Fuel Amounts (GHC)" },
    { id: 1, name: "Fuel Quantity (Ltrs)" },
    { id: 2, name: "Fuel Amount & Quantity " },
  ];
  const criteria2 = [
    { id: 0, name: "Opening Dip Balance" },
    { id: 1, name: "Opening Meter Reading" },
    { id: 2, name: "Dip Balance and Meter Reading " },
  ];

  const chartLs = [
    { id: 0, name: "Bar" },
    { id: 1, name: "Line" },
    { id: 2, name: "Area" },
  ];
  const [FDdata] = useFetch(
    `FuelManagement/GetFuelDumpAuthoriserFuelDumpDips?sstID=${states.selectedRecord?.sstIDpk}&startDate=${states.startDate}&endDate=${states.endDate}`,
    refresh,
    "fddIDpk"
  );
  const [VDdata] = useFetch(
    `FuelManagement/GetFuelDumpAuthoriserFuelUsage?vehicleDetailId=${states.selectedRecord?.vdtIDpk}&startDate=${states.startDate}&endDate=${states.endDate}`,
    refresh,
    "fmgIDpk"
  );
  const [FDdataChart] = useFetch(
    `FuelManagement/GetFuelDumpAuthoriserDrawFuelDumpDipChart?ServiceStationId=${states.selectedRecord?.sstIDpk}&startDate=${states.startDate}&endDate=${states.endDate}&Criteria=${states.criteriaState?.id}`
  );
  const [VDdataChart] = useFetch(
    `FuelManagement/GetFuelUsageChart?VehicleDetailId=${states.selectedRecord?.vdtIDpk}&startDate=${states.startDate}&endDate=${states.endDate}&Criteria=${states.criteriaState?.id}`
  );

  const [sendTo, setsendTo] = useState<any>(1);
  const [sendSMS] = useFetch(
    `FuelManagement/GetFuelDumpAuthoriserSendSMS?startDate=${states.startDate}&endDate=${states.endDate}&authoriseRequesterEmpID=${states.selectedRecord?.dispenserEmpID}&sstID=${states.selectedRecord?.sstIDpk}&toggle=${1}`
  );

  const purchaseModeChange = (e: any) => {
    updateStates("purModeState", {
      id: e.pcmIDpk ?? e.pcmIdpk,
      name: e.pcmName,
    });
  };

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setOpenModal(true);
      setshowModalDisableButton(true);
      setModalData({
        message:
          "Please your computer is not connected to the internet. Please check your internet connection and try again",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };

  const message =
    states.quanA +
    " Litres of fuel authorize with request ID " +
    states.selectedRecord?.fmgIDpk +
    " has been authorized for " +
    states.selectedRecord?.dispenserEmpName;

  let mobNox = "";

  const smsAlert = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.driverEmpID) {
        mobNox = states.selectedRecord?.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsSender: userInfo.empName,
        slgSent: true,
        imsRecordIdfk: states.selectedRecord.fmgIDpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: states.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: states.selectedRecord.driverEmpID,
        altFormCode: formCode,
        altMessage: message,
        altTakeAction: true,
      });

      updateStates("setIcon", "success");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message: "SMS sent successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch (err: any) {
      updateStates("setIcon", "warning");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const msgAuth = `${states.quanA} ltrs of fuel with request ID ${states.selectedRecord?.fmgIDpk}  has been authorised for ${states.selectedRecord?.driverName} `;
  const msgAuthAlert = `${states.quanA} ltrs of fuel with request ID ${states.selectedRecord?.fmgIDpk} authorised for ${states.selectedRecord?.driverName} (${states.selectedRecord?.driverEmpID})`;
  const smsAuth = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.driverEmpID) {
        mobNox = states.selectedRecord?.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: msgAuth,
        imsSender: userInfo.empName,
        imsRecordIdfk: states.selectedRecord.fmgIDpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: states.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        recipientEmpID: states.selectedRecord.driverEmpID,
        altFormCode: "asmTransMaintenanceSchedules",
        altMessage: msgAuthAlert,
        altTakeAction: true,
      });

      updateStates("setIcon", "success");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message: "SMS Sent Successfully",
        title: "Success",
        okText: "Ok",
      });
    } catch {
      updateStates("setIcon", "warning");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message:
          "Please failed to send alert!..Please contact your administrator.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const msgCan = `Fuel request for ${states.selectedRecord?.driverName} with request ID ${states.selectedRecord?.fmgIDpk} has been cancelled. Thanks`;
  const msgCanAlert = `Cancelled fuel request for ${states.selectedRecord?.driverName} (${states.selectedRecord.driverEmpID}) with request ID ${states.selectedRecord?.fmgIDpk}`;
  const smsCancel = async () => {
    try {
      checkConnection();
      if (states.selectedRecord?.driverEmpID) {
        mobNox = states.selectedRecord?.dispenserMobNox.trim().replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: msgCan,
        imsSender: userInfo.empName,
        imsRecordIdfk: states.selectedRecord.vdtIDpk,
        imsFormCode: formCode,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecipientEmpIdfk: states.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });

      await Posting(`USCXGenAlerts/SaveAlert`, {
        altFormCode: formCode,
        altMessage: msgCanAlert,
        altTakeAction: true,
      });

      updateStates("setIcon", "success");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message: "SMS sent successfully.",
        title: "Success",
        okText: "Ok",
      });
    } catch (error: any) {
      updateStates("setIcon", "warning");
      updateStates("setAuthModalOpen", true);
      setshowModalDisableButton(true);
      updateStates("setModalData", {
        message: error.response ? error.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const cancelAuthData = async () => {
    try {
      await Updating(
        `FuelManagement/CancelAcknowledge?Id=${states.selectedRecord.fmgIDpk}`,
        {
          fmgEditedDate: dayjs().format(),
          fmgAcknowledged: false,
        },
        `Cancelled Acknowledgement the Asset Mgr-Transport-Fuel Log for vehicle with Reg No ${states.selectedRecord.vdtRegNo} , Dispensed By ${states.selectedRecord.dispenserEmpName} ${states.selectedRecord.dispenserStaffNo} for record with ID ${states.selectedRecord.fmgIDpk}`
      );

      smsCancel();
      setRefresh(!refresh);
      updateStates("setIcon", "success");
      updateStates("setModalData", {
        message: "Request rejected successfully!",
        title: "Cancelled Request",
        okText: "Ok",
      });
      updateStates("authModalOpen", true);
    } catch (err: any) {
      updateStates("setIcon", "warning");
      updateStates("setModalData", {
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      updateStates("authModalOpen", true);
      setshowModalDisableButton(true);
    }
  };

  const authModalData2 = {
    message: `Please only fueL dump logs can be authorised.`,
    title: "Access Denied",
    okText: "Ok",
    cancelText: "No",
  };

  const authData = async () => {
    try {
      await Patching(
        `FuelManagement/Authorise?Id=${states.selectedRecord?.fmgIDpk}`,
        {
          fmgQuantityAuthorised: states.quanA,
          fmgAuthoriserRmks: states.remarksA,
          fmgEditedBy: employeeId,
        },
        `Acknowledged the Asset Mgr-Transport-Fuel Log for vehicle with Reg No ${states.selectedRecord.vdtRegNo} , Dispensed By ${states.selectedRecord.dispenserEmpName} ${states.selectedRecord.dispenserStaffNo} for request with ID ${states.selectedRecord.fmgIDpk}`
      );
  
      if (sendTo === 1) {
        setsendTo(2);
        await sendSMS;
      }
  
      smsAuth();
      setRefresh(!refresh);
  
      updateStates("setIcon", "success");
      updateStates("setModalData", {
        message: "Please request has been authorized successfully.",
        title: "Authorization successful",
        okText: "Ok",
      });
      updateStates("authModalOpen", true);
    } catch (err: any) {
      updateStates("setIcon", "warning");
      updateStates("setModalData", {
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
      updateStates("authModalOpen", true);
      setshowModalDisableButton(true);
    }
  };

  useEffect(() => {
    set_form_height(formRef.current?.clientHeight + 25);

    if (
      states.purModeState?.name === "Fuel Dump" ||
      states.purModeState?.name === "Card"
    ) {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    }
  }, [isChart, states.purModeState?.name, dispatch]);

  useMemo(() => {
    const purModeName = states.purModeState?.name;

    if (purModeName === "Fuel Dump") {
      return (
        <FuelDump_Transaction_Details_Form
          statesx={states}
          statesxx={states}
          updateStatesx={updateStates}
          setRefreshx={setRefresh}
          purchaseModeChange={purchaseModeChange}
        />
      );
    } else if (
      purModeName === undefined ||
      (purModeName !== "Card" && purModeName !== "Petty Cash")
    ) {
      return (
        <Return_Request_Details_Form
          updateStatesx={updateStates}
          statesx={states}
        />
      );
    } else {
      return (
        <Fuel_Transaction_Details_Form
          statesx={states}
          setRefreshx={setRefresh}
          purchaseModeChange={purchaseModeChange}
        />
      );
    }
  }, [states, updateStates, setRefresh]);

  const formModal: formModalStates[] = [
    {
      id: 0,
      label: "Transport- Alert Driver & Permit Holders",
      module: <Alert_Drivers />,
    },
  ];

  return (
    <>
      {DBerror && <WarningAlert />}
      <div className="w-full h-full dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base ">
        <div>
          <AuthModal
            openx={openAuthModal}
            okTextx={""}
            titlex={"Authorise Fuel Request"}
            Gridtitlex={"Fuel Dump Dip Opening Balances"}
            cancelHandlerx={() => {
              setOpenAuthModal(false);
            }}
            gridheightx={240}
            optionsx={VDdata}
            optionsx2={FDdata}
            chartDatax={FDdataChart}
            chartDatax2={VDdataChart}
            chartTypex={states.chartLState?.name}
            criteriax={criteria}
            criteriax2={criteria2}
            columnsx={footer_datagrid_auth_cols}
            columnsx2={footer_datagrid_authV_cols}
            chartLsx={chartLs}
            XAxis_val={"xAxis"}
            chartColumnx={FD_cols}
            chartColumnx2={VF_cols}
            selectedRecordx={states.selectedRecord}
            unitsDatax={authUnitsData}
            updateStatesx={updateStates}
            statesx={states}
            Gridtitlex2={"Vehicle Fuel Amounts"}
            authDatax={authData}
            cancelAuthDatax={cancelAuthData}
            smsAlertx={smsAlert}
          />

          <FormModal
            openState={states.openFormModalState}
            width={"80%"}
            title={formModal[0].label}
            child={formModal[0].module}
            onCancel={() => {
              updateStates("openFormModalState", false);
            }}
          />
        </div>

        <ModalTemplate
          isIcon={modalData.title === passwordModalData.title ? false : true}
          icon={icon}
          cancelText={modalData.cancelText}
          disableCancel={
            icon === "warning" || icon === "success" ? true : false
          }
          cancelHandler={() => {
            setOpenModal(false);
          }}
          open={openModal}
          okHandler={() => {
            modalData.title === lockModalData.title ||
            modalData.title === unlockModalData
              ? Lock()
              : modalData.title === lockAllModalData.title ||
                modalData.title == unlockAllModalData.title
              ? LockAll()
              : modalData.title === deleteModalData.title
              ? deleteData()
              : modalData.title === verifyModalData.title
              ? verifyData()
              : modalData.title === updateFuelReturnsData.title
              ? updateFuelReturns()
              : modalData.title === passwordModalData.title
              ? passwordData()
              : setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
          withInputType={"password"}
          withInput2={
            modalData.title === passwordModalData.title ? true : false
          }
          inputOnchange={(e) => {
            updateStates("password", e);
          }}
        />

        <div>
          <Fuel_transactiontoolbar
            toggler={switch_toggle}
            setSwitch_change={setSwitch_change}
            searchTextOnchange={(searchValue) => {
              setSearchText({ temp: searchValue, text: searchText.text });
            }}
            checkOnChange={(check) => {
              setActiveStatus({ temp: check, status: activeStatus.status });
            }}
            handleFind={() => {
              setStartDate({ text: startDate.temp, temp: startDate.temp });
              setEndDate({ text: endDate.temp, temp: endDate.temp });
              setSearchText({ temp: searchText.temp, text: searchText.temp });
              setActiveStatus({
                temp: activeStatus.temp,
                status: activeStatus.temp,
              });
              setsearhCriteria({
                temp: searchCriteria.temp,
                index: searchCriteria.temp,
              });
            }}
            searchCriteriaOnchange={(newIndex, criteria) => {
              setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
            }}
            handleRefresh={() => {
              setSearchText({ temp: "", text: "" });
              setsearhCriteria({ temp: 0, index: 0 });
              setActiveStatus({ temp: "", status: "" });
            }}
            startDate={startDate.temp}
            endDate={endDate.temp}
            startDateChanged={(date) =>
              setStartDate({
                temp: dayjs(date).format(genFormat),
                text: startDate.text,
              })
            }
            endDateChanged={(date) =>
              setEndDate({
                temp: dayjs(date).format(genFormat),
                text: endDate.text,
              })
            }
            handleToggleChart={() => {
              setIsChart(!isChart);
            }}
            handleLock={() => {
              setOpenModal(true);
              states.selectedRecord.fmgLocked === true
                ? setModalData(unlockModalData)
                : setModalData(lockModalData);
              setIcon("question");
            }}
            handleLockAll={() => {
              setOpenModal(true);
              states.selectedRecord.fmgLocked === true
                ? setModalData(unlockAllModalData)
                : setModalData(lockAllModalData);
              setIcon("question");
            }}
            handleDelete={() => {
              setOpenModal(true);
              setModalData(deleteModalData);
              setIcon("question");
            }}
            handleVerify={() => {
              setOpenModal(true);
              setModalData(verifyModalData);
              setIcon("question");
            }}
            handleAuth={() => {
              setOpenModal(true);
              setIcon(
                states.selectedRecord.pcmShtName === "Fuel Dump"
                  ? "question"
                  : "warning"
              );
              states.selectedRecord.pcmShtName === "Fuel Dump"
                ? setModalData(passwordModalData)
                : setModalData(authModalData2);
            }}
            handleUpdateReturns={() => {
              setOpenModal(true);
              setModalData(updateFuelReturnsData);
              setIcon("question");
            }}
            handleSMS={() => {
              updateStates("openFormModalState", true);
            }}
            searchCriteriaData={searchCriteriaData}
            setOuterSelected={() => {
              updateStates("selectedRecord", {});
            }}
            outerSelected={states.selectedRecord}
          />
        </div>

        {isChart ? (
          <div className="w-full flex">
            <ChartEntries manualData={DBdata} height={fullPage} />
          </div>
        ) : (
          <div>
            {switch_change && (
              <div ref={formRef} className="">
                <p
                  style={{ borderColor: borderTheme, width: "99%" }}
                  className="border-[1px] border-b-0  pt-1 mx-2 px-4 w-full bg-slate-100 rounded-t-md"
                >
                  Transaction Details
                </p>
                <div
                  style={{ height: "320px" }}
                  className="w-full px-2 flex justify-between gap-x-4 border-b-4 mb-1 pb-1"
                >
                  <div
                    style={{ width: "100%", height: "315px" }}
                    className="mb-1"
                  >
                    {/* {states.purModeState?.name === 'Fuel Dump' && (
                    <FuelDump_Transaction_Details_Form
                      statesxx={states}
                      updateStatesx={updateStates}
                      setRefreshxx={setRefresh}
                      purchaseModeChange={purchaseModeChange}
                    />
                    )} */}

                    {states.purModeState === undefined ||
                    (states.purModeState.name !== "Card" &&
                      states.purModeState.name !== "Petty Cash" &&
                      states.purModeState.name !== "Fuel Dump") ? (
                      <Return_Request_Details_Form
                        updateStatesx={updateStates}
                        statesx={states}
                      />
                    ) : states.purModeState.name === "Card" ||
                      states.purModeState.name === "Petty Cash" ? (
                      <Fuel_Transaction_Details_Form
                        statesx={states}
                        updateStatesx={updateStates}
                        setRefreshx={setRefresh}
                        purchaseModeChange={purchaseModeChange}
                        refereshx={refresh}
                      />
                    ) : states.purModeState.name === "Fuel Dump" ? (
                      <FuelDump_Transaction_Details_Form
                        statesxx={states}
                        updateStatesx={updateStates}
                        setRefreshx={setRefresh}
                        refereshx={refresh}
                        purchaseModeChange={purchaseModeChange}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div
              style={{ height: "" }}
              className="px-2  dark:bg-slate-900 dark:text-darkModeSkin-base"
            >
              <Datagrid_template
                deselectFirstRow={true}
                gridheight={switch_change === true ? pageHeight : fullPage}
                columns={footer_datagrid_fuelreturns_cols}
                data={DBdata}
                handlePageSize={(e: number) => {
                  UpdateStates("pageSize", e, setPaging);
                }}
                handlePageNumber={(e: number) => {
                  UpdateStates("pageNumber", e, setPaging);
                }}
                pageSize={paging.pageSize}
                pageNumber={paging.pageNumber}
                onRowClick={(e) => {
                  updateStates("selectedRecord", e);
                  updateStates("purModeState", {
                    id: e.pcmIDpk,
                    name: e.pcmName,
                  });
                  dispatch(
                    setGeneralValue({ expr: "formDisabled", value: true })
                  );

                  dispatch(
                    setGeneralValue({ expr: "updateMode", value: false })
                  );
                }}
                rowDoubleClicked={(e) => {
                  updateStates("selectedRecord", e);
                  doubleClickAccessPriv(e);
                }}
                summary={
                  <Summary>
                    <TotalItem
                      cssClass={"one"}
                      column="fmgQuantityRequested"
                      summaryType={qtyReq}
                      // displayFormat="QTY REQ. {0}"
                    />
                    <TotalItem
                      cssClass={"two"}
                      column="fmgQuantityAuthorised"
                      summaryType={`${
                        qtyAuth.toLowerCase() as
                          | "avg"
                          | "count"
                          | "custom"
                          | "max"
                          | "min"
                          | "sum"
                          | undefined
                      }`}
                      valueFormat=""
                    />
                    <TotalItem
                      cssClass={"three"}
                      column="QTY DISP."
                      summaryType={`${
                        qtyDisp.toLowerCase() as
                          | "avg"
                          | "count"
                          | "custom"
                          | "max"
                          | "min"
                          | "sum"
                          | undefined
                      }`}
                      valueFormat=""
                    />

                    <TotalItem
                      cssClass={"four"}
                      column="AMOUNT"
                      summaryType={`${
                        amountType.toLowerCase() as
                          | "avg"
                          | "count"
                          | "custom"
                          | "max"
                          | "min"
                          | "sum"
                          | undefined
                      }`}
                      valueFormat="currency"
                    />
                    <TotalItem
                      cssClass={"five"}
                      column="DISTANCE(km)"
                      summaryType={`${
                        distanceType.toLowerCase() as
                          | "avg"
                          | "count"
                          | "custom"
                          | "max"
                          | "min"
                          | "sum"
                          | undefined
                      }`}
                      valueFormat=""
                    />
                  </Summary>
                }
              />

              <ContextMenu
                dataSource={[
                  { text: "Sum" },
                  { text: "Min" },
                  { text: "Max" },
                  { text: "Count" },
                  { text: "avg" },
                ]}
                width={200}
                target=".one"
                onItemClick={(itemClick: any) => {
                  itemClick === "avg"
                    ? setQtyReq("avg")
                    : setQtyReq(itemClick["itemData"]["text"]);
                }}
              />

              <ContextMenu
                dataSource={[
                  { text: "Sum" },
                  { text: "Min" },
                  { text: "Max" },
                  { text: "Count" },
                  { text: "avg" },
                ]}
                width={200}
                target=".two"
                onItemClick={(itemClick: any) => {
                  itemClick === "avg"
                    ? setQtyAuth("avg")
                    : setQtyAuth(itemClick["itemData"]["text"]);
                }}
              />

              <ContextMenu
                dataSource={[
                  { text: "Sum" },
                  { text: "Min" },
                  { text: "Max" },
                  { text: "Count" },
                  { text: "avg" },
                ]}
                width={200}
                target=".three"
                onItemClick={(itemClick: any) => {
                  itemClick === "avg"
                    ? setQtyDisp("avg")
                    : setQtyDisp(itemClick["itemData"]["text"]);
                }}
              />

              <ContextMenu
                dataSource={[
                  { text: "Sum" },
                  { text: "Min" },
                  { text: "Max" },
                  { text: "Count" },
                  { text: "avg" },
                ]}
                width={200}
                target=".four"
                onItemClick={(itemClick: any) => {
                  itemClick === "avg"
                    ? setAmountType("avg")
                    : setAmountType(itemClick["itemData"]["text"]);
                }}
              />

              <ContextMenu
                dataSource={[
                  { text: "Sum" },
                  { text: "Min" },
                  { text: "Max" },
                  { text: "Count" },
                  { text: "avg" },
                ]}
                width={200}
                target=".four"
                onItemClick={(itemClick: any) => {
                  itemClick === "avg"
                    ? setDistanceType("avg")
                    : setDistanceType(itemClick["itemData"]["text"]);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
