/* eslint-disable react/jsx-pascal-case */
import { Form } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  useModalData,
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import submit from "../../../../../../../assets/forward.png";
import correct from "../../../../../../../assets/correct.png";
import search1 from "../../../../../../../assets/search1.png";
import reset from "../../../../../../../assets/reset.png";
import {
  vehicle_condition_data,
  vehicle_condition_TH,
} from "../data/vehicle-condition-returns-data";
import { Tooltip } from "devextreme-react";

import useFetch from "../../../../../../../hooks/useFetch";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { InputsTemplate } from "../../../../../../../templates/input";
import Datagrid_template from "../../../../../../../templates/Datagrid";
import { CheckboxTemlate } from "../../../../../../../templates/checkbox";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { CellPreparedEvent } from "devextreme/ui/pivot_grid";
import dayjs from "dayjs";
import "../widgets/style.css";
import { SaveButton } from "../../../../../../accessories/buttons";
import { useCrudFunc } from "../../../../../../../functions/crud";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import { debounce } from "lodash";
import {
  fetchDate,
  fetchDept,
  fetchSection,
} from "../../../../../../../features/apiCalls/vehConditionsApi";
import { GetAsmTmsPrivilegesInterface } from "../../../../../../../interfaces";
import dxDataGrid, { InitializedEvent } from "devextreme/ui/data_grid";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";

export type t_allVehicles = {
  vasIdpk: number;
  vdtIdpk: number;
  vdtChassisNo: string;
  vdtRegNo: string;
  vhcName: string;
  vmkName: string;
  mftName: string;
  mdlName: string;
  vdtManufactureDate: Date;
  vdtSupplyDate: Date;
  ownerStaffNo: string;
  ownerEmpName: string;
  dptShtName: string;
  sxnShtName: string;
  manufactureAge: number;
  supplyAge: number;
  vdtPrice: number;
  vstName: string;
  vdtRmks: string;
  ownerLocShtName: string;
  ownerPlc: null | string;
};
export type t_vehicleConditions = t_allVehicles & {
  vcrIDpk: number;
  vdtIDpk: number;
  vcrInside: string;
  vcrOutside: string;
  vcrTyre: string;
  vcrSuspension: string;
  vcrAirConditioner: string;
  vcrEngine: string;
  vcrBoard: boolean;
  vcrCard: boolean;
  vcrNonCard: boolean;
  vcrCondition: string;
  vcrOdometerStart: number;
  vcrOdometerEnd: number;
  vcrAcknowledged: boolean;
  vcrSubmitted: boolean;
  vcrRmks: string;

  //custom
  vcrSave: boolean;
};
export interface props {
  toggled: boolean;
  refreshGrid?: () => void;
  table_one_data?: any;
  setRefreshAssignmentGrid: React.Dispatch<React.SetStateAction<boolean>>;
  refreshAssignmentGrid: boolean;
  handleAcknowledgex?: () => {};
  handleClosex?: () => {};
  selectedTopData?: any;
  setSelectedTopData?: any;
  formPriviledges?: GetAsmTmsPrivilegesInterface;
  setCurrentDatex?: any;
  isOpenDropdown?: boolean;
  refreshState: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<
    React.SetStateAction<{ pageSize: number; pageNumber: number }>
  >;
}

type stateTypes = {
  status?: string;
};
interface columnsTypes {
  vasIdpk: number;
  inside: string;
  outside: string;
  tyre: string;
  suspension: string;
  airCondition: string;
  engine: string;
  board: boolean;
  card: boolean;
  noCard: boolean;
  save: boolean;
  remarks: string;
  odomStart: number;
  odomEnd: number;
  distance: number;
}

const columns = {
  inside: "Not Saved",
  outside: "Not Saved",
  tyre: "Not Saved",
  suspension: "Not Saved",
  airCondition: "Not Saved",
  engine: "Not Saved",
  board: false,
  card: false,
  noCard: false,
  save: false,
  remarks: "",
  odomStart: 0,
  odomEnd: 0,
  distance: 0,
};

const VehicleConditionDetails = ({
  toggled,
  refreshGrid,
  table_one_data,
  setSelectedTopData,
  setCurrentDatex,
  setPagination,
  refreshState,
  formPriviledges,

  setRefreshAssignmentGrid,
  refreshAssignmentGrid,
}: props) => {
  const [states, setStates] = useState<stateTypes>({
    status: "",
  });

  const [modalData, setModalData] = useModalData();

  const [posting, updating] = useCrudFunc();

  const allowFormSave = !!formPriviledges?.tupSaveConditionReturns;
  const allowFormSubmit = !!formPriviledges?.tupSubmitConditionReturns;
  const vehicleRef = useRef<dxDataGrid<any, any>>();

  const [allVeh, setAllVeh] = useState<{
    pageSize: number;
    pageNumber: number;
  }>({
    pageSize: 20,
    pageNumber: 1,
  });

  const [formState, setFormState] = useState<any>({
    date: { id: undefined, value: undefined },
    dept: { id: 0, value: "" },
    sect: { id: 0, value: "" },
    refresh: false,
    isOpenDropdown: false,
    isOpenDropdown1: false,
    isOpenDropdown2: false,
  });
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const form_height = 185;
  let [resizeMode, initialResizeMode] = useResizeMode(toggled);

  const dateData = useSelector(
    (state: RootState) => state.vehCondition.dateData
  );
  const deptData = useSelector(
    (state: RootState) => state.vehCondition.deptData
  );
  const sectiondata = useSelector(
    (state: RootState) => state.vehCondition.sectiondata
  );
  const [searchApi, setSearch] = useState("");
  const [fetchSection] = useFetch(
    `AssetTamVehicleConditionReturn/GetOrgAdminSxnAssetTamVehicleConditionReturn?Id=${formState?.dept?.id}&PageNumber=1&PageSize=20&search${searchApi}`
  );
  console.log("fetchSect", fetchSection);
  console.log("formState1111111111", formState);
  const updateState = (stateName: string, value: any) => {
    setFormState((currentState: any) => ({
      ...currentState,
      [stateName]: value,
    }));
  };
  //.................Extra................
  const [detailsUrl, setDetailsUrl] = useState(``);

  const [details_data, details_error, details_loading] = useFetch(
    detailsUrl,
    refreshAssignmentGrid
  );

  const [mockedVehicles, setMockedVehicles] = useState<t_vehicleConditions[]>(
    []
  );
  const [VehicleConditions, setVehicleConditions] = useState<
    t_vehicleConditions[]
  >([]);

  useEffect(() => {
    // const d = details_data?.map((veh, c) => ({
    //   ...veh,
    //   vcrRmks: `Good Vehicle ${c + 1}`,
    //   rowId: `${c + 1}`,
    //   vcrInside: "Good",
    //   vcrOutside: "Good",
    //   vcrTyre: "Good",
    //   vcrSuspension: "Good",
    //   vcrAirConditioner: "Good",
    //   vcrEngine: "Good",
    //   vcrBoard: true,
    //   vcrCard: true,
    //   vcrNonCard: true,
    //   distance: 500,
    //   saved: true,
    //   vcrOdometerStart: 1000,
    //   vcrOdometerEnd: 1500,
    // }));
    // setMockedVehicles(d);
    const f = refineAssignments(details_data);
    setVehicleConditions(f);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details_data, refreshAssignmentGrid, allVeh.pageNumber, allVeh.pageSize]);

  const [combinedData, setCombinedData] = useState<columnsTypes[]>([]);

  const combineDataWithColumns = (data: any) => {
    return data.map((item: any, index: any) => ({
      ...item,
      ...columns,
      rowId: index,
    }));
  };

  useEffect(() => {
    if (details_data && Array.isArray(details_data)) {
      const updatedData = combineDataWithColumns(details_data);
      setCombinedData(updatedData);
    }
  }, [details_data]);

  const clearData = () => {
    setDetailsUrl(``);
  };

  const dropdownCondition = ["Good", "Fair", "Poor", "Not Saved"];

  const customCellTemplate = useCallback((cellElement: any, cellInfo: any) => {
    cellElement.classList.add("my-custom-cell-style");
    cellElement.textContent = cellInfo.value;

    // Clear previous content
    while (cellElement.firstChild) {
      cellElement.removeChild(cellElement.firstChild);
    }

    const container = document.createElement("div");
    container.className = "custom-cell-container";

    // Text
    const textNode = document.createTextNode(cellInfo.value ?? "");
    container.appendChild(textNode);

    // Icon
    const icon = document.createElement("i");
    icon.className = "fas fa-caret-down";
    container.appendChild(icon);

    cellElement.appendChild(container);
  }, []);

  const [loadingAlert, setLoadingAlert] = useState(false);

  //Date Logics ---------------------------------------------------------------
  const [currentDate, setcurrentDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );

  const dateValue = formState.date.value;

  let monthName = "";
  let year = "";

  if (dateValue && typeof dateValue === "string") {
    [monthName, year] = dateValue.split(", ");
  }

  if (!monthName || !year) {
    console.error("Invalid date format or date is undefined");
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const indexMonthDate = monthNames.indexOf(monthName);

  const firstDay = new Date(Number(year), indexMonthDate, 1);

  const lastDay = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth() + 1,
    0
  ).getDate();

  let formattedLastDayDate = `${year}-${(indexMonthDate + 1)
    .toString()
    .padStart(2, "0")}-${lastDay.toString().padStart(2, "0")}`;

  // setCurrentDatex && setCurrentDatex(currentDate);
  setCurrentDatex && setCurrentDatex(formState.date.id);

  //End Date logics----------------------------------------------------------------
  const extraColumns = [
    {
      fixed: true,
      id: 12,
      caption: "Save ?",
      dataField: "vcrSave",
      allowEditing: false,
      dataType: "boolean",
      // dataType: "string",
      alignment: "left",
      width: 90,
      // calculateCellValue: (d: any) => {
      //   if (
      //     d.vcrSave === "acknowleged" ||
      //     d.vcrSave === "submitted" ||
      //     d.vcrSave === "saved"
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // },
    },

    {
      fixed: false,
      id: 14,
      caption: "Odom .Start",
      // dataField: "odomStart",
      dataField: "vcrOdometerStart",
      allowEditing: true,
      alignment: "left",
      showEditorAlways: true,

      width: 89,
    },
    {
      fixed: false,
      id: 15,
      caption: "Odom .end",
      // dataField: "odomEnd",
      dataField: "vcrOdometerEnd",
      allowEditing: true,
      showEditorAlways: true,

      alignment: "left",

      width: 89,
    },
    {
      fixed: false,
      id: 16,
      caption: "Distance",
      // dataField: "distance",
      dataField: "vcrDistance",
      allowEditing: true,
      showEditorAlways: true,
      calculateCellValue: (rowData: any) => {
        return (
          Number(rowData.vcrOdometerEnd) - Number(rowData.vcrOdometerStart)
        );
      },
      alignment: "left",

      width: 89,
    },
    {
      id: 17,
      width: 80,
      caption: "Inside",
      // dataField: "inside",
      dataField: "vcrInside",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(newData: any, value: any) {
        newData.vcrInside = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrInside;
        },
      },
    },
    {
      id: 18,
      width: 80,
      caption: "Outside",
      // dataField: "outside",
      dataField: "vcrOutside",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(newData: any, value: any, id: any) {
        newData.vcrOutside = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrOutside;
        },
      },
    },
    {
      id: 19,
      width: 80,
      caption: "Tyre",
      // dataField: "tyre",
      dataField: "vcrTyre",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(newData: any, value: any) {
        newData.vcrTyre = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrTyre;
        },
      },
    },
    {
      id: 20,
      width: 80,
      caption: "Suspension",
      // dataField: "suspension",
      dataField: "vcrSuspension",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(rowData: any, value: any) {
        rowData.vcrSuspension = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrSuspension;
        },
      },
    },
    {
      id: 21,
      width: 80,
      caption: "Air Condition",
      // dataField: "airCondition",
      dataField: "vcrAirConditioner",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(newData: any, value: any) {
        newData.vcrAirConditioner = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrAirConditioner;
        },
      },
    },
    {
      id: 22,
      width: 80,
      caption: "Engine",
      // dataField: "engine",
      dataField: "vcrEngine",
      allowEditing: true,
      cellTemplate: customCellTemplate,
      setCellValue(rowData: any, value: any) {
        rowData.vcrEngine = value;
      },
      lookup: {
        dataSource: dropdownCondition,

        calculateCellValue: (rowData: any) => {
          // return rowData?.vcrEngine;
        },
      },
    },
    {
      fixed: false,
      id: 23,
      caption: "Board",
      // dataField: "board",
      dataField: "vcrBoard",
      alignment: "left",
      setCellValue(newData: any, value: any) {
        newData.vcrBoard = value;
      },
      dataType: "boolean",
      allowEditing: true,
      showEditorAlways: true,

      width: 80,
    },
    {
      fixed: false,
      id: 24,
      caption: "Card",
      // dataField: "card",
      dataField: "vcrCard",
      alignment: "left",
      dataType: "boolean",
      allowEditing: true,
      showEditorAlways: true,

      width: 80,
    },
    {
      fixed: false,
      id: 25,
      caption: "No Card",
      // dataField: "noCard",
      dataField: "vcrNonCard",
      alignment: "left",
      dataType: "boolean",
      allowEditing: true,
      showEditorAlways: true,

      width: 80,
    },
    {
      fixed: false,
      id: 26,
      caption: "Remarks",
      // dataField: "remarks",
      dataField: "vcrRmks",
      allowEditing: true,

      alignment: "left",
      showEditorAlways: true,

      width: 4000,
    },
  ];

  const combinedColumns = [...vehicle_condition_TH, ...extraColumns];
  //.....................................

  //Table Height
  var pageHeight = resizeMode - (navbar_height + form_height + 420);
  var tableHeight = initialResizeMode - (heights_out + 60);

  const [employeeId, userId, userInfo] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [loadData, setLoadData] = useState(false);
  const [disableSearchParams, setDisableSearchParams] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [dept_data, dept_data_error, dept_data_loading] = useFetch(
    "AssetTamVehicleConditionReturn/GetOrgAdminDeptAssetTamVehicleConditionReturn",
    refreshAssignmentGrid
  );
  const [date_data, _e, _l]: [
    date_data: { monthYear: string; dateValue: string }[],
    _e: any,
    _l: boolean
  ] = useFetch(
    `AssetTamVehicleConditionReturn/GetDateVehicleConditionReturn`,
    refreshAssignmentGrid
  );
  const [sxnData, sxnData_error, sxnData_loading] = useFetch(
    `AssetTamVehicleConditionReturn/GetOrgAdminSxnAssetTamVehicleConditionReturn/${formState.dept.id}`,
    refreshAssignmentGrid
  );

  const [updateId, setUpdateId] = useState();
  const updatedGridData = async () => {
    updateState(
      "gridData",
      details_data.map((item: any) => ({ ...item, states: states }))
    );
  };

  let dataNotValid: number[] = [];

  const [topDataRecord, setTopRowRecord] = useState<any>({});

  const runSubmit = async () => {
    try {
      const response = await updating(
        `AssetTamVehicleConditionReturn/SubmitAssetTamVehicleConditionReturn`,
        {
          selectedDate: `${formState.date.id}`,
        },
        `Updated vehicle condition successfully with ${formState.date.id}`
      );
      if (!!response.data.success) {
        setModalData((current) => ({
          ...current,
          state: true,
          icon: "success",
          title: "Record Submited",
          cancelText: " ",
          message: response.data.message || "Entries saved successfuly",
          okHandler: () => {
            setModalData((current) => ({
              ...current,
              state: false,
            }));
          },
          cancelHandler: () => {},
          disableCancel: true,
        }));
        setRefreshAssignmentGrid((state) => !state);
        refreshState((state) => !state);
      }
      setPagination((state) => ({
        ...state,
        pageSize: state.pageSize + 1, // increment by 1 to force a refresh
      }));

      refreshGrid!();
    } catch (e: any) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Record not saved",
        cancelText: " ",
        message: "Please contact the system's administrator",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: true,
      }));
    }
  };

  const validation = () => {
    if (!formState.date.id || !formState.date.value) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Empty Field",
        message: "Please select Date",
        disableCancel: true,
        okHandler() {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
      }));
    }
    // else if (
    //   loadData === false &&
    //   (formState.dept.id === 0 || formState.dept.value === undefined)
    // ) {
    //   setModalData((current) => ({
    //     ...current,
    //     state: true,
    //     icon: "warning",
    //     title: "Empty Field",
    //     message: "Please select Department",
    //     disableCancel: true,
    //     okHandler() {
    //       setModalData((current) => ({
    //         ...current,
    //         state: false,
    //       }));
    //     },
    //   }));
    // }
    else {
      loadData === true
        ? // ? setDetailsUrl(
          //   `AssetTamVehicleConditionReturn/GetAllVehicleLoadDataVehicleConditionReturn?loadData=${loadData}&dptID=${""}&sxnID=${""}&cmbSearch=${searchText}&dtpDate=${formState?.date?.id
          //   }&pageNumber=${allVeh.pageNumber}&pageSize=${allVeh.pageSize}`
          // )
          // : setDetailsUrl(
          //   `AssetTamVehicleConditionReturn/GetAllVehicleLoadDataVehicleConditionReturn?loadData=${loadData}&dptID=${formState.dept.id}&sxnID=${formState.sect.id}&cmbSearch=${searchText}&dtpDate=${formState?.date?.id}&pageNumber=${allVeh.pageNumber}&pageSize=${allVeh.pageSize}`
          // );
          setDetailsUrl(
            `AssetTamVehicleConditionReturn/GetAllGdcVehicles?loadData=${loadData}&dptIDpk=${""}&sxnIdpk=${""}&cmbSearch=${searchText}&dtpDate=${
              formState?.date?.id
            }&pageNumber=${allVeh.pageNumber}&pageSize=${allVeh.pageSize}`
          )
        : setDetailsUrl(
            `AssetTamVehicleConditionReturn/GetAllGdcVehicles?loadData=${loadData}&dptIDpk=${formState.dept.id}&sxnIdpk=${formState.sect.id}&cmbSearch=${searchText}&dtpDate=${formState?.date?.id}&pageNumber=${allVeh.pageNumber}&pageSize=${allVeh.pageSize}`
          );
      updatedGridData();
    }
  };

  const AccessPriv_on_SaveButton = () => {
    if (savePriv === true) {
      // dataValidate();
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "question",
        title: "Save Records",
        cancelText: "No",
        message: "Are you sure you want to save this record?",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
          handleSave(vehicleRef.current.getDataSource()?.items());
        },
        cancelHandler: () => {
          console.log(
            "GRID DATA\n",
            vehicleRef.current.getDataSource()?.items()
          );
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: false,
      }));
    } else {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Acces denied",
        cancelText: " ",
        message: "Please contact the system's administrator",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        cancelHandler: () => {},
        disableCancel: true,
      }));
    }
  };
  const AccessPriv_on_SubmitButton = () => {
    if (savePriv === true) {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "question",
        title: "Note that only saved returns will be submitted.",
        cancelText: "No",
        message:
          "Are you sure you want to proceed and submit the vehicle conditions returns for the month? ",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
          runSubmit();
          // handleSubmit();
        },
        cancelHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        disableCancel: false,
      }));
    } else {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Acces denied",
        cancelText: " ",
        message: "Please contact the system's administrator",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        cancelHandler: () => {},
        disableCancel: true,
      }));
    }
  };
  const AccessPriv_on_DataGridDoubleclick = (e: any) => {
    if (updatePriv === true) {
      setUpdateId(e?.vcrIdpk);
      setTopRowRecord(e);
    } else {
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Acces denied",
        cancelText: " ",
        message: "Please contact the system's administrator",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        cancelHandler: () => {},
        disableCancel: true,
      }));
    }
  };

  const trimmer = (value: any) => {
    if (value instanceof String) {
      return value.trim();
    }
    return value;
  };
  const cellColoring = useCallback((e: CellPreparedEvent) => {
    const trimmer = (value: any) => {
      if (value instanceof String) {
        return value.trim();
      }
      return value;
    };
    if (e.columnIndex === 9 && trimmer(e.cell?.value) !== "Save?") {
      e.cellElement!.style.backgroundColor = "#F0E68C"; // yellow
    } else if (
      (e.columnIndex as number) >= 13 &&
      (e.columnIndex as number) <= 18
    ) {
      e.cellElement!.style.backgroundColor = "#B0B0B0";
    }
  }, []);

  // const onCellPrepared = useCallback(
  //   (e: DataGridTypes.CellPreparedEvent<any, any>) => {
  //     if (e.columnIndex === 9 && e.rowType === "data") {
  //       const record = e.data as any as t_vehicleConditions;
  //       if (record.vdtIDpk === record.vdtIdpk) {
  //         if (record.vcrAcknowledged && record.vcrSubmitted) {
  //           // if both are true, acknowledge will take precedence
  //           e.cellElement!.style.backgroundColor = "#00FF00"; // Acknowledged
  //         }
  //         if (record.vcrSubmitted && !record.vcrAcknowledged) {
  //           // if submitted is true and acknowledge is false, submitted will take precedence
  //           e.cellElement!.style.backgroundColor = "#ffff00"; // Submitted
  //         }
  //         if (!!record.vcrSave) {
  //           e.cellElement!.classList.add("bg-green-100"); // for testing
  //         }
  //         e.cellElement!.classList.add("bg-orange-100"); // Saved, not Submitted
  //       } else {
  //         e.cellElement!.style.backgroundColor = "#f0e68c"; // Not Saved
  //       }
  //     }
  //   },
  //   []
  // );
  const onCellPrepared = useCallback(
    (e: DataGridTypes.CellPreparedEvent<any, any>) => {
      if (e.columnIndex === 9 && e.rowType === "data") {
        const record = e.data as any as t_vehicleConditions;
        if (record.vdtIDpk === record.vdtIdpk) {
          if (record.vcrAcknowledged && record.vcrSubmitted) {
            // if both are true, acknowledge will take precedence
            // e.cellElement!.style.backgroundColor = "#00FF00"; // Acknowledged
            e.cellElement!.style.backgroundColor = "#86efac"; // Acknowledged
          } else if (record.vcrSubmitted && !record.vcrAcknowledged) {
            // if submitted is true and acknowledge is false, submitted will take precedence
            e.cellElement!.style.backgroundColor = "#ffff00"; // Submitted
          } else if (!record.vcrSubmitted && record.vcrAcknowledged) {
            e.cellElement!.style.backgroundColor = "#86efac"; // Acknowledged
          } else {
            e.cellElement.style.backgroundColor = "#ffedd5"; // Saved, not Submitted
          }
        } else {
          e.cellElement!.style.backgroundColor = "#f0e68c"; // Not Saved
        }
      }
    },
    []
  );

  const singleEndpoint: any = fetchDate;
  const singleEndpoint2: any = fetchDept;
  const singleEndpoint3: any = fetchSection;

  const dispatch = useDispatch();

  const handleCheckRecords = (dataToBeSaved: any[] = []) => {
    const updatedRecords = dataToBeSaved.map((i) => {
      let isSave = true;
      if (
        i.vcrInside === "Not Saved" ||
        i.vcrOutside === "Not Saved" ||
        i.vcrTyre === "Not Saved" ||
        i.vcrSuspension === "Not Saved" ||
        i.vcrAirConditioner === "Not Saved" ||
        i.vcrSuspension === "Not Saved" ||
        i.vcrOdometerStart === 0 ||
        i.vcrOdometerEnd === 0
      ) {
        isSave = false;
      }
      return {
        ...i,
        vcrSave: isSave,
        vcrDistance: i.vcrOdometerEnd - i.vcrOdometerStart,
      };
    });
    // return updatedRecords;
    console.log("UPDATED RECORD\n", updatedRecords);
    setVehicleConditions(updatedRecords);
    return updatedRecords;
  };

  const refineAssignments = (data?: any) => {
    const refinedData = data?.leftGrid?.map((item: any) => {
      const d = data?.rightGrid?.find(
        (rightItem: any) => item.vdtIdpk === rightItem.vdtIDpk
      );
      if (d) {
        return {
          ...item,
          vcrIDpk: d.vcrIDpk,
          vcrOdometerStart: d.vcrOdometerStart,
          vcrOdometerEnd: d.vcrOdometerEnd,
          vcrDistance: d.vcrOdometerEnd - d.vcrOdometerStart,
          vcrInside: d.vcrInside,
          vcrOutside: d.vcrOutside,
          vcrTyre: d.vcrTyre,
          vcrSuspension: d.vcrSuspension,
          vcrAirConditioner: d.vcrAirConditioner,
          vcrEngine: d.vcrEngine,
          vcrBoard: d.vcrBoard,
          vcrCard: d.vcrCard,
          vcrNonCard: d.vcrNonCard,
          // vcrSave: (d.vcrAcknowledged || d.vcrSubmitted) && !!d ? true : false,
          vcrSave: true,
          vcrSubmitted: d.vcrSubmitted,
          vcrAcknowledged: d.vcrAcknowledged,
          vcrRmks: d.vcrRmks,
          vdtIDpk: d.vdtIDpk,
        };
      }
      return {
        ...item,
        vcrIDpk: 0,
        vcrOdometerStart: 0,
        vcrOdometerEnd: 0,
        vcrDistance: 0,
        vcrInside: "Not Saved",
        vcrOutside: "Not Saved",
        vcrTyre: "Not Saved",
        vcrSuspension: "Not Saved",
        vcrAirConditioner: "Not Saved",
        vcrEngine: "Not Saved",
        vcrBoard: false,
        vcrCard: false,
        vcrNonCard: false,
        vcrSave: false,
        vcrSubmitted: false,
        vcrAcknowledged: false,
        vdtIDpk: 0,
        vcrRmks: "",
      };
    });
    console.log("refinedData", refinedData);
    return refinedData;
  };

  const handleSave = async (data: any) => {
    const dataToBeSaved = handleCheckRecords(data);

    console.log("dataToBeSaved", dataToBeSaved);

    try {
      dataToBeSaved?.forEach(async (record) => {
        if (record.vcrIDpk === 0 && !!record.vcrSave) {
          await posting(
            `AssetTamVehicleConditionReturn/CreateAssetTamVehicleConditionReturn`,
            {
              vcrVehicleAssignmentIdfk: record.vasIdpk,
              vcrDate: `${formState.date.id}`,
              // vcrCondition: record.vcrCondition,
              vcrCondition: record.vcrRmks,
              vcrInside: record.vcrInside,
              vcrOutside: record.vcrOutside,
              vcrTyre: record.vcrTyre,
              vcrSuspension: record.vcrSuspension,
              vcrEngine: record.vcrEngine,
              vcrAirConditioner: record.vcrAirConditioner,
              vcrBoard: record.vcrBoard,
              vcrCard: record.vcrCard,
              vcrNonCard: record.vcrNonCard,
              vcrOdometerStart: record.vcrOdometerStart,
              vcrOdometerEnd: record.vcrOdometerEnd,
              vcrRmks: record.vcrRmks,
            },
            `Created vehicle condition returns successfully for ${record.vdtVehicleAssignment}, ${record.vcrOdometerStart} - ${record.vcrOdometerEnd}, ${record.vcrInside}, ${record.vcrOutside}, ${record.vcrTyre}, ${record.vcrSuspension}, ${record.vcrEngine}, ${record.vcrAirConditioner}, ${record.vcrBoard}, ${record.vcrCard}, ${record.vcrNonCard}, ${record.vcrRmks}`
          );
          setRefreshAssignmentGrid((state) => !state);
          refreshState((state) => !state);
        } else if (!!record.vcrSave) {
          const response = await updating(
            `AssetTamVehicleConditionReturn/UpdateAssetTamVehicleConditionReturn`,
            {
              vcrIDpk: record.vcrIDpk,
              vcrVehicleAssignmentIdfk: record.vasIdpk,
              vcrDate: `${currentDate}`,
              vcrCondition: record.vcrRmks,
              vcrInside: record.vcrInside,
              vcrOutside: record.vcrOutside,
              vcrTyre: record.vcrTyre,
              vcrSuspension: record.vcrSuspension,
              vcrEngine: record.vcrEngine,
              vcrAirConditioner: record.vcrAirConditioner,
              vcrBoard: record.vcrBoard,
              vcrCard: record.vcrCard,
              vcrNonCard: record.vcrNonCard,
              vcrOdometerStart: record.vcrOdometerStart,
              vcrOdometerEnd: record.vcrOdometerEnd,
              vcrRmks: record.vcrRmks,
            },
            `Updated vehicle condition returns successfully for ${record.vdtVehicleAssignment}, ${record.vcrOdometerStart} - ${record.vcrOdometerEnd}, ${record.vcrInside}, ${record.vcrOutside}, ${record.vcrTyre}, ${record.vcrSuspension}, ${record.vcrEngine}, ${record.vcrAirConditioner}, ${record.vcrBoard}, ${record.vcrCard}, ${record.vcrNonCard}, ${record.vcrRmks}`
          );

          if (response.data?.success) {
            setModalData((current) => ({
              ...current,
              state: true,
              icon: "success",
              title: "Success",
              cancelText: " ",
              okText: "Ok",
              message: "Records saved successfully",
              okHandler: () => {
                setModalData((current) => ({
                  ...current,
                  state: false,
                }));
              },
              disableCancel: true,
            }));
          }
          // refreshState((state) => !state);
          // setRefreshAssignmentGrid((state) => !state);
        }
      });
      setRefreshAssignmentGrid((state) => !state);
      refreshState((state) => !state);
    } catch (e: any) {
      setLoadingAlert(false);
      setModalData((current) => ({
        ...current,
        state: true,
        icon: "warning",
        title: "Record not saved",
        cancelText: " ",
        message: "Failed to save records",
        okHandler: () => {
          setModalData((current) => ({
            ...current,
            state: false,
          }));
        },
        cancelHandler: () => {},
        disableCancel: true,
      }));
    }
  };

  // const handleSubmit = async () => {
  //   try {
  //     await posting(
  //       `AssetTamVehicleConditionReturn/CreateAssetTamVehicleConditionReturn`,
  //       { selectedDate: formState.date.value },
  //       `Submitted vehicle condition returns successfully for ${formState.date.value},`
  //     );

  //     setModalData((current) => ({
  //       ...current,
  //       state: true,
  //       icon: "success",
  //       title: "Success",
  //       cancelText: " ",
  //       okText: "Ok",
  //       message: "Records submitted successfully",
  //       okHandler: () => {
  //         setModalData((current) => ({
  //           ...current,
  //           state: false,
  //         }));
  //       },
  //       disableCancel: true,
  //     }));

  //     // updateState("refresh", !formState.refresh);
  //     setRefreshAssignmentGrid((state) => !state);
  //   } catch (e: any) {
  //     setLoadingAlert(false);
  //     setModalData((current) => ({
  //       ...current,
  //       state: true,
  //       icon: "warning",
  //       title: "Record not submitted",
  //       cancelText: " ",
  //       message: "No records to submit",
  //       okHandler: () => {
  //         setModalData((current) => ({
  //           ...current,
  //           state: false,
  //         }));
  //       },
  //       cancelHandler: () => {},
  //       disableCancel: true,
  //     }));
  //   }
  // };

  return (
    <>
      {/* {loadingAlert && <CustomLoader text="Saving Data Entries" />}
      {(details_error ||
        dept_data_error ||
        date_data_error ||
        sxnData_error) && <WarningAlert />} */}
      <div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
            height:22px
          }   
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        />

        {toggled && (
          <div
            style={{ borderColor: borderTheme }}
            className="w-full border rounded-md rounded-b-xs"
          >
            <div
              id={"form_header"}
              style={{ borderColor: borderTheme }}
              className="border-b-[1px] border-l-1 border-l-0 border-r-1 border-t-1  pt-1 px-2 w-full bg-slate-100 font-medium"
            >
              Vehicle Condition Details
            </div>
            <div className="  ">
              <div className=" pb-1">
                <div className="flex text-xs">
                  <div className="flex px-2 my-0.5">
                    <ModalTemplate
                      icon={modalData.icon}
                      disableCancel={modalData.disableCancel}
                      cancelText={modalData.cancelText}
                      open={modalData.state}
                      okHandler={modalData.okHandler}
                      cancelHandler={modalData.cancelHandler}
                      message={modalData.message}
                      okText={modalData.okText}
                      title={modalData.title}
                    />

                    <Form.Item
                      label={<p className="text-xs">{"Date"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        style={{ display: "inline-block", width: "150px" }}
                      >
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            style={{ width: "200px" }}
                            isOpenDropdown={formState.isOpenDropdown1}
                            handleRefresh={() => {
                              setFormState({
                                ...formState,
                                date: {
                                  id: 0,
                                  value: "",
                                },
                              });
                              // updateState("refresh", !formState.refresh);
                              setRefreshAssignmentGrid((state) => !state);
                            }}
                            disabled={false}
                            options={date_data}
                            dataexp="monthYear"
                            idexpr="dateValue"
                            selectedValue={(e) => {
                              updateState("date", {
                                id: e.dateValue,
                                value: e.monthYear,
                              });

                              updateState("isOpenDropdown1", false);
                            }}
                            handleSearch={
                              debounce((e: any) => {
                                updateState("date"!, e);
                                // dispatch(singleEndpoint(e));
                                setSearch(e);

                                updateState(
                                  "isOpenDropdown1",
                                  !formState.isOpenDropdown1
                                );
                              }, 500) // Adjust the debounce delay as needed
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                "isOpenDropdown1",
                                !formState.isOpenDropdown1
                              );
                              dispatch(singleEndpoint(e));
                            }}
                            placeHolder={formState.date.value}
                            useCallFunc={true}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs ml-5">{"Dept"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        style={{ display: "inline-block", width: "150px" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            handleRefresh={() => {
                              setFormState({
                                ...formState,
                                dept: {
                                  id: 0,
                                  value: "",
                                },
                              });
                              // updateState("refresh", !formState.refresh);
                              setRefreshAssignmentGrid((state) => !state);
                            }}
                            style={{ width: "200px" }}
                            disabled={disableSearchParams}
                            isOpenDropdown={formState.isOpenDropdown2}
                            handleSearch={
                              debounce((e: any) => {
                                updateState("dept"!, e);
                                dispatch(singleEndpoint2(e));

                                updateState(
                                  "isOpenDropdown2",
                                  !formState.isOpenDropdown2
                                );
                              }, 500) // Adjust the debounce delay as needed
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                "isOpenDropdown2",
                                !formState.isOpenDropdown2
                              );
                              dispatch(singleEndpoint2(e));
                            }}
                            options={deptData}
                            dataexp="dptShtName"
                            idexpr="dptIDpk"
                            selectedValue={(e) => {
                              updateState("dept", {
                                id: e.dptIDpk,
                                value: e.dptShtName,
                              });
                              updateState("isOpenDropdown2", false);
                            }}
                            placeHolder={formState.dept.value}
                            useCallFunc={true}
                          />
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item
                      label={<p className="text-xs ml-5">{"Section"}</p>}
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        name="issuedBy"
                        style={{ display: "inline-block", width: "150px" }}
                      >
                        {" "}
                        <div className="flex flex-row items-center">
                          <InputSelect_template
                            handleRefresh={() => {
                              // setFormState({
                              //   ...formState,
                              //   sect: {
                              //     id: 0,
                              //     value: "",
                              //   },
                              // });
                              // updateState("refresh", !formState.refresh);
                            }}
                            style={{ width: "200px" }}
                            disabled={disableSearchParams}
                            isOpenDropdown={formState.isOpenDropdown3}
                            handleSearch={
                              debounce((e: any) => {
                                updateState("sect"!, e);
                                setSearch(e);
                                // dispatch(singleEndpoint3(e));
                                updateState(
                                  "isOpenDropdown3",
                                  !formState.isOpenDropdown3
                                );
                              }, 500) // Adjust the debounce delay as needed
                            }
                            handleFocus={(e: any) => {
                              updateState(
                                "isOpenDropdown3",
                                !formState.isOpenDropdown3
                              );
                              // console.log("formState",formState.dept.id)
                              // dispatch(singleEndpoint3("" , formState));
                              // console.log("formState",formState.dept.id)
                            }}
                            options={fetchSection}
                            dataexp="sxnShtName"
                            idexpr="sxnIDpk"
                            selectedValue={(e) => {
                              updateState("sect", {
                                id: e.sxnIDpk,
                                value: e.sxnShtName,
                              });
                              updateState("isOpenDropdown3", false);
                            }}
                            placeHolder={formState.sect.value}
                            useCallFunc={true}
                          />
                          <Tooltip
                            target="#section"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            hideOnOutsideClick={true}
                          >
                            <p className="">
                              Select Section to load vehicles for or select
                              nothing here to select all vehicles in the
                              department that are assigned to you, directly or
                              indirectly
                            </p>
                          </Tooltip>
                        </div>
                      </Form.Item>
                    </Form.Item>

                    <div id="loadmydata" className="ml-4 flex items-center">
                      <CheckboxTemlate
                        customDisabled={false}
                        withBorders={true}
                        useCallbackFunc
                        setCheckboxOnchange={(val) => {
                          setLoadData(val);
                          setDisableSearchParams(val);
                          console.log("Check value", val);
                          clearData();
                        }}
                      />
                      <span className="text-md mx-1">Load my data</span>
                      <Tooltip
                        target="#loadmydata"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="">
                          Check to load vehicles that you are Admin or Vehicle
                          Controller for. Uncheck to load all vehicles that are
                          assigned to you, directly or indirectly
                        </p>
                      </Tooltip>
                    </div>
                    <div className=" mt-1">
                      <li
                        onClick={() => {
                          validation();
                        }}
                        id="search1"
                        style={{ borderWidth: "1px", borderColor: borderTheme }}
                        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                      >
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                          {" "}
                          <img
                            alt=""
                            style={{ height: "15px", width: "17px" }}
                            className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                            src={search1}
                          />
                        </span>
                        <Tooltip
                          target="#search1"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          hideOnOutsideClick={true}
                        >
                          <p className="">
                            Click to search for assigned vehicles
                          </p>
                        </Tooltip>
                      </li>
                    </div>
                    <div className=" mt-1">
                      <li
                        onClick={() => {
                          setLoadData(false);
                          setDisableSearchParams(false);
                          setFormState({
                            ...formState,
                            date: { id: 0, value: "" },
                            dept: { id: 0, value: "" },
                            sect: { id: 0, value: "" },
                          });
                          // updateState("refresh", !formState.refresh);
                          setRefreshAssignmentGrid((state) => !state);
                        }}
                        id="reset"
                        style={{ borderWidth: "1px", borderColor: borderTheme }}
                        className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                      >
                        <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                          {" "}
                          <img
                            alt=""
                            style={{ height: "15px", width: "17px" }}
                            className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                            src={reset}
                          />
                        </span>
                        <Tooltip
                          target="#reset"
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          hideOnOutsideClick={true}
                        >
                          <p className="">Refresh search criteria</p>
                        </Tooltip>
                      </li>
                    </div>
                  </div>
                </div>

                <div className="px-2 ">
                  <Datagrid_template
                    deselectFirstRow
                    dataId="vasIdpk"
                    handlePageNumber={(page) => {
                      setAllVeh((prev) => ({ ...prev, pageNumber: page }));
                    }}
                    handlePageSize={(size) => {
                      setAllVeh((prev) => ({ ...prev, pageSize: size }));
                    }}
                    disablePaging={false}
                    disableGroupPanel
                    disableSearch={false}
                    // data={combinedData}
                    // data={mockedVehicles}
                    data={VehicleConditions}
                    columns={combinedColumns}
                    gridheight={320}
                    allowColumnEditing={true}
                    // cellColoringFxn={cellColoring}
                    cellColoringFxn={onCellPrepared}
                    onRowPrepared={(e) => {}}
                    onRowUpdated={(e) => {}}
                    onContentReady={(e) => {
                      vehicleRef.current = e.component;
                    }}
                  />
                </div>

                <div className=" mt-1 flex justify-between px-1">
                  <div className="flex">
                    <div>
                      {" "}
                      <button
                        style={{ backgroundColor: "#f0e68c" }}
                        className=" dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-0.5 border border-stone-200 rounded"
                      >
                        Not Saved
                      </button>
                    </div>
                    <div>
                      {" "}
                      <button className="bg-orange-100 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  px-2 py-0.5 border border-stone-200 rounded">
                        Saved, Not Submitted
                      </button>
                    </div>
                    <div>
                      {" "}
                      <button
                        style={{ backgroundColor: "#ffff00" }}
                        className=" dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1  px-2 py-0.5 border border-stone-200 rounded"
                      >
                        Submitted
                      </button>
                    </div>
                    <div>
                      {" "}
                      <button className="bg-green-300 dark:text-darkModeSkin-base flex flex-row items-center justify-center ml-1   px-2 py-0.5 border border-stone-200 rounded">
                        Acknowledged
                      </button>
                    </div>

                    <li
                      onClick={() => {
                        const data = vehicleRef.current.getDataSource().items();
                        handleCheckRecords(data);
                      }}
                      id="newRecord"
                      style={{ borderWidth: "1px", borderColor: borderTheme }}
                      className="hover:cursor-pointer ml-20 mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                    >
                      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                        {" "}
                        <img alt="" className="pr-1 " src={correct} />
                        Check{" "}
                      </span>
                    </li>
                  </div>
                  <div
                    className="flex mr-1"
                    // style={{
                    //   opacity: !disableSearchParams ? 0.5 : 1,
                    //   pointerEvents: !disableSearchParams ? "none" : "auto",
                    // }}
                  >
                    <SaveButton
                      // className="flex h-8 hover:border-blue-200 flex-row items-center justify-center disabled:cursor-not-allowed mr-4"
                      useCallbackFunc={true}
                      handleSave={AccessPriv_on_SaveButton}
                      disableButton={!allowFormSave}
                    />
                    <button
                      disabled={!allowFormSubmit}
                      onClick={() => {
                        AccessPriv_on_SubmitButton();
                      }}
                      id="submit"
                      style={{ borderWidth: "1px", borderColor: borderTheme }}
                      className="disabled:cursor-not-allowed disabled:opacity-70 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                    >
                      <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                        {" "}
                        <img alt="" className="pr-1 " src={submit} />
                        Submit{" "}
                      </span>
                    </button>
                    <Tooltip
                      target="#submit"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Submit saved returns</p>
                    </Tooltip>
                    <Tooltip
                      target="#save"
                      showEvent="dxhoverstart"
                      hideEvent="dxhoverend"
                      hideOnOutsideClick={true}
                    >
                      <p className="">Save correctly entered returns</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="pt-0.5">
          <Datagrid_template
            deselectFirstRow
            dataId="vcrIdpk"
            disablePaging={false}
            handlePageNumber={(page) => {
              setPagination((prev) => ({ ...prev, pageNumber: page }));
              // alert("Page Number" + page);
              // refreshState((state) => !state);
            }}
            handlePageSize={(size) => {
              setPagination((prev) => ({ ...prev, pageSize: size }));
              // alert("Page Size" + size);
              // refreshState((state) => !state);
            }}
            data={table_one_data}
            columns={vehicle_condition_data}
            gridheight={!toggled === true ? tableHeight : pageHeight}
            onRowClick={(e) => {
              AccessPriv_on_DataGridDoubleclick(e);
              setSelectedTopData && setSelectedTopData(e);
              // dispatch(setGeneralValue({ expr: "topDataRecord", value: topDataRecord }));
              // setDate( dayjs(topDataRecord.vcrDate))
              // dispatch( setGeneralValue({ expr: "date", value: date }));
              // console.log("%^& DownGrid", e)
              console.log("SELECTED: ", e);
              console.log("%^& topDataRecord", topDataRecord);
            }}
          />

          <div className=" px-4 text-center justify-center border text-xs">{`Total: 0,000.00`}</div>
          <Tooltip
            target="#total"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            hideOnOutsideClick={true}
          >
            <p className="">Vehicle details</p>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default VehicleConditionDetails;
