import { Item } from "devextreme-react/accordion";
import { useEffect, useRef, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import MeterDetails from "./meter-details";
import { MeterToolbar } from "./meter-toolbar";
import useFetch from "../../../../../../hooks/useFetch";

const Meter = () => {
  const taskDetailsHeight = "309px";

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true);
  const [task_datagrid_height, set_task_datagrid_height] = useState(
    `calc(100% - ${taskDetailsHeight})`
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const current_page_route = "/tasks-todos";
  const view = searchParams.get("view");
  const taskDataGrid = "task-data-grid-extended";
  const [showTable, setShowTable] = useState(true);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const [searchText, setSearchText] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [meter_data, meter_errors, meter_loads] = useFetch(
    `meters/GetAllMeters?searchTearm=${searchText}`,
    refresh,
    "mtrIdpk",
    false,
    "powerapp"
  );
  const childRef = useRef<any>();

  return (
    <>
      <div className="">
        <MeterToolbar
          handleFind={() => setRefresh(!refresh)}
          searchTextOnchange={(text) => setSearchText(text)}
          toggler={switch_toggle}
        />
        {/* <Staff_customtoolbar /> */}
      </div>

      <div className="px-2 mb-0.5 ">
        <MeterDetails
          searchTextValue={searchText}
          ref={childRef}
          toggled={showTable}
          meter_data={meter_data}
          meter_loads={meter_loads}
        />
      </div>
    </>
  );
};

export default Meter;
