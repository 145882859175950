import { Console, log } from "console";
import { Item } from "devextreme-react/accordion";
import { useEffect, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import useFetch from "../../../../../../hooks/useFetch";

import GroupPPEStockDetails from "./capacitor-bank-details";
import CapacitorBankDetails from "./capacitor-bank-details";
import CentralPPEStockDetails from "./capacitor-bank-details";
import { CapacitorBankToolbar } from "./capacitor-bank-toolbar";

const CapacitorBank = () => {
  const taskDetailsHeight = "309px";

  const [tasks_update_state, setTask_update_state] = useState<boolean>(true);
  const [task_datagrid_height, set_task_datagrid_height] = useState(
    `calc(100% - ${taskDetailsHeight})`
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const current_page_route = "/tasks-todos";
  const view = searchParams.get("view");
  const taskDataGrid = "task-data-grid-extended";
  const [showTable, setShowTable] = useState(true);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  return (
    <>
      <div className="">
        <CapacitorBankToolbar toggler={switch_toggle} />
        {/* <Staff_customtoolbar /> */}
      </div>

      <div className="px-2 mb-0.5  ">
        <CapacitorBankDetails toggled={showTable} />
      </div>
    </>
  );
};

export default CapacitorBank;
