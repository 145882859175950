import React from "react";

import general from "../../../assets/general.png";
import executive from "../../../assets/executive.png";
import human from "../../../assets/human.png";
import docs from "../../../assets/docs.png";
import assets from "../../../assets/assets.png";
import power from "../../../assets/power.png";
import performance from "../../../assets/performance.png";
import budget from "../../../assets/budget.png";
import safety from "../../../assets/safety.png";
import legal from "../../../assets/legal.png";
import financials from "../../../assets/financials.png";
import payroll from "../../../assets/payroll.png";
import software from "../../../assets/software.png";
import help from "../../../assets/help.png";
import projects from "../../../assets/projects.png";
import administrator from "../../../assets/administrator.png";

export const Drawerdata = [
  // EXECUTIVE DASHBOARD
  {
    ID: "1",
    name: "Executive Dashboard",
    icon: executive,
    isExpanded: false,
  },

  //## GENERAL SERVICES
  {
    ID: "2",
    name: "General",
    icon: general,
    isExpanded: false,
  },

  // HR

  {
    ID: "3",
    name: "Human Resource",
    icon: human,
  },

  //HUMAN RESOURCE entries
  {
    ID: "3_2",
    categoryId: "3",
    name: "Entries",
  },
  //HUMAN RESOURCE entries Staff Management
  {
    ID: "3_2_1",
    categoryId: "3_2",
    name: "Staff Management",
  },
  {
    ID: "3_2_1_1",
    categoryId: "3_2_1",
    name: "Staff Details",
    route: "/human-resource/entries/staff-management/staff-details",
    serverId: 64,
  },

  //HUMAN RESOURCE entries travel management
  {
    ID: "3_2_6",
    categoryId: "3_2",
    name: "Travels Management",
  },

  {
    ID: "3_2_6_1",
    categoryId: "3_2_6",
    name: "Passport Pictures",
    route: "/human-resource/entries/staff-management/passport-picture",
  },

  //HUMAN RESOURCE Setups
  {
    ID: "3_3",
    categoryId: "3",
    name: "Setups",
  },
  //HUMAN RESOURCE Setups HR operations
  {
    ID: "3_3_1",
    categoryId: "3_3",
    name: "HR Operations",
  },
  {
    ID: "3_3_1_1",
    categoryId: "3_3_1",
    name: "Branches",
    route: "/human-resource/setups/hr-operations/branches",
    serverId: 427,
  },
  {
    ID: "3_3_1_2",
    categoryId: "3_3_1",
    name: "Department",
    route: "/human-resource/setups/hr-operations/department",
    serverId: 69,
  },
  {
    ID: "3_3_1_3",
    categoryId: "3_3_1",
    name: "Sections",
    route: "/human-resource/setups/hr-operations/sections",
    serverId: 84,
  },
  {
    ID: "3_3_1_4",
    categoryId: "3_3_1",
    name: "Unit",
    route: "/human-resource/setups/hr-operations/unit",
    serverId: 89,
  },
  {
    ID: "3_3_1_5",
    categoryId: "3_3_1",
    name: "Areas",
    route: "/human-resource/setups/hr-operations/areas",
    serverId: 65,
  },
  {
    ID: "3_3_1_6",
    categoryId: "3_3_1",
    name: "Locations",
    route: "/human-resource/setups/hr-operations/locations",
    serverId: 77,
  },

  {
    ID: "3_3_1_7",
    categoryId: "3_3_1",
    name: "Titles",
    route: "/human-resource/setups/hr-operations/titles",
    serverId: 87,
  },
  {
    ID: "3_3_1_8",
    categoryId: "3_3_1",
    name: "Marital Status",
    route: "/human-resource/setups/hr-operations/marital-status",
  },
  {
    ID: "3_3_1_9",
    categoryId: "3_3_1",
    name: "Employee Types",
    route: "/human-resource/setups/hr-operations/employee-types",
  },
  {
    ID: "3_3_1_10",
    categoryId: "3_3_1",
    name: "Employee Groups",
    route: "/human-resource/setups/hr-operations/employee-groups",
    serverId: 496,
  },
  {
    ID: "3_3_1_11",
    categoryId: "3_3_1",
    name: "Person Types",
    route: "/human-resource/setups/hr-operations/person-types",
    serverId: 291,
  },
  {
    ID: "3_3_1_12",
    categoryId: "3_3_1",
    name: "Assigment Statuses",
    route: "/human-resource/setups/hr-operations/assignment-statuses",
    serverId: 290,
  },
  {
    ID: "3_3_1_13",
    categoryId: "3_3_1",
    name: "Office Locations",
    route: "/human-resource/setups/hr-operations/office-locations",
    serverId: 77,
  },
  {
    ID: "3_3_1_14",
    categoryId: "3_3_1",
    name: "Grades",
    route: "/human-resource/setups/hr-operations/grades",
    serverId: 73,
  },

  {
    ID: "3_3_1_15",
    categoryId: "3_3_1",
    name: "Job Titles",
    route: "/human-resource/setups/hr-operations/job-titles",
    serverId: 76,
  },
  {
    ID: "3_3_1_16",
    categoryId: "3_3_1",
    name: "Steps",
    route: "/human-resource/setups/hr-operations/steps",
    serverId: 86,
  },
  {
    ID: "3_3_1_17",
    categoryId: "3_3_1",
    name: "Room NOs",
    route: "/human-resource/setups/hr-operations/room-nos",
    serverId: 295,
  },
  {
    ID: "3_3_1_18",
    categoryId: "3_3_1",
    name: "Appraisal Groups",
    route: "/human-resource/setups/hr-operations/appraisal-groups",
  },
  {
    ID: "3_3_1_19",
    categoryId: "3_3_1",
    name: "Genders",
    route: "/human-resource/setups/hr-operations/genders",
  },
  {
    ID: "3_3_1_20",
    categoryId: "3_3_1",
    name: "Employee Categories",
    route: "/human-resource/setups/hr-operations/employee-categories",
  },
  {
    ID: "3_3_1_21",
    categoryId: "3_3_1",
    name: "Countries",
    route: "/human-resource/setups/hr-operations/countries",
  },
  {
    ID: "3_3_1_22",
    categoryId: "3_3_1",
    name: "Regions",
    route: "/human-resource/setups/hr-operations/regions",
    serverId: 83,
  },
  {
    ID: "3_3_1_23",
    categoryId: "3_3_1",
    name: "Districts",
    route: "/human-resource/setups/hr-operations/districts",
    serverId: 82,
  },
  {
    ID: "3_3_1_24",
    categoryId: "3_3_1",
    name: "Towns",
    route: "/human-resource/setups/hr-operations/towns",
  },
  {
    ID: "3_3_1_25",
    categoryId: "3_3_1",
    name: "Cost Centers",
    route: "/human-resource/setups/hr-operations/cost-centers",
  },
  {
    ID: "3_3_1_26",
    categoryId: "3_3_1",
    name: "Assignment Categories",
    route: "/human-resource/setups/hr-operations/assignment-categories",
    serverId: 240,
  },

  //DOCUMENTS MANAGER
  {
    ID: "4",
    name: "Document Manager",
    icon: docs,
    isExpanded: false,
  },

  //ASSETS MANAGER
  {
    ID: "5",
    name: "Assets Manager",
    icon: assets,
  },

  // ASSETS TRANSPORT
  {
    ID: "5_2",
    categoryId: "5",
    name: "Transport",
  },
  {
    ID: "5_2_1",
    categoryId: "5_2",
    name: "Entries",
  },

  {
    ID: "5_2_1_1",
    categoryId: "5_2_1",
    name: "Vehicles",
  },
  {
    ID: "5_2_1_1_1",
    categoryId: "5_2_1_1",
    name: "Vehicle Details",
    route: "/assets-manager/transport/entries/vehicles/vehicle-details",
    serverId: 256,
  },
  {
    ID: "5_2_1_1_2",
    categoryId: "5_2_1_1",
    name: "Upload Vehicle Details",
    route: "/assets-manager/transport/entries/vehicles/vehicle-data-upload",
  },
  {
    ID: "5_2_1_1_3",
    categoryId: "5_2_1_1",
    name: "Vehicle Assignments",
    route: "/assets-manager/transport/entries/vehicles/vehicle-assignments",
    serverId: 339,
  },
  {
    ID: "5_2_1_1_4",
    categoryId: "5_2_1_1",
    name: "Vehicle Log Book",
    route: "/assets-manager/transport/entries/vehicles/vehicle-log-book",
    serverId: 368,
  },
  {
    ID: "5_2_1_1_5",
    categoryId: "5_2_1_1",
    name: "Vehicle Requests",
    route: "/assets-manager/transport/entries/vehicles/vehicle-requests",
    serverId: 398,
  },

  {
    ID: "5_2_1_1_6",
    categoryId: "5_2_1_1",
    name: "Vehicle Condition Returns",
    route:
      "/assets-manager/transport/entries/vehicles/vehicle-condition-returns",
    serverId: 385,
  },
  {
    ID: "5_2_1_1_7",
    categoryId: "5_2_1_1",
    name: "Vehicle SIM Card Assignments",
    route:
      "/assets-manager/transport/entries/vehicles/vehicle-sim-card-assignments",
    serverId: 402,
  },
  {
    ID: "5_2_1_1_8",
    categoryId: "5_2_1_1",
    name: "Vehicle Fuel Card Assignments",
    route:
      "/assets-manager/transport/entries/vehicles/vehicle-fuel-card-assignments",
    serverId: 498,
  },

  {
    ID: "5_2_1_1_9",
    categoryId: "5_2_1_1",
    name: "Vehicle Images",
    route: "/assets-manager/transport/entries/vehicles/vehicle-images",
    serverId: 371,
  },
  {
    ID: "5_2_1_1_10",
    categoryId: "5_2_1_1",
    name: "Vehicle Requests Security",
    route:
      "/assets-manager/transport/entries/vehicles/vehicle-requests-security",
    serverId: 474,
  },

  {
    ID: "5_2_1_1_12",
    categoryId: "5_2_1_1",
    name: "Vehicle And Vehicle Class Assignments",
    route:
      "/assets-manager/transport/entries/vehicles/vehicle&vehicle-class-assignments",
    serverId: 475,
  },

  // ASSETS TRANSPORT FUEL
  {
    ID: "5_2_1_2",
    categoryId: "5_2_1",
    name: "Fuel",
  },

  {
    ID: "5_2_1_2_1",
    categoryId: "5_2_1_2",
    name: "Fuel Returns & Requests",
    route: "/assets-manager/transport/entries/fuel/return_request",
  },
  {
    ID: "5_2_1_2_2",
    categoryId: "5_2_1_2",
    name: "Fuel Transaction Uploads",
    route: "/assets-manager/transport/entries/fuel/fuel-transaction-uploads",
  },
  {
    ID: "5_2_1_2_3",
    categoryId: "5_2_1_2",
    name: "Fuel Top-Up Requests",
    route: "/assets-manager/transport/entries/fuel/fuel-topup-request",
    serverId: 497,
  },

  {
    ID: "5_2_1_2_4",
    categoryId: "5_2_1_2",
    name: "Fuel Dump Dips",
    route: "/assets-manager/transport/entries/fuel/fuel-dump-dips",
    serverId: 412,
  },
  {
    ID: "5_2_1_2_5",
    categoryId: "5_2_1_2",
    name: "Backlog Fuel Entries",
    route: "/assets-manager/transport/entries/fuel/fuel-backlog-entries",
    serverId: 406,
  },

  {
    ID: "5_2_1_2_7",
    categoryId: "5_2_1_2",
    name: "Tag Comments",
    route: "/assets-manager/transport/entries/fuel/tag-comments",
    serverId: 423,
  },

  // ASSETS TRANSPORT Maintenance
  {
    ID: "5_2_1_3",
    categoryId: "5_2_1",
    name: "Maintenance",
  },

  {
    ID: "5_2_1_3_1",
    categoryId: "5_2_1_3",
    name: "Maintenance  Requests",
    route: "/assets-manager/transport/entries/Maintenance/maintenance-requests",
    serverId: 424,
  },
  {
    ID: "5_2_1_3_2",
    categoryId: "5_2_1_3",
    name: "Maintenance Activities",
    route:
      "/assets-manager/transport/entries/Maintenance/maintenance-activities",
    serverId: 279,
  },

  {
    ID: "5_2_1_3_3",
    categoryId: "5_2_1_3",
    name: "Maintenance Schedules",
    route: "/assets-manager/transport/entries/Maintenance/maintenance-schedule",
    serverId: 278,
  },
  {
    ID: "5_2_1_3_4",
    categoryId: "5_2_1_3",
    name: "Maintenance Schedules Alerts",
    route:
      "/assets-manager/transport/entries/maintenance/maintenance-schedule-sms-alerts",
    serverId: 380,
  },

  {
    ID: "5_2_1_3_6",
    categoryId: "5_2_1_3",
    name: "PM Activities Capture",
    route: "/assets-manager/transport/entries/activities-capture",
  },
  // General

  {
    ID: "5_2_1_5",
    categoryId: "5_2_1",
    name: "General",
  },

  {
    ID: "5_2_1_5_1",
    categoryId: "5_2_1_5",
    name: "Alert Drivers & Permit Holders",
    route:
      "/assets-manager/transport/entries/transport-alerts-drivers-permit-holders",
    serverId: 405,
  },
  {
    ID: "5_2_1_5_2",
    categoryId: "5_2_1_5",
    name: "Transport Documents",
    route: "/assets-manager/transport/entries/fuel/fuel-documents",
    serverId: 518,
  },
  {
    ID: "5_2_1_5_3",
    categoryId: "5_2_1_5",
    name: "Card Details",
    route: "/assets-manager/transport/setups/general/card-details",
    serverId: 275,
  },
  {
    ID: "5_2_1_5_4",
    categoryId: "5_2_1_5",
    name: "ELog Book",
    route: "/assets-manager/transport/entries/general/elogger",
    serverId: 311,
  },
  {
    ID: "5_2_1_5_5",
    categoryId: "5_2_1_5",
    name: "Station Readings",
    route: "/assets-manager/transport/entries/general/station-readings",
    serverId: 0,
  },
  {
    ID: "5_2_1_5_6",
    categoryId: "5_2_1_5",
    name: "Bus Details",
    route: "/assets-manager/transport/entries/general/bus-details",
    serverId: 911,
  },
  {
    ID: "5_2_1_5_7",
    categoryId: "5_2_1_5",
    name: "Equipment Outage Types",
    route: "/assets-manager/transport/entries/general/equipment-outage-types",
    serverId: -2,
  },
  {
    ID: "5_2_1_5_8",
    categoryId: "5_2_1_5",
    name: "Law Catalogs",
    route: "/assets-manager/transport/entries/general/law-catalogs",
    serverId: -3243,
  },
  {
    ID: "5_2_1_5_424",
    categoryId: "5_2_1_5",
    name: "Judgement Catalogs",
    route: "/assets-manager/transport/entries/general/judgement-catalogs",
    serverId: -3243,
  },

  {
    ID: "5_2_1_5_9",
    categoryId: "5_2_1_5",
    name: "Transformer Details",
    route: "/assets-manager/transport/entries/general/transformer-details",
    serverId: 3,
  },
  {
    ID: "5_2_1_5_10",
    categoryId: "5_2_1_5",
    name: "Line Details",
    route: "/assets-manager/transport/entries/general/line-details",
    serverId: 8,
  },
  // TODO: remember to add serverId for station details

  // ASSETS TRANSPORT Exams
  {
    ID: "5_2_1_6",
    categoryId: "5_2_1",
    name: "Exams",
  },
  {
    ID: "5_2_1_6_1",
    categoryId: "5_2_1_6",
    name: "Exams Names",
    route: "/assets-manager/transport/setups/general/exams-names",
    serverId: 376,
  },
  {
    ID: "5_2_1_6_2",
    categoryId: "5_2_1_6",
    name: "Exams Questions",
    route: "/assets-manager/transport/setups/general/exams-questions",
    serverId: 374,
  },
  {
    ID: "5_2_1_6_3",
    categoryId: "5_2_1_6",
    name: "Exams Registrations",
    route:
      "/assets-manager/transport/setups/general/driving-test-registrations",
  },
  {
    ID: "5_2_1_6_4",
    categoryId: "5_2_1_6",
    name: "Driving Tests",
    route: "/assets-manager/transport/entries/exams/driving-tests",
  },

  // ASSETS TRANSPORT ENTRIES Priviledges
  {
    ID: "5_2_1_7",
    categoryId: "5_2_1",
    name: "Priviledges Assignments",
  },

  {
    ID: "5_2_1_7_2",
    categoryId: "5_2_1_7",
    name: "Fuel Dump Attendants",
    route: "/assets-manager/transport/entries/maintenance/fuel-dump-attendants",
    serverId: 369,
  },
  {
    ID: "5_2_1_7_3",
    categoryId: "5_2_1_7",
    name: "Driver's Code Assignments",
    route:
      "/assets-manager/transport/entries/priviledges/drivers-code-assignments",
    serverId: 308,
  },
  {
    ID: "5_2_1_7_4",
    categoryId: "5_2_1_7",
    name: "Drivers/Permit Holders Assignments",
    route:
      "/assets-manager/transport/entries/priviledges/drivers&permit-holders-assignments",
  },
  {
    ID: "5_2_1_7_5",
    categoryId: "5_2_1_7",
    name: "Workshop Attendant Assignments",
    route:
      "/assets-manager/transport/entries/priviledges/workshop-attendant-assignments",
    serverId: 370,
  },
  {
    ID: "5_2_1_7_6",
    categoryId: "5_2_1_7",
    name: "Vehicle Request priviledges",
    route:
      "/assets-manager/transport/entries/priviledges/vehicle-request-priveledges",
  },

  // ASSETS TRANSPORT SETUPS
  {
    ID: "5_2_2",
    categoryId: "5_2",
    name: "Setups",
  },
  //---vehicles---
  {
    ID: "5_2_2_1",
    categoryId: "5_2_2",
    name: "Vehicles",
  },
  {
    ID: "5_2_2_1_1",
    categoryId: "5_2_2_1",
    name: "Vehicle Classes",
    route: "/assets-manager/transport/setups/vehicles/vehicle-classes",
  },
  {
    ID: "5_2_2_1_2",
    categoryId: "5_2_2_1",
    name: "Vehicle Make",
    route: "/assets-manager/transport/setups/vehicles/vehicle-make",
    serverId: 267,
  },
  {
    ID: "5_2_2_1_3",
    categoryId: "5_2_2_1",
    name: "Vehicle Models",
    route: "/assets-manager/transport/setups/vehicles/vehicle-models",
    serverId: 268,
  },
  {
    ID: "5_2_2_1_4",
    categoryId: "5_2_2_1",
    name: "Manufacturers",
    route: "/assets-manager/transport/setups/vehicles/manufacturers",
    serverId: 266,
  },
  {
    ID: "5_2_2_1_5",
    categoryId: "5_2_2_1",
    name: "Suppliers",
    route: "/assets-manager/transport/setups/vehicles/suppliers",
    serverId: 265,
  },
  {
    ID: "5_2_2_1_6",
    categoryId: "5_2_2_1",
    name: "Engine Types",
    route: "/assets-manager/transport/setups/vehicles/engine-types",
    serverId: 261,
  },
  {
    ID: "5_2_2_1_7",
    categoryId: "5_2_2_1",
    name: "Power Trains",
    route: "/assets-manager/transport/setups/vehicles/power-trains",
    serverId: 259,
  },
  {
    ID: "5_2_2_1_8",
    categoryId: "5_2_2_1",
    name: "Transmission Types",
    route: "/assets-manager/transport/setups/vehicles/transmission-types",
    serverId: 281,
  },
  {
    ID: "5_2_2_1_9",
    categoryId: "5_2_2_1",
    name: "Vehicle Statuses",
    route: "/assets-manager/transport/setups/vehicles/vehicle-statuses",
  },
  {
    ID: "5_2_2_1_10",
    categoryId: "5_2_2_1",
    name: "Engine Makes",
    route: "/assets-manager/transport/setups/vehicles/engine-makes",
    serverId: 303,
  },

  {
    ID: "5_2_2_1_11",
    categoryId: "5_2_2_1",
    name: "Cubic Capacity",
    route: "/assets-manager/transport/setups/vehicles/cubic-capacity",
    serverId: 280,
  },
  {
    ID: "5_2_2_1_12",
    categoryId: "5_2_2_1",
    name: "Vehicle Accessories",
    route: "/assets-manager/transport/setups/vehicles/vehicle-accessories",
    serverId: 465,
  },

  //---general---
  {
    ID: "5_2_2_2",
    categoryId: "5_2_2",
    name: "General",
  },
  {
    ID: "5_2_2_2_1",
    categoryId: "5_2_2_2",
    name: "Document Types",
    route: "/assets-manager/transport/setups/general/document-types",
    serverId: 526,
  },
  {
    ID: "5_2_2_2_2",
    categoryId: "5_2_2_2",
    name: "Accessories",
    route: "/assets-manager/transport/setups/general/accessories",
    serverId: 465,
  },
  {
    ID: "5_2_2_2_3",
    categoryId: "5_2_2_2",
    name: "Accessory Values",
    route: "/assets-manager/transport/setups/general/accessory-values",
    serverId: 264,
  },
  {
    ID: "5_2_2_2_4",
    categoryId: "5_2_2_2",
    name: "Products",
    route: "/assets-manager/transport/setups/general/products",
    serverId: 277,
  },
  {
    ID: "5_2_2_2_5",
    categoryId: "5_2_2_2",
    name: "Product Prices",
    route: "/assets-manager/transport/setups/general/product-prices",
    serverId: 287,
  },
  {
    ID: "5_2_2_2_6",
    categoryId: "5_2_2_2",
    name: "Transport Units",
    route: "/assets-manager/transport/setups/general/transport-units",
    serverId: 361,
  },
  {
    ID: "5_2_2_2_7",
    categoryId: "5_2_2_2",
    name: "Insurance Companies",
    route: "/assets-manager/transport/setups/general/insurance-companies",
    serverId: 362,
  },

  {
    ID: "5_2_2_2_9",
    categoryId: "5_2_2_2",
    name: "Vehicle Request Areas",
    route: "/assets-manager/transport/setups/general/vehicle-request-areas",
    serverId: 371,
  },
  {
    ID: "5_2_2_2_10",
    categoryId: "5_2_2_2",
    name: "Vehicle Request Categories",
    route:
      "/assets-manager/transport/setups/general/vehicle-request-categories",
    serverId: 463,
  },
  {
    ID: "5_2_2_2_11",
    categoryId: "5_2_2_2",
    name: "Drivers/Permit Holders Categories",
    route:
      "/assets-manager/transport/setups/general/Drivers-permit-holders-categories",
    serverId: 382,
  },
  {
    ID: "5_2_2_2_13",
    categoryId: "5_2_2_2",
    name: "Drivers/Workshop Details",
    route: "/assets-manager/transport/setups/general/workshop-details",
    serverId: 299,
  },
  {
    ID: "5_2_2_2_16",
    categoryId: "5_2_2_2",
    name: "Bulk Product Prices",
    route: "/assets-manager/transport/setups/general/bulk-product-prices",
    serverId: 457,
  },
  {
    ID: "5_2_2_2_17",
    categoryId: "5_2_2_2",
    name: "Event Types",
    route: "/assets-manager/transport/setups/general/event-types",
    serverId: 457,
  },

  //---fuel---
  {
    ID: "5_2_2_3",
    categoryId: "5_2_2",
    name: "Fuel",
  },

  {
    ID: "5_2_2_3_1",
    categoryId: "5_2_2_3",
    name: "Fuel Types",
    route: "/assets-manager/transport/setups/fuel/fuel-types",
    serverId: 262,
  },
  {
    ID: "5_2_2_3_2",
    categoryId: "5_2_2_3",
    name: "Fuel Levels",
    route: "/assets-manager/transport/setups/fuel/fuel-levels",
    serverId: 270,
  },
  {
    ID: "5_2_2_3_3",
    categoryId: "5_2_2_3",
    name: "Fuel Units",
    route: "/assets-manager/transport/setups/fuel/fuel-units",
    serverId: 269,
  },
  {
    ID: "5_2_2_3_4",
    categoryId: "5_2_2_3",
    name: "Fuel Purchase Modes",
    route: "/assets-manager/transport/setups/fuel/fuel-purchase-modes",
  },
  {
    ID: "5_2_2_4",
    categoryId: "5_2_2",
    name: "Maintenance",
  },
  {
    ID: "5_2_2_4_1",
    categoryId: "5_2_2_4",
    name: "Service Stations",
    route: "/assets-manager/transport/setups/maintenance/service-stations",
    serverId: 276,
  },
  {
    ID: "5_2_2_4_2",
    categoryId: "5_2_2_4",
    name: "Service Providers",
    route: "/assets-manager/transport/setups/maintenance/service-providers",
    serverId: 274,
  },
  {
    ID: "5_2_2_4_3",
    categoryId: "5_2_2_4",
    name: "Service Items",
    route: "/assets-manager/transport/setups/maintenance/service-items",
    serverId: 329,
  },
  {
    ID: "5_2_2_4_4",
    categoryId: "5_2_2_4",
    name: "Maintenance Types",
    route: "/assets-manager/transport/setups/maintenance/maintenance-types",
    serverId: 288,
  },
  {
    ID: "5_2_2_4_5",
    categoryId: "5_2_2_4",
    name: "Maintenance Items/Checklist",
    route:
      "/assets-manager/transport/setups/maintenance/maintenance-items&checklist",
    serverId: 341,
  },
  {
    ID: "5_2_2_4_6",
    categoryId: "5_2_2_4",
    name: "Maintenance Locations",
    route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    serverId: 283,
  },
  // ASSETS TRANSPORT REPORTS
  {
    ID: "5_2_3",
    categoryId: "5_2",
    name: "Reports",
  },

  //POWER APPS
  {
    ID: "6",
    name: "Power Apps",
    icon: power,
    isExpanded: true,
  },
  {
    ID: "6_1",
    categoryId: "6",
    name: "Elog Book",
    // route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    // serverId: 283,
  },
  {
    ID: "6_1_1",
    categoryId: "6_1",
    name: "Entries",
    // route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    // serverId: 283,
  },
  {
    ID: "6_1_2",
    categoryId: "6_1",
    name: "Setups",
    // route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    // serverId: 283,
  },
  {
    ID: "6_2",
    categoryId: "6",
    name: "Station Readings",
    route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    serverId: 283,
  },
  {
    ID: "6_2_1",
    categoryId: "6_2",
    name: "Entries",
    
  },
  {
    ID: "6_2_2",
    categoryId: "6_2",
    name: "Setups",
    route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
    serverId: 283,
  },
  {
    ID: "6_2_2_1",
    categoryId: "6_2_2",
    name: "Meter",
    route: "/power-apps/station-readings/setups/meter-details",
    // serverId: 283,
  },
  {
    ID: "6_2_2_2",
    categoryId: "6_2_2",
    name: "Capacitor Bank",
    route: "/power-apps/station-readings/setups/capacitor-bank",
    // serverId: 283,
  },
  // {
  //   ID: "6_2_2",
  //   categoryId: "6_2",
  //   name: "Setups",
  //   route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
  //   serverId: 283,
  // },
  // {
  //   ID: "6_2_2",
  //   categoryId: "6_2",
  //   name: "Setups",
  //   route: "/assets-manager/transport/setups/maintenance/maintenance-locations",
  //   serverId: 283,
  // },

  //PERFORMANCE MANAGER

  {
    ID: "7",
    name: "Performance Manager",
    icon: performance,
    isExpanded: false,
  },

  //BUDGET MANAGEMENT
  {
    ID: "8",
    name: "Budget Manager",
    icon: budget,
    isExpanded: false,
  },

  //SAFETY MANAGER
  {
    ID: "9",
    name: "Safety Manager",
    icon: safety,
    isExpanded: false,
  },

  //LEGAL MANAGER
  {
    ID: "10",
    name: "Legal Manager",
    icon: legal,
    isExpanded: false,
  },

  //FINANCIALS
  {
    ID: "11",
    name: "Finance Manager",
    icon: financials,
    isExpanded: false,
  },

  //PAYROLL
  {
    ID: "12",
    name: "Payroll Manager",
    icon: payroll,
    isExpanded: false,
  },

  //SOFTWARE DEV
  {
    ID: "13",
    name: "Software Development Manager",
    icon: software,
    isExpanded: false,
  },

  //HELP DESK
  {
    ID: "14",
    name: "Help Desk",
    icon: help,
    isExpanded: false,
  },

  //PROJECTS AND CONTRACTS MANAGER
  {
    ID: "15",
    name: "Project Manager",
    icon: projects,
    isExpanded: false,
  },

  //SYSTEMS ADMINISTRATION
  {
    ID: "16",
    name: "System Administration",
    icon: administrator,
  },
  {
    ID: "16_1",
    categoryId: "16",
    name: "User Administration",
    route: "/system-administration/user-administration",
  },
  {
    ID: "16_2",
    categoryId: "16",
    name: "Reset Password",
    route: "/system-administration/reset-password",
  },
  {
    ID: "16_3",
    categoryId: "16",
    name: "Access Priveleges Assignments",
    route: "/system-administration/access-privileges-assignments",
    serverId: 208,
  },

  {
    ID: "16_5",
    categoryId: "16",
    name: "Audit trail",
    route: "/system-administration/audit-trail",
    serverId: 407,
  },
  {
    ID: "16_6",
    categoryId: "16",
    name: "Instant Messages",
    route: "/system-administration/sms-logs",
  },

  {
    ID: "16_10",
    categoryId: "16",
    name: "Application Forms",
    route: "/system-administration/application-form",
  },
  {
    ID: "16_11",
    categoryId: "16",
    name: "Application Modules",
    route: "/system-administration/application-modules",
  },
  {
    ID: "20",
    categoryId: "16",
    name: "Settings",
  },

  {
    ID: "20_1",
    categoryId: "20",
    name: "System Settings",
    route: "/system-administration/sysSettings",
  },
  {
    ID: "20_2",
    categoryId: "20",
    name: "General Settings",
    route: "/system-administration/genSettings",
  },
  {
    ID: "20_3",
    categoryId: "20",
    name: "TMS Settings",
    route: "/system-administration/TmsSettings",
  },
  {
    ID: "20_4",
    categoryId: "20",
    name: "SMS Settings",
    route: "/system-administration/SmsSettings",
  },
  {
    ID: "20_5",
    categoryId: "20",
    name: "CoreHR Settings",
    route: "/system-administration/CoreHRSettings",
  },
  {
    ID: "20_6",
    categoryId: "20",
    name: "SMS Alert Intervals Settings",
    route: "/system-administration/SMSIntSettings",
  },
  {
    ID: "20_7",
    categoryId: "20",
    name: "Maint. Schedules Freq. Settings",
    route: "/system-administration/MaintSettings",
  },

  //HELP AND OTHERS
  {
    ID: "17",
    name: "Help Center",
    icon: help,
    isExpanded: false,
  },
];

export default Drawerdata;

export const Drawericons = [
  // EXECUTIVE DASHBOARD
  {
    ID: "1",
    name: "",
    icon: executive,
    isExpanded: false,
  },

  //## GENERAL SERVICES
  {
    ID: "2",
    name: "",
    icon: general,
    isExpanded: false,
  },
  // HUMAN RESOURCE
  {
    ID: "3",
    name: "",
    icon: human,
  },

  //DOCUMENTS MANAGER
  {
    ID: "4",
    name: "",
    icon: docs,
    isExpanded: false,
  },

  //ASSETS MANAGER
  {
    ID: "5",
    name: "",
    icon: assets,
  },

  //POWER APPS
  {
    ID: "6",
    name: "",
    icon: power,
    isExpanded: false,
  },

  //PERFORMANCE MANAGER
  {
    ID: "7",
    name: "",
    icon: performance,
    isExpanded: false,
  },

  //BUDGET MANAGEMENT
  {
    ID: "8",
    name: "",
    icon: budget,
    isExpanded: false,
  },

  //SAFETY MANAGER
  {
    ID: "9",
    name: "",
    icon: safety,
    isExpanded: false,
  },

  //LEGAL MANAGER
  {
    ID: "10",
    name: "",
    icon: legal,
    isExpanded: false,
  },

  //FINANCIALS
  {
    ID: "11",
    name: "",
    icon: financials,
    isExpanded: false,
  },

  //PAYROLL
  {
    ID: "12",
    name: "",
    icon: payroll,
    isExpanded: false,
  },

  //SOFTWARE DEV
  {
    ID: "13",
    name: "",
    icon: software,
    isExpanded: false,
  },

  //HELP DESK
  {
    ID: "14",
    name: "",
    icon: help,
    isExpanded: false,
  },

  //PROJECTS AND CONTRACTS MANAGER
  {
    ID: "15",
    name: "",
    icon: projects,
    isExpanded: false,
  },

  //SYSTEMS ADMINISTRATION
  {
    ID: "16",
    name: "",
    icon: administrator,
  },

  //HELP AND OTHERS
  {
    ID: "17",
    name: "",
    icon: help,
    isExpanded: false,
  },
];
