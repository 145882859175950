import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";

import { Tooltip } from "devextreme-react";
import edit from "../../../assets/edit.png";
import alter from "../../../assets/alter.png";
import question from "../../../assets/question.png";
import statusLoader from "../../../assets/statusloader.gif";
import {
  AlertSMS,
  AppraiseSettings,
  DeleteTask,
  TaskSettings,
} from "./widgets/customToolbarModals";

import { DatePicker, Input, Select } from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import dayjs from "dayjs";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import close from "../../../assets/close.png";
import newfile from "../../../assets/new.png";
import printer from "../../../assets/printer.png";
import refresh from "../../../assets/refresh.png";
import {
  task_footer_update,
  task_form_disable,
} from "../../../features/Task&Todo/Task/TaskDetails";
import {
  setGeneralValue,
  settask_footer_update,
  settask_form_disable,
} from "../../../features/generalSlice";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import { saveModalData } from "../component_infos";

interface props {
  startDate?: string;
  endDate?: string;
  searchTextOnchange?: (value: string) => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: () => void;
  handleRefresh?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  setSearchParams?: string | any | undefined;
  setSearchParamspathname?: string | any | undefined;
  opened?: () => void;
  updateStates?: (key: string, value: any) => void;
  toggle?: () => void;
  change?: (e: boolean) => void;
  selectedRecord?:any
}

export const ActivityCap_toolbar = ({
  startDate,
  opened,
  endDate,
  checkBoxAfterHelp,
  searchCriteria,
  searchTextOnchange,
  startDateChanged,
  endDateChanged,
  checkOnChange,
  handleFind,
  handleRefresh,
  searchCriteriaOnchange,
  checkBoxAfterHelpOnchange,
  assignmentsForm,
  searchCriteriaData,
  setSearchParams,
  setSearchParamspathname,
  updateStates,
  toggle,
  change,
  selectedRecord
}: props) => {
  const { Search } = Input;
  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchTextValue, setSearchTextValue] = useState("");
  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });
  const form_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const update_state_change = useSelector(
    (state: RootState) => state.general.taskFooterUpdate
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [SettingsisActive, SettingssetIsActive] = useState<boolean>(false);
  const [AlertSMSisActive, AlertSMSsetIsActive] = useState<boolean>(false);
  const [AppraiseisActive, AppraisesetIsActive] = useState<boolean>(false);
  const size: number = 16;

  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    // checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  const dispatch = useDispatch();

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  // const selectedRecord = useSelector(
  //   (state: RootState) => state.safetyManagerDetails.selectedRecord
  // );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const handleNew = () => {
    updateStates && updateStates("selectedRecord", "");
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

    dispatch(task_form_disable(false));
    dispatch(settask_form_disable(false));

    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    change && change(true);
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));

    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };
  console.log("selectedRecordzxzxz", selectedRecord);
  const handleUpdate = () => {
    if (selectedRecord === undefined) {
      dispatch(task_form_disable(true));
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setIcon("warning");
      setModalData({
        message: "Please select a record to edit.",
        title: "No record selected!",
      });
      setOpenModal(true);
    } else {
      dispatch(task_form_disable(false));

      dispatch(task_footer_update(true));
    }
  };

  const newAccessPriv = () => {
    if (savePriv === true) {
      handleNew();
    } else {
      setIcon("warning");
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const editAccessPriv = () => {
    if (updatePriv === true) {
      handleUpdate();
      change && change(true);
    } else {
      setIcon("warning");
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const findAccessPriv = () => {
    if (readPriv === true) {
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  const printAccessPriv = () => {
    if (readPriv === true) {
    } else {
      setIcon("warning");
      setModalData({ message: "No Access privilege", title: "Access Denied!" });
      setOpenModal(true);
    }
  };

  return (
    <>
      <ModalTemplate
        icon_width={modalData.iconSize === "large" ? 100 : 50}
        icon={icon}
        cancelText={modalData.cancelText}
        disableCancel={icon === "warning" ? true : false}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        open={openModal}
        okHandler={() => {
          setOpenModal(false);
        }}
        message={modalData.message}
        okText={modalData.okText}
        title={modalData.title}
      />

      <div
        style={{ borderWidth: "1px", height: 32, borderColor: borderTheme }}
        className="px-0.5 mt-0.5 rounded  w-full h-full flex  border-gray-200 mb-1"
      >
        <div className="flex justify-center">
          <ul className="flex justify-evenly w-full items-center h-full py-0.5">
            {/* new and cancel section */}
            {disablex || updateMode ? (
              <li
                id="newRecord"
                onClick={() => newAccessPriv()}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "69px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={newfile} />
                  New{" "}
                </span>
                <Tooltip
                  target="#newRecord"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">New Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
            {!updateMode ? (
              <li
                id="editRecordst"
                onClick={() => {
                  editAccessPriv();
                }}
                style={{
                  borderWidth: "1px",
                  borderColor: borderTheme,
                  width: "58px",
                }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    alt=""
                    className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                    src={edit}
                  />
                  Edit{" "}
                </span>
                <Tooltip
                  target="#editRecordst"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Edit Record</p>
                </Tooltip>
              </li>
            ) : (
              <li
                id="close-cancel"
                onClick={() => handleCancel()}
                style={{ borderWidth: "1px", borderColor: borderTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img alt="" className="pr-1 " src={close} />
                  Cancel{" "}
                </span>
                <Tooltip
                  target="#close-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Cancel</p>
                </Tooltip>
              </li>
            )}
            <li
              id="switch"
              onClick={toggle}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  alt=""
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch{" "}
              </span>
              <Tooltip
                target="#switch"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>
            <li
              id="help"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
            >
              <span className="flex">
                {" "}
                <img
                  className="dark:bg-slate-900  dark:text-gray-100 duration-100"
                  src={question}
                />{" "}
              </span>
              <Tooltip
                target="#help"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Help Center</p>
              </Tooltip>
            </li>

            {/* Differents starts here */}

            <li className="px-1 flex items-center ml-4 ">
              <label className="flex text-xs  px-2">Search Criteria</label>
              <Select
                value={criteriaDataLocal}
                dropdownMatchSelectWidth={false}
                className=" mt-0.5"
                onChange={(data: any) => {
                  const criteriaData = JSON.parse(data);
                  // console.log('@#',criteriaData);
                  setCriterialDataLocal({
                    id: criteriaData?.index,
                    value: criteriaData?.criteria,
                  }); //local
                  searchCriteriaOnchange &&
                    searchCriteriaOnchange(
                      criteriaData?.index,
                      criteriaData?.criteria
                    ); //external
                }}
                id="search"
                size="small"
                showSearch={true}
                style={{ width: 110, height: "25px" }}
              >
                {searchCriteriaData?.map(({ id, value }, index) => (
                  <Select.Option
                    key={id}
                    value={JSON.stringify({ index: index, criteria: value })}
                  >
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </li>

            <li className="px-1 flex items-center ml-4 ">
              <label className="flex text-xs px-2">Search Text</label>
              <Input
                onChange={(e: any) => {
                  const searchText: string = e.target.value;
                  searchTextOnchange && searchTextOnchange(searchText);
                  setSearchTextValue(e.target.value);
                }}
                value={searchTextValue}
                name="search"
                id="search"
                size="small"
                allowClear={true}
                style={{ width: 130, height: "24px" }}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                value={dayjs(startDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={startDateChanged}
                size="small"
                placeholder="Start Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li className="px-0.5">
              <DatePicker
                value={dayjs(endDate) ?? dayjs()}
                clearIcon={<span style={{ display: "none" }}></span>}
                onChange={(date) => {
                  endDateChanged && endDateChanged(date);
                }}
                size="small"
                placeholder="End Date"
                style={{ width: 125 }}
                format={"DD MMM YYYY"}
              />
            </li>
            <li
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              id="status"
              className="hover:cursor-pointer mx-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
            >
              <Checkbox
                checked={checkedBox}
                indeterminate={indeterminate}
                onChange={(checkValue: any) => {
                  // console.log("checkValue", checkValue.target.checked);
                  onChange(checkValue);
                  checkOnChange && checkOnChange(checkValue.target.checked);
                }}
              />
              <Tooltip
                target="#status"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Approval status of request</p>
              </Tooltip>
            </li>

            <li
              id="refresh"
              onClick={() => {
                handleRefresh && handleRefresh();
                setSearchTextValue("");
                setCheckedBox(false);
                setIndeterminate(true);
                setCheckValue("");
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  src={refresh}
                  className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
                />{" "}
              </span>
              <Tooltip
                target="#refresh"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Refresh</p>
              </Tooltip>
            </li>
            <li
              id="find"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              onClick={findAccessPriv}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <FaBinoculars size={15.5} color="#007AFF" />{" "}
              </span>
              <Tooltip
                target="#find"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Find</p>
              </Tooltip>
            </li>
            {/* <li
              id="print"
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              onClick={printAccessPriv}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            >
              <span>
                {" "}
                <img
                  className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                  src={printer}
                />{" "}
              </span>
              <Tooltip
                target="#print"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p>Print / Export</p>
              </Tooltip>
            </li> */}

            <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
              {disablex && (
                <p className=" italic font-semibold text-gray-600">Done.</p>
              )}

              {!disablex && (
                <div className="  flex flex-row items-center">
                  <img
                    style={{ width: "31px", height: "31px" }}
                    src={statusLoader}
                    alt="gif"
                  />
                  {!update_state_change ? (
                    <p className=" italic text-gray-600">New Record</p>
                  ) : (
                    <p className=" italic text-gray-600">Editing</p>
                  )}
                </div>
              )}
            </li>
          </ul>
        </div>

        <div className="">
          <ul className=" "></ul>

          <DeleteTask isActive={isActive} setIsActive={setIsActive} />
          <TaskSettings
            SettingsisActive={SettingsisActive}
            SettingssetIsActive={SettingssetIsActive}
          />
          <AlertSMS
            AlertSMSisActive={AlertSMSisActive}
            AlertSMSsetIsActive={AlertSMSsetIsActive}
          />
          <AppraiseSettings
            AppraiseisActive={AppraiseisActive}
            AppraisesetIsActive={AppraisesetIsActive}
          />
        </div>
      </div>
    </>
  );
};
