import { useState } from "react";

import useFetch from "../../../../../../../hooks/useFetch";
import VehicleAssignmentDetails from "./vehicle-assignments-details";

import { VehicleAssignmentsToolbar } from "./vehicle-assignments-toolbar";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import CustomLoader from "../../../../../../accessories/custom-loader";

import {
  currentDate,
  startingDate,
} from "../../../../../../accessories/component_infos";
import dayjs from "dayjs";

const VehicleAssignments = () => {
  const [showTable, setShowTable] = useState(true);

  // switch button toggler
  const switch_toggle = () => {
    setShowTable(!showTable);
  };

  const [th, setTH] = useState(true);

  const [refresh, setRefresh] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(startingDate).format("YYYY-MM-DD"),
    index: dayjs(startingDate).format("YYYY-MM-DD"),
  });
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    index: dayjs(currentDate).format("YYYY-MM-DD"),
  });

  const [searchText, setSearchText] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });
  const [activeStatus, setActiveStatus] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({
    temp: "",
    status: "",
  });
  const [deleteStatus, setDeleteStatus] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({
    temp: "",
    status: "",
  });
  let [searchCriteria, setsearhCriteria] = useState<{
    temp: number;
    index: number;
  }>({
    temp: 0,
    index: 0,
  });

  const [allStates, setAllState] = useState<any>({
    startDate: currentDate,
    endDate: currentDate,
    department: { name: "", id: 0 },
    section: { name: "", id: 0},
    unit: { name: "", id: 0},
    transportUnit: { name: "", id: 0},
    location: { name: "", id: 0},
    approvingOfficer: { name: "", id: 0},
    driver: { name: "", id: 0},
    vehicleController: { name: "", id: 0},
    administrator: { name: "", id: 0},
    manager: { name: "", id: 0},
    director: { name: "", id: 0},
    defaultWorkshop: { name: "", id: 0},
    footerId: { id: 0},
    remarks: "",
    active: false,
    refresh: false,
    isOpenDropdown1 : false,
    isOpenDropdown2 : false,
    isOpenDropdown3 : false,
    isOpenDropdown4 : false,
    isOpenDropdown5 : false,
    searchSection:"",
    searchUnit:"",
    searchTransportUnit:"",
    searchLocation:"",
    searchDepartment:"",
    paging : {pageSize: 15, pageNumber: 1 }
  });
  const [addedSelected, setAddSelected] = useState<any>([]);
  const [updateId, setUpdateId] = useState("");

  const searchCriteriaData = [
    { id: 0, value: "Registration No" },
    { id: 1, value: "Chassis No" },
    { id: 2, value: "Vehicle Type" },
    { id: 3, value: "Vehicle Make" },
    { id: 4, value: "Vehicle Model" },
    { id: 5, value: "Manufacturer" },
    { id: 6, value: "Supplier" },
    { id: 7, value: "Manufacture Date" },
    { id: 8, value: "Supply Date" },
  ];
  const [showDataOnSecondGrid, setShowDataOnSecondGrid] = useState({});
  const [selectedData, setSelectedData] = useState({});
  console.log("outerSelected from prarent module:", selectedData);
  const [footerGrid_data, footerGrid_error, footerGrid_loading] = useFetch(
    `AssetTamVehicleAssignment/SearchAllVehicleAssignment?dtpStartDate=${startDate.index}&dtpEndDate=${endDate.index}&criteria=${searchCriteria.index}&deleteStatus=${deleteStatus.status}&chkActive=${activeStatus.status}&cmbSearchx=${searchText.text}&PageNumber=${1}&PageSize=${allStates.paging.pageSize}`,
    refresh
  );
  const handleResetDataFetch = () => {
    if(refresh === true){
      setRefresh(false);
    }else{
      setRefresh(true);
    }
  }
  console.log('searchText:', searchText);
  return (
    <>
      {footerGrid_error && <WarningAlert />}
      {footerGrid_loading && <CustomLoader text="Fetching Data" />}
      <div className="mx-2">
        <VehicleAssignmentsToolbar
          switchState={setTH}
          toggler={() => {
            setTH(!th);
          }}
          startDateChanged={(date) => {
            setStartDate({ temp: date, index: startDate.index});
          }}
          endDateChanged={(date) => {
            setEndDate({ temp: date, index: endDate.index });
          }}
          searchTextOnchange={(value) => {
            setSearchText((prev)=>({...prev, temp: value, text:"" }));
          }}
          checkOnChange={(val) => {
            setActiveStatus({
              temp: val,
              status: activeStatus.status,
            });
          }}
          deleteCheckOnchange={(val) => {
            setDeleteStatus({
              temp: val,
              status: deleteStatus.status,
            });
          }}
          handleFind={() => {
            setSearchText({ temp: searchText.temp, text: searchText.temp }); //search text
            setActiveStatus({
              temp: activeStatus?.temp,
              status: activeStatus?.temp,
            }); //active status
            setDeleteStatus({
              temp: deleteStatus?.temp,
              status: deleteStatus?.temp,
            });
            setsearhCriteria({
              temp: searchCriteria.temp,
              index: searchCriteria.temp,
            });
            setEndDate({ temp: endDate.temp, index: endDate.temp });
            setStartDate({ temp: startDate.temp, index: startDate.temp });
          }}
          searchCriteriaOnchange={(newIndex) => {
            setsearhCriteria({ temp: newIndex, index: searchCriteria.index });
          }}
          withDates={false}
          startDate={startDate.temp}
          endDate={endDate.temp}
          searchCriteria
          searchCriteriaData={searchCriteriaData}
          handleRefresh={() => {
            // setRefresh(!refresh);
            // setSearchText({ temp: " ", text: "" }); //search text
            setSearchText((prev)=>({...prev, temp:"", text:""})); //search text
            setActiveStatus({
              temp: "",
              status: "",
            }); //active status
            setDeleteStatus({
              temp: "",
              status: "",
            });
            setsearhCriteria({
              temp: 0,
              index: 0,
            });
            setEndDate({
              temp: dayjs(currentDate).format("YYYY-MM-DD"),
              index: dayjs(currentDate).format("YYYY-MM-DD"),
            });
            setStartDate({
              temp: dayjs(startingDate).format("YYYY-MM-DD"),
              index: dayjs(startingDate).format("YYYY-MM-DD"),
            });
            handleResetDataFetch();
          }}
          allStates={allStates}
          setAllState={setAllState}
          addedSelected={addedSelected}
          setAddSelected={setAddSelected}
          setUpdateId={setUpdateId}
          outerSelected={selectedData}
          setOuterSelected={setSelectedData}
          setShowDataOnSecondGrid={setShowDataOnSecondGrid}
          setVisible={() => {
            setTH(true);
          }}
        />
        {/* <Staff_customtoolbar /> */}
      </div>

      <div className="px-2 mb-0.5  ">
        <VehicleAssignmentDetails
          footerGrid_data={footerGrid_data}
          th={th}
          runRefresh={() => {
            setRefresh(!refresh);
          }}
          allStates={allStates}
          setAddSelected_drop={setAddSelected}
          addedSelected_drop={addedSelected}
          setAllState={setAllState}
          setUpdateId={setUpdateId}
          setOuterSelected={setSelectedData}
          setShowDataOnSecondGrid={setShowDataOnSecondGrid}
          showDataOnSecondGrid={showDataOnSecondGrid}
          outerSelected={selectedData}
          activeStatus={activeStatus.temp}
          // handleActiveVehi={() => {
          //   setActiveStatus({
          //     temp: activeStatus.temp,
          //     status: activeStatus.temp,
          //   });
          // }}
          setVisible={() => {
            setTH(true);
          }}
        />
      </div>
    </>
  );
};

export default VehicleAssignments;
