import { Select, Input, Form } from "antd";
import {
  Column,
  DataGrid,
  Editing,
  GroupPanel,
  Selection,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect, useRef, useState } from "react";

import search from "../../../../../../../assets/search.png";
import plus_add from "../../../../../../../assets/plus_add.png";
import {
  navbar_height,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicleAssignmentFooter_TH,
  DataGridOne,
  vehicle_assignment_TH,
} from "../data/vehicle-assignments-data";
import useFetch from "../../../../../../../hooks/useFetch";
// import search from "antd/es/transfer/search";
import VehicleAssignmentDropDowns from "./vehicle_assignment_dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import  Datagrid_template from "../../../../../../../templates/Datagrid";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { InputsTemplate } from "../../../../../../../templates/input";
import { FaBinoculars } from "react-icons/fa";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { backgroundColor } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";
import { log } from "console";

import {
  setDisableForm,
  setEdit_Cancel_Button,
  setNew_Cancel_Button,
  setSave_Update_Button,
} from "../../../../../../../features/VehicleRedux/TMS-Sicers/Vehicles/VehicleAssignmentSlice";
import dayjs from "dayjs";
import { MathSum } from "docx";
import { UpdateStates } from "../../../../../../../functions/updateStates";
import { set } from "lodash";
import {
  CustomizableButton,
  SearchButton,
} from "../../../../../../accessories/buttons";
import dxDataGrid from "devextreme/ui/data_grid";

const { Option } = Select;
interface props {
  th?: any;
  footerGrid_data?: any;
  footerStatusRefresh?:boolean;
  runRefresh?: any;
  allStates?: any;
  setAllState?: (e: any) => void;
  addedSelected_drop?: any;
  setAddSelected_drop?: (e: any) => void;
  update?: any;
  setUpdateId?: (e: any) => void;
  setOuterSelected?: (e: any) => void;
  setShowDataOnSecondGrid?:(e:any)=>void;
  showDataOnSecondGrid?:any;
  outerSelected?: any;
  activeStatus?: string | boolean;
  setVisible?: () => void;
}

const VehicleAssignmentsDetails = ({
  th,
  footerGrid_data,
  footerStatusRefresh,
  runRefresh,
  allStates,
  setAllState,
  addedSelected_drop,
  setAddSelected_drop,
  setOuterSelected,
  setShowDataOnSecondGrid,
  showDataOnSecondGrid,
  outerSelected,
  activeStatus,
  setVisible,
}: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const heights_out = navbar_height + news_flash_height + toolbar_height;
  const formdisabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );

  //--------------------eagleMantality🦅--------------------------------------
  let DisableForm = useSelector(
    (state: RootState) => state.TMSModule1.VehicleAssignment.disableForm
  );
  let Edit_Cancel_Button = useSelector(
    (state: RootState) => state.TMSModule1.VehicleAssignment.Edit_Cancel_Button
  );
  const Vdispatch = useDispatch();
  // ---------------------------------------------------------------------------

  let [resizeMode, initialResizeMode] = useResizeMode(th);

  //varying form height

  //Table Height
  const pageHeight = resizeMode - (heights_out - 420);

  const fullPage = initialResizeMode - (heights_out + 578);

  const [refresh, setRefresh] = useState<boolean>(true);
  //const[vehicleAssignment_data,vehicleAssignment_error,vehicleAssignment_loading] = useFetch(`api/AssetTamVehicleAssignment/GetAllVehicleAssignment`,refresh);
  const [department_data, department_error, department_loading] = useFetch(
    `AssetTamVehicleAssignment/GetDepartment`
  );

  const [deptName, setDeptName] = useState<any>();
  // const [employeeId, userId, userObject] = useAccountInfo()
  const [section_data, section_error, section_loading] = useFetch(
    `AssetTamVehicleAssignment/GetAllSection?dptName=${deptName}&dptActive=true`
  );
  const [searchText, setSearchText] = useState("");
  let [searchCriteria, setSearchCriteria] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: "Unassigned",
  });

  // -------------  End point for first Datagrid -----------------------
  // const [dataGridOneUrl, setDataGridOneUrl] = useState(
  //   "AssetTamVehicleAssignment/GetAllVehiclesDetailsAssetTamVehicleAssignment"
  // );
  let [criteriaIndex, setCriteriaIndex] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });
  let [cmbSearch, setCmbSearch] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });

  // let [activeState, setActiveState] = useState<{temp: string|boolean, checked: string|boolean}>({
  //   temp: activeStatus,
  //   checked:''
  // })
 let [activeState, setActiveState] = useState<boolean|string>('');
  let [firstDatagridRefresh, setFirstDatagridRefresh] = useState(false);

  const [dataGridOne_data, dataGridOne_error, dataGridOne_loading] = useFetch(
    `AssetTamVehicleAssignment/SearchVehicleDetails?criteriaIndex=${criteriaIndex.index}&cmbSearch=${cmbSearch.text}&chkActive=${activeState}`,
    firstDatagridRefresh
  );

  const Get_AV_AAV_AU_NB = (refresh: boolean) => {
    const ALL_Assigned_Veh:any = useFetch(`AssetTamVehicleAssignment/GetStatAssigned`,refresh)
    // const ALL_UnAssigned_Veh: any = useFetch(
    //   `AssetTamVehicleAssignment/GetStatUnAssigned`,
    //   refresh
    // );
    const ALL_Boarded_Veh: any = useFetch(
      `AssetTamVehicleAssignment/GetStatBoarded`,
      refresh
    );
    const Total_No_Act_veh: any =  useFetch(
      `AssetTamVehicleAssignment/GetStatTotalVehicles`,
      refresh
    );
    const Total_No_InActiveStaff: any =  useFetch(
      `AssetTamVehicleAssignment/GetInActiveStaff`,
      refresh
    );

    const No_Of_ALL_Assigned_Veh = ALL_Assigned_Veh[0][0]?.vdtRegNo;
    // const No_Of_ALL_UnAssigned_Veh =  ALL_UnAssigned_Veh[0][0]?.vdtRegNo;
    const No_Of_ALL_Boarded = ALL_Boarded_Veh[0][0]?.vdtRegNo;
    const Total_Veh = Total_No_Act_veh[0][0]?.vdtRegNo;
    const No_Of_InActiveStaff = Total_No_InActiveStaff[0][0]?.vdtRegNo;
    const No_Of_ALL_UnAssigned_Veh =   Total_Veh - No_Of_ALL_Assigned_Veh;

    return [Total_Veh, No_Of_ALL_Assigned_Veh, No_Of_ALL_UnAssigned_Veh, No_Of_ALL_Boarded,No_Of_InActiveStaff];
  };

  const [Total_No_Act_veh, No_Assigned_Veh, No_UnAssigned_Veh, No_Boarded, InActiveStaff] = Get_AV_AAV_AU_NB(footerStatusRefresh);
  

  // console.log("active data of assigned vehicles :", No_Assigned_Veh[0].length)
  console.log("@#$ criteriaIndex: ", criteriaIndex);
  console.log("@#$ cmbSearch: ", cmbSearch);
  console.log("@#$ activeState: ", activeState);
  console.log("@#$ activeStatus: ", activeStatus);
  // console.log("First Datagrid :", dataGridOne_data);
  //-----------------------------------------------------------------------
  // INITIALIZE STATES
  const dataRef = useRef<any>();
  console.log(dataRef);
  const [updateId, setUpdateId] = useState("");

  //import use dispatch
  const dispatch = useDispatch();
  const datagrid2Ref = useRef<dxDataGrid>();

  //Selected for grid two

  const [updating, setUpdating] = useState(false);

  const [modalData, setModalData] = useState<any>(saveModalData);
  const [Icon, setIcon] = useState<"warning" | "question" | "success">(
    "warning"
  );
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [userId, employID, userObj] = useAccountInfo();

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );

  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const [container_For_DataGrid_2, setContainer_For_DataGrid_2] = useState<any>(
    []
  );

  console.log("selected Record from first Grid : ", selectedRecord);

  console.log("added Record from first Grid : ", container_For_DataGrid_2);

  const SelectOnchangehandler = (e: any) => {
    UpdateStates(
      "searchCriteria",
      { id: e["id"], name: e["name"] },
      setSearchCriteria
    );
    setCriteriaIndex({ temp: e?.id, index: criteriaIndex.index });
  };
  const SelectData: any = [
    { id: 0, name: "Unassigned" },
    { id: 1, name: "Assigned" },
    { id: 2, name: "Active" },
    { id: 3, name: "Boarded" },
  ];

  const gridOneRef = useRef<any>();
  //Reset fields on new button click

  //selected Row for footer data grid
  const selectVehDetails = (e: any) => {
    // dataRef.current?.fill(e);
    setAllState!({
      ...allStates,
      ["startDate"]: e?.vasStartDate,
      ["endDate"]: e?.vasEndDate,
      ["department"]: { name: e?.dptName, id: e?.dptIDpk },
      ["section"]: { name: e?.sxnName, id: e?.sxnIDpk },
      ["unit"]: { name: e?.untName, id: e?.untIDpk },
      ["transportUnit"]: { name: e?.truName, id: e?.truIDpk },
      ["location"]: { name: e?.locShtName, id: e?.locIDpk },
      ["approvingOfficer"]: {
        name: e?.ownerEmpName15,
        id: e?.ownerEmpIDfk,
      },
      ["driver"]: { name: e?.driverEmpName15, id: e?.driverEmpIDfk },
      ["vehicleController"]: {
        name: e?.controllerEmpName15,
        id: e?.controllerEmpIDfk,
      },
      ["administrator"]: { name: e?.adminEmpName15, id: e?.adminEmpIDfk },
      ["manager"]: { name: e?.mgrEmpName15, id: e?.mgrEmpIDfk },
      ["director"]: { name: e?.dirEmpName15, id: e?.dirEmpIDfk },
      ["defaultWorkshop"]: { name: e?.wsdName, id: e?.wsdIDpk },
      ["remarks"]: e?.vasRmks,
      ["active"]: e?.vasActive,
      ["footerId"]: { id: e?.vasIDpk },
    });
    console.log(e);
    setUpdateId(e?.vasIdpk);
    dispatch(task_form_disable(true));
    dispatch(task_footer_update(false));
    dispatch(
      setGeneralValue({ expr: "activeAssignment", value: e?.vdtActive })
    );
    dispatch(setGeneralValue({ expr: "selectedAssignment", value: e }));
    setOuterSelected && setOuterSelected(e);
    //------------- eagleMentality🦅---------------------
    Vdispatch(setEdit_Cancel_Button(true));
    Vdispatch(setDisableForm(true));
    Vdispatch(setSave_Update_Button(true));
    Vdispatch(setNew_Cancel_Button(true));
    //---------------------------------------------------

    const dataGridInstance = gridOneRef.current?.instance;
    //  dataGridInstance.selectRowsByIndexes([0]);

    const filteredEmployees = dataGridOne_data?.findIndex(
      (employee: any) => employee.vdtRegNo === e?.vdtRegNo
    );
    //  const selectedRowKeys = filteredEmployees.map((employee:any) => employee.ID);
    dataGridInstance?.selectRowsByIndexes([filteredEmployees]);
    console.log(filteredEmployees);
  };

  //Double click for footer data grid
  const doubleClick = (e: any) => {
    console.log("$#@ dblC", e)
    dataRef.current?.doubleClick(e);
    // dataRef.current?.fill(e);
    setAllState!({
      ...allStates,
      ["startDate"]: e?.vasStartDate,
      ["endDate"]: e?.vasEndDate,
      ["department"]: { name: e?.dptName, id: e?.dptIDpk },
      ["section"]: { name: e?.sxnName, id: e?.sxnIDpk },
      ["unit"]: { name: e?.untName, id: e?.untIDpk },
      ["transportUnit"]: { name: e?.truName, id: e?.truIDpk },
      ["location"]: { name: e?.locShtName, id: e?.locIDpk },
      ["approvingOfficer"]: {
        name: e?.ownerEmpName15,
        id: e?.ownerEmpIDfk,
      },
      ["driver"]: { name: e?.driverEmpName15, id: e?.driverEmpIDfk },
      ["vehicleController"]: {
        name: e?.controllerEmpName15,
        id: e?.controllerEmpIDfk,
      },
      ["administrator"]: { name: e?.adminEmpName15, id: e?.adminEmpIDfk },
      ["manager"]: { name: e?.mgrEmpName15, id: e?.mgrEmpIDfk },
      ["director"]: { name: e?.dirEmpName15, id: e?.dirEmpIDfk },
      ["defaultWorkshop"]: { name: e?.wsdName, id: e?.wsdIDpk },
      ["remarks"]: e?.vasRmks,
      ["active"]: e?.vasActive,
      ["footerId"]: { id: e?.vasIDpk },
    });
    setContainer_For_DataGrid_2([e]);
    setOuterSelected!(e);
    console.log("erjjj");
    console.log(e);
    setUpdating(true);
  };

  const AccessPriv_on_DataGridDoubleClick = (e: any) => {
    console.log("$#@ acc",e)
    setPrivType("update");
    setVisible!();
    if (updatePriv === true) {
      // dispatch(task_form_disable(false));
      // dispatch(task_footer_update(true));
      Vdispatch(setDisableForm(false));
      Vdispatch(setEdit_Cancel_Button(false));
      Vdispatch(setSave_Update_Button(false));
      Vdispatch(setNew_Cancel_Button(true));
      setOuterSelected && setOuterSelected(e)
      dataRef.current?.fill(e);
      //  this destroys the update mode state
      doubleClick(e);
    } else {
      setDisableButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "ok",
        cancelText: "No,Cancel",
      });
      setIcon("warning");
      setOpenModal(true);
    }
  };

  function Remove_Duplicates_From_DataGrid2(arr: any) {
    let remove_Dup = arr?.filter((item: any, index: any, self: any) => {
      return (
        self.findIndex(
          (otherItem: any) => otherItem.vdtRegNo === item.vdtRegNo
        ) === index
      );
    });
    return remove_Dup;
  }

  const [doubleClickedItem, setDoubleClickedItem] = useState({});
  const [isDoubleClicked, setIsDoubleClicked] = useState(false);

  
  const addSelectedRecord = () => {
    let records = Remove_Duplicates_From_DataGrid2([
      ...container_For_DataGrid_2,
      ...selectedRecord,
    ]);
    setContainer_For_DataGrid_2(records);
  };

  useEffect(()=>{
    setContainer_For_DataGrid_2(showDataOnSecondGrid)
  },[showDataOnSecondGrid])

  useEffect(() => {
    const Remove_Item_onDoubleClick = (record: any) => {
      let target = record;
      let remainingRecords = container_For_DataGrid_2.filter(
        (items: any) => items !== target
      );
      setContainer_For_DataGrid_2(remainingRecords);
    };
    Remove_Item_onDoubleClick(doubleClickedItem);
    return () => {
      setDoubleClickedItem({})
      setIsDoubleClicked(false);
    };
  }, [isDoubleClicked]);
 
  return (
    <>
      <ModalTemplate
        title={modalData.title}
        icon={Icon}
        okHandler={() => {
          disableButton === true ? setOpenModal(false) : setOpenModal(true);
        }}
        okText={modalData.okText}
        cancelHandler={() => {
          setOpenModal(false);
        }}
        cancelText={modalData.cancelText}
        disableCancel={disableButton}
        message={modalData.message}
        open={openModal}
      />
      <div>
        {th && (
          <div className="">
            <div
              style={{ borderColor: borderTheme}}
              className="flex justify-between border-[1px] rounded p-1 "
            >
              <div>
                <VehicleAssignmentDropDowns
                  ref={dataRef}
                  refresh={() => {
                    runRefresh();
                    setRefresh(!refresh);
                  }}
                  allStates={allStates}
                  setAllState={setAllState}
                  addedSelected={container_For_DataGrid_2}
                  setAddSelected={setContainer_For_DataGrid_2}
                  setOuterSelected={setOuterSelected}
                  setShowDataOnSecondGrid={setShowDataOnSecondGrid}
                  // outerSelected ={outerSelected}
                  setUpdateId={setUpdateId}
                />
              </div>
              <div style={{ width: `calc(100% - 550px)`, height: "60%" }}>
                <div
                  style={{ height: "" }}
                  className="flex w-full h-full bg-slate-50 dark:bg-darkModeSkin-divs_backgroundColor dark:text-darkModeSkin-base "
                >
                  {/* <h4 className=" justify-start  w-32 ml-4 mb-2 bg-slate-200 ">Tasks Update</h4> */}
                  <li className=" list-none justify-center items-center py-1">
                    {/* <Select placeholder="" size="small" /> */}
                    <Form size="small" className="flex">
                      <div className="flex flex-row space-x-1 w-full ">
                        <li className=" w-[150px]">
                          <SelectsTemplate
                            useCallFunc
                            span
                            options={SelectData}
                            selectStyle={{ width: "250px" }}
                            disabled={false}
                            idexpr="id"
                            dataexp="name"
                            selectedValue={SelectOnchangehandler}
                            placeHolder={searchCriteria?.name}
                          />
                        </li>

                        <li className=" w-[160px]">

                          <InputsTemplate
                            span
                            useCallbackFunc
                            placeholder="Search Text"
                            orderOnchange={(val) => {
                              setSearchText(val.toString());
                              setCmbSearch({
                                temp: val.toString(),
                                text: cmbSearch.text,
                              });
                            }}
                            inputStyle={{ width: "120px", height: "23px" }}
                            disabledStatus={false}
                          />
                        </li>
                        <li className="flex gap-3 w-[200px]">
                          <SearchButton
                            classname={""}
                            isText={false}
                            handleOnClick={() => {
                              setFirstDatagridRefresh(!firstDatagridRefresh);
                              setCmbSearch({
                                temp: cmbSearch.temp,
                                text: cmbSearch.temp,
                              });
                              setCriteriaIndex({
                                temp: criteriaIndex.temp,
                                index: criteriaIndex.temp,
                              });
                              // setActiveState({temp:activeStatus, checked:activeState.temp})
                              setActiveState(activeStatus)
                            }}
                          />
                          <CustomizableButton
                            classname={"px-2 py-1 border-2"}
                            customizableBtnImage={plus_add}
                            handleCustomizableClick={() => {
                              addSelectedRecord();
                            }}
                          />
                        </li>
                      </div>
                    </Form>
                  </li>

                  {/* <ul className=" mb-1 ml-1 flex items-center justify-start w-full bg-slate-100  self-end dark:bg-darkModeSkin-div_headers_backgroundColor dark:text-darkModeSkin-headers">
                    <li className="mr-3 justify-center items-center">
                      <button
                        onClick={() => {
                          setFirstDatagridRefresh(!firstDatagridRefresh);
                          setCmbSearch({
                            temp: cmbSearch.temp,
                            text: cmbSearch.temp,
                          });
                          setCriteriaIndex({
                            temp: criteriaIndex.temp,
                            index: criteriaIndex.temp,
                          });
                        }}
                        style={{ borderColor: borderTheme }}
                        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                      >
                        <FaBinoculars size={15.5} color="#007AFF" />
                      </button>
                    </li>
                    <li>
                      {" "}
                      <button
                        onClick={() => {
                          // addTask();
                          addSelectedTask();
                        }}
                        style={{ borderColor: borderTheme }}
                        className="dark:bg-slate-900 dark:text-darkModeSkin-base flex flex-row items-center justify-center  px-2 py-1 border-2 border-slate-200 rounded hover:border-blue-300"
                      >
                        <img src={plus_add} />
                      </button>
                    </li>
                  </ul> */}
                </div>

                {/* 1st Datagrid table */}
                {/* {!updateMode && ( */}
                <div
                  style={{ width: "100%", marginBottom: "5px" }}
                  className="w-full mr-11 dark:bg-slate-900 dark:text-darkModeSkin-base"
                >
                  <Datagrid_template
                    dataGridRef={gridOneRef}
                    // gridheight={DisableForm ? 401 : 260}
                    handlePageNumber={()=>{}}
                    handlePageSize={()=>{}}
                    gridheight={DisableForm ? 413 : 260}
                    columns={DataGridOne}
                    data={dataGridOne_data}
                    disablePaging={true}
                    disableGroupPanel={true}
                    disableSearch={true}
                    selectionMode="multiple"
                    deselectFirstRow={true}
                    selectedItemsChange={(obj: any) => {
                      setSelectedRecord(obj);
                    }}
                  />
                </div>

                {/* 2nd Datagrid table */}
                {/* {(updateMode || !disablex) && ( */}
                {(!DisableForm || !Edit_Cancel_Button) && (
                  <div
                    style={{ width: "100%", marginBottom: "5px" }}
                    className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base "
                  >
                    <Datagrid_template
                      // gridheight={138}
                      gridheight={148}
                      columns={DataGridOne}
                      data={container_For_DataGrid_2}
                      disablePaging={true}
                      deselectFirstRow={false}
                      disableGroupPanel={true}
                       disableSearch={true}
                      rowDoubleClicked={
                       (e)=>{
                        e &&  setIsDoubleClicked(true)
                        setDoubleClickedItem(e)
                      }
                      }
                      onDatagridInitialized={(e) => {
                        datagrid2Ref.current = e.component;
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className=" relative pt-0.5">
          <Datagrid_template
            gridheight={th ? fullPage + 45 : pageHeight - 450}
            columns={vehicleAssignmentFooter_TH}
            // columns={vehicle_assignment_TH}
            data={footerGrid_data}
            pageNumber={allStates?.paging?.pageNumber}
            pageSize={allStates?.paging?.pageSize}
            handlePageSize={(e:number)=> {UpdateStates("paging", {pageSize: e, pageNumber: 1}, setAllState)}}
            disablePaging={false}
            onRowClick={selectVehDetails}
            rowDoubleClicked={AccessPriv_on_DataGridDoubleClick}
            // handleRefresh={()=>{ runRefresh && runRefresh()}}
          />
          <div className="absolute z-[55%] flex flex-row gap-[55px] ml-[10%] -mt-[2.8%]">
            <div className="text-blue-500 text-[11px] ">
              {`Total Active Vehicle: ${Total_No_Act_veh}`}
            </div>
            <div className="text-blue-500 text-[11px] ">
              {`Number assigned : ${No_Assigned_Veh} (${Number((No_Assigned_Veh / (No_Assigned_Veh+No_UnAssigned_Veh))*100).toFixed(2)}%)`}
            </div>
            <div className="text-blue-500 text-[11px] ">
              {`Number Unassigned : ${No_UnAssigned_Veh} (${Number((No_UnAssigned_Veh / (Total_No_Act_veh))*100).toFixed(2)}%)`}
            </div>
            <div className="text-blue-500 text-[11px]">
              {`Number Boarded : ${No_Boarded} (${Number((No_Boarded / Total_No_Act_veh)*100).toFixed(2)}%)`}
            </div>
            <div className="text-blue-500 text-[11px]">
              {`Inactive staff : ${
               InActiveStaff
              }`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleAssignmentsDetails;
