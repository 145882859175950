import { useState } from "react";
import "devextreme/dist/css/dx.light.css";
import { Tooltip } from "devextreme-react";
import alter from "../../../../../../../assets/alter.png";
import edit from "../../../../../../../assets/edit.png";
import newfile from "../../../../../../../assets/new.png";
import correct from "../../../../../../../assets/correct.png";
import turnBack from "../../../../../../../assets/turn-back.png";
import send from "../../../../../../../assets/send.png";
import key from "../../../../../../../assets/key.png";
import log from "../../../../../../../assets/log.png";
import question from "../../../../../../../assets/question.png";
import close from "../../../../../../../assets/close.png";
import printer from "../../../../../../../assets/printer.png";
import refresh from "../../../../../../../assets/refresh.png";
import statusLoader from "../../../../../../../assets/statusloader.gif";

import { Checkbox, DatePicker, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import {
  setGeneralValue,
  settask_form_disable,
  settask_footer_update,
} from "../../../../../../../features/generalSlice";
import {
  task_form_disable,
  task_footer_update,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { saveModalData } from "../../../../../../accessories/component_infos";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { FaBinoculars } from "react-icons/fa";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import dayjs from "dayjs";
import { DateTemplate } from "../../../../../../../templates/date";

type stateTypes = {
  searchTexts: string;
  approveStatus: boolean;
  cancelStatus: boolean;
};

interface props {
  toggler?: () => void;
  launchSendSMSAlertModal?: () => void;
  launchVehicleLogBookModal?: () => void;
  launchVehicleKeyModal?: () => void;
  launchSecurityModal?: () => void;
  Help_Center?: () => void;
  searchTextOnchange?: (value: string) => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  onAprroveStatus?: (value: any) => void;
  onCancelStatus?: (value: any) => void;
  handleFind?: () => void;
  handleRefresh?: () => void;
  handlePrint?: () => void;
  handleAmmendments?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  searchCriteriaData?: { id: number; value: string }[];
  searchCriteria?: boolean;
  startDate?: string;
  endDate?: string;
  switchState?: (value: boolean) => void;
  setVisible?: () => void;
  setAllState?: (value: any) => void;
  AllState?: any;
  outerSelectedRecord?: any;
  setOuterSelectedRecord?: (e: any) => any;
}

export const VehicleRequestsToolbar = ({
  toggler,
  launchSendSMSAlertModal,
  launchVehicleLogBookModal,
  launchVehicleKeyModal,
  launchSecurityModal,
  Help_Center,
  handleFind,
  searchTextOnchange,
  startDateChanged,
  endDateChanged,
  onAprroveStatus,
  onCancelStatus,
  handleRefresh,
  handlePrint,
  searchCriteriaOnchange,
  searchCriteriaData = [],
  searchCriteria,
  startDate,
  endDate,
  switchState,
  setVisible,
  setAllState,
  AllState,
  outerSelectedRecord,
  setOuterSelectedRecord,
  handleAmmendments
}: props) => {
  const dateFormat = "DD MMM YYYY";
  const dispatch = useDispatch();

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });

  const [states, setStates] = useState<stateTypes>({
    searchTexts: "",
    approveStatus: false,
    cancelStatus: false,
  });

  const { searchTexts, approveStatus, cancelStatus } = states;

  const updateState = (key: string, value: any) => {
    setStates((prev: any) => ({ ...prev, [key]: value }));
  };

  const UpdateAllState = (key: string, value: any) => {
    setAllState!((currentValue: any) => ({ ...currentValue, [key]: value }));
  }

  const resetFields = () => {
    UpdateAllState("active", true);
    UpdateAllState("purpose", "");
    UpdateAllState("remark", "");
    UpdateAllState("route", "");
    UpdateAllState("numOfEmployees", "");
    UpdateAllState("departureTime", dayjs());
    UpdateAllState("returnTime", dayjs());
    UpdateAllState("isDriver", false);
    UpdateAllState("isVehicle", false);

    UpdateAllState("selectedFromLocation", {
      id: "",
      name: "",
    });

    UpdateAllState("selectedToLocation", {
      id: "",
      name: "",
    });

    UpdateAllState("requestArea", {
      id: 0,
      name: "",
    });

    UpdateAllState("requestBy", {
      id: 0,
      name: "",
    });

    UpdateAllState("requestType", {
      id: 0,
      name: "",
    });

    UpdateAllState("recommendedBy", {
      id: 0,
      name: "",
    });

    UpdateAllState("selectedFromCountry", {
      id: 0,
      name: "",
    });

    UpdateAllState("selectedToCountry", {
      id: 0,
      name: "",
    });
    UpdateAllState("selectedFromRegion", {
      name: "",
      id: "",
    });
    UpdateAllState("selectedToRegion", {
      name: "",
      id: "",
    });
  };

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const sideAlert = useSelector(
    (state: RootState) => state.general.sideAlertTest
  );

  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(
    privType,
  );

  const AccessPriv_on_FindButton = () => {
    setPrivType("read");
    if (savePriv === true) {
      handleFind && handleFind();
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_PrintButton = () => {
    if (savePriv === true) {
      alert("No Function Implemented For Print Button");
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      switchState && switchState(true);
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      setOuterSelectedRecord && setOuterSelectedRecord({})
      dispatch(task_form_disable(false));
      dispatch(settask_form_disable(false));

      dispatch(task_footer_update(false));
      dispatch(settask_footer_update(false));
      resetFields();
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };
  const AccessPriv_on_EditButton = () => {
    setPrivType("update");
    setVisible && setVisible()
    const eX_selected = outerSelectedRecord;
    if (updatePriv === true) {
      if (Object.keys(eX_selected ?? {}).length < 1) {
        setIcon("warning");
        setshowModalDisableButton(true);
        setModalData({
          message: "No record selected",
          title: "Invalid record",
          okText: "Ok",
        });
        setOpenModal(true);
        dispatch(task_form_disable(true));

        dispatch(task_footer_update(false));
      } else {
        if (outerSelectedRecord?.vrqRecommended && !outerSelectedRecord?.vrqApproved) {
          setModalData({
            title: "You Can't Edit this Record",
            message: "This Record has been Recommended",
            okText: "Ok",
          });
          setOpenModal(true);
          dispatch(task_form_disable(true));

          dispatch(task_footer_update(false));
        } else {

          dispatch(task_form_disable(false));

          dispatch(task_footer_update(true));
        }
      }
    } else {
      setIcon("warning");
      setshowModalDisableButton(true);
      setModalData({
        message: "No Access Privilege",
        title: "Access Denied!",
        okText: "Ok",
      });
      setOpenModal(true);
    }
  };

  const handleNew = () => {
    AccessPriv_on_NewButton();
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(task_form_disable(true));
    dispatch(settask_form_disable(true));
    dispatch(task_footer_update(false));
    dispatch(settask_footer_update(false));

    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  const [showModalDisableButton, setshowModalDisableButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>(saveModalData);
  const [icon, setIcon] = useState<"warning" | "success" | "question">();

  const handleUpdate = () => {
    AccessPriv_on_EditButton();
  };

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>(""); //actual value to use

  const [D_indeterminate, setD_indeterminate] = useState<any>(true);
  const [D_checkedBox, setD_checkedBox] = useState<any>(false);
  const [D_checkValue, setD_checkValue] = useState<boolean | string>("");

  const D_onChange = () => {
    if (D_indeterminate === true) {
      setD_checkedBox(true);
      setD_indeterminate(false);
      setD_checkValue(true);
      onCancelStatus && onCancelStatus(true);
    } else if (!D_indeterminate && D_checkedBox) {
      setD_checkedBox(false);
      setD_indeterminate(false);
      setD_checkValue(false);
      onCancelStatus && onCancelStatus(false);
    } else if (!D_indeterminate && !D_checkedBox) {
      setD_checkedBox(false);
      setD_indeterminate(true);
      setD_checkValue("");
      onCancelStatus && onCancelStatus("");
    }
  };

  const onChange = () => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
      onAprroveStatus && onAprroveStatus(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
      onAprroveStatus && onAprroveStatus(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
      onAprroveStatus && onAprroveStatus("");
    }
  };

  return (
    <div
      style={{
        width: "40000px",
        borderWidth: "1px",
        height: 32,
        borderColor: borderTheme,
      }}
      className="px-0.5 mt-0.5 rounded   w-full h-full flex  border-gray-200 mb-1"
    >
      <div className="flex justify-center ">
        <ModalTemplate
          icon_width={modalData.iconSize === "large" ? 100 : 40}
          icon={icon}
          disableCancel={true}
          open={openModal}
          okHandler={() => {
            setOpenModal(false);
          }}
          message={modalData.message}
          okText={modalData.okText}
          title={modalData.title}
        />
        <ul className="flex justify-evenly w-full items-center h-full py-0.5">
          {disablex || updateMode ? (
            <li
              id="newRecord"
              onClick={() => handleNew()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={newfile} />
                New{" "}
              </span>
              <Tooltip
                target="#newRecord"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">New Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}
          {!updateMode ? (
            <li
              id="editRecordst"
              onClick={() => {
                handleUpdate();
              }}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                  src={edit}
                />
                Edit{" "}
              </span>
              <Tooltip
                target="#editRecordst"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Edit Record</p>
              </Tooltip>
            </li>
          ) : (
            <li
              id="close-cancel"
              onClick={() => handleCancel()}
              style={{ borderWidth: "1px", borderColor: borderTheme }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img className="pr-1 " src={close} />
                Cancel{" "}
              </span>
              <Tooltip
                target="#close-cancel"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Cancel</p>
              </Tooltip>
            </li>
          )}

          <li
            id="switch"
            onClick={toggler}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
                src={alter}
              />
              Switch{" "}
            </span>
            <Tooltip
              target="#switch"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Switch interface / table view</p>
            </Tooltip>
          </li>

          <li
            id="assignments"
            onClick={launchSendSMSAlertModal}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <img
              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
              src={send}
            />
            <Tooltip
              target="#assignments"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Send SMS alerts</p>
            </Tooltip>
          </li>

          <li
            id="key"
            onClick={launchVehicleKeyModal}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <img
              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
              src={key}
            />
            <Tooltip
              target="#key"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Vehicle key and Inspection Management</p>
            </Tooltip>
          </li>

          <li
            id="log"
            onClick={launchVehicleLogBookModal}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <img
              className="dark:bg-slate-900 dark:text-darkModeSkin-base duration-100"
              src={log}
            />
            <Tooltip
              target="#log"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Vehicle log book</p>
            </Tooltip>
          </li>

          <li
            id="correct"
            onClick={launchSecurityModal}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2  py-1  text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img src={correct} />{" "}
            </span>
            <Tooltip
              target="#correct"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Approve request for Security Gates</p>
            </Tooltip>
          </li>

          <li
            id="turnBack"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            onClick={() => { handleAmmendments && handleAmmendments() }} >
            <span>
              {" "}
              <img src={turnBack} />{" "}
            </span>
            <Tooltip
              target="#turnBack"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Request for Ammendments</p>
            </Tooltip>
          </li>
          <li
            id="help"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1  text-gray-600 rounded flex items-center"
          >
            <span className="flex">
              {" "}
              <img
                className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                onClick={() => {
                  Help_Center && Help_Center()
                }}
                src={question}
              />{" "}
            </span>
            <Tooltip
              target="#help"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Help Center</p>
            </Tooltip>
          </li>

          <li className="px-1 text-xs">Search Criteria</li>
          <li className="items-right  ">
            <Select
              value={criteriaDataLocal}
              dropdownMatchSelectWidth={false}
              className=" mt-0.5"
              onChange={(data: any) => {
                const criteriaData = JSON.parse(data);
                setCriterialDataLocal({
                  id: criteriaData?.index,
                  value: criteriaData?.criteria,
                }); //local
                searchCriteriaOnchange &&
                  searchCriteriaOnchange(criteriaData?.index, criteriaData?.criteria);
              }}
              id="search"
              size="small"
              showSearch={true}
              style={{
                width: `${sideAlert === true ? 20 : 110}`,
                height: "25px",
              }}
            >
              {searchCriteriaData?.map(({ id, value }, index) => (
                <Select.Option
                  key={id}
                  value={JSON.stringify({ index: index, criteria: value })}
                >
                  {value}
                </Select.Option>
              ))}
            </Select>
          </li>
        </ul>
      </div>

      <div className="flex items-center">
        <ul className="flex items-center">
          <li className="px-1 text-xs">Search Text</li>
          <li className="px-1">
            <Input
              onChange={(e: any) => {
                const searchText: string = e.target.value;
                searchTextOnchange && searchTextOnchange(searchText);
                updateState("searchTexts", e.target.value);
              }}
              value={searchTexts}
              name="search"
              id="search"
              size="small"
              allowClear={true}
              style={{
                width: ` ${sideAlert === true ? 20 : 150}`,
                height: "24px",
              }}
            />
          </li>
          <li className="px-0.5">
            <DateTemplate
              selectedDate={dayjs(startDate) ?? dayjs().format(dateFormat)}
              changeDate={(e: any) => {
                const hold = dayjs(e).format("YYYY-MM-DD HH:mm:ss");
                startDateChanged!(hold);
              }}
              datePlaceholder="Start Date"
              style={{ width: 125 }}
              format={dateFormat}
              disabled={false}
            />
          </li>
          <li className="px-0.5">
            <DateTemplate
              selectedDate={dayjs(endDate) ?? dayjs().format(dateFormat)}
              changeDate={(e: any) => {
                const hold = dayjs(e).format("YYYY-MM-DD HH:mm:ss");
                endDateChanged!(hold);
              }}
              datePlaceholder="End Date"
              style={{ width: 125 }}
              format={dateFormat}
              disabled={false}
            />
          </li>
          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="app"
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={checkedBox}
              indeterminate={indeterminate}
              value={checkValue}
              onChange={(checkValue: any) => {
                // const app: boolean = checkValue.target.checked; 
                // onAprroveStatus && onAprroveStatus(app);
                onChange();
                updateState("approveStatus", checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#app"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Approval status of request</p>
            </Tooltip>
          </li>
          <li
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            id="stats"
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
          >
            <Checkbox
              checked={D_checkedBox}
              indeterminate={D_indeterminate}
              value={D_checkValue}
              onChange={(D_checkValue: any) => {
                // const cancel: boolean = D_checkValue.target.checked;
                // onCancelStatus && onCancelStatus(cancel);
                D_onChange();
                updateState("cancelStatus", D_checkValue.target.checked);
              }}
            />
            <Tooltip
              target="#stats"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Cancel status of request</p>
            </Tooltip>
          </li>
          <li
            id="find"
            onClick={() => {
              AccessPriv_on_FindButton();
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <FaBinoculars size={15.5} color="#007AFF" />{" "}
            </span>
            <Tooltip
              target="#find"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Find</p>
            </Tooltip>
          </li>
          <li
            id="refresh"
            onClick={() => {
              handleRefresh && handleRefresh();
              updateState("searchTexts", "");
              setIndeterminate(true)
              setD_indeterminate(true)
              setCriterialDataLocal({
                id: searchCriteriaData![0]?.id ?? 0,
                value: searchCriteriaData![0]?.value ?? "",
              });
            }}
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
          >
            <span>
              {" "}
              <img
                src={refresh}
                className="w-4  dark:bg-slate-900 dark:text-gray-100 duration-100"
              />{" "}
            </span>
            <Tooltip
              target="#refresh"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Refresh</p>
            </Tooltip>
          </li>
          {/* 
          <li
            id="print"
            style={{ borderWidth: "1px", borderColor: borderTheme }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1 text-gray-600 rounded flex items-center"
            onClick={() => {
              AccessPriv_on_PrintButton();
            }}
          >
            <span>
              {" "}
              <img src={printer} />{" "}
            </span>
            <Tooltip
              target="#print"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p>Print / Export</p>
            </Tooltip>
          </li> */}

          <li className="hover:cursor-pointer ml-20  px-2  flex items-center">
            {disablex && (
              <p className=" italic font-semibold text-gray-600">Done.</p>
            )}

            {!disablex && (
              <div className="  flex flex-row items-center">
                <img
                  style={{
                    width: "31px",
                    height: "31px",
                    borderColor: borderTheme,
                  }}
                  src={statusLoader}
                  alt="gif"
                />
                {!updateMode ? (
                  <p className=" italic text-gray-600">New Record</p>
                ) : (
                  <p className=" italic text-gray-600">Editing</p>
                )}
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};