import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import { Tooltip } from "devextreme-react";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useId, useState } from "react";
import { FaBinoculars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RootState } from "../../../app/store";
import alter from "../../../assets/alter.png";
import clearAll from "../../../assets/clear-all.png";
import close from "../../../assets/close.png";
import edit from "../../../assets/edit.png";
import help from "../../../assets/help.png";
import newfile from "../../../assets/new.png";
import printer from "../../../assets/printer.png";
import reset from "../../../assets/refresh-small.png";
import statusLoader from "../../../assets/statusloader.gif";
import { setGeneralValue } from "../../../features/generalSlice";
import { useAccountInfo } from "../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../hooks/useFormPriviledge";
import { ModalTemplate } from "../../../templates/modal";
import {
  searchTextTypes,
  selectsTypes,
} from "../../human-resource/setups/data/types/selectsTypes";

import { Popup_Tips } from "../../Assets-Manager/Transport/Entries/Vehicles/Vehicle-Requests/widgets/widgets/popUpTips";
import { FormModal } from "../formModal";

import InputSelect_template from "../../../templates/InputSelect";
import { formPropsConst } from "../constants";
import { DateTemplate } from "../../../templates/date";

interface props {
  startDate?: string;
  endDate?: string;
  useCallBackFunc?: boolean;
  toggler?: (e: any) => void;
  clearRemarks?:() => void;
  handleDelete?: () => void;
  handleSave?: () => void;
  handleNew?: () => void;
  handleUpdate?: () => void;
  searchTextOnchange?: (value: string) => void;
  startDateChanged?: (value: any) => void;
  endDateChanged?: (value: any) => void;
  checkOnChange?: (value: any) => void;
  handleFind?: (value: any) => void;
  handleRefresh?: (value: any) => void;
  powerTrainRefresh?:()=>void;
  handlePrint?: () => void;
  handleToggle?: () => void;
  handleToggleAll?: () => void;
  searchCriteriaOnchange?: (index: number, criteria: string) => void;
  checkBoxAfterHelpOnchange?: (checkValue: boolean) => void;
  disableNew_edit?: boolean;
  withDates?: boolean;
  outerSelected?: any;
  setOuterSelected?: (state: any) => void;
  updateStatesx?: (key: string, value: any) => void;
  statesx?: any;
  checkBoxAfterHelp?: boolean;
  searchCriteria?: boolean;
  assignmentsForm?: boolean;
  searchCriteriaData?: { id: number; value: string }[];
  setVisible?: (e: boolean) => void;
  setSearchParams?: string | any | undefined;
  setSearchParamspathname?: string | any | undefined;
  setCurrentStates?: () => void;
  currentStates?: any;
  selected_Record?: any;
  extraWidgetsAfterSwitch?: any;
  setSelected_Record?: (val: any) => void;
  isSwitch?: boolean;
  Switch?: boolean;
  toolbarInputSelectsData?: selectsTypes[]; //holds everything about the inputSelect
  isSearch?: boolean;
  isAFRP?: boolean;
  isInputSelect?: boolean;
  lastadditionalWidget?: any;
  extraWidgetAfterHelp?: any;
  ResetFunctionFromVehDetails_Form?:(e:boolean)=>void;
  operateResetFromVehDetails_Form?:boolean;
  isSearchTextDisable?:boolean;
}

export const HrSetupsCustomToolbar = ({
  toggler,
  clearRemarks,
  setCurrentStates,
  currentStates,
  startDate,
  endDate,
  updateStatesx,
  statesx,
  handleToggle,
  handleToggleAll,
  setOuterSelected,
  searchCriteriaOnchange,
  checkBoxAfterHelp = false,
  searchCriteriaData = [],
  assignmentsForm,
  checkBoxAfterHelpOnchange,
  searchCriteria,
  handleNew: newButtonClick,
  handleRefresh,
  handlePrint,
  outerSelected,
  disableNew_edit,
  useCallBackFunc,
  withDates = true,
  powerTrainRefresh,
  handleDelete,
  searchTextOnchange,
  handleSave,
  handleUpdate,
  startDateChanged,
  endDateChanged,
  handleFind,
  checkOnChange,
  setVisible,
  setSearchParams,
  setSearchParamspathname,
  setSelected_Record,
  selected_Record,
  extraWidgetsAfterSwitch,
  isSwitch = true,
  Switch,
  isSearch = true,
  isAFRP = true,
  isInputSelect = false,
  lastadditionalWidget,
  extraWidgetAfterHelp,
  toolbarInputSelectsData,
  ResetFunctionFromVehDetails_Form,
  operateResetFromVehDetails_Form,
  isSearchTextDisable,
}: props) => {
  const dispatch = useDispatch();
  const id = useId();

  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const colorTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const borderBg = useSelector((state: RootState) => state.general.cssBorderBg);
  const selectedRecord = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [empID, userId, userInfo] = useAccountInfo();
  const [privtype, setprivtype] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privtype);

  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalData, setWarningModalData] = useState<any>({
    title: "",
    message: "",
  });
  const selected = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  const [indeterminate, setIndeterminate] = useState<any>(true);
  const [checkedBox, setCheckedBox] = useState<any>(false);
  const [checkValue, setCheckValue] = useState<boolean | string>("");
  const [searchText, setSearchText] = useState("");
  const [searchTextValue, setSearchTextValue] = useState<searchTextTypes>({
    temp: "",
    text: "",
  });

  const [criteriaDataLocal, setCriterialDataLocal] = useState({
    id: searchCriteriaData![0]?.id ?? 0,
    value: searchCriteriaData![0]?.value ?? "",
  });
  const [helpCenter, setHelpCenter] = useState(false);
  useEffect(() => {
    dispatch(setGeneralValue({ expr: "activeStatus", value: checkValue }));
    checkOnChange && checkOnChange(checkValue);
  }, [checkValue]);

  const onChange = (value: boolean) => {
    if (indeterminate === true) {
      setCheckedBox(true);
      setIndeterminate(false);
      setCheckValue(true);
    } else if (!indeterminate && checkedBox) {
      setCheckedBox(false);
      setIndeterminate(false);
      setCheckValue(false);
    } else if (!indeterminate && !checkedBox) {
      setCheckedBox(false);
      setIndeterminate(true);
      setCheckValue("");
    }
  };

  
  const editAccessPriv = () => {
    setprivtype("update");
    if (updatePriv === true) {
      handleEdit();
      handleUpdate && handleUpdate();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privileges",
      });
    }
  };
  const findAccessPriv = () => {
    setprivtype("read");
    if (readPriv === true) {
      handleFind &&
        handleFind(
          `&searchCriteria=${searchParams}` +
          (checkValue !== null ? `&active=${checkValue}` : "") +
          (searchText !== "" ? `&searchText=${searchText}` : "") +
          `&startDate=${startDate}&endDate=${endDate}`
        );
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privileges",
      });
    }
  };
  const printAccessPriv = () => {
    setprivtype("read");
    if (readPriv === true) {
      handlePrint && handlePrint();
    } else {
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privileges",
      });
    }
  };
  const reduxDispatchs = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    dispatch(setGeneralValue({ expr: "selectedFieldData", value: {} }));
  };

  const handleNew = () => {
    setVisible && setVisible(true);
    // toggler && toggler(true);
    navigate({
      pathname: setSearchParamspathname,
      search: createSearchParams({
        view: setSearchParams ?? "form-and-table",
      }).toString(),
    });
    ResetFunctionFromVehDetails_Form && ResetFunctionFromVehDetails_Form(!operateResetFromVehDetails_Form);
    setOuterSelected && setOuterSelected({});
    setSelected_Record && setSelected_Record({});
    setCurrentStates && setCurrentStates();
    clearRemarks && clearRemarks();
    reduxDispatchs();
    newButtonClick && newButtonClick();
  };
  
  const newAccessPriv = () => {
    setprivtype("save");
    if (savePriv === true) {
      handleNew();
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      setWarningModal(true);
      setWarningModalData({
        title: "Access Denied",
        message: "No Access Privileges",
      });
    }
  };
  const setModal = () => {
    setWarningModal(true);
    setWarningModalData({
      title: "Select record",
      message: "Please select a record to edit",
    });
  };
  const OnModalOk = () => {
    setWarningModal(false);
  };
  const OnModalCancel = () => {
    setWarningModal(false);
  };

  const handleEdit = () => {
    // navigate({
    //   pathname: setSearchParamspathname,
    //   search: createSearchParams({
    //     view: setSearchParams ?? "form-and-table",
    //   }).toString(),
    // });
    // toggler && toggler(true);

    setVisible && setVisible(true);
    // toggler && toggler(true);
    navigate({
      pathname: setSearchParamspathname,
      search: createSearchParams({
        view: setSearchParams ?? "form-and-table",
      }).toString(),
    });

    if (outerSelected && !useCallBackFunc) {
      if (Object.keys(outerSelected).length < 1) {
        setModal();
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }

      return;
    } else if (outerSelected && useCallBackFunc) {
      return;
    }

    if (selected_Record && Object.keys(selected_Record).length < 1) {
      setWarningModal(true);
      setWarningModalData({
        title: "Select record",
        message: "Please select a record to edit",
      });
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    }
    // For SetupsTemplate
    if (selectedRecord.length < 1) {
      setModal();
      dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
      dispatch(setGeneralValue({ expr: "updateMode", value: false }));
    } else {
      dispatch(setGeneralValue({ expr: "formDisabled", value: false }));
      dispatch(setGeneralValue({ expr: "updateMode", value: true }));
    }
  };

  const handleCancel = () => {
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    dispatch(setGeneralValue({ expr: "updateMode", value: false }));
  };

  return (
    <>
      {
        <FormModal
          title={"Popup Tips"}
          child={<Popup_Tips />}
          width={"500px"}
          openState={helpCenter}
          onCancel={() => {
            setHelpCenter(false);
          }}
        />
      }
      <div
        style={{ height: 32, borderColor: colorTheme }}
        className="px-0.5 mt-0.5 rounded border-[1px] w-full h-full flex  border-gray-200 mb-1"
      >
        {/* ------------------------- for print -------------------------- */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
                        .ant-btn.ant-btn-sm {
                            
                            
                            justify-content: center;
                        }

                        :where(.css-dev-only-do-not-override-1n7nwfa).ant-btn.ant-btn-sm{
                            height: 26px;
                        }
                    `,
          }}
        />
        {/* -------------------------------------------------------------------------- */}
        <ModalTemplate
          icon={"warning"}
          cancelHandler={OnModalCancel}
          disableCancel={true}
          open={warningModal}
          okHandler={OnModalOk}
          message={warningModalData.message}
          okText={"Ok"}
          title={warningModalData.title}
        />
        <ul
          style={{ width: !disableNew_edit ? "340px" : "240px" }}
          className="flex items-center h-full py-0.5"
        >
          {/* new and cancel section */}
          {!disableNew_edit && (
            <span className="flex items-center h-full ">
              {disabled || updateMode ? (
                <li
                  id="newRecord"
                  onClick={() => newAccessPriv()}
                  style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg}}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                    {" "}
                    <img className="pr-1 " src={newfile} />
                    New{" "}
                  </span>
                  <Tooltip
                    target="#newRecord"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">New Record</p>
                  </Tooltip>
                </li>
              ) : (
                <li
                  id="close-cancel"
                  onClick={() => handleCancel()}
                  style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                    {" "}
                    <img className="pr-1 " src={close} />
                    Cancel{" "}
                  </span>
                  <Tooltip
                    target="#close-cancel"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Cancel</p>
                  </Tooltip>
                </li>
              )}

              {!updateMode ? (
                <li
                  id="editRecordst"
                  onClick={() => editAccessPriv()}
                  style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                    {" "}
                    <img
                      className="dark:bg-slate-900 pr-1 dark:text-gray-100 duration-100"
                      src={edit}
                    />
                    Edit{" "}
                  </span>
                  <Tooltip
                    target="#editRecordst"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Edit Record</p>
                  </Tooltip>
                </li>
              ) : (
                <li
                  id="close-cancel"
                  onClick={() => handleCancel()}
                  style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                    {" "}
                    <img className="pr-1 " src={close} />
                    Cancel{" "}
                  </span>
                  <Tooltip
                    target="#close-cancel"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Cancel</p>
                  </Tooltip>
                </li>
              )}
            </span>
          )}

          {isSwitch && (
            <li
              id="switchInterface"
              onClick={() => { toggler && toggler(!Switch) }}
              style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg }}
              className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
            >
              <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                {" "}
                <img
                  className="dark:bg-slate-900 pr-1 dark:text-darkModeSkin-base duration-100"
                  src={alter}
                />
                Switch
              </span>
              <Tooltip
                target="#switchInterface"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Switch interface / table view</p>
              </Tooltip>
            </li>
          )}

          {extraWidgetsAfterSwitch && extraWidgetsAfterSwitch}

          <li
            id="helpst"
            style={{ borderWidth: "1px", borderColor: colorTheme, backgroundColor: borderBg }}
            onClick={() => {
              setHelpCenter(true);
            }}
            className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-2 py-1   text-gray-600 rounded flex items-center"
          >
            <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
              {" "}
              <img
                className="dark:bg-slate-900 dark:text-gray-100 duration-100 mr-1"
                src={help}
              />
              Help{" "}
            </span>
            <Tooltip
              target="#helpst"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              hideOnOutsideClick={true}
            >
              <p className="">Help</p>
            </Tooltip>
          </li>

          {assignmentsForm && (
            <>
              <li
                id="clear-cancel"
                onClick={() => handleToggle && handleToggle()}
                style={{ borderWidth: "1px", borderColor: colorTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img
                    style={{ paddingTop: 1, paddingBottom: 1 }}
                    className=" w-10/12 py-0.5 h-10/12"
                    src={close}
                  />
                </span>
                <Tooltip
                  target="#clear-cancel"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">
                    Toggle active status of selected assignment
                  </p>
                </Tooltip>
              </li>

              <li
                id="clear-cancel-all"
                onClick={() => handleToggleAll && handleToggleAll()}
                style={{ borderWidth: "1px", borderColor: colorTheme }}
                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
              >
                <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                  {" "}
                  <img className="" src={clearAll} />
                </span>
                <Tooltip
                  target="#clear-cancel-all"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  hideOnOutsideClick={true}
                >
                  <p className="">Toggle active status of all assignments</p>
                </Tooltip>
              </li>
            </>
          )}

          {extraWidgetAfterHelp && extraWidgetAfterHelp}

          {checkBoxAfterHelp && (
            <li
              style={{
                borderWidth: "1px",
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 5,
                paddingRight: 5,
              }}
              id="loadAll"
              className="  border-slate-200  hover:cursor-pointer mr-1  text-xs px-1 text-gray-600 rounded flex items-center"
            >
              <Checkbox
                onChange={(checkValue) => {
                  checkBoxAfterHelpOnchange &&
                    checkBoxAfterHelpOnchange(checkValue.target.checked);
                }}
              />
              <Tooltip
                target="#loadAll"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                hideOnOutsideClick={true}
              >
                <p className="">Load all forms</p>
              </Tooltip>
            </li>
          )}
        </ul>

        <ul
          style={
            !disableNew_edit
              ? { width: "calc(100% - 340px)" }
              : { width: "calc(100% - 240px)" }
          }
          className="flex  w-full mr-4"
        >
          <section className="flex justify-start items-center">
            {(searchCriteria || assignmentsForm) && (
              <li className="px-1 flex items-center flex-row ">
                <label
                  htmlFor={"search-" + id}
                  style={{ width: "87px" }}
                  className=" text-xs"
                >
                  Search Criteria
                </label>
                <Select
                  value={criteriaDataLocal}
                  dropdownMatchSelectWidth={false}
                  className=" mt-0.5"
                  onChange={(data: any) => {
                    const criteriaData = JSON.parse(data);
                    setCriterialDataLocal({
                      id: criteriaData?.index,
                      value: criteriaData?.criteria,
                    });
                    searchCriteriaOnchange &&
                      searchCriteriaOnchange(
                        criteriaData?.index,
                        criteriaData?.criteria
                      );
                  }}
                  id={"search-" + id}
                  size="small"
                  showSearch={true}
                  style={{ width: 110, height: "25px" }}
                >
                  {searchCriteriaData?.map(({ id, value }, index) => (
                    <Select.Option
                      key={id}
                      value={JSON.stringify({ index: index, criteria: value })}
                    >
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </li>
            )}

            {isSearch && (
              <li className="px-1 flex items-center flex-row ml-1 ">
                <label className="w-[80px] text-xs flex">Search Text</label>
                {isInputSelect ? (
                  <Form {...formPropsConst}>
                    {toolbarInputSelectsData?.map(
                      ({
                        id,
                        label,
                        idExpr,
                        dataExpr,
                        defaultValue,
                        style,
                        optionsData,
                        selectedValue,
                      }: selectsTypes) => {
                        return (
                          <InputSelect_template
                            useCallFunc
                            span
                            selectStyle={{ width: 180 }}
                            disabled={false}
                            placeHolder={defaultValue}
                            options={optionsData}
                            idexpr={idExpr}
                            dataexp={dataExpr}
                            selectedValue={selectedValue}
                          />
                        );
                      }
                    )}
                  </Form>
                ) : (
                  <Input
                    onChange={(e: any) => {
                      const searchText: string = e.target.value;
                      searchTextOnchange && searchTextOnchange(searchText);
                      setSearchTextValue({
                        temp: searchTextValue.temp,
                        text: searchText,
                      });
                    }}
                    disabled={isSearchTextDisable ?? false}
                    value={searchTextValue.text}
                    name="search"
                    id="search"
                    size="small"
                    allowClear={false}
                    style={{ width: 130, height: "24px" }}
                  />
                )}
              </li>
            )}
            {withDates && (
              <ul className=" flex flex-row items-center mt-0.5">
                
                <li className="px-0.5">
                <DateTemplate
                  width={125}
                  changeDate={startDateChanged}
                  selectedDate={dayjs(startDate)}
                  dbFormat={true}
                  disabled={false}

                />
                  {/* <DatePicker
                    value={dayjs(startDate) ?? dayjs().format("YYYY-MM-DD")}
                    clearIcon={<span style={{ display: "none" }}></span>}
                    onChange={startDateChanged}
                    size="small"
                    placeholder="Start Date"
                    style={{ width: 125 }}
                    format={"DD MMM YYYY"}
                  /> */}
                </li>

                

                <li className="px-0.5">
                <DateTemplate
                  width={125}
                  changeDate={endDateChanged}
                  selectedDate={dayjs(endDate)}
                  dbFormat={true}
                  disabled={false}

                />
                
                  {/* <DatePicker
                    value={dayjs(endDate)}
                    clearIcon={<span style={{ display: "none" }}></span>}
                    onChange={endDateChanged}
                    size="small"
                    placeholder="End Date"
                    style={{ width: 125 }}
                    format={"DD MMM YYYY"}
                  /> */}
                </li>
              </ul>
            )}

            {isAFRP && (
              <>
                <li
                  id="status"
                  style={{
                    borderWidth: "1px",
                    paddingTop: 0.8,
                    paddingBottom: 0.8,
                    paddingLeft: 3.25,
                    paddingRight: 3,
                    borderColor: colorTheme,
                  }}
                  className="hover:cursor-pointer mr-1.5 ml-1  text-xs   text-gray-600 rounded flex items-center"
                >
                  <Checkbox
                    checked={checkedBox}
                    indeterminate={indeterminate}
                    onChange={(checkValue: any) => {
                      onChange(checkValue);
                    }}
                  />
                  <Tooltip
                    target="#status"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Show Status</p>
                  </Tooltip>
                </li>

                <li
                  onClick={() => {
                    findAccessPriv();
                  }}
                  id="findst"
                  style={{ borderWidth: "1px", borderColor: colorTheme }}
                  className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    <FaBinoculars size={15.5} color="#007AFF" />
                  </span>
                  <Tooltip
                    target="#findst"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Find</p>
                  </Tooltip>
                </li>

                {/* refresh */}
                <li
                  onClick={() => {
                    handleRefresh &&
                      handleRefresh(
                        `&searchCriteria=${searchParams}` +
                        (searchText !== ""
                          ? `&searchText=${searchText}`
                          : "") +
                        `&startDate=${startDate}&endDate=${endDate}`
                      );
                    powerTrainRefresh && powerTrainRefresh();
                    setSearchTextValue({ temp: "", text: "" });
                    setCheckedBox(false);
                    setIndeterminate(true);
                    setCheckValue("");
                    setCriterialDataLocal({
                      id: searchCriteriaData![0]?.id ?? 0,
                      value: searchCriteriaData![0]?.value ?? "",
                    });
                  }}
                  id="REFRESH"
                  style={{ borderWidth: "1px", borderColor: colorTheme }}
                  className="hover:cursor-pointer mr-1.5 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    {" "}
                    <img
                      className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                      src={reset}
                    />
                  </span>
                  <Tooltip
                    target="#REFRESH"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Refresh</p>
                  </Tooltip>
                </li>

                {/* <li
                  onClick={() => printAccessPriv()}
                  id="printst"
                  style={{ borderWidth: "1px", borderColor: colorTheme }}
                  className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                >
                  <span className="flex dark:bg-slate-900 dark:text-darkModeSkin-base duration-100 ">
                    {" "}
                    <img
                      className="dark:bg-slate-900 dark:text-gray-100 duration-100"
                      src={printer}
                    />
                  </span>
                  <Tooltip
                    target="#printst"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    hideOnOutsideClick={true}
                  >
                    <p className="">Print/Export</p>
                  </Tooltip>
                </li> */}
              </>
            )}

            {lastadditionalWidget && lastadditionalWidget}
            {/* Exports */}
          </section>

          {/* Form status label */}
          {!disableNew_edit && (
            <section className="flex pl-10 h-full flex-row items-center">
              {/* done state */}
              {disabled && <p className=" text-gray-600">Done.</p>}

              {/* new record or editing state */}
              {!disabled && (
                <div className="gif  flex flex-row items-center">
                  <img className="w-11 h-7" src={statusLoader} alt="gif" />
                  {!updateMode ? (
                    <p className=" text-gray-600">New record...</p>
                  ) : (
                    <p className=" text-gray-600">Editing...</p>
                  )}
                </div>
              )}
            </section>
          )}
        </ul>
      </div>
    </>
  );
};
