import { Select, DatePicker, Form, Input, Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  Column,
  DataGrid,
  GroupPanel,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import {
  SaveButton,
  NewButton,
  Cancel,
  UpdateButton,
} from "../../../../../accessories/buttons";
import {
  api_url,
  navbar_height,
  toolbar_height,
} from "../../../../../accessories/component_infos";

import reset from "../../../../../../assets/reset.png";
import dayjs from "dayjs";
import {
  capacitor_bank_data,
  capacitor_bank_TH,
} from "../data/capacitor-bank-data";
import { CheckBox } from "devextreme-react";
import useFetch from "../../../../../../hooks/useFetch";
import { log } from "console";
import { rootCertificates } from "tls";
import { ModalTemplate } from "../../../../../../templates/modal";
import axios from "axios";
import { async } from "q";
import {
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../../../features/Task&Todo/Task/TaskDetails";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../app/store";
import {
  setFormData,
  setGeneralValue,
} from "../../../../../../features/generalSlice";
import { modalPropTypes, modalTypes } from "../../../../../human-resource/setups/data/types/selectsTypes";
import SetupsDatalistTemplat from "../../../../../human-resource/setups/data/setups_datalist_template";

const { Option } = Select;

interface props {
  toggled?: boolean;
}

const CapacitorBankDetails = ({ toggled }: props) => {
  const news_flash_height = useSelector((state:RootState)=>state.general.newsflashHeight);
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  const pageHeight = window.innerHeight - navbar_height; //height without navbar
  const [tableHeight, settableHeight] = useState(
    window.innerHeight - (heights_out + 70)
  ); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const dateFormat = " DD MMM YYYY HH: mm:ss";
  const [validationModal, setValidationModal] = useState<modalPropTypes>({state:false, title: "", message:""});
  const [confirmModal, setConfirmModal] = useState<modalPropTypes>({state:false, title: "", message:"", func:()=>{},action:'post'});
  const [successModal, setSuccessModal] = useState<modalPropTypes>({state:false, title: "", message:""});

  const defaultDate = dayjs().toString();
  const [showTable, setShowTable] = useState(true);
  const [station, setSation] = useState<any>({ id: "", name: "" });
  const [nomenclature, setNomenclature] = useState<any>({ id: "", name: "" });
  const [nomenclatureShtName, setNomenclatureShtName] = useState<any>({
    id: 0,
    name: "",
  });
  const [startDate, setStartDate] = useState<any>(defaultDate);
  const [endDate, setEndDate] = useState<any>(defaultDate);
  const [active, setActive] = useState<any>(true);
  const [remarks, setRemarks] = useState<any>("");
  const [refreshState, setRefreshState] = useState<any>(false);
  const [selectedID, setSelectedID] = useState<any>(0);

  /*For button */
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(false);
  const [deactivateFields, setDeactivateFields] = useState(true);
  const [rowData, setRowData] = useState<any>({});
  const [deafultModalState, setDeafultModalState] = useState<any>({
    title: "",
    message: "",
    state: false,
  });

  const startDate_con = `${dayjs(startDate).format()}`;
  const endDate_con = `${dayjs(endDate).format()}`;

  const postData = async function () {
    setConfirmModal({state:false,title:'',message:''});
    try {
      const res = await axios
        .post(
          `${api_url}/api/AsmTamEntCapacitorBank/CreateAsmTamCapacitorBank`,
          {
            cbkStartDate: startDate_con,
            cbkEndDate: endDate_con,
            cbkStationIdfk: station.id,
            cbkNomenclature: nomenclature.name,
            cbkShtName: nomenclatureShtName.name,
            cbkStationService: true,
            cbkActive: active,
            cbkRmks: remarks,
            cbkCreatedBy: 1,
          }
        )
        .then((res) => {
          setSuccessModal({state:true,title:'Record Created',message:'Record Created succesfully'})
          console.log(res.data);
          setRefreshState(!refreshState);
          setDeactivateFields(true);
          setShowCancelBtn(false);

          dispatch(task_form_disable(true));
          // dispatch(update_route_state(false))
          dispatch(task_footer_update(false));
          //disable form
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          //disable update mode
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const putData = async function () {
    setConfirmModal({state:false,title:'',message:''});
    try {
      const res = await axios
        .put(
          `${api_url}/api/AsmTamEntCapacitorBank/UpdateAsmTamCapacitorBank/${selectedID}`,
          {
            cbkIdpk: selectedID,
            cbkStartDate: startDate_con,
            cbkEndDate: endDate_con,
            cbkStationIdfk: station.id,
            cbkNomenclature: nomenclature.name,
            cbkShtName: nomenclatureShtName.name,
            cbkStationService: true,
            cbkActive: active,
            cbkRmks: remarks,
            cbkEditedBy: 1,
            cbkEditedDate: "2023-02-14T15:20:09.366Z",
          }
        )
        .then((res) => {
          setSuccessModal({state:true,title:'Record Updated',message:'Record updated succesfully'})
          console.log(res.data);
          setRefreshState(!refreshState);
          setDeactivateFields(true);
          setShowCancelBtn(false);
          //disable form
          dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
          //disable update mode
          dispatch(setGeneralValue({ expr: "updateMode", value: false }));
        });
    } catch (err) {
      console.log(err);
    }
  };


  

  const validateData = function (method: string) {
    if (station === "") {
     setValidationModal({
      state:true,
      title:'required',
      message:'select station'
     })
      return;
    }
    if (nomenclature === "") { 
      setValidationModal({
        state:true,
        title:'required',
        message:'Select Nomenclature'
       })
      return;
    }
    if (nomenclatureShtName === "") {
      setValidationModal({
        state:true,
        title:'required',
        message:'Select Nomenclature short name'
       })
      return;
    }

    if (method.toLowerCase() === "post") {
      setConfirmModal({
        state:true,
        message:'Capacitor Bank added Successfully',
        title:'Success',
        func() {
          postData();
        },
        action:'post'
      })
    } else {
      setConfirmModal({
        state:true,
        message:'Capacitor Bank updated Successfully',
        title:'Success',
        func() {
          putData()
        },
        action:'update'
      })
      ;
    }
  };

  const [stationData, stationErr, stationLoading] = useFetch(
    "api/AsmTamEntCapacitorBank/GetAsmTamCapacitorBankLueStation"
  );
  const [nomenClatureData, nomenClatureErr, nomenClatureLoading] = useFetch(
    "api/AsmTamEntCapacitorBank/GetAsmTamCapicatorBankLueNomenclature"
  );

  const [gdvData, gdvErr, gdvLoading] = useFetch(
    "api/AsmTamEntCapacitorBank/GetAllAsmTamCapacitorBank",
    refreshState
  );
  if (gdvData) {
    // setSelectedID(gdvData[0].cbkIdpk);
  }

  function onRowClicked(data: any) {
    setRowData(data);
    setActive(data.cbkActive);
    setNomenclature({
      id: data.cbkIdpk,
      name: data.cbkNomenclature,
    });
    setNomenclatureShtName({
      id: data.cbkIdpk,
      name: data.cbkShtName,
    });
    setSation({
      id: data.fstnIdpk,
      name: data.fstnName,
    });

    setSelectedID(rowData.cbkIdpk); 
  }

  function resetRowData() {
    setRowData({});
    setNomenclature({
      id: "",
      name: "",
    });
    setNomenclatureShtName({
      id: "",
      name: "",
    });
    setSation({
      id: "",
      name: "",
    });
    setActive(false);
  }

  useEffect(() => {
    console.log(`inside ${toggled}`);
    console.table(nomenClatureData);
  }, [toggled]);

  const disablex = useSelector(
    (state: RootState) => state.addTask.taskFormStatus
  );
  const updateMode = useSelector(
    (state: RootState) => state.addTask.taskFooterUpdate
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!disablex) {
      console.log("ds");

      setShowCancelBtn(true);
      setDeactivateFields(false);
      resetRowData();
      dispatch(task_footer_table_selected({}));

      dispatch(task_form_disable(false));
      //  this destroys the update mode state
      dispatch(task_footer_update(false));
    } else {
      setShowCancelBtn(false);
      setDeactivateFields(true);
      setShowUpdateBtn(false);
      // setShowCancel(false); setDisableForms(true); setUpdating(false)
    }
    if (updateMode) {
      //enable forms
      dispatch(task_form_disable(true));

      //enable Update Mode
      dispatch(task_footer_update(true));
      setDeactivateFields(false);
      setShowUpdateBtn(true);
      setShowCancelBtn(true);
 
    }
  }, [disablex, updateMode]);



  const modals:modalTypes[] = [
    //validation
    {disableCancel : true , icon : 'warning' ,open : validationModal.state, okHandler : ()=>setValidationModal({state:false,title:'',message:''}), cancelHandler : ()=> setValidationModal({state:false,title:'string',message:'string'}), message : validationModal.message, okText : 'Ok', title : validationModal.title,cancelText : 'No, cancel'},
    
    //confirmation
    {disableCancel : false , icon : 'question' ,open : confirmModal.state, okHandler : confirmModal.func ? confirmModal.func:()=>{}, cancelHandler : ()=> setConfirmModal({state:false,title:'string',message:'string'}), message : confirmModal.message, okText : confirmModal.action==='update' ? 'Yes, Update':'Yes, Save', title :confirmModal.title,cancelText : 'No, cancel'},

    //success
    {disableCancel : true , icon : 'success' ,open : successModal.state, okHandler : ()=>setSuccessModal({state:false,title:'string',message:'string'}), cancelHandler : ()=> setSuccessModal({state:false,title:'string',message:'string'}), message : successModal.message, okText : 'Ok', title : successModal.title,cancelText : 'No, cancel'},
]

  return (
    <>
      {/* modals */}
      {modals.map(({disableCancel,icon,okHandler,cancelHandler,open,cancelText,okText,message,title}:modalTypes,index)=>{
                return(
                    <div key = {index}>
                        <ModalTemplate  icon= {icon && icon} disableCancel = {disableCancel} cancelText = {cancelText && cancelText} open = {open} okHandler={okHandler} cancelHandler={cancelHandler} message= {message} okText= {okText} title={title}/>
                    </div>
                )
            })}
      <div>
        {/* <style
          dangerouslySetInnerHTML={{
            __html: `
           input[type="text"]:disabled {
            background: #fffbeb;
          }  
          
          .dx-datagrid-header-panel .dx-toolbar {
            margin-bottom: 1.5px;
        }

          .filterTodos .dx-datagrid-headers{
              margin-top: 10px;
          }

            `,
          }}
        /> */}

        {toggled && (
          <div className="w-full   border rounded-md">
            <div className="text-xs bg-slate-100 rounded-t-md py-1 px-2">
              Capacitor Bank Details
            </div>
            {/* The form */}
            <div className="py-2 flex justify-between items-end">
              <Form
                name="complex-form"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                size={"small"}
                className="ml-2 "
                style={{ width: "50%" }}
              >
                <Form.Item
                  label={<p className="text-xs">{"Start & End Date"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    style={{ display: "inline-block", width: "calc(46% )" }}
                  >
                    <DatePicker
                      placeholder={startDate}
                      // key={startDate}
                      onChange={(dateString, date) => {
                        setStartDate(dateString);
                        // alert(dateString);
                        console.log(dateString);
                      }}
                      format={dateFormat}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      disabled={deactivateFields}
                    />
                  </Form.Item>

                  <Form.Item
                    name="month"
                    labelCol={{ span: 10 }}
                    style={{
                      display: "inline-block",
                      width: "calc(46% )",
                      marginLeft: "2.5%",
                    }}
                  >
                    <DatePicker
                      placeholder={endDate}
                      // key={endDate}
                      onChange={(dateString, date) => {
                        console.log(dateString);
                        setEndDate(dateString);
                      }}
                      format={dateFormat}
                      showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
                      disabled={deactivateFields}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Station"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="issuedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <Select
                        key={station.id}
                        defaultValue={station.name}
                        onChange={(e: any) => {
                          setSation({
                            id: JSON.parse(e).stnIdpk,
                            name: JSON.parse(e).stnName,
                          });
                        }}
                        disabled={deactivateFields}
                        className="W w-full mr-0 mb-0.5 py-0"
                        placeholder=""
                      >
                        {stationData?.map((option: any, index: number) => {
                          return (
                            <option key={index} value={JSON.stringify(option)}>
                              {`${option["stnName"]}`}
                            </option>
                          );
                        })}
                      </Select>

                      <span
                                                    onClick={()=>{
                                                      setSation({
                                                        id: '',
                                                        name: '',
                                                      });
                                                    }}
                                                    style={{height:22.5, marginBottom:0.6,borderWidth:1,width:25.2}}
                                                    className=' ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  label={<p className="text-xs">{"Nomenclature"}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="receivedBy"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center">
                      <Select
                        key={nomenclature.id}
                        defaultValue={nomenclature.name}
                        onChange={(e: any) => {
                          setNomenclature({
                            id: JSON.parse(e).cbkIdpk,
                            name: JSON.parse(e).cbkNomenclature,
                          });
                          // console.log(e);

                          // setNomenclature(e);
                        }}
                        disabled={deactivateFields}
                        className="W w-full mr-0 mb-0.5 py-0"
                        placeholder=""
                      >
                        {nomenClatureData?.map((option: any, index: number) => {
                          return (
                            <option key={index} value={JSON.stringify(option)}>
                              {`${option["cbkNomenclature"]} `}
                            </option>
                          );
                        })}
                      </Select>

                      <span
                                                    onClick={()=>{
                                                      setNomenclature({
                                                        id: '',
                                                        name: '',
                                                      });
                                                    }}
                                                    style={{height:22.5, marginBottom:0.6,borderWidth:1,width:25.2}}
                                                    className=' ml-1 flex justify-center hover:cursor-pointer border-gray-300 rounded'>
                                                        <img className='w-full hover:bg-blue-50 h-full flex p-0.5 items-center justify-center' src= {reset} alt="reset" />
                                                </span>
                    </div>
                  </Form.Item>
                </Form.Item>

                {/* <Form.Item
                  label={<p className="text-xs">{""}</p>}
                  style={{ marginBottom: 0 }}
                >
                  <Form.Item
                    name="requestWindow"
                    rules={[{ required: false }]}
                    style={{ display: "inline-block", width: "calc(100%)" }}
                  >
                    {" "}
                    <div className="flex flex-row items-center"> */}
                      {/* <Select
                        key={nomenclatureShtName.id}
                        defaultValue={nomenclatureShtName.name}
                        onChange={(e: any) => {
                          setNomenclature({
                            id: JSON.parse(e).cbkIdpk,
                            name: JSON.parse(e).cbkShtName,
                          });
                        }}
                        disabled={deactivateFields}
                        className="W w-full mr-0 mb-0.5 py-0"
                        placeholder=""
                      >
                        {nomenClatureData?.map(
                          (options: any, index: number) => {
                            return (
                              <option
                                key={index}
                                value={JSON.stringify(options)}
                              >
                                {`${options["cbkShtName"]} `}
                              </option>
                            );
                          }
                        )}
                      </Select> */}
                       <SetupsDatalistTemplat 
            useCallbackFunc
            outerDisable={deactivateFields}
            selectedData={nomenclatureShtName.name}
            listid='name'
            setCallbackValue={(value)=>{
               setNomenclatureShtName({id:0, name:value})
            }}
            options={nomenClatureData}
            idExpr='cbkIdpk'
            nameExpr={'cbkShtName'}
            label='Short Name'/>
                      
                    {/* </div>
                  </Form.Item>
                </Form.Item> */}

                <Form.Item
                  style={{ display: "inline-block", marginBottom: "2px" }}
                  label={<p className="text-xs">{"Active"}</p>}
                  className="w-full mb-1"
                >
                  <div className=" items-end">
                    <div className="w-full flex  flex-row justify-between  ">
                      <div>
                        <Checkbox
                          defaultChecked={active}
                          disabled={deactivateFields}
                          key={active}
                          onChange={(e) => setActive(e.target.checked)}
                        />
                      </div>
                      <ul className="flex">
                        {showUpdateBtn === true ? (
                          <UpdateButton
                            handleUpdate={() => {
                              validateData("put");
                            }}
                          />
                        ) : (
                          <SaveButton
                            handleSave={() => {
                              validateData("post");
                            }}
                          />
                        )}
                        {showCancelBtn === true ? (
                          <Cancel
                            useCallbackFunc={true}
                            cancel_button_toggler={() => {
                              setShowUpdateBtn(false);
                              setShowCancelBtn(false);
                              setDeactivateFields(true);

                              resetRowData();

                              dispatch(task_form_disable(true));
                              // dispatch(update_route_state(false))
                              dispatch(task_footer_update(false));
                            }}
                          />
                        ) : (
                          <NewButton
                            useCallbackFunc={true}
                            new_button_toggler={() => {
                              //enable forms
                              dispatch(task_form_disable(true));

                              //enable Update Mode
                              dispatch(task_footer_update(true));
                              setShowCancelBtn(true);
                              setDeactivateFields(false);

                              resetRowData();
                            }}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </Form.Item>
              </Form>
              <Form.Item
                style={{ display: "inline-block", marginBottom: "1px" }}
                label={<p className="text-xs">{"Remarks"}</p>}
                className="w-2/3 mb-1 mr-2"
              >
                <TextArea
                  onChange={(e: any) => {
                    setRemarks(e.target.value);
                  }}
                  readOnly={deactivateFields}
                  style={{
                    resize: "none",
                    maxHeight: "137px",
                    minHeight: "137px",
                    height: "137px",
                  }}
                  className="o overflow-y-scroll resize-none"
                  rows={3}
                />
              </Form.Item>
            </div>
          </div>
        )}

        <div className="pt-0.5">
          <DataGrid
            dataSource={gdvData}
            showBorders={true}
            columnWidth={150}
            showRowLines={true}
            showColumnLines={true}
            onRowClick={(rowData) => {
              console.log(rowData.data);
              onRowClicked(rowData?.data);
              setDeactivateFields(true);
              dispatch(task_footer_table_selected(rowData.data));
            }}
            onRowDblClick={(e) => {
              setSelectedID(e.data.cbkIdpk);
              setShowUpdateBtn(true);
              setShowCancelBtn(true);
              setDeactivateFields(false);
              //enable forms
              dispatch(task_form_disable(true));

              //enable Update Mode
              dispatch(task_footer_update(true));
            }}
            //Remember to change
            height={toggled === true ? tableHeight - 130: tableHeight}
          >
            <SearchPanel visible={true} width={200} />
            <GroupPanel visible={true} />
            <Pager visible={false} />

            {/* STAFF DETAILS COLUMNS */}
            {/* FIXED COLUMNS */}

            {capacitor_bank_TH.map((data) => {
              return (
                <Column
                  key={data.id}
                  caption={data.caption}
                  dataField={data.dataField}
                  // alignment={data.alignment}
                  fixed={data.fixed}
                  width={data.width === null ? 150 : data.width}
                />
              );
            })}

            {/* SCROLLBAR  */}

            {/* <Scrolling columnRenderingMode="finite" /> */}
          </DataGrid>
        </div>
      </div>
    </>
  );
};

export default CapacitorBankDetails;
