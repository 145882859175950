

export const meter_TH = [
    {
      id: 0,
      caption: "METRE No",
      dataField: "mtrMeterNo",
      alignment: "left",
      fixed: true,
      width: null,
    },
    {
      id: 1,
      caption: "SERIAL No",
      dataField: "mtrSerialNo",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 2,
      caption: "SHORT NAME",
      dataField: "mtrShtName",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 3,
      caption: "STATION",
      dataField: "fstnName",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 4,
      caption: "CUSTOMER",
      dataField: "fcustName",
      alignment: "left",
      fixed: true,
      width: null
    },
    {
      id: 5,
      caption: "ACTIVE?",
      dataField: "mtrActive",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    {
      id: 6,
      caption: "VERIFIED",
      dataField: "mtrVerified",
      alignment: "left",
      fixed: true,
      width: 60
  
    },
    
    {
      id: 7,
      caption: "STATUS",
      dataField: "estName",
      alignment: "left",
      fixed: true,
      width: null
  
    },
    
    
    {
      id: 8,
      caption: "FIT STATUS",
      dataField: "estFitStatus",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 9,
      caption: "STA. SERV.?",
      dataField: "emaStationService",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 10,
      caption: "MANUFACTURER",
      dataField: "mftName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 11,
      caption: "CT RATIO",
      dataField: "emaCtRatio", 
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 12,
      caption: "PT RATIO",
      dataField: "ptRatio",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 13,
      caption: "ACCURACY CLASS",
      dataField: "aclName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 14,
      caption: "DISPLAY TYPE",
      dataField: "dptName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 15,
      caption: "FEEDER",
      dataField: "fdrName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 16,
      caption: "SUPPLY CONN",
      dataField: "spcShtName",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 17,
      caption: "MANUFACTURE DATE",
      dataField: "mtrManufactureDate",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 18,
      caption: "SUPPLY DATE",
      dataField: "mtrSupplyDate",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 19,
      caption: "INSTALL START DATE",
      dataField: "mtrInstallationDate",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 20,
      caption: "COMMISSION",
      dataField: "mtrDateCommissioned",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 21,
      caption: "DECOMMISSION",
      dataField: "mtrDateDecommissioned",
      alignment: "left",
      fixed: false,
      width: null
  
    },
    {
      id: 22,
      caption: "REMARKS",
      dataField: "mtrRmks",
      alignment: "left",
      fixed: false,
      width: 4000
  
    },
  ]
  
  export const meter_data = [
    
  ]