/* eslint-disable react/jsx-pascal-case */
import { Input, Checkbox } from "antd";
import newIcon from "../../../../../../../../assets/new.png";
import refresh from "../../../../../../../../assets/refresh.png";

import { lowerShare_cols, upperShare_cols } from "../../data/datagrid_cols";
import useFetch from "../../../../../../../../hooks/useFetch";
import Datagrid_template from "../../../../../../../../templates/Datagrid";
import { useCallback, useEffect, useState } from "react";
import useResizeMode from "../../../../../../../../hooks/useResizeMode";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../app/store";

interface props {
  setSharedEmps: (e: any) => void;
  sharedEmps: any;
}

export const Transport_Documents_Share = ({
  setSharedEmps,
  sharedEmps,
}: props) => {
  const [search, setSearch] = useState("");
  const [refreshShareEmps, setRefreshShareEmps] = useState(false);
  const [shareEmps, shareEmpsErr, shareEmpsLoading] = useFetch(
    `AmsTmsEntTransportDocuments/GetDgvShare?search=${search}`,
    refreshShareEmps
  );
  const addedCols = {
    swtOwn: false,
    swtRead: false,
    swtPrint: false,
  };
  
  useEffect(() => {
    shareEmps.forEach((obj: any) => Object.assign(obj, addedCols));
  }, [shareEmps]);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  // const form_access_disable = useSelector(
  //   (state: RootState) => state.general.TransformDisabled
  // );
  const form_access_disable = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const selectAllOwn = (checked: boolean) => {
    setSharedEmps((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtOwn: checked }))
    );
  };

  const selectAllRead = (checked: boolean) => {
    setSharedEmps((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtRead: checked }))
    );
  };
  const selectAllPrint = (checked: boolean) => {
    setSharedEmps((prev: any) =>
      prev.map((obj: any) => ({ ...obj, swtPrint: checked }))
    );
  };

  const addSharedEmp = () => {
    if (Object.keys(selectedRecord).length != 0) {
      const alreadyExists = sharedEmps.some(
        (obj: any) => selectedRecord.empIDpk === obj.empIDpk
      );

      if (!alreadyExists) {
        const record = { ...selectedRecord };
        setSharedEmps((prev: any) => [...prev, record] as typeof sharedEmps);
      }
    }
  };

  const removeDblClicked = useCallback(
    (e: any) => {
      setSharedEmps((prev: any) =>
        prev.filter(
          (item: any) =>
            item.empIDpk !== e.empIDpk || item.empIdpk !== e.empIdpk
        )
      );
    },
    [setSharedEmps]
  );

  const [resizeMode, initialResizeMode] = useResizeMode(true);

  return (
    <>
      <div className="w-full h-full justify-evenly ">
        <ul className=" w-full flex items-center  pl-8">
          <li className="pr-10">
            <Checkbox
              disabled={form_access_disable}
              onChange={(e) => selectAllOwn(e.target.checked)}
            />
          </li>
          <li className="pr-6">
            <Checkbox
              disabled={form_access_disable}
              onChange={(e) => selectAllRead(e.target.checked)}
            />
          </li>
          <li className=" pl-5">
            <Checkbox
              disabled={form_access_disable}
              onChange={(e) => selectAllPrint(e.target.checked)}
            />
          </li>
          <li className="justify-center items-center ml-4">
            <p>Find</p>
          </li>
          <li className="mx-2 justify-center items-center ">
            <Input
              value={search}
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
              className="w-16 mt-0.5"
              size="small"
            />
          </li>
          <li
            onClick={() => {
              if (!form_access_disable) addSharedEmp();
            }}
            className="m-1 flex justify-center items-center border-2 rounded cursor-pointer"
          >
            <img alt="" className="w-5 p-0.5 " src={newIcon} />
          </li>
          <li
            onClick={() => {
              setSearch("");
            }}
            className="m-1 flex  border-2 rounded cursor-pointer"
          >
            <img
              alt=""
              className="w-5 p-0.5 items-center justify-center "
              src={refresh}
            />
          </li>
        </ul>
        <div className="pt-0.5">
          <Datagrid_template
            allowColumnEditing={!form_access_disable}
            deselectFirstRow
            selectionMode="single"
            disableGroupPanel
            disableSearch
            disablePaging
            data={sharedEmps}
            gridheight={192}
            style={{ maxWidth: "400px" }}
            noColumn={150}
            columns={upperShare_cols}
            showSelectionCheckBox="always"
            rowDoubleClicked={(e) => {
              if (!form_access_disable) removeDblClicked(e);
            }}
          />
        </div>
        <div className="pt-0.5 mr-1">
          <Datagrid_template
            style={{ maxWidth: "auto" }} //  h-[60%]
            deselectFirstRow
            selectionMode="single"
            disableGroupPanel
            disableSearch
            disablePaging
            data={shareEmps}
            gridheight={resizeMode - 485}
            gridwidth={800}
            noColumn={150}
            columns={lowerShare_cols}
            onRowClick={(e) => {
              setSelectedRecord(e);
            }}
            showSelectionCheckBox="always"
          />
        </div>
      </div>
    </>
  );
};
