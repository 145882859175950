import React, { useCallback, useMemo } from 'react';
import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";

interface DocViewerProps {
  docs: IDocument[];
  activeDoc: IDocument | null;
  setActiveDoc: (doc: IDocument) => void;
  CustomImageRenderer: any;
}

const MemoizedDocViewer = React.memo(({ docs, activeDoc, setActiveDoc, CustomImageRenderer }: DocViewerProps) => {
  const handleDocumentChange = useCallback((newDoc: IDocument) => {
    if (newDoc !== activeDoc) {
      setActiveDoc(newDoc);
    }
  }, [activeDoc, setActiveDoc]);

  const memoizedDocs = useMemo(() => docs, [docs]);

  const memoizedPluginRenderers = useMemo(() => [...DocViewerRenderers, CustomImageRenderer], [CustomImageRenderer]);

  return (
    <DocViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
          retainURLParams: false,
        },
      }}
      documents={memoizedDocs}
      activeDocument={activeDoc}
      onDocumentChange={handleDocumentChange}
      pluginRenderers={memoizedPluginRenderers}
      className="text-wrap"
    />
  );
}, (prevProps: DocViewerProps, nextProps: DocViewerProps) => {
  // Custom comparison function
  return (
    prevProps.docs === nextProps.docs &&
    prevProps.activeDoc === nextProps.activeDoc &&
    prevProps.CustomImageRenderer === nextProps.CustomImageRenderer
  );
});

export const OptimizedDocViewer: React.FC<DocViewerProps> = (props) => {
  return <MemoizedDocViewer {...props} />;
};