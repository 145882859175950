import { useState } from "react";
import useFetch from "../../../../../../../hooks/useFetch";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { selectsTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import dayjs, { Dayjs } from "dayjs";
import { ModalTemplate } from "../../../../../../../templates/modal";
import { Form } from "antd";
import { DateTemplate } from "../../../../../../../templates/date";
import { SelectsTemplate } from "../../../../../../../templates/select";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { SaveButton } from "../../../../../../accessories/buttons";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";

interface props {
  statesxx?: any;
  setRefreshx?: (e: boolean) => void;
  refereshx?: boolean;
}

interface stateTypes2 {
  dept?: { id: number; name: string };
  sxn?: { id: number; name: string };
  cost?: { id: number; name: string };
  quan1: number;
  quan2: number;
  uom1?: { id: number; name: string };
  prevOdom: number;
  curOdom: number;
  active?: boolean;
  remarks2?: string;
  disp?: number;
  selectedDate1?: Dayjs;
  password?: string;
  amount?: number;
}

const DispenseForm = ({ statesxx, setRefreshx, refereshx }: props) => {
  const [states, setStates] = useState<stateTypes2>({
    dept: { id: 0, name: "" },
    sxn: { id: 0, name: "" },
    cost: { id: 0, name: "" },
    quan1: 0,
    quan2: 0,
    uom1: { id: 0, name: "" },
    prevOdom: 0,
    curOdom: 0,
    active: true,
    remarks2: "",
    disp: 0,
    selectedDate1: dayjs(),
    password: "",
    amount: 0,
  });

  const setState = (key: string, value: any) => {
    setStates((prev) => ({ ...prev, [key]: value }));
  };

  const [dept_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebits"
  );
  const [sxn_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitSections?debitDepartmentId=${states.dept?.id}`
  );
  const [cost_data] = useFetch(
    `AsmTmsEntFuelManagement/GetEntAsmTmsLueDumpDebitCostCenters?debitDepartmentId=${states.dept?.id}&debitSectionId=${states.sxn?.id}`
  );

  const [uom_data] = useFetch(
    "AsmTmsEntFuelManagement/GetEntAsmTmsLueFuelUnits"
  );

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");
  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);

  const [disShowModalDisableButton, setDisShowModalDisableButton] =
    useState(false);
  const [disOpenModal, setDisOpenModal] = useState<boolean>(false);
  const [disModalData, setDisModalData] = useState<any>("");
  const [icon, setIcon] = useState<"warning" | "success" | "question">();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [Posting, Updating] = useCrudFunc();

  const dispenseSelects: selectsTypes[] = [
    {
      id: 0,
      label: "Debit Department",
      optionsData: dept_data,
      idExpr: "dptIdpk",
      dataExpr: "dptShtName",
      stateName: "dept",
      defaultValue: states.dept?.name,
      style: "",
    },
    {
      id: 1,
      label: "Debit Section",
      optionsData: sxn_data,
      idExpr: "sxnIdpk",
      dataExpr: "sxnShtName",
      stateName: "sxn",
      defaultValue: states.sxn?.name,
      style: "",
    },
    {
      id: 2,
      label: "Debit Cost Center",
      optionsData: cost_data,
      idExpr: "cctIdpk",
      dataExpr: "cctName",
      stateName: "cost",
      defaultValue: states.cost?.name,
      style: "",
    },
  ];
  const dispenseInputs: any[] = [
    {
      id: 0,
      stateName: "quan1",
      defaultValue: statesxx?.selectedRecord?.fmgQuantityRequested,
      style: "",
    },
    {
      id: 1,
      stateName: "quan2",
      defaultValue: statesxx?.selectedRecord?.fmgQuantityAuthorised,
      style: "",
    },
    { id: 2, stateName: "disp", defaultValue: states?.disp, style: "" },
    {
      id: 3,
      stateName: "amount",
      defaultValue:
        statesxx?.selectedRecord?.fmgQuantityAuthorised *
        (statesxx?.selectedRecord?.fmgAmount /
          statesxx?.selectedRecord?.fmgQuantityRequested),
      style: "",
    },
  ];
  const dispenseInputs2: any[] = [
    {
      id: 0,
      stateName: "prevOdom",
      defaultValue: states?.prevOdom,
      style: "",
    },
    { id: 1, stateName: "curOdom", defaultValue: states?.curOdom, style: "" },
  ];

  const checkConnection = () => {
    if (!navigator.onLine) {
      setIcon("warning");
      setDisOpenModal(true);
      setDisShowModalDisableButton(true);
      setDisModalData({
        message:
          "Your computer is not connected to the Internet. Please check your Internet connection and try again.",
        title: "Connectivity Error!",
        okText: "Ok",
      });
    }
  };
  const formCode = useSelector((state: RootState) => state.general.formCode);

  const message = `Dispensed ${states.disp} ltr for the Asset Mgr-Trans-Fuel Dump Transaction Log with request ID ${statesxx.selectedRecord?.fmgIDpk}, Requested By ${statesxx.selectedRecord?.driverEmpID} successfully`;

  let mobNox = ``;

  const smsDispenseAlert = async () => {
    try {
      checkConnection();
      if (statesxx.selectedRecord?.driverEmpID) {
        mobNox = statesxx.selectedRecord?.dispenserMobNox
          .trim()
          .replace("", "");
      }
      if (mobNox.trim.length >= 10) {
        mobNox = mobNox.substring(0, 10);
      }

      await Posting(`SMSAlert/SaveSMS`, {
        imsMessage: message,
        imsSender: userInfo.empName,
        imsMessageType: "string",
        imsMessageClass: "string",
        imsRecordIdfk: statesxx.selectedRecord.fmgIDpk,
        imsFormCode: formCode,
        imsRecipientEmpIdfk: statesxx.selectedRecord.driverEmpID,
        imsInitiatorEmpIdfk: userId,
        imsCreatedBy: userId,
      });
    } catch {
      setIcon("warning");
      setDisOpenModal(true);
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Failed to send Alert!.. Please contact your administrator.",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const updateDispenseData = async () => {
    try {
      const res = await Updating(
        `FuelManagement/DispenseFuel?fmgIDpk=${statesxx.selectedRecord?.fmgIDpk}`,
        {
          fmgDateDispensed: states.selectedDate1,
          fmgDebitDeptIDfk: states.dept?.id,
          fmgDebitSectionIDfk: states.sxn?.id,
          fmgCostCenterIDfk: states.cost?.id,
          fmgQuantityDispensed: states.disp,
          fmgAmount: statesxx?.selectedRecord?.fmgAmount,
          fmgPreviousOdometerReading: states.prevOdom,
          fmgCurrentOdometerReading: states.curOdom,
          fmgDispenserRmks: states.remarks2,
          fmgEditedDate: dayjs().format(),
          password: states.password,
        },
        `Dispensed ${states.disp} ltr for the Asset Mgr-Trans-Fuel Dump Transaction Log with request ID-${statesxx.selectedRecord?.fmgIDpk},Dispensed on ${states.selectedDate1}, with Debit Department-${states.dept.name}, Debit Section -${states.sxn.name}, Debit Cost Center - ${states.cost.name}, Total Amount -${statesxx?.selectedRecord?.fmgAmount}, Previous Odom Readings - ${states.prevOdom}, Current Odom Readings - ${states.curOdom} and  Requested By - ${userInfo.empName} successfully`
      );

      smsDispenseAlert();
      setIcon("success");
      setDisOpenModal(true);
      setDisModalData({
        message: "Request dispensed successfully",
        title: "Request Dispensed",
        okText: "Ok",
      });
      setRefresh(!refresh);
      setRefreshx && setRefreshx(!refereshx);
    } catch (err: any) {
      setIcon("warning");
      setDisOpenModal(true);
      setDisShowModalDisableButton(false);
      setDisModalData({
        message: err.response ? err.response.data.message : "Server Error",
        title: "Unexpected Error!",
        okText: "Ok",
      });
    }
  };

  const validation = () => {
    setIcon("warning");
    if (
      dayjs(states.selectedDate1).isBefore(
        dayjs(statesxx?.selectedRecord?.fmgDate)
      )
    ) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message:
          "Please, the dispense date cannot be less than the request date.",
        title: "Invalid Date",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.dept?.id === 0 || states.dept?.id === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, select the debit department.",
        title: "Invalid Department",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.sxn?.id === 0 || states.sxn?.id === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, select the section to be debited.",
        title: "Invalid Section",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.cost?.id === 0 || states.cost?.id === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, select the cost center to be debited.",
        title: "Invalid Cost Center",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.disp === 0 || states.disp === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, enter the quantity to be dispensed.",
        title: "Invalid Quantity",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.curOdom <= states.prevOdom) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message:
          "Please, the current odometer reading cannot be less than the previous reading.",
        title: "Invalid Odometer Reading",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.uom1?.id === 0 || states.uom1?.id === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, select the Unit of Measure.",
        title: "Invalid Unit of Measure",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else if (states.password === "" || states.password === undefined) {
      setDisShowModalDisableButton(true);
      setDisModalData({
        message: "Please, enter your password.",
        title: "Invalid Password",
        okText: "Ok",
      });
      setDisOpenModal(true);
    } else {
      setDisModalData({
        title: "Dispense Request",
        message: "Are you sure you want to dispense this request?",
        okText: "Yes",
        cancelText: "No",
      });
      setDisShowModalDisableButton(false);
      setIcon("question");
      setDisOpenModal(true);
    }
  };

  const updatedDispensePriv = () => {
    if (updatePriv === true) {
      validation();
    } else {
      setIcon("warning");
      setDisOpenModal(true);
      setDisModalData({
        title: "Access Denied!",
        message: "No Access Privilege",
      });
    }
  };
  return (
    <>
      <ModalTemplate
        icon={icon}
        open={disOpenModal}
        cancelText={disModalData.cancelText}
        disableCancel={icon === "warning" || icon === "success" ? true : false}
        cancelHandler={() => {
          setDisOpenModal(false);
        }}
        okHandler={() => {
          disModalData.title === "Dispense Request"
            ? updateDispenseData()
            : setDisOpenModal(false);
          setDisOpenModal(false);
        }}
        message={disModalData.message}
        okText={disModalData.okText}
        title={disModalData.title}
      />
      <Form
        className="w-full dark:bg-slate-900 dark:text-darkModeSkin-base ml-1 rounded py-2 px-4"
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 10 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        size="small"
        layout="horizontal"
      >
        <section className="w-full h-full">
          <DateTemplate
            disabled={false}
            label="Date Dispensed"
            selectedDate={dayjs(states.selectedDate1)}
            changeDate={(date: Dayjs) => {
              setState("selectedDate1", date);
            }}
            span
            width={200}
            format={"ddd DD MMM YYYY HH:MM"}
            disabledDate={(current: any) => current && current.isAfter(dayjs())}
          />

          {dispenseSelects.map(
            ({
              id,
              idExpr,
              dataExpr,
              label,
              optionsData,
              stateName,
              defaultValue,
              style,
            }) => {
              return (
                <>
                  <SelectsTemplate
                    disabled={false}
                    label={label}
                    idexpr={idExpr}
                    dataexp={dataExpr}
                    options={optionsData}
                    placeHolder={defaultValue}
                    useCallFunc
                    selectedValue={(e) => {
                      setState(stateName!, {
                        id: e[idExpr!],
                        name: e[dataExpr!],
                      });
                    }}
                    handleClear={() => {
                      setState(stateName!, { id: 0, name: "" });
                    }}
                  />
                </>
              );
            }
          )}

          <Form.Item
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            label={<p>Quantity (R/A/D)</p>}
            className="w-full"
          >
            <div className="w-full flex space-x-1">
              {dispenseInputs.map(
                ({ id, label, stateName, defaultValue, style }) => {
                  return (
                    <>
                      <InputsTemplate
                        disabledStatus={id === 2 ? false : true}
                        useCallbackFunc
                        span
                        inputStyle={{
                          width: 58,
                          backgroundColor: id === 2 ? "" : "#fff9a0",
                        }}
                        numberOnly={true}
                        defaultValue={defaultValue}
                        orderOnchange={(e) => {
                          setState(stateName!, e);
                        }}
                      />
                    </>
                  );
                }
              )}

              <>
                <SelectsTemplate
                  disabled={false}
                  useCallFunc
                  selectStyle={{ width: 75 }}
                  idexpr={"funIdpk"}
                  dataexp={"funShtName"}
                  options={uom_data}
                  placeHolder={states.uom1?.name}
                  selectedValue={(e) => {
                    setState("uom1", {
                      id: e["funIdpk"],
                      name: e["funShtName"],
                    });
                  }}
                  handleClear={() => {
                    setState("uom1", { id: 0, name: "" });
                  }}
                />
              </>
            </div>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            label={<p>{"Odom. Readings (P/C)"}</p>}
            className="pb-[1px]"
          >
            <div className="w-full flex space-x-1 ">
              {dispenseInputs2.map(
                ({ id, label, stateName, defaultValue, style }) => {
                  return (
                    <>
                      <InputsTemplate
                        disabledStatus={false}
                        useCallbackFunc
                        span
                        numberOnly={true}
                        defaultValue={defaultValue}
                        orderOnchange={(e) => {
                          setState(stateName!, e);
                        }}
                      />
                    </>
                  );
                }
              )}

              <p className="w-full flex items-center text-blue-600">{`${
                states?.curOdom - states?.prevOdom
              } km`}</p>
            </div>
          </Form.Item>

          <TextareaTemplate
            height={70}
            labelCol={7}
            label={"Remarks"}
            useCallbackFunc
            defaultValue={states.remarks2}
            readonly={false}
            disabled={false}
            setCallbackValue={(e) => {
              setState("remarks2", e);
            }}
          />

          <Form.Item
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            label={
              <p className="text-xs flex items-center">
                {"Receipient Password"}
              </p>
            }
            className="w-full"
          >
            <div className="w-full  flex justify-between items-center gap-1">
              <InputsTemplate
                inputType="password"
                span
                useCallbackFunc
                disabledStatus={false}
                inputStyle={{
                  width: 250,
                  height: 23,
                  backgroundColor: "#fed7aa",
                }}
                defaultValue={states.password}
                orderOnchange={(e) => {
                  setState("password", e);
                }}
              />
              <SaveButton
                useCallbackFunc
                disableButton={false}
                marginRight={'0'}
                title="Dispense"
                handleSave={() => {
                  updatedDispensePriv();
                }}
              />
            </div>
          </Form.Item>
        </section>
      </Form>
    </>
  );
};

export default DispenseForm;
