/* eslint-disable react/jsx-pascal-case */
import {
  Select,
  DatePicker,
  Form,
  Input,
  Checkbox,
  Radio,
  RadioChangeEvent,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {
  Column,
  DataGrid,
  GroupPanel,
  Pager,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import save from "../../../../../../../assets/floppy-disk.png";
import reset from "../../../../../../../assets/reset.png";
import { SelectsTemplate } from "../../../../../../../templates/select";
import {
  UpdateButton,
  SaveButton,
  Cancel,
  NewButton,
} from "../../../../../../accessories/buttons";

import { useDispatch, useSelector } from "react-redux";
import {
  api_url,
  currentDate,
  navbar_height,
  startingDate,
  toolbar_height,
} from "../../../../../../accessories/component_infos";
import {
  vehicleSimCard_data,
  vehicleSimCard_TH,
} from "../../Vehicle-Requests/data/vehicle-requests-data";
import useFetch from "../../../../../../../hooks/useFetch";
import axios from "axios";
import { DropDownBox, RadioGroup, Tooltip } from "devextreme-react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ModalTemplate } from "../../../../../../../templates/modal";
import {
  vehicle_log_book_Authorized_By,
  vehicle_log_book_Reg_No,
  vehicle_log_book_TH,
} from "../data/vehicle-log-book-data";
import Datagrid_template from "../../../../../../../templates/Datagrid";

import {
  task_footer_table,
  task_footer_table_selected,
  task_footer_update,
  task_form_disable,
} from "../../../../../../../features/Task&Todo/Task/TaskDetails";
import { RootState } from "../../../../../../../app/store";
import CustomLoader from "../../../../../../accessories/custom-loader";
import { setGeneralValue } from "../../../../../../../features/generalSlice";
import { DateTemplate } from "../../../../../../../templates/date";
import { InputsTemplate } from "../../../../../../../templates/input";
import { TextareaTemplate } from "../../../../../../../templates/textarea";
import { EntryCustomToolbar } from "../../../../../../accessories/custom-toolbars/entryCustomtoolbar";
import close from "../../../../../../../assets/close.png";
import correct from "../../../../../../../assets/correct.png";
import { useAccountInfo } from "../../../../../../../hooks/useAccountInfo";
import {
  UpdateDataFunc,
  UpdateDataFuncParams,
} from "../../../../../../../functions/update";
import { WarningAlert } from "../../../../../../accessories/warningAlert";
import { useFormPriviledge } from "../../../../../../../hooks/useFormPriviledge";
import useResizeMode from "../../../../../../../hooks/useResizeMode";
import { debounce, isNaN, isNumber, set } from "lodash";
import { PostDataFunc } from "../../../../../../../functions/post";
import { start } from "repl";
import { statusTypes } from "../../../../../../human-resource/setups/data/types/selectsTypes";
import { PatchDataFunc } from "../../../../../../../functions/patch";
import { DeleteDataFunc } from "../../../../../../../functions/delete";
import { DropdownGrid_template } from "../../../../../../../templates/DropdownGrid";
import { useCrudFunc } from "../../../../../../../functions/crud";
import { useBusyLoader } from "../../../../../../../hooks/useBusyLoader";
import InputSelect_template from "../../../../../../../templates/InputSelect";
import {
  fetchFromCountry,
  fetchFromLocation,
  fetchFromRegion,
  fetchToCountry,
  fetchToLocation,
  fetchToRegion,
} from "../../../../../../../features/apiCalls/vehicleLogBookApiSlice";
import { FormModal } from "../../../../../../accessories/formModal";
import { OdometerModal } from "./OdometerModal";

const { Option } = Select;

interface props {
  toggled?: boolean;
  setToggled?: () => void;
  setShowTable?: (e: boolean) => void;
}

interface stateTypes {
  selectedDepartureDate?: any;
  selectedArrivalDate?: any;
  selectedRegNo?: any;
  selectedAuthorized?: any;
  selectedFromCountry?: any;
  selectedToCountry?: any;
  selectedFromRegion?: any;
  selectedToRegion?: any;
  selectedFromLocation?: any;
  selectedToLocation?: any;
  selectedStartFuelLevel?: any;
  selectedEndFuelLevel?: any;
  remarks?: string;
  active?: boolean;
  refresh?: boolean;
  purpose?: any;
  startOdometer?: any;
  endOdometer?: any;
  deactivateFields?: boolean;
  showUpdateButton?: boolean;
  showCancelButton?: boolean;
  modalState?: any;
  confirmState?: any;
  successModal?: any;
  selectedItemId?: number;
  vlbAuthorised?: boolean;
  vlbIdpk?: any;
  authorisedByEmpIdfk?: any;
  isOpenDropdown?: boolean;
  isLoading?: boolean;
  isOpenDropdown1?: boolean;
  isLoading1?: boolean;
  isOpenDropdown2?: boolean;
  isLoading2?: boolean;
  isOpenDropdown3?: boolean;
  isLoading3?: boolean;
  isOpenDropdown4?: boolean;
  isLoading4?: boolean;
  isOpenDropdown5?: boolean;
  isLoading5?: boolean;
}

const VehicleLogDetails = ({ toggled, setToggled, setShowTable }: props) => {
  const news_flash_height = useSelector(
    (state: RootState) => state.general.newsflashHeight
  );
  const baseUrl = "AsmTmsVehicleLogBook/"; // Dropdown baseUrl

  const [empID, setEmpID] = useState<number>(2);

  const disabled = useSelector(
    (state: RootState) => state.general.formDisabled
  );
  const updateMode = useSelector(
    (state: RootState) => state.general.updateMode
  );
  const borderTheme = useSelector(
    (state: RootState) => state.general.cssBorderClass
  );
  const selected = useSelector(
    (state: RootState) => state.general.selectedFieldData
  );

  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const dispatch = useDispatch();
  const approveOfficerdropdownRef = useRef<any>({});
  const vehicleRef = useRef<any>({});

  const [states, setState] = useState<stateTypes>({
    vlbIdpk: "",
    vlbAuthorised: false,
    authorisedByEmpIdfk: "",
    active: true,
    remarks: "",
    selectedDepartureDate: currentDate,
    selectedArrivalDate: currentDate,
    selectedRegNo: { id: 0, name: "" },
    selectedToCountry: { id: 0, name: "" },
    selectedAuthorized: { id: 0, name: "" },
    selectedFromCountry: { id: 0, name: "" },
    selectedFromRegion: { id: 0, name: "" },
    selectedToRegion: { id: 0, name: "" },
    selectedFromLocation: { id: 0, name: "" },
    selectedToLocation: { id: 0, name: "" },
    selectedStartFuelLevel: { id: 0, name: "" },
    selectedEndFuelLevel: { id: 0, name: "" },
    purpose: "",
    startOdometer: "",
    endOdometer: "",
    modalState: {
      state: false,
      title: "",
      message: "",
    },
    successModal: {
      state: false,
      title: "",
      message: "",
    },
    confirmState: {
      state: false,
      title: "",
      message: "",
      okText: "",
      cancelText: "",
      event: () => {},
    },
    refresh: false,
    deactivateFields: true,
    showUpdateButton: false,
    showCancelButton: false,
    selectedItemId: 0,
    isOpenDropdown: false,
    isLoading: false,
    isOpenDropdown1: false,
    isLoading1: false,
    isOpenDropdown2: false,
    isLoading2: false,
    isOpenDropdown3: false,
    isLoading3: false,
    isOpenDropdown4: false,
    isLoading4: false,
    isOpenDropdown5: false,
    isLoading5: false,
  });
  const startAndFinalOdometerDiff = states.endOdometer - states.startOdometer;
  const singleEndPoint1: any = fetchToCountry;
  const singleEndPoint: any = fetchFromCountry;
  const singleEndPoint2: any = fetchFromRegion;
  const singleEndPoint3: any = fetchToRegion;
  const singleEndPoint4: any = fetchFromLocation;
  const singleEndPoint5: any = fetchToLocation;
  // update the start odometer in the state with  its initial type as string
  // then we convert the value to number in the endpoint.
  // we check for the value if it's not a number. then we throw an error
  const [lookups, lookupsErr, lookupsLoading] = useFetch(
    `AsmTmsVehicleLogBook/VehileLogBookLookup/VehicleLogBookLookUp?pagesize=20&pagenumber=1&Search=`
  );

  console.log(lookups, "lookups");

  console.log("@#$", states);

  // const [fromRegions, setFromRegion] = useState<any>([]);
  // const [toRegions, setToRegions] = useState<any>([]);
  //const [fromLocations, setFromLocations] = useState<any>([]);
  //const [toLocations, setToLocations] = useState<any>([]);

  const [refresh_RegNo, setRefresh_RegNo] = useState(false);

  const [refresh_AuthBy, setRefresh_Authby] = useState(false);
  // Use fetch
  const [regNo, regNoErr, regNoLoading] = useFetch(
    baseUrl.concat("GetAllVehicleRegisterationNumbers"),
    refresh_RegNo
  );

  const [authorizedBy, authorizedErr, authorizedLoading] = useFetch(
    baseUrl.concat("GetAllVehicleAutherisers"),
    refresh_AuthBy
  );

  // const [countryFrom, countryFromErr, countryFromLoading] = useFetch(
  //   baseUrl.concat("GetVehicleDepartureCountry")
  // );

  // const [countryTo, countryToErr, countryToLoading] = useFetch(
  //   baseUrl.concat("GetVehicleDestinationCountry")
  // );
  // const [regionFrom, regionFromErr, regionFromLoading] = useFetch(
  //   baseUrl.concat(
  //     `GetVehicleDepatureRegion?CtyIDpk=${states.selectedFromCountry.id}&RgnActive=true`
  //   )
  // );
  // const [toRegions, regionToErr, regionToLoading] = useFetch(
  //   baseUrl.concat(
  //     `GetVehicleDestinationRegion?CtyIDpk=${states.selectedToCountry.id}&RgnActive=true`
  //   )
  // );
  // const [fromLocations, fromLocationsErr, fromLocationsLoading] = useFetch(
  //   baseUrl.concat(
  //     `GetVehicleDepartureLocation?FromRgnID=${states.selectedFromRegion.id}&FromCountryID=${states.selectedFromCountry.id}`
  //   )
  // );
  // const [toLocations, toLocationsErr, toLocationsLoading] = useFetch(
  //   baseUrl.concat(
  //     `GetVehicleDestinationLocation?ToRgnCtyID=${states.selectedToCountry.id}&ToRgnID=${states.selectedToRegion.id}`
  //   )
  // );
  const [fuelLevels, fuelLevelsErr, fuelLevelLoading] = useFetch(
    "AsmTmsVehicleLogBook/GetAllFuelLevels"
  );
  const [
    GetCurrentVehicleAssignmentID,
    currentVehicleAssignmentErr,
    currentVehicleAssignmentloading,
  ] = useFetch(
    `AsmTmsVehicleLogBook/GetCurrentVehicleAssignmentID?VdtRegNo=${
      states.selectedRegNo.name || selectedRecord?.vdtRegNo
    }`
  );
  const updateState = (stateName: string, value: any) => {
    setState((currentState: any) => ({ ...currentState, [stateName]: value }));
  };
  const DataGridSelectBox = [
    {
      id: 0,
      label: "Vehicle reg. No",
      width: 220,
      labelCol: 7,
      columnData: vehicle_log_book_Reg_No,
      gridData: regNo,
      defaultValue: states.selectedRegNo.name,
      stateName: "selectedRegNo",
      stateField_id: "vdtIdpk",
      statefield_name: "vdtRegNo",
      refreshState: () => {
        updateState("selectedRegNo", { id: "", name: "" });
        setRefresh_RegNo(!refresh_RegNo);
      },
    },
    {
      id: 1,
      label: "Authorised By",
      width: 220,
      labelCol: 7,
      columnData: vehicle_log_book_Authorized_By,
      gridData: authorizedBy,
      defaultValue: states.selectedAuthorized.name,
      stateName: "selectedAuthorized",
      stateField_id: "empIdpk",
      statefield_name: "empName",
      refreshState: () => {
        updateState("selectedAuthorized", { id: "", name: "" });
        setRefresh_Authby(!refresh_AuthBy);
      },
    },
  ];

  //api calls
  const from_region = useFetch(
    `AsmTmsVehicleLogBook/GetVehicleDepatureRegion?CtyIDpk=${states.selectedFromCountry.id}&RgnActive=true&pagesize=20&pagenumber=1`
  );
  const to_region = useFetch(
    `AsmTmsVehicleLogBook/GetVehicleDestinationRegion?CtyIDpk=${states.selectedToCountry.id}&CtyActive=true&pagesize=20&pagenumber=1`
  );
  const from_location = useFetch(
    `AsmTmsVehicleLogBook/GetVehicleDepartureLocation?ToRgnCtyID=${states.selectedFromCountry.id}&FromRgnID=${states.selectedFromRegion.id}&PageSize=20&PageNumber=1`
  );
  const to_location = useFetch(
    `AsmTmsVehicleLogBook/GetVehicleDestinationLocation?ToRgnCtyID=${states.selectedToCountry.id}&ToRgnID=${states.selectedToRegion.id}&pagesize=20&pagenumber=1`
  );

  const fromCountryData = useSelector(
    (state: RootState) => state.vehicleLogBook.fromCountryData
  );
  const toCountryData = useSelector(
    (state: RootState) => state.vehicleLogBook.toCountryData
  );
  const fromRegionData = useSelector(
    (state: RootState) => state.vehicleLogBook.fromRegionData
  );
  const toRegionData = useSelector(
    (state: RootState) => state.vehicleLogBook.toRegionData
  );
  const fromLocationData = useSelector(
    (state: RootState) => state.vehicleLogBook.fromLocationData
  );
  const toLocationData = useSelector(
    (state: RootState) => state.vehicleLogBook.toLocationData
  );
  console.log("fromCountryData", fromCountryData);
  //Code to fix error on clicking order fields
  const sFuelLevel = document.getElementById("startOdometerId");

  sFuelLevel?.addEventListener("mouseenter", function () {
    this.focus();
  });

  //Disable focus on mouse out. 🎉
  sFuelLevel?.addEventListener("mouseout", function () {
    this.blur();
  });

  //Code to fix error on clicking order fields
  const eFuelLevel = document.getElementById("finalOdometerId");

  eFuelLevel?.addEventListener("mouseenter", function () {
    this.focus();
  });

  //Disable focus on mouse out. 🎉
  eFuelLevel?.addEventListener("mouseout", function () {
    this.blur();
  });

  // const fetchRegions = (id: any) => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${api_url}/${baseUrl}GetVehicleDepatureRegion?CtyIDpk=${id}&RgnActive=true`,
  //         {
  //           headers: {
  //             "Content-Type": [
  //               "application/json",
  //               "text/plain",
  //               "charset=utf-8",
  //             ],
  //           },
  //         }
  //       );
  //       setFromRegion(res.data);
  //     } catch (error: any) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // };

  let [resizeMode, initialResizeMode] = useResizeMode(!!toggled);
  const heights_out = navbar_height + news_flash_height + toolbar_height;

  //varying form height
  const form_height = 490;

  //Table Height
  var pageHeight = resizeMode - (navbar_height + form_height + 40); // form + datagrid
  var tableHeight = initialResizeMode - (heights_out + 15); // datagrid

  const [tableWidth, setTableWidth] = useState(window.innerWidth); //height without navbar and toolbar
  const [table_form_Height, settable_form_Height] = useState(
    window.innerHeight - heights_out
  ); //height without navbar and toolbar and form

  const [PostDataFunc, UpdateDataFunc] = useCrudFunc();
  const [BusyLoader, setBusyLoader] = useBusyLoader();
  // const [showTable, setShowTable] = useState(true);
  const dateFormat = "ddd, DD MMM YYYY HH: mm:ss";
  const postData = async () => {
    updateState("confirmState", {
      state: true,
      title: "Save log?",
      okText: "Yes",
      cancelText: "No",
      message: "Are you sure you want to save this log?",
      event: () => {
        updateState("confirmState", { state: false });
        setBusyLoader("Saving log...");
        PostDataFunc(
          `${baseUrl}CreateAsmTmsVehicleLogBook`,
          {
            // vlbVehicleAssignIdfk: empID,
            vdtRegNo: states.selectedRegNo?.name,
            vlbTimeIn: states.selectedArrivalDate,
            vlbTimeOut: states.selectedDepartureDate,
            vlbOdometerStart: Number(states.startOdometer),
            vlbOdometerEnd: Number(states.endOdometer),
            vlbToRegionIdfk: states.selectedToRegion.id,
            vlbFromRegionIdfk: states.selectedFromRegion.id,
            vlbFromTown: states.selectedFromLocation.id,
            vlbToTown: `${states.selectedToLocation.id}`,
            vlbAuthorisedByEmpIdfk: states.selectedAuthorized.id,
            vlbDescription: states.purpose,
            vlbStartFuelLevelIdfk: states.selectedStartFuelLevel.id,
            vlbFinalFuelLevelIdfk: states.selectedEndFuelLevel.id,
            vlbActive: states.active,
            vlbRmks: states.remarks,
            vlbCreatorEmpIdfk: empID,
          },
          `Created the Asset Mgr-Transport-Vehicle Log Book with 
        Id:${states.authorisedByEmpIdfk} as authoriser for vehicle with Reg. No.:${states.selectedRegNo.name},Time out:${states.selectedDepartureDate},
        Time In:${states.selectedArrivalDate},Authorized by:${states.selectedAuthorized.name},from the country:${states.selectedFromCountry.name},
        to the country:${states.selectedToCountry.name},from the region:${states.selectedFromRegion.name},to the region:${states.selectedToRegion.name},
        from location:${states.selectedFromLocation.name},to location:${states.selectedToLocation.name}, start fuel level:${states.selectedStartFuelLevel.name},
        end fuel level:${states.selectedEndFuelLevel.name}, start Odometer:${states.startOdometer} and end odometer:${states.endOdometer}
        `
        )
          .then((response) => {
            const success: boolean = response.data.success;
            const statusCode: number = response.data.statusCode;
            if (!success && statusCode !== 200) {
              const errors = response.data.errors;
              const message = response.data.message;
              console.log("@#$ 1", errors);
              console.log("@#$ 1", errors);
              updateState("modalState", {
                state: true,
                title: `${message}`,
                message: `${errors}`,
              });
              setBusyLoader("");
            } else {
              console.log(response);
              // resetFields();

              setRefresh(!refresh);
              setBusyLoader("");
              updateState("successModal", {
                state: true,
                title: "Success!",
                message: "Vehicle log saved successfully.",
              });
              dispatch(
                setGeneralValue({
                  expr: "formDisabled",
                  value: true,
                })
              );
              updateState("deactivateFields", true);
              // setRefresh(!refresh);
              setSelectedRecord({ states });
            }
          })
          .catch((errors) => {
            console.log("@#$ 2", errors);
            updateState("modalState", {
              state: true,
              title: "Log already exist.",
              // message: "please see the systems administrator for assistance",
              message: "Vehicle has already been assigned.",
            });
            setBusyLoader("");
          });
      },
    });
  };

  const updateData = async () => {
    updateState("confirmState", {
      state: true,
      title: "Update?",
      message: "Are you sure you want to update log?",
      okText: "Yes",
      cancelText: "No",
      event: () => {
        updateState("confirmState", { state: false });
        setBusyLoader("Updating log...");
        UpdateDataFunc(
          `${baseUrl}UpdateAsmTmsVehicleLogBook/${states.selectedItemId}`,
          {
            vdtRegNo: states.selectedRegNo?.name,
            vlbTimeIn: states.selectedArrivalDate,
            vlbTimeOut: states.selectedDepartureDate,
            vlbOdometerStart: Number(states.startOdometer),
            vlbOdometerEnd: Number(states.endOdometer),
            vlbToRegionIdfk: states.selectedToRegion.id,
            vlbFromRegionIdfk: states.selectedFromRegion.id,
            vlbFromTown: states.selectedFromLocation.name,
            vlbToTown: states.selectedToLocation.name,
            vlbAuthorisedByEmpIdfk: states.selectedAuthorized.id,
            vlbDescription: states.purpose,
            vlbStartFuelLevelIdfk: states.selectedStartFuelLevel.id,
            vlbFinalFuelLevelIdfk: states.selectedEndFuelLevel.id,
            vlbRmks: states.remarks,
          },
          `Edited the Asset Mgr-Transport-Vehicle Log Book with 
            ID:${states.selectedItemId}, Id:${states.authorisedByEmpIdfk} as authoriser for vehicle with Reg. No.:${states.selectedRegNo.name}, time out:${states.selectedDepartureDate},
            time in:${states.selectedArrivalDate},Authorized by:${states.selectedAuthorized.name},from the country:${states.selectedFromCountry.name},
            to the country:${states.selectedToCountry.name},from the region:${states.selectedFromRegion.name},to the region:${states.selectedToRegion.name},
            from location:${states.selectedFromLocation.name},to location:${states.selectedToLocation.name}, start fuel level:${states.selectedStartFuelLevel.name},
            end fuel level:${states.selectedEndFuelLevel.name}, start Odometer:${states.startOdometer} and end odometer:${states.endOdometer}
          `
        )
          .then((response) => {
            const success: boolean = response.data.success;
            const statusCode: number = response.data.statusCode;
            if (!success && statusCode == 400) {
              const errors = response.data.errors;
              console.log(errors);
              setBusyLoader("");
              updateState("modalState", {
                state: true,
                title: "Record not updated",
                message: "please see the systems administrator for assistance.",
              });
            } else {
              console.log(response);
              setBusyLoader("");
              updateState("successModal", {
                state: true,
                title: "Success!",
                message: "Record updated successfully.",
              });
              setRefresh(!refresh);
            }
          })
          .catch((err) => {
            console.log(err);
            setBusyLoader("");
            updateState("modalState", {
              state: true,
              title: "Record not updated.",
              message: "please see the systems administrator for assistance.",
            });
          });
      },
    });
  };

  const checkVehicleAssignmentBeforeSavingData = () => {
    if (GetCurrentVehicleAssignmentID === 0) {
      updateState("modalState", {
        state: true,
        title: "Invalid assignment",
        message:
          "The vehicle selected has not been assigned yet. Please contact your transport officer for assistance.",
      });
    } else {
      postData();
    }
  };
  const validateSaveRecord = async (method: string) => {
    if (states.selectedRegNo.id === 0) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select vehicle registration number.",
      });
      return;
    }
    if (states.selectedAuthorized.id === 0) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select personnel authorized by.",
      });
      return;
    }
    // alert(dayjs(states.selectedArrivalDate).isSame(states.selectedDepartureDate))
    if (
      dayjs(states.selectedArrivalDate).isSame(states.selectedDepartureDate)
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Arrival time cannot be the same as departure time.",
      });
      return;
    }
    // alert(dayjs(states.selectedDepartureDate) > dayjs(states.selectedArrivalDate))
    if (
      dayjs(states.selectedDepartureDate) > dayjs(states.selectedArrivalDate)
    ) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Departure time cannot be greater than arrival time.",
      });
      return;
    }

    if (states.startOdometer === "" || isNaN(Number(states.startOdometer))) {
      updateState("modalState", {
        state: true,
        message:
          states.startOdometer === ""
            ? "Please enter start odometer."
            : "Please start odometer should be a number.",
        title: "Required field",
      });
      return;
    }
    if (isNaN(Number(states.startOdometer))) {
      updateState("modalState", {
        state: true,
        message: "Please enter a valid number for the start odometer.",
        title: "Required field",
      });
      return;
    }
    if (isNaN(Number(states.endOdometer))) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Final odometer required.",
      });
      return;
    }
    if (states.selectedFromCountry.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select From-Country.",
      });
      return;
    }
    if (states.selectedToCountry.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select To-Country.",
      });
      return;
    }

    if (states.selectedFromRegion.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select From-Region.",
      });
      return;
    }

    if (states.selectedToRegion.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select To-Region.",
      });
      return;
    }

    if (states.selectedFromLocation.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select From-Location.",
      });
      return;
    }

    if (method === "put" && states.selectedItemId === 0) {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select an item to update.",
      });
      return;
    }
    if (states.selectedToLocation.name === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please select To-Location.",
      });
      return;
    }
    if (states?.purpose === "") {
      updateState("modalState", {
        state: true,
        title: "Required field",
        message: "Please enter the purpose.",
      });
      return;
    }
    if (method === "post") {
      return checkVehicleAssignmentBeforeSavingData();
    } else if (method === "put") {
      return updateData();
    }
  };

  console.log("seeeeee: ", selected);

  //set on row click
  const rowClicked = (data: any) => {
    console.table(data);
    dispatch(setGeneralValue({ expr: "formDisabled", value: true }));
    updateState("active", data?.vlbActive);
    updateState("purpose", data?.vlbDescription);
    updateState("remarks", data?.vlbRmks);
    updateState("startOdometer", data?.vlbOdometerStart);
    updateState("endOdometer", data?.vlbOdometerEnd);
    updateState("selectedArrivalDate", data?.vlbTimeIn);
    updateState("selectedDepartureDate", data?.vlbTimeOut);
    updateState("selectedItemId", data?.vlbIdpk);
    updateState("authorisedByEmpIdfk", data?.authorisedByEmpIdfk);
    updateState("vlbAuthorised", data?.vlbAuthorised);
    updateState("vlbIdpk", data?.vlbIdpk);

    updateState("selectedStartFuelLevel", {
      id: data?.startFuelLevelId,
      name: "",
    });

    updateState("selectedEndFuelLevel", {
      id: data?.finalFuelLevelId,
      name: "",
    });

    updateState("selectedFromRegion", {
      id: data?.fromRegionId,
      name: data?.fromRegionName,
    });

    updateState("selectedFromLocation", {
      id: data?.vlbFromTown,
      name: data?.vlbFromTown,
    });

    updateState("selectedToRegion", {
      id: data?.toRegionId,
      name: data?.toRegionName,
    });

    updateState("selectedToLocation", {
      id: data?.vlbToTown,
      name: data?.vlbToTown,
    });

    updateState("selectedRegNo", {
      id: data?.["vdtIdpk"],
      name: data?.["vdtRegNo"],
    });
    updateState("selectedAuthorized", {
      id: data?.["authorisedByEmpIdfk"],
      name: data?.["authorisedByEmpName"],
    });
    updateState("selectedFromCountry", {
      id: data?.["fromCountryId"],
      name: data?.["fromCountryName"],
    });

    updateState("selectedToCountry", {
      id: data?.["toCountryId"],
      name: data?.["toCountryName"],
    });
  };

  // Reset fields
  const resetFields = () => {
    updateState("remarks", "");
    updateState("startOdometer", "");
    updateState("endOdometer", "");
    updateState("purpose", "");
    updateState("active", true);
    updateState("selectedDepartureDate", dayjs());
    updateState("selectedArrivalDate", dayjs());
    updateState("selectedRegNo", { id: 0, name: "" });
    updateState("selectedToCountry", { id: 0, name: "" });
    updateState("selectedAuthorized", { id: 0, name: "" });
    updateState("selectedFromCountry", { id: 0, name: "" });
    updateState("selectedToLocation", { id: 0, name: "" });
    updateState("selectedFromLocation", { id: 0, name: "" });
    updateState("selectedToRegion", { id: 0, name: "" });
    updateState("selectedFromRegion", { id: 0, name: "" });
    updateState("selectedStartFuelLevel", { id: 1, name: "" });
    updateState("selectedEndFuelLevel", { id: 1, name: "" });
    updateState("selectedItemId", 0);
  };

  useEffect(() => {
    console.log(`inside ${toggled}`);
  }, [toggled]);

  useEffect(() => {
    if (!disabled && !updateMode) {
      updateState("deactivateFields", false);
      updateState("showCancelButton", true);
      // resetFields();
    }
    if (disabled) {
      updateState("deactivateFields", true);
      updateState("showUpdateButton", false);
      updateState("showCancelButton", false);
    }
    if (updateMode) {
      updateState("deactivateFields", false);
      updateState("showUpdateButton", true);
      updateState("showCancelButton", true);
    }
  }, [disabled, updateMode]);

  // const closeGrid1 = () => {
  //   approveOfficerdropdownRef.current!.instance.close();
  // };
  // const closeGrid2 = () => {
  //   vehicleRef.current!.instance.close();
  // };

  // const dataGridRender = (
  //   data: any,
  //   columnHeader: any,
  //   callBackFunction: any
  // ) => {
  //   return (
  //     <DataGrid
  //       height={"100%"}
  //       style={{ maxHeight: "200px", maxWidth: "1000px" }}
  //       width={"100%"}
  //       columnWidth={80}
  //       columnResizingMode={"widget"}
  //       showColumnLines={true}
  //       showRowLines={true}
  //       onRowClick={(e: any) => {
  //         callBackFunction(e.data);
  //         console.log(e.data);
  //       }}
  //       onRowDblClick={() => {}}
  //       wordWrapEnabled={false}
  //       allowColumnResizing={true}
  //       dataSource={data}
  //     >
  //       <Pager visible={false} />
  //       {columnHeader?.map((reqBy: any) => {
  //         return (
  //           <Column
  //             key={reqBy.id}
  //             dataType={reqBy.dataType}
  //             caption={reqBy.caption}
  //             dataField={reqBy.dataField}
  //             alignment="left"
  //             visible={reqBy.visible}
  //             width={reqBy.width === null ? 150 : reqBy.width}
  //             fixed={reqBy.fixed}
  //           />
  //         );
  //       })}
  //     </DataGrid>
  //   );
  // };

  const [searchText, setSearchText] = useState<{ temp: string; text: string }>({
    temp: "",
    text: "",
  });
  const [startDate, setStartDate] = useState<{ temp: string; index: string }>(
    // dayjs(startingDate).format("YYYY-MM-DD")
    {
      temp: dayjs(startingDate).format("YYYY-MM-DD"),
      index: dayjs(startingDate).format("YYYY-MM-DD"),
    }
  );
  const [endDate, setEndDate] = useState<{ temp: string; index: string }>({
    temp: dayjs(currentDate).format("YYYY-MM-DD"),
    index: dayjs(currentDate).format("YYYY-MM-DD"),
  });
  const [searchCriteria, setSearchCriteria] = useState<{
    temp: number;
    index: number;
  }>({ temp: 0, index: 0 });
  const [active, setActive] = useState<{
    temp: string | boolean;
    status: string | boolean;
  }>({ temp: "", status: "" });
  const [refresh, setRefresh] = useState(false);
  const [paging, setPaging] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 1, // backend having issue with their offset counting, it should start from 0 but they started with 1 instead
    pageSize: 15,
  });
  const [defaultGridData, error, loading] = useFetch(
    //AsmTmsVehicleLogBook/GetAllAsmTmsVehicleLogBook?SearchCriteriaIndex=${searchCriteria.index}&SearchText=${searchText.text}&TimeOut=${startDate.index}&TimeIn=${endDate.index}&PageSize=20&PageNumber=1
    `AsmTmsVehicleLogBook/GetAllAsmTmsVehicleLogBook?SearchCriteriaIndex=${searchCriteria.index}&SearchText=${searchText.text}&TimeOut=${startDate.index}&TimeIn=${endDate.index}&Active=${active.status}&PageSize=${paging.pageSize}&PageNumber=${paging.pageNumber}`,
    refresh,
    "vlbIdpk"
  );

  const [employeeId, userId, userInfo] = useAccountInfo();
  const [privType, setPrivType] = useState<string>("save");

  const [savePriv, readPriv, updatePriv] = useFormPriviledge(privType);
  const AccessPriv_on_NewButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      updateState("deactivateFields", false);
      updateState("showCancelButton", true);
      resetFields();
      dispatch(setGeneralValue({ expr: "formDisabled", value: false })); //disable form
      dispatch(setGeneralValue({ expr: "updateMode", value: false })); //disable update mode
      //clear data to redux
      dispatch(setGeneralValue({ expr: "selectedFieldData", value: [] }));
      setSelectedRecord({});
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege.",
      });
    }
  };
  const AccessPriv_on_SaveButton = () => {
    setPrivType("save");
    if (savePriv === true) {
      validateSaveRecord("post");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege.",
      });
    }
  };

  const AccessPriv_on_UpdateButton = () => {
    setPrivType("update");
    if (updatePriv === true) {
      validateSaveRecord("put");
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege.",
      });
    }
  };

  // const AccessPriv_on_FinalOdo_UpdateButton = () => {
  //   if (states.endOdometer === "" || isNaN(Number(states.endOdometer))) {
  //     updateState("modalState", {
  //       state: true,
  //       title: "Required field.",
  //       message:
  //         "Please final odometer reading can not be empty and should be a number.",
  //     });
  //     return;
  //   }

  //   setPrivType("update");
  //   if (updatePriv === true) {

  //     if (isNaN(Number(states.startOdometer)) === false) {
  //       const res = UpdateDataFunc(
  //         `AsmTmsVehicleLogBook/UpdateFinalOdometerReading?vlbIDpk=${states?.vlbIdpk}&userID=${userId}&regNo=${states.selectedRegNo.name}`,
  //         {},
  //         `Updated Final OdoMeter with a start odometer : ${states.startOdometer} and Final Odometer : ${states.endOdometer}
  //          with  userId: ${userId} and registration number : ${states.selectedRegNo.name} `
  //       ).then((res) => {
  //         updateState("modalState", {
  //           state: true,
  //           title: "Access Denied!",
  //           message: `${res.data.message}`,
  //         });
  //       });
  //     }
  //   } else if (isNaN(Number(states.startOdometer)) === false) {
  //     updateState("modalState", {
  //       state: true,
  //       title: "Start odometer",
  //       message: "Please enter a valid number.",
  //     });
  //   } else {
  //     updateState("modalState", {
  //       state: true,
  //       title: "Access Denied!",
  //       message: "No Access Privilege.",
  //     });
  //   }
  // };

  const AccessPriv_on_DataGridDoubleClick = (selected_Value: any) => {
    setPrivType("update");
    setShowTable && setShowTable(true);
    if (updatePriv === true) {
      if (selected_Value?.vlbAuthorised) {
        updateState("modalState", {
          state: true,
          title: "Access Denied!",
          message: "This log has been authorized. You cannot edit it.",
        });
        dispatch(setGeneralValue({ expr: "formDisabled", value: true }));

        //enable Update Mode
        dispatch(setGeneralValue({ expr: "updateMode", value: false }));
      } else {
        dispatch(setGeneralValue({ expr: "formDisabled", value: false }));

        //enable Update Mode
        dispatch(setGeneralValue({ expr: "updateMode", value: true }));
      }
    } else {
      updateState("modalState", {
        state: true,
        title: "Access Denied!",
        message: "No Access Privilege.",
      });
    }
  };

  const [employeeID] = useAccountInfo();

  console.log("active status: ", active);

  const Delete_Log = async () => {
    try {
      updateState("active", false);
      await UpdateDataFunc(
        `AsmTmsVehicleLogBook/DeleteAsmTmsVehicleLogBook?vlbIDpk=${states.vlbIdpk}`,
        {},
        `Deleted log with  ID:${states.selectedItemId}, Id:${states.authorisedByEmpIdfk} as authoriser for vehicle with Reg. No.:${states.selectedRegNo.name}, time out:${states.selectedDepartureDate},
            time in:${states.selectedArrivalDate},Authorized by:${states.selectedAuthorized.name},from the country:${states.selectedFromCountry.name},
            to the country:${states.selectedToCountry.name},from the region:${states.selectedFromRegion.name},to the region:${states.selectedToRegion.name},
            from location:${states.selectedFromLocation.name},to location:${states.selectedToLocation.name}, start fuel level:${states.selectedStartFuelLevel.name},
            end fuel level:${states.selectedEndFuelLevel.name}, start Odometer:${states.startOdometer} and end odometer:${states.endOdometer}`
      )
        .then(() => {
          updateState("successModal", {
            state: true,
            title: "Log Deleted!",
            okText: "Ok",
            message: "Log Deleted Successfully.",
          });
          setRefresh(!refresh);
        })
        .catch(() => {
          updateState("modalState", {
            state: true,
            title: "Log not Deleted!",
            // okText: "Ok",
            message:
              "Log is not Deleted. Try contacting the systems administrator.",
          });
        });
    } catch (eer) {
      updateState("modalState", {
        state: true,
        title: "Log not Deleted!!",
        // okText: "Ok",
        message: "Log is not Deleted ",
      });
    }
  };

  const Undo_Deleted_Log = async () => {
    try {
      updateState("active", true);
      await UpdateDataFunc(
        `AsmTmsVehicleLogBook/UndoDeleteAsmTmsVehicleLogBook?vlbIDpk=${states.vlbIdpk}`,
        {},
        `Deleted Log with id = ${states.vlbIdpk}`
      )
        .then(() => {
          updateState("successModal", {
            state: true,
            title: "Log Restored!",
            okText: "Ok",
            message: "Deleted log restored successfully.",
          });
          setRefresh(!refresh);
        })
        .catch(() => {
          updateState("modalState", {
            state: true,
            title: "Log not Restored!",
            okText: "Ok",
            message: "Please contact to systems Administrator for assistance.",
          });
        });
    } catch (eer) {
      updateState("modalState", {
        state: true,
        title: "Log not Restored!",
        okText: "Ok",
        message: "Please contact to systems Administrator for assistance.",
      });
    }
  };

  const AuthorizeLog = async () => {
    if (selected.vlbActive === false) {
      updateState("modalState", {
        state: true,
        title: "Access Denied",
        message: "This log has been Deleted. You cannot authorise it ",
      });
    } else {
      updateState("confirmState", {
        state: true,
        title: "UnAuthorize log?",
        message: "Are you sure you want to Unauthorize this log ?",
        okText: "Yes",
        cancelText: "No",
        event: async () => {
          updateState("confirmState", { state: false });
          await UpdateDataFunc(
            `AsmTmsVehicleLogBook/ApproveAsmTmsVehicleLogBook?vlbAuthorisedByEmpIDfk=${
              states.authorisedByEmpIdfk
            }&vlbAuthorised=${false}&vlbDateAuthorised=${dayjs(
              Date.now()
            ).format("YYYY-MM-DD")}&vlbIDpk=${states.vlbIdpk}`,
            {
              vlbAuthorisedByEmpIDfk: states.authorisedByEmpIdfk,
              vlbAuthorised: false,
              vlbDateAuthorised: dayjs(Date.now()).format("YYYY-MM-DD"),
              vlbIDpk: states.vlbIdpk,
            },
            `Authorized log with id=${states.vlbIdpk}, date Authorized=${currentDate} and authorized by employee Id = ${states.authorisedByEmpIdfk}`
          )
            .then(() => {
              updateState("successModal", {
                state: true,
                title: "Success!",
                message: "Log unauthorised successfully.",
              });
              setRefresh(!refresh);
            })
            .catch(() => {
              updateState("modalState", {
                state: true,
                title: "Log not authorised.",
                message: "Please contact systems administrator.",
              });
            });
        },
      });
    }
  };
  const [openOdometerReadingModal, setOpenOdometerReadingModal] =
    useState<boolean>(false);
  console.log("abcd", selected);
  return (
    <>
      {error && <WarningAlert />}
      {loading && <CustomLoader />}
      <FormModal
        title={"Transport - Update Odometer Reading"}
        child={
          <OdometerModal
            refreshAction={() => {
              setRefresh(!refresh);
            }}
            selectedRecord={selectedRecord}
            openModal={openOdometerReadingModal}
            action={(e: any) => {
              setOpenOdometerReadingModal(e);
            }}
          />
        }
        width={"500px"}
        openState={openOdometerReadingModal}
        onCancel={() => {
          setOpenOdometerReadingModal(false);
        }}
      />
      {
        <>
          <div className="">
            <div className=" ">
              <EntryCustomToolbar
                searchCriteriaOnchange={(val) => {
                  setSearchCriteria({ temp: val, index: searchCriteria.index });
                }}
                searchCriteria={true}
                searchCriteriaData={[
                  { id: 0, value: "Reg No" },
                  { id: 1, value: "Logged By" },
                  { id: 2, value: "Authorized By" },
                  { id: 3, value: "Odometer Start" },
                  { id: 4, value: "Odometer End" },
                  { id: 5, value: "From Region" },
                  { id: 6, value: "From Town/Loc" },
                  { id: 7, value: "To Region" },
                  { id: 8, value: "To Town/Loc" },
                  { id: 9, value: "Description" },
                ]}
                outerSelected={selectedRecord}
                setOuterSelected={setSelectedRecord}
                handlePrint={() => {
                  //do something
                  console.log("Do something");
                }}
                resetFields={() => {
                  resetFields();
                }}
                handleRefresh={() => {
                  setSearchCriteria({
                    temp: 0,
                    index: 0,
                  });
                  setSearchText({
                    temp: " ",
                    text: " ",
                  });
                  setActive({ temp: " ", status: " " });
                  setStartDate({
                    temp: dayjs(startingDate).format("YYYY-MM-DD"),
                    index: dayjs(startingDate).format("YYYY-MM-DD"),
                  });
                  setEndDate({
                    temp: dayjs(currentDate).format("YYYY-MM-DD"),
                    index: dayjs(currentDate).format("YYYY-MM-DD"),
                  });
                  setRefresh(!refresh);
                }}
                checkOnChange={(value: boolean) => {
                  setActive({ temp: value, status: active.status });
                }}
                searchTextOnchange={(text: string) => {
                  setSearchText({ temp: text, text: searchText.text });
                }}
                handleFind={() => {
                  setSearchCriteria({
                    temp: searchCriteria.temp,
                    index: searchCriteria.temp,
                  });
                  setSearchText({
                    temp: searchText.temp,
                    text: searchText.temp,
                  });
                  setActive({ temp: active.temp, status: active.temp });
                  setStartDate({ temp: startDate.temp, index: startDate.temp });
                  setEndDate({ temp: endDate.temp, index: endDate.temp });
                  setRefresh(!refresh);
                }}
                withDates={true}
                startDateChanged={(startDateV) => {
                  setStartDate({
                    temp: dayjs(startDateV).format("YYYY-MM-DD"),
                    index: startDate.index,
                  });
                }}
                endDateChanged={(endDateV) => {
                  setEndDate({
                    temp: dayjs(endDateV).format("YYYY-MM-DD"),
                    index: endDate.index,
                  });
                }}
                startDate={startDate.temp}
                endDate={endDate.temp}
                handleSave={() => {}}
                handleUpdate={() => {
                  if (selectedRecord?.vlbAuthorised) {
                    updateState("modalState", {
                      state: true,
                      title: "Access Denied!",
                      message:
                        "This log has been authorized. You cannot edit it.",
                    });
                    dispatch(
                      setGeneralValue({ expr: "formDisabled", value: true })
                    );
                    //enable Update Mode
                    dispatch(
                      setGeneralValue({ expr: "updateMode", value: false })
                    );
                  }
                }}
                handleDelete={() => {}}
                toggler={() => {
                  setToggled!();
                }}
                setVisible={() => {
                  setShowTable && setShowTable(true);
                }}
                setShowGrid={() => {
                  setShowTable!(true);
                }}
                setIsActive={(activeStatus) => {
                  setActive({ temp: activeStatus, status: active.status });
                }}
                additionalComponentsAfter={
                  <>
                    <li
                      id="deleteLog"
                      onClick={async () => {
                        if (selected.loggedByEmpIdfk !== employeeID) {
                          updateState("modalState", {
                            state: true,
                            title: "Access Denied!",
                            message:
                              "You did not create this log. You are not allowed to delete it.",
                          });
                        } else if (selected.vlbAuthorised == true) {
                          updateState("modalState", {
                            state: true,
                            title: "Access Denied!",
                            message:
                              "This Log has been authorized. You are not allowed to delete it.",
                          });
                        } else if (
                          selected.loggedByEmpIdfk == employeeID &&
                          selected.vlbActive == false
                        ) {
                          updateState("confirmState", {
                            state: true,
                            title: "Undo Delete log ?",
                            message:
                              "This Log has been deleted. Do you want to undo delete ?",
                            okText: "Yes Undo",
                            event: () => {
                              updateState("confirmState", {
                                state: true,
                                title: "Undo Delete log?",
                                okText: "Yes Undo",
                                message:
                                  "Are you sure you want to undo Delete for this log ?",
                                event: () => {
                                  updateState("confirmState", { state: false });
                                  Undo_Deleted_Log();
                                },
                              });
                            },
                          });
                        } else if (
                          selected.loggedByEmpIdfk == employeeID &&
                          selected.vlbActive == true
                        ) {
                          updateState("confirmState", {
                            state: true,
                            title: "Delete log ?",
                            message:
                              "Are you sure you want to delete this log ?",
                            okText: "Yes Delete",
                            event: () => {
                              updateState("confirmState", {
                                state: true,
                                title: "Delete log ?",
                                message:
                                  "Are you really sure you want to continue and delete this log ?",
                                okText: "Yes Delete",
                                event: () => {
                                  updateState("confirmState", { state: false });
                                  Delete_Log();
                                },
                              });
                            },
                          });
                        }
                      }}
                      style={{ borderWidth: "1px", borderColor: borderTheme }}
                      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                    >
                      <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                        {" "}
                        <img className="" src={close} />
                      </span>
                      <Tooltip
                        target="#deleteLog"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                        className="flex flex-col justify-left text-left"
                      >
                        <p className="text-left">Delete Log.</p>
                        <p className="text-left">
                          {" "}
                          This is only possible if you have created
                        </p>
                        <p className="text-left">
                          the log and it has not been authorised
                        </p>
                      </Tooltip>
                    </li>
                    <li
                      id="authoriseLog"
                      onClick={async () => {
                        if (states.vlbIdpk === "") {
                          updateState("modalState", {
                            state: true,
                            title: "No Record.",
                            message:
                              "There are no records to authorise. Please try again later or contact your Systems Administrator for assistance.",
                          });
                        } else {
                          if (selected?.authorisedByEmpIdfk !== employeeID) {
                            updateState("modalState", {
                              state: true,
                              title: "Access Denied",
                              message:
                                "You do not have the privilege to authorise this Log",
                            });
                          }
                          if (selected.vlbActive === false) {
                            updateState("modalState", {
                              state: true,
                              title: "Access Denied",
                              message:
                                "This log has been Deleted. You cannot authorise it ",
                            });
                          }
                          if (
                            states.vlbAuthorised === false &&
                            selected.vlbActive === true &&
                            selected?.authorisedByEmpIdfk === employeeID
                          ) {
                            updateState("confirmState", {
                              state: true,
                              title: "Authorize log?",
                              message:
                                "Are you sure you want to authorize this log ?",
                              cancelText: "No",
                              okText: "Yes",
                              event: async () => {
                                updateState("confirmState", { state: false });
                                await UpdateDataFunc(
                                  `AsmTmsVehicleLogBook/ApproveAsmTmsVehicleLogBook?vlbAuthorisedByEmpIDfk=${
                                    states.authorisedByEmpIdfk
                                  }&vlbAuthorised=${true}&vlbDateAuthorised=${dayjs(
                                    Date.now()
                                  ).format("YYYY-MM-DD")}&vlbIDpk=${
                                    states.vlbIdpk
                                  }`,
                                  {},
                                  // {
                                  //   vlbAuthorisedByEmpIDfk:
                                  //     states.authorisedByEmpIdfk,
                                  //   vlbAuthorised: true,
                                  //   vlbDateAuthorised: dayjs(Date.now()).format(
                                  //     "YYYY-MM-DD"
                                  //   ),
                                  //   vlbIDpk: states.vlbIdpk,
                                  // },
                                  `AsmTmsVehicleLogBook/ApproveAsmTmsVehicleLogBook updated with ID:${
                                    states.vlbIdpk
                                  }, AuthorizedByEmpIdfk:${
                                    states.authorisedByEmpIdfk
                                  }, Authorized :${true} and date Authorized :${dayjs(
                                    Date.now()
                                  ).format("YYYY-MM-DD")} `
                                )
                                  .then(() => {
                                    updateState("successModal", {
                                      state: true,
                                      title: "Success!",
                                      message: "Log authorised successfully.",
                                    });
                                    setRefresh(!refresh);
                                  })
                                  .catch(() => {
                                    updateState("modalState", {
                                      state: true,
                                      title: "Log not authorised.",
                                      message:
                                        "Please Contact Systems Administrator.",
                                    });
                                  });
                              },
                            });
                          } else if (
                            states.vlbAuthorised === true &&
                            selected.vlbActive === true &&
                            selected?.authorisedByEmpIdfk === employeeID
                          ) {
                            AuthorizeLog();
                          }
                        }
                      }}
                      style={{ borderWidth: "1px", borderColor: borderTheme }}
                      className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1 py-1   text-gray-600 rounded flex items-center"
                    >
                      <span className="flex justify-center items-center  dark:bg-slate-900 dark:text-darkModeSkin-base duration-100">
                        {" "}
                        <img className="" src={correct} />
                      </span>
                      <Tooltip
                        target="#authoriseLog"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        hideOnOutsideClick={true}
                      >
                        <p className="text-left">Authorise Log.</p>
                        <p className="text-left">
                          This is only possible if you have been assigned to
                        </p>
                        <p className="text-left">
                          undertake this task by the person who created the log.
                        </p>
                      </Tooltip>
                    </li>
                  </>
                }
              />
            </div>
          </div>
          <ModalTemplate
            disableCancel={true}
            icon="warning"
            title={states.modalState.title}
            okText={""}
            message={states.modalState.message}
            open={states.modalState.state}
            okHandler={() => {
              updateState("modalState", { state: false });
            }}
            cancelHandler={() => {
              updateState("modalState", { state: false });
            }}
          />
          <ModalTemplate
            icon="success"
            disableCancel={true}
            title={states.successModal.title}
            okText={""}
            message={states.successModal.message}
            open={states.successModal.state}
            okHandler={() => {
              updateState("successModal", { state: false });
            }}
            cancelHandler={() => {
              updateState("successModal", { state: false });
            }}
          />

          <ModalTemplate
            icon="question"
            title={states.confirmState.title}
            okText={states.confirmState.okText}
            message={states.confirmState.message}
            open={states.confirmState.state}
            cancelText={states.confirmState.cancelText}
            okHandler={states.confirmState.event}
            cancelHandler={() => {
              updateState("confirmState", { state: false });
            }}
          />
          {authorizedBy && (
            <div>
              {toggled && (
                <div
                  style={{ borderColor: borderTheme }}
                  className="w-full   border-[1px]   rounded-t-md "
                >
                  <div
                    id={"form_header"}
                    style={{ borderColor: borderTheme }}
                    className="border-b-[1px] font-medium text-xs bg-slate-100 rounded-t-md py-1 px-2"
                  >
                    Vehicle Log Book Details
                  </div>
                  {/* The form */}
                  <div className="py-2 flex ">
                    <Form
                      name="complex-form"
                      labelCol={{ span: 7 }}
                      wrapperCol={{ span: 24 }}
                      size={"small"}
                      className="ml-2 flex flex-col gap-0.5"
                      style={{ width: "500px" }}
                    >
                      {DataGridSelectBox.map(
                        ({
                          id,
                          label,
                          labelCol,
                          width,
                          columnData,
                          defaultValue,
                          gridData,
                          stateName,
                          stateField_id,
                          statefield_name,
                          refreshState,
                        }) => {
                          return (
                            <>
                              <DropdownGrid_template
                                KeyExpr={stateField_id}
                                label={label}
                                ref={vehicleRef}
                                cusBtnStyle={true}
                                dropdownBtnStyles={{
                                  width: width,
                                  overflow: "hidden",
                                  marginVertical: 0,
                                }}
                                labelCol={labelCol}
                                columns={columnData}
                                disabled={
                                  states.deactivateFields === true
                                    ? true
                                    : false
                                }
                                gridData={gridData}
                                defaultValue={defaultValue}
                                isHide={false}
                                handleRefresh={refreshState}
                                onRowClick={(e) => {
                                  updateState(stateName!, {
                                    id: e[stateField_id!],
                                    name: e[statefield_name!],
                                  });
                                  // close();
                                  // setishide(true)
                                }}
                              />
                            </>
                          );
                        }
                      )}
                      <DateTemplate
                        width={"220px"}
                        label="Departure Time"
                        changeDate={(e: any) => {
                          updateState("selectedDepartureDate", e);
                        }}
                        selectedDate={dayjs(states.selectedDepartureDate)}
                        disabled={states.deactivateFields}
                        format={dateFormat}
                        span={false}
                      />

                      <DateTemplate
                        width={"220px"}
                        label="Arrival Time"
                        changeDate={(e: any) => {
                          updateState("selectedArrivalDate", e);
                        }}
                        selectedDate={dayjs(states.selectedArrivalDate)}
                        disabled={states.deactivateFields}
                        format={dateFormat}
                        span={false}
                      />

                      <InputsTemplate
                        id="startOdometer"
                        numberOnly={false}
                        cusWidth={true}
                        labelColPan={7}
                        // style={{width:100}}
                        inputStyle={{ height: 24, maxWidth: "90px" }}
                        defaultValue={states.startOdometer}
                        span={true}
                        useCallbackFunc
                        orderOnchange={(value) => {
                          updateState("startOdometer", value);
                          updateState("endOdometer", value);
                          updateState("startOdometer", value);
                          updateState("endOdometer", value);
                        }}
                        label={"Start Odometer"}
                        additionalWidget={
                          <span
                            onClick={() => {}}
                            style={{
                              height: 22.5,
                              width: 24,
                              position: "absolute",
                              marginTop: "-5px",
                              marginLeft: "12px",
                            }}
                            className="ml-2 w-full text-center hover:cursor-pointer text-blue-500 text-xs font-medium "
                          >
                            {startAndFinalOdometerDiff}km
                          </span>
                        }
                      />

                      {/* <div className="mb-0.5"></div> */}
                      <InputsTemplate
                        disabledStatus={true}
                        id="finalOdometer"
                        // numberOnly
                        labelColPan={7}
                        inputStyle={{ height: 24, maxWidth: "90px" }}
                        defaultValue={states.endOdometer}
                        span={true}
                        useCallbackFunc
                        // orderOnchange={(value) => {
                        //   updateState("endOdometer", Number(value).toFixed(2));
                        // }}
                        label={"Final Odometer"}
                        additionalWidget={
                          <button
                            onClick={() => {
                              if (selectedRecord?.vlbAuthorised) {
                                updateState("modalState", {
                                  state: true,
                                  title: "Access Denied!",
                                  message:
                                    "This log has been authorized. You cannot edit it.",
                                });
                                dispatch(
                                  setGeneralValue({
                                    expr: "formDisabled",
                                    value: true,
                                  })
                                );

                                //enable Update Mode
                                dispatch(
                                  setGeneralValue({
                                    expr: "updateMode",
                                    value: false,
                                  })
                                );
                              } else {
                                setOpenOdometerReadingModal(true);
                              }
                            }}
                            disabled={false}
                            style={{
                              height: 22.5,
                              // marginBottom: 2,
                              borderWidth: 1,
                              width: "78px",
                              maxWidth: "78px",
                              marginTop: "-11px",
                              borderColor: borderTheme,
                            }}
                            id="updateButton"
                            className={`${
                              states.deactivateFields
                                ? "bg-gray-100 cursor-not-allowed"
                                : ""
                            } ml-1 px-1 flex justify-center cursor-pointer border-gray-300 rounded absolute items-center `}
                          >
                            <img
                              style={{ width: "calc(100%-69%)" }}
                              id="updateButton"
                              className="  hover:bg-blue-50 h-full flex p-0.5 items-center justify-center"
                              src={save}
                              alt="reset"
                            />
                            <span
                              id="updateButton"
                              className={`${
                                states.deactivateFields ? "text-gray-400 " : ""
                              } text-xs ml-1`}
                            >
                              {" "}
                              Update
                            </span>
                            <Tooltip
                              target="#updateButton"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              hideOnOutsideClick={true}
                            >
                              <p className="">
                                Click to update final odometer reading
                              </p>
                            </Tooltip>
                          </button>
                        }
                      />

                      {/* <div className="mb-0.5"></div> */}
                      <Form.Item
                        // style={{ marginBottom: "10px" }}
                        label={
                          <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                            {"Start Fuel Level"}
                          </p>
                        }
                      >
                        <div
                          className="flex border-[1px] rounded-md "
                          style={{
                            borderRadius: "4px",
                            width: "259px",
                            maxWidth: "259px",
                            height: "65px",
                            borderColor: borderTheme,
                          }}
                        >
                          <Radio.Group
                            size="small"
                            className="w-full grid grid-cols-2 p-2"
                            disabled={states.deactivateFields}
                            value={states.selectedStartFuelLevel.id}
                            onChange={(e: RadioChangeEvent) => {
                              updateState("selectedStartFuelLevel", {
                                id: e.target.value,
                                name: "clicked",
                              });
                              updateState("selectedEndFuelLevel", {
                                id: e.target.value,
                                name: "clicked",
                              });
                            }}
                          >
                            {fuelLevels.slice(0, 4)?.map((fuelLevel: any) => {
                              return (
                                <Radio
                                  key={fuelLevel.flvIdpk}
                                  value={fuelLevel.flvIdpk}
                                >
                                  {fuelLevel.flvName}
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ marginBottom: "1px" }}
                        label={
                          <p className="dark:bg-fill dark:text-darkModeSkin-base text-xs">
                            {"End Fuel Level"}
                          </p>
                        }
                      >
                        <div
                          className="flex border-[1px] rounded-md   "
                          style={{
                            borderRadius: "4px",
                            width: "259px",
                            maxWidth: "259px",
                            height: "65px",
                            borderColor: borderTheme,
                          }}
                        >
                          <Radio.Group
                            className="w-full grid grid-cols-2 p-2 "
                            disabled={true}
                            value={states.selectedEndFuelLevel.id}
                            // value={states.selectedStartFuelLevel.id}
                            onChange={(e) => {
                              updateState("selectedEndFuelLevel", {
                                id: e.target.value,
                                name: "clicked",
                              });
                            }}
                          >
                            {fuelLevels.slice(0, 4)?.map((fuelLevel: any) => {
                              return (
                                <Radio
                                  key={fuelLevel.flvIdpk}
                                  value={fuelLevel.flvIdpk}
                                >
                                  {fuelLevel.flvName}
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ display: "inline-block", marginBottom: "2px" }}
                        label={<p className="text-xs">{"Remarks"}</p>}
                        className="w-full"
                      >
                        <div className=" items-end">
                          <div
                            className=" flex pr-2  flex-row justify-between"
                            style={{ marginBottom: "1px", width: "970px" }}
                          >
                            <TextArea
                              value={states.remarks}
                              readOnly={states.deactivateFields}
                              onChange={(e) => {
                                updateState("remarks", e.target.value);
                              }}
                              style={{
                                resize: "none",
                                maxHeight: "40px",
                                minHeight: "40px",
                                height: "40px",
                                width: "",
                              }}
                              className=" mr-2 overflow-y-scroll resize-none"
                              rows={3}
                            />
                          </div>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ display: "inline-block", marginBottom: "2px" }}
                        label={<p className="text-xs">{"Active"}</p>}
                        className="w-full mb-1"
                      >
                        <div className=" items-end">
                          <div
                            className=" flex pr-2  flex-row justify-between "
                            style={{ marginBottom: "1px", width: "963px" }}
                          >
                            <div>
                              <li
                                style={{
                                  borderWidth: "1px",
                                  borderColor: borderTheme,
                                }}
                                id="status"
                                className="hover:cursor-pointer mr-1 border-slate-200 text-xs px-1  text-gray-600 rounded flex items-center"
                              >
                                <Checkbox
                                  defaultChecked={states.active}
                                  disabled={states.deactivateFields}
                                  onChange={(e) => {
                                    updateState("active", e.target.checked);
                                  }}
                                />
                              </li>
                            </div>
                            <ul className="flex ">
                              {/* {states.showUpdateButton === true ? ( */}
                              {updateMode === true ? (
                                <UpdateButton
                                  handleUpdate={() => {
                                    AccessPriv_on_UpdateButton();
                                  }}
                                />
                              ) : (
                                <SaveButton
                                  handleSave={() => {
                                    AccessPriv_on_SaveButton();
                                  }}
                                />
                              )}
                              {states.showCancelButton === true ? (
                                <Cancel
                                  useCallbackFunc={true}
                                  cancel_button_toggler={() => {
                                    dispatch(
                                      setGeneralValue({
                                        expr: "formDisabled",
                                        value: true,
                                      })
                                    );
                                    dispatch(
                                      setGeneralValue({
                                        expr: "updateMode",
                                        value: false,
                                      })
                                    );
                                    updateState("deactivateFields", true);
                                    updateState("showCancelButton", true);
                                    // resetFields();
                                  }}
                                />
                              ) : (
                                <NewButton
                                  useCallbackFunc={true}
                                  new_button_toggler={() => {
                                    AccessPriv_on_NewButton();
                                  }}
                                />
                              )}
                            </ul>
                          </div>
                        </div>
                      </Form.Item>
                    </Form>

                    {/* Second */}
                    <Form
                      name="complex-form "
                      labelCol={{ span: 8 }}
                      size={"small"}
                      className="mr-2"
                      style={{ width: "600px" }}
                    >
                      <div className="flex  ">
                        <InputSelect_template
                          labelCol={4}
                          label="From - Country"
                          style={{ width: "50%", marginLeft: "0%" }}
                          options={
                            states.selectedFromCountry?.length > 0
                              ? fromCountryData
                              : lookups.getAllFromCountry
                          }
                          dataexp="fromCtyName"
                          idexpr="fromCtyID"
                          isLoading={states.isLoading}
                          isOpenDropdown={states.isOpenDropdown}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown", false);
                            updateState("selectedFromCountry", {
                              name: e.fromCtyName,
                              id: e.fromCtyID,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedFromCountry", e);
                              updateState("isLoading", true);
                              dispatch(singleEndPoint(e));

                              updateState("isOpenDropdown", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleRefresh={() => {
                            updateState("selectedFromCountry", {
                              name: "",
                              id: 0,
                            });
                          }}
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown",
                              !states.isOpenDropdown
                            );
                            dispatch(singleEndPoint(""));
                          }}
                          placeHolder={states.selectedFromCountry.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                        <InputSelect_template
                          label="To - Country"
                          style={{ width: "50%", paddingRight: "0%" }}
                          options={
                            states.selectedToCountry?.length > 0
                              ? toCountryData
                              : lookups.getAllToCountry
                          }
                          dataexp="toCtyName"
                          idexpr="toCtyID"
                          isLoading={states.isLoading1}
                          isOpenDropdown={states.isOpenDropdown1}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown1", false);
                            updateState("selectedToCountry", {
                              name: e.toCtyName,
                              id: e.toCtyID,
                            });
                          }}
                          handleRefresh={() => {
                            updateState("selectedToCountry", {
                              name: "",
                              id: 0,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedToCountry", e);
                              updateState("isLoading1", true);
                              dispatch(singleEndPoint1(e));

                              updateState("isOpenDropdown1", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown1",
                              !states.isOpenDropdown1
                            );
                            dispatch(singleEndPoint1(""));
                          }}
                          placeHolder={states.selectedToCountry.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                      </div>

                      <div className="flex">
                        <InputSelect_template
                          labelCol={4}
                          label="From - Region"
                          style={{ width: "50%", marginLeft: "0%" }}
                          options={
                            states.selectedFromRegion?.length > 0
                              ? fromRegionData
                              : from_region[0]
                          }
                          dataexp="fromRgnName"
                          idexpr="fromRgnID"
                          isLoading={states.isLoading2}
                          isOpenDropdown={states.isOpenDropdown2}
                          handleRefresh={() => {
                            updateState("selectedFromRegion", {
                              name: "",
                              id: 0,
                            });
                          }}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown2", false);
                            updateState("selectedFromRegion", {
                              name: e.fromRgnName,
                              id: e.fromRgnID,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedToCountry", e);
                              updateState("isLoading2", true);
                              dispatch(
                                singleEndPoint2(
                                  e,
                                  states.selectedFromCountry.id
                                )
                              );

                              updateState("isOpenDropdown2", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown2",
                              !states.isOpenDropdown2
                            );
                            dispatch(
                              singleEndPoint2("", states.selectedFromCountry)
                            );
                          }}
                          placeHolder={states.selectedFromRegion.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                        <InputSelect_template
                          label="To - Region"
                          style={{ width: "50%", paddingRight: "0%" }}
                          options={
                            states.selectedToRegion?.length > 0
                              ? toRegionData
                              : to_region[0]
                          }
                          dataexp="toRgnName"
                          idexpr="toRgnID"
                          isLoading={states.isLoading3}
                          isOpenDropdown={states.isOpenDropdown3}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown3", false);
                            updateState("selectedToRegion", {
                              name: e.toRgnName,
                              id: e.toRgnID,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedToRegion", e);
                              updateState("isLoading3", true);
                              dispatch(
                                singleEndPoint3(e, states.selectedToCountry.id)
                              );

                              updateState("isOpenDropdown3", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleRefresh={() => {
                            updateState("selectedToRegion", {
                              name: "",
                              id: 0,
                            });
                          }}
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown3",
                              !states.isOpenDropdown3
                            );
                            dispatch(
                              singleEndPoint3("", states.selectedToCountry.id)
                            );
                          }}
                          placeHolder={states.selectedToRegion.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                      </div>

                      <div className="flex">
                        <InputSelect_template
                          labelCol={4}
                          label="From - Location"
                          style={{ width: "50%", marginLeft: "0%" }}
                          options={
                            states.selectedFromLocation?.length > 0
                              ? fromLocationData
                              : from_location[0]
                          }
                          dataexp="vrqFromTown"
                          idexpr="vrqIdpk"
                          isLoading={states.isLoading4}
                          isOpenDropdown={states.isOpenDropdown4}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown4", false);
                            updateState("selectedFromLocation", {
                              name: e.vrqFromTown,
                              id: e.vrqFromTown,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedFromLocation", e);
                              updateState("isLoading4", true);
                              dispatch(singleEndPoint4(e, states));

                              updateState("isOpenDropdown4", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleRefresh={() => {
                            updateState("selectedFromLocation", {
                              name: "",
                              id: 0,
                            });
                          }}
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown4",
                              !states.isOpenDropdown4
                            );
                            dispatch(singleEndPoint4("", states));
                          }}
                          placeHolder={states.selectedFromLocation.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                        <InputSelect_template
                          label="To - Location"
                          style={{ width: "50%", paddingRight: "0%" }}
                          options={
                            states.selectedToLocation?.length > 0
                              ? toLocationData
                              : to_location[0]
                          }
                          dataexp="vrqToTown"
                          idexpr="vrqIdpk"
                          isLoading={states.isLoading5}
                          isOpenDropdown={states.isOpenDropdown5}
                          selectedValue={(e) => {
                            updateState("isOpenDropdown5", false);
                            updateState("selectedToLocation", {
                              name: e.vrqToTown,
                              id: e.vrqIdpk,
                            });
                          }}
                          handleSearch={
                            debounce((e) => {
                              updateState("selectedToLocation", e);
                              updateState("isLoading5", true);
                              dispatch(singleEndPoint5(e, states));
                              updateState("isOpenDropdown5", true);
                            }, 500) // Adjust the debounce delay as needed
                          }
                          handleRefresh={() => {
                            updateState("selectedToLocation", {
                              name: "",
                              id: 0,
                            });
                          }}
                          handleFocus={() => {
                            updateState(
                              "isOpenDropdown5",
                              !states.isOpenDropdown5
                            );
                            dispatch(singleEndPoint5("", states));
                          }}
                          placeHolder={states.selectedToLocation.name}
                          useCallFunc={true}
                          disabled={states.deactivateFields}
                        />
                      </div>

                      <TextareaTemplate
                        height={221}
                        labelCol={4}
                        label={"Purpose"}
                        defaultValue={states.purpose}
                        readonly={states.deactivateFields}
                        useCallbackFunc={true}
                        setCallbackValue={(e) => {
                          updateState("purpose", e);
                        }}
                      />
                      {/* <div className="flex mr-5 ml-7" style={{marginLeft:"12%"}}>
                              <div className="mr-1">Purpose:</div>
                              <TextArea
                                value={states.purpose}
                                readOnly={states.deactivateFields}
                                onChange={(e) => {
                                  updateState("purpose", e.target.value);
                                }}
                                style={{
                                  resize: "none",
                                  // maxHeight: "40px",
                                  // minHeight: "40px",
                                  height: "115px",
                                  // width: "1100px",
                                  marginLeft:'0.6%'
                                }}
                                className="o w-full mr-2 overflow-y-scroll resize-none"
                                rows={3}
                              />
                       </div> */}

                      {/* <Form.Item
                      label={<p className="text-xs">{"Purpose"}</p>}
                      style={{ marginBottom: 0, marginTop: "" }}

                      labelCol={{ span: 4 }}
                    >

                      <TextArea
                        value={states.purpose}
                        readOnly={states.deactivateFields}
                        onChange={(e) => {
                          updateState("purpose", e.target.value);
                        }
                        }
                        style={{
                          resize: "none",

                          height: "210px",

                        }}
                        className="o mr-2 overflow-y-scroll bg-slate- resize-none"
                        rows={3}
                      />
                    </Form.Item> */}
                    </Form>

                    {/* Third */}

                    {/* <Form
                    name="complex-form"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 20 }}
                    size={"small"}
                    className="ml-2 "
                    style={{ width: "25%" }}
                  >
                  </Form> */}
                  </div>
                </div>
              )}

              <div className="pt-0.5">
                <Datagrid_template
                  data={defaultGridData}
                  gridheight={toggled === true ? pageHeight : tableHeight}
                  columns={vehicle_log_book_TH}
                  pageNumber={paging.pageNumber}
                  pageSize={paging.pageSize}
                  handlePageSize={(e: number) => {
                    setPaging((prev) => ({ ...prev, pageSize: e }));
                  }}
                  handlePageNumber={(e: number) => {
                    setPaging((prev) => ({ ...prev, pageNumber: e }));
                  }}
                  onRowClick={(selectedData: any) => {
                    updateState("deactivateFields", true);
                    rowClicked(selectedData);
                    setSelectedRecord(selectedData);
                    dispatch(
                      setGeneralValue({ expr: "updateMode", value: false })
                    );
                    dispatch(
                      setGeneralValue({
                        expr: "selectedFieldData",
                        value: selectedData,
                      })
                    );
                  }}
                  rowDoubleClicked={(selectedData: any) => {
                    setSelectedRecord(selectedData);
                    AccessPriv_on_DataGridDoubleClick(selectedData);
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default VehicleLogDetails;
